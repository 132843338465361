import React, { useEffect, useState } from "react";
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonInput,
  IonItem,
  IonItemGroup,
  IonLabel,
  IonSpinner,
} from "@ionic/react";
import { useAppDispatch } from "../../../../../store/store";
import { signIn } from "../../../../../store/actions/user.actions";
import "./Login.css";
import { t } from "i18next";

const Login = () => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [isProccessing, setIsProccessing] = useState<boolean>(false);
  const timeoutRef = React.useRef<any>();

  const call = useAppDispatch();
  useEffect(() => () => {
    clearTimeout(timeoutRef.current);
  });
  const handleSignIn = () => {
    setIsProccessing(true);
    call(signIn({ email, password }));
    timeoutRef.current = setTimeout(() => {
      setIsProccessing(false);
    }, 3000);
  };

  return (
    <IonCard className="login-card">
      <IonCardHeader>
        <IonCardTitle>{t("Sign In")}</IonCardTitle>
      </IonCardHeader>
      <IonCardContent>
        <IonItemGroup>
          <IonItem>
            <IonLabel position="floating">Email*</IonLabel>
            <IonInput
              type="email"
              value={email}
              onIonChange={({ detail }) =>
                setEmail(detail.value?.toLowerCase() || "")
              }
            />
          </IonItem>

          <IonItem>
            <IonLabel position="floating"> Password*</IonLabel>
            <IonInput
              type="password"
              onIonChange={({ detail }) => setPassword(detail.value || "")}
            />
          </IonItem>
        </IonItemGroup>
        <IonButton
          expand="block"
          color="dark"
          onClick={handleSignIn}
          disabled={!email.length || password.length < 8}
        >
          {isProccessing ? (
            <IonSpinner color="light" name="crescent" />
          ) : (
            t("Sign In")
          )}
        </IonButton>
      </IonCardContent>
    </IonCard>
  );
};

export default Login;
