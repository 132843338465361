import React from "react";
import { IonChip, IonIcon, IonItem, IonLabel } from "@ionic/react";
import { checkmarkCircleOutline } from "ionicons/icons";
import { Business, Profile } from "../../../../../types/DBTypes";
import "./ItemOutlet.css";
import Avatar from "../../../../common/Avatar/Avatar";
import { t } from "i18next";

interface IProps {
  outlet?: Business;
  owner: Profile;
}

const ItemOutlet = ({ outlet, owner }: IProps) => {
  const { picture, slug } = owner;
  const {
    verified = false,
    name = t("Not registered yet"),
    details,
  } = outlet || {};

  return (
    <IonItem routerLink={`/outlet/${slug}`} detail={false}>
      <Avatar url={picture} slot="start" />
      <IonLabel>
        <h3 className="employee-name">
          {name}
          {verified && (
            <IonIcon
              icon={checkmarkCircleOutline}
              size="small"
              color="success"
            />
          )}
        </h3>
        {/*<p>{description}</p>*/}
      </IonLabel>
      {!!details?.location?.region?.length && (
        <IonChip slot="end">{details?.location?.region}</IonChip>
      )}
    </IonItem>
  );
};

export default ItemOutlet;
