import { IonAvatar, IonChip, IonItem, IonLabel } from "@ionic/react";
import React from "react";
import Skeleton from "../../../../common/Skeleton/Skeleton";

interface ItemOutletSkeletonProps {}

const ItemOutletSkeleton: React.FC<ItemOutletSkeletonProps> = ({}) => {
  return (
    <IonItem>
      <IonAvatar slot="start">
        <Skeleton />
      </IonAvatar>
      <IonLabel>
        <Skeleton width="10ch" />
        <Skeleton width="12ch" />
      </IonLabel>
      <IonChip slot="end">
        <Skeleton width="10ch" />
      </IonChip>
    </IonItem>
  );
};

export default ItemOutletSkeleton;
