import React, { useContext } from "react";
import { IonInput, IonItem, IonItemGroup } from "@ionic/react";
import { FieldContext } from "./EnterpriseUserCreate";
import { t } from "i18next";

const Step2 = () => {
  const context = useContext(FieldContext);
  if (!context) return null;
  const { fields, setField, setTitle } = context;
  setTitle(t("Company address"));
  return (
    <>
      <IonItem>
        <IonInput
          label={t("Address line 1")}
          value={fields.address.address}
          onIonChange={setField("address.address")}
          labelPlacement="floating"
        />
      </IonItem>
      <IonItem>
        <IonInput
          label={t("Address line 2")}
          value={fields.address.address2}
          onIonChange={setField("address.address2")}
          labelPlacement="floating"
        />
      </IonItem>
      <IonItem>
        <IonInput
          label={t("City")}
          value={fields.address.city}
          onIonChange={setField("address.city")}
          labelPlacement="floating"
        />
      </IonItem>
      <IonItem>
        <IonInput
          label={t("Region")}
          value={fields.address.region}
          onIonChange={setField("address.region")}
          labelPlacement="floating"
        />
      </IonItem>
      <IonItemGroup>
        <IonItem>
          <IonInput
            label={t("Postal code")}
            value={fields.address.postcode}
            onIonChange={setField("address.postcode")}
            labelPlacement="floating"
          />
        </IonItem>
        <IonItem>
          <IonInput
            label={t("Country")}
            value={fields.address.country}
            onIonChange={setField("address.country")}
            labelPlacement="floating"
            disabled
          />
        </IonItem>
      </IonItemGroup>
    </>
  );
};

export default Step2;
