import { Profile } from "../../../../../types/DBTypes";
import { useState } from "react";
import { IonList } from "@ionic/react";
import ItemIndividual from "../ItemIndividual/ItemIndividual";
import ItemIndividualSkeleton from "../ItemIndividual/ItemIndividualSkeleton";
import IndividualModal from "../IndividualModal/IndividualModal";

interface IProps {
  data?: Profile[];
}

const IndividualsList = ({ data }: IProps) => {
  const [profile, setProfile] = useState<Profile | undefined>(undefined);

  return (
    <IonList>
      {!!data?.length
        ? data
            .sort((a, b) => b?.createdAt?.localeCompare(a?.createdAt))
            .map((user) => (
              <ItemIndividual key={user.id} user={user} onClick={setProfile} />
            ))
        : [
            <ItemIndividualSkeleton />,
            <ItemIndividualSkeleton />,
            <ItemIndividualSkeleton />,
            <ItemIndividualSkeleton />,
            <ItemIndividualSkeleton />,
            <ItemIndividualSkeleton />,
          ]}
      <IndividualModal user={profile} setUser={setProfile} />
    </IonList>
  );
};

export default IndividualsList;
