import React from "react";
import { IonButton, IonContent, IonIcon, IonItem, IonText } from "@ionic/react";

import { cardOutline, close } from "ionicons/icons";
import "./BankCardModal.css";
import PaymentForm from "../PaymentForm/PaymentForm";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { t } from "i18next";

const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLIC_KEY as string
);

const PRIVACY_POLICY_URL = "https://tipinc.tech/privacy-policy";
const TERMS_OF_CONDITIONS_URL = "https://tipinc.tech/terms-and-conditions";

interface IProps {
  onDismiss: (data?: string | null | undefined | number, role?: string) => void;
  clientSecret: string;
  totalAmount: number;
  paymentIntent: string;
  slug: string;
}

const BankCardModal = ({
  onDismiss,
  clientSecret,
  totalAmount,
  slug,
  paymentIntent,
}: IProps) => {
  return (
    <IonContent className="ion-padding bank-card-modal">
      <IonItem className="card-modal-header" lines="none">
        <div>
          <IonIcon slot="start" icon={cardOutline} />
          <span>Pay</span>
        </div>
        <IonButton
          className="close-button"
          onClick={() => onDismiss(null, "cancel")}
          slot={"end"}
        >
          <IonIcon
            icon={close}
            className={"close-icon"}
            color="medium"
            slot="icon-only"
          />
        </IonButton>
      </IonItem>
      {clientSecret && (
        <Elements
          stripe={stripePromise}
          options={{ clientSecret, appearance: { theme: "flat" } }}
        >
          <PaymentForm
            successUrl={`${window.location.protocol}//${process.env.REACT_APP_TIPPING_URL}/${slug}/result`}
            totalAmount={totalAmount}
          />
        </Elements>
      )}

      <IonText className="disclaimer">
        <p>
          {t("Customer Terms and Privacy Policy", {
            termsUrl: TERMS_OF_CONDITIONS_URL,
            privacyUrl: PRIVACY_POLICY_URL,
          })}
        </p>
      </IonText>
    </IonContent>
  );
};

export default BankCardModal;
