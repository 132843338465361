import Header from "../../../../common/Header/Header";
import {
  IonBadge,
  IonButton,
  IonCheckbox,
  IonItemGroup,
  IonList,
  useIonLoading,
} from "@ionic/react";
import TransferEmployee from "../TransferEmployee/TransferEmployee";
import React, { useState } from "react";
import { EmployeeTransfer } from "../../../../../types/DBTypes";
import useConfirmationModal from "../../../../../hooks/useConfirmationModal";
import { repeatEmployeeTransferBatch } from "../../../../../store/actions/splits.actions";
import { useAppDispatch } from "../../../../../store/store";
import { t } from "i18next";

interface IProps {
  transfers: EmployeeTransfer[];
}

const TransfersToEmployeeSection = ({ transfers }: IProps) => {
  const [transfersToRepeat, setTransfersToRepeat] = useState<string[]>([]);
  const [showLoading] = useIonLoading();

  const call = useAppDispatch();
  const handleRepeatTransfers = useConfirmationModal(() => {
    showLoading({
      message: "Repeating...",
      duration: transfersToRepeat.length * 1500,
    });
    call(repeatEmployeeTransferBatch({ ids: transfersToRepeat }));
    setTransfersToRepeat([]);
  }, "Would you like to repeat transfers for selected items?");

  const handleCheckboxChange = (id: string) => {
    if (transfersToRepeat?.indexOf(id) === -1) {
      setTransfersToRepeat([...transfersToRepeat, id]);
      return;
    }

    setTransfersToRepeat(transfersToRepeat.filter((item) => item !== id));
  };

  const handleSelectAll = () => {
    if (selectedAll) return setTransfersToRepeat([]);
    setTransfersToRepeat(failedTransfersId);
  };

  const failedTransfersId = transfers
    .filter(({ status }) => status === "fail")
    .map(({ id }) => id);
  const selectedAll = transfersToRepeat.length === failedTransfersId.length;

  return (
    <>
      <Header title={t("Transfers to employees")}>
        <IonButton fill="outline" onClick={handleSelectAll}>
          {selectedAll ? "Unselect all" : "Select all"}
        </IonButton>
      </Header>
      <IonList inset>
        {transfers?.map(
          (transfer) =>
            transfer && (
              <IonItemGroup key={transfer.id} className="employee-controller">
                <TransferEmployee transfer={transfer} />
                {transfer.status === "fail" && (
                  <IonCheckbox
                    checked={transfersToRepeat.some((id) => transfer.id === id)}
                    onIonChange={() => handleCheckboxChange(transfer.id)}
                  />
                )}
              </IonItemGroup>
            )
        )}
      </IonList>
      {transfersToRepeat.length > 0 && (
        <IonButton onClick={handleRepeatTransfers} expand="full" color="dark">
          Repeat transfers
          <IonBadge className="ion-margin-start" color="light">
            {transfersToRepeat.length}
          </IonBadge>
        </IonButton>
      )}
    </>
  );
};
export default TransfersToEmployeeSection;
