import React, { useEffect, useState } from "react";
import {
  IonButtons,
  IonChip,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonImg,
  IonPage,
  IonRow,
  IonText,
} from "@ionic/react";
import {
  businessSharp,
  callOutline,
  callSharp,
  ellipsisHorizontal,
  globeOutline,
  locationOutline,
  mailOpenSharp,
  personOutline,
} from "ionicons/icons";
import "./OutletProfile.css";
import Header from "../../../../common/Header/Header";
import IconList from "../../../../common/IconList/IconList";
import { useHistory, useParams } from "react-router";
import { useSelector } from "react-redux";
import { getOutletBySlug } from "../../../../../store/selectors/outlets.selectors";
import { TRootState, useAppDispatch } from "../../../../../store/store";
import { BusinessEmployee, Profile } from "../../../../../types/DBTypes";
import TabPageHeader from "../../../../layout/TabPageHeader/TabPageHeader";
import VerifiedIcon from "../../../../common/VerifiedIcon/VerifiedIcon";
import EmployeesList from "../../components/EmployeesList/EmployeesList";
import OutletPopoverController from "../../components/OutletPopoverController/OutletPopoverController";
import SolutionsController from "../../components/SolutionsController/SolutionsController";
import { handleImageUrl } from "../../../../../utils";
import { Business } from "../../../../../types/types";
import ContactDetails from "../../../../common/ContactDetails/ContactDetails";
import handleAddress from "../../../../../utils/handleAddress";
import avatarDefault from "../../../../../assets/images/avatar-default.svg";
import StatisticsRow from "../../components/StatisticsRow/StatisticsRow";
import { getOutletBySlug as getOutletBySlugAction } from "../../../../../store/actions/outlets.actions";
import { t } from "i18next";

const OutletProfilePage = () => {
  const { slug } = useParams<{ slug: string }>();
  const profile = useSelector((state: TRootState) =>
    getOutletBySlug(state)(slug)
  );

  const [ownerProfile, setOwnerProfile] = useState<Profile>();

  const history = useHistory();
  const call = useAppDispatch();
  useEffect(() => {
    if (profile) setOwnerProfile(profile as Profile);
    else call(getOutletBySlugAction({ slug }));
  }, [history, profile]);

  if (!ownerProfile) return null;

  const {
    firstName,
    lastName,
    business,
    picture,
    // location,
    details: ownerDetails,
  } = ownerProfile;
  const { name, verified, details, employees, answers } = business || {};
  const { website, email, phone, jobRole, contactName, location } =
    details || {};
  const title = (
    <>
      {name} <IonChip color="secondary">@{slug}</IonChip>
      <VerifiedIcon verified={verified} />
    </>
  );

  const contactArr = [
    { icon: globeOutline, label: website, value: name },
    { icon: personOutline, label: jobRole, value: contactName },
    {
      icon: callOutline,
      label: t("Contact details"),
      value: [email, phone].filter(Boolean).join("\n"),
    },
    {
      icon: locationOutline,
      label: t("Address"),
      value: location ? handleAddress(location) : null,
    },
  ].filter((item) => item.label && item.value);

  return (
    <IonPage className="page--outlet-profile">
      <TabPageHeader defaultBackHref="/outlet" title={title}>
        <IonButtons slot="end" id="profile-popover">
          <IonChip color="secondary">
            <IonIcon icon={ellipsisHorizontal} />
          </IonChip>
        </IonButtons>
      </TabPageHeader>

      <IonContent>
        <IonGrid>
          <IonRow>
            <IonCol sizeXl="9" sizeLg="7" sizeSm="12">
              <StatisticsRow id={business?.id} />
              <IonRow>
                <IonCol>
                  <EmployeesList
                    data={employees?.items as BusinessEmployee[]}
                  />
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <div className="section-inset">
                    <Header title={t("Owner")} />
                    <IconList
                      data={[
                        {
                          icon: businessSharp,
                          text: `${firstName} ${lastName}`,
                        },
                        { icon: callSharp, text: ownerDetails?.phone_number },
                        { icon: mailOpenSharp, text: ownerDetails?.email },
                      ]}
                    />
                  </div>
                </IonCol>
                <IonCol>
                  <div className="section-inset">
                    <Header title={t("Solutions")} />
                    <SolutionsController
                      outlet={profile?.business as Business}
                    />
                  </div>
                </IonCol>
              </IonRow>
            </IonCol>

            <IonCol sizeXl="3" sizeLg="5" sizeSm="12">
              <IonImg
                className="venue-item-picture"
                src={picture ? handleImageUrl(picture) : avatarDefault}
              />
              {/*@ts-ignore*/}
              {contactArr?.length > 0 && <ContactDetails data={contactArr} />}

              <div className="info-line ion-padding">
                <IonText color="dark">
                  <p>{t("Number of employees")}</p>
                </IonText>
                <IonText color="dark">
                  <b>{employees?.items?.length} {t("employees")}</b>
                </IonText>
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>

      {profile && business && (
        <OutletPopoverController profile={profile} business={business} />
      )}
    </IonPage>
  );
};

export default OutletProfilePage;
