import React from "react";
import { IonChip, IonIcon, IonItem, IonLabel } from "@ionic/react";
import { checkmarkCircleOutline } from "ionicons/icons";
import "./ItemIndividual.css";
import { Profile } from "../../../../../types/DBTypes";
import Avatar from "../../../../common/Avatar/Avatar";
import { t } from "i18next";

interface User extends Profile {
  jobRole?: string;
  verified?: boolean;
  stripeAcc?: boolean;
}

interface IProps {
  user: User;
  onClick: (user: Profile) => void;
}

const ItemIndividuals = ({ user, onClick }: IProps) => {
  const {
    firstName,
    lastName,
    details,
    picture,
    id,
    jobRole,
    verified,
    stripeAcc = details?.tippingAccount?.verified,
  } = user;

  const handleClick = () => {
    onClick(user);
  };
  return (
    <IonItem onClick={handleClick} button>
      <Avatar url={picture} slot="start" />
      <IonLabel>
        <h3 className="employee-name">
          {firstName} {lastName}
          {verified && (
            <IonIcon
              icon={checkmarkCircleOutline}
              size="small"
              color="success"
            ></IonIcon>
          )}
        </h3>
        {jobRole && <p>{jobRole}</p>}
      </IonLabel>

      {!stripeAcc ? (
        <IonChip color="danger">{t("No Stripe Account")}</IonChip>
      ) : (
        details?.feedRegion && (
          <IonChip slot="end">{details?.feedRegion}</IonChip>
        )
      )}
    </IonItem>
  );
};

export default ItemIndividuals;
