import React from "react";
import { IonCheckbox, IonItem, IonLabel, IonText } from "@ionic/react";
import { TipTransaction } from "../../../../../types/DBTypes";
import { t } from "i18next";

interface TProps {
  transfer: TipTransaction;
}

const TipItem = ({ transfer }: TProps) => {
  return (
    <IonItem>
      <IonLabel>{t("{{datetime}}", { datetime: transfer.createdAt })}</IonLabel>
      <IonText>
        <b>{t("{{amount}}", { amount: +transfer.amount })}</b>
      </IonText>
    </IonItem>
  );
};

export default TipItem;
