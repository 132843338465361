import React, { createContext, useState } from "react";
import {
  IonCol,
  IonContent,
  IonGrid,
  IonPage,
  IonRow,
  IonText,
} from "@ionic/react";
import Step1 from "./Step1";
import "./EnterpriseUserCreate.css";
import { CreateProfileEnterpriseInput } from "../../../../../types/DBTypes";
import { IonInputCustomEvent } from "@ionic/core/dist/types/components";
import StepByStep from "../../components/StepByStep/StepByStep";
import Step3 from "./Step3";
import Step2 from "./Step2";
import Step4 from "./Step4";
import TabPageHeader from "../../../../layout/TabPageHeader/TabPageHeader";
import { t } from "i18next";

export interface IFields extends CreateProfileEnterpriseInput {
  email: string;
}

export interface IFieldContext {
  title: string;
  setTitle: (title: string) => void;
  fields: IFields;
  setField: (field: string) => (e: IonInputCustomEvent<any>) => void;
}
export const FieldContext = createContext<IFieldContext | null>(null);

interface IProps {
  onDismiss: () => void;
}

const EnterpriseUserCreate = ({ onDismiss }: IProps) => {
  const [fields, setFields] = useState<IFields>({
    name: "",
    active: false,
    email: "",
    address: {
      country: t("Default-Country"),
    },
    contactDetails: {
      name: "",
      role: "",
      phone: "",
      email: "",
    },
  });
  const [title, setTitle] = useState("");

  const setField = (field: string) => {
    return ({ detail }: IonInputCustomEvent<any>) => {
      const [field1, field2] = field.split(".");
      if (!field2) {
        setFields((f) => {
          const fNew = { ...f };
          //@ts-ignore
          fNew[field1] = detail.value;
          return fNew;
        });
        return;
      }

      setFields((f) => {
        const fNew = { ...f };

        // @ts-ignore
        fNew[field1][field2] = detail.value;
        console.log({ f }, { fNew });
        return fNew;
      });
    };
  };

  return (
    <IonPage>
      <FieldContext.Provider value={{ title, setTitle, fields, setField }}>
        <TabPageHeader
          defaultBackHref="/enterprise"
          title={t("Create enterprise user")}
        />
        <IonContent>
          <IonGrid>
            <IonRow>
              <IonCol sizeMd="8" size="12">
                <StepByStep>
                  <Step1 />
                  <Step2 />
                  <Step3 />
                  <Step4 />
                </StepByStep>
              </IonCol>
              <IonCol sizeMd="4" size="12">
                <div className="section-inset">
                  <IonText>
                    <h2>{t("Disclaimer")}</h2>
                  </IonText>
                  <IonText>
                    <p>
                      {t("DisclaimerP1L1")}
                      {t("DisclaimerP1L2")}
                      {t("DisclaimerP1L3")}
                      {t("DisclaimerP1L4")}
                    </p>
                    <p>
                      {t("DisclaimerP2L1")}
                      {t("DisclaimerP2L2")}
                      {t("DisclaimerP2L3")}
                      {t("DisclaimerP2L4")}
                    </p>
                  </IonText>
                </div>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonContent>
      </FieldContext.Provider>
    </IonPage>
  );
};

export default EnterpriseUserCreate;
