import React, { useState } from "react";
import BusinessDropdown from "../../../../common/BusinessDropdown/BusinessDropdown";
import {
  IonAvatar,
  IonButton,
  IonChip,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonLabel,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { Business } from "../../../../../types/DBTypes";
import { handleImageUrl } from "../../../../../utils";
import { closeCircle } from "ionicons/icons";
import { showToast } from "../../../../../store/actions/ui.actions";
import { useAppDispatch } from "../../../../../store/store";
import { API } from "aws-amplify";
import { updateProfileEnterprise } from "../../../../../graphql/mutations";
import { t } from "i18next";

interface IProps {
  id: string;
  onDismiss: () => void;
  businesses?: Business[];
  setBusinesses: (businesses: Business[]) => void;
}

const AddOutletModal = ({
  businesses = [],
  setBusinesses,
  id,
  onDismiss,
}: IProps) => {
  const [outlets, setOutlets] = useState<Business[]>(businesses);

  const call = useAppDispatch();
  const handleSelectOutlet = (outlet: Business | null) => {
    if (!outlet) return;
    setOutlets((outlets) => {
      if (outlets.includes(outlet)) {
        call(showToast({ text: t("Outlet already added") }));
        return outlets;
      } else {
        return outlets.concat(outlet);
      }
    });
  };

  const handleRemoveOutlet = (id: string) => {
    setOutlets((outlets) => outlets.filter((outlet) => outlet.id !== id));
  };

  const handleSaveOutlets = async () => {
    try {
      await API.graphql({
        query: updateProfileEnterprise,
        variables: {
          input: {
            id,
            businesses: outlets.map(({ id }) => id),
          },
        },
      });
      onDismiss();
      setBusinesses(outlets);
      call(showToast({ text: t("Outlets are updated") }));
    } catch (e) {
      console.log(e);
      call(showToast({ text: t("Failed to update outlets") }));
    }
  };
  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonTitle>
            {outlets?.length > 0 ? t("Edit outlets list") : t("Add outlets")}
          </IonTitle>
        </IonToolbar>
        {outlets?.length > 0 && (
          <IonToolbar>
            {outlets.map(({ id, name, ownerProfile }) => (
              <IonChip key={id}>
                {ownerProfile?.picture && (
                  <IonAvatar>
                    <img
                      src={handleImageUrl(ownerProfile?.picture)}
                      alt="outlet logo"
                    />
                  </IonAvatar>
                )}
                <IonLabel>{name}</IonLabel>
                <IonIcon
                  onClick={() => handleRemoveOutlet(id)}
                  icon={closeCircle}
                ></IonIcon>
              </IonChip>
            ))}
          </IonToolbar>
        )}
      </IonHeader>
      <IonContent>
        <BusinessDropdown onSelect={handleSelectOutlet} value={null} />
      </IonContent>
      <IonFooter>
        <IonToolbar>
          <IonButton
            onClick={onDismiss}
            slot="start"
            color="error"
            fill="clear"
          >
            Cancel
          </IonButton>
          <IonButton
            onClick={handleSaveOutlets}
            slot="end"
            color="success"
            fill="clear"
          >
            Save
          </IonButton>
        </IonToolbar>
      </IonFooter>
    </>
  );
};

export default AddOutletModal;
