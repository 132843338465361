import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonIcon,
  IonText,
} from "@ionic/react";
import avatarDefault from "../../../../../assets/images/avatar-default.svg";
import { ForwardedRef, forwardRef } from "react";
import handleImageUrl from "../../../../../utils/handleImageUrl";
import logoOriginal from "../../../../../assets/images/logo-original.svg";
import "./ProfileHeader.css";
import { t } from "i18next";

interface IProps {
  title: string | JSX.Element;
  subtitle: string;
  picture?: string;
  src?: string;
  children?: JSX.Element | JSX.Element[];
  className?: string;
}

const ProfileHeader = forwardRef(
  (
    { title, subtitle, picture, src, children, className = "" }: IProps,
    ref: ForwardedRef<HTMLIonCardElement>
  ) => {
    return (
      <IonCard className={`profile-header-card ${className}`} ref={ref}>
        <IonCardHeader>
          {children}
          <IonText className="powered-by">
            <span>{t("Powered by")}</span> <IonIcon icon={logoOriginal} />
          </IonText>
        </IonCardHeader>
        <img
          alt={title.toString()}
          src={picture ? handleImageUrl(picture, 300) : src || avatarDefault}
        />

        <IonCardContent>
          <IonText>
            <h1>{title}</h1>
          </IonText>
          <IonText>
            <p>{subtitle}</p>
          </IonText>
        </IonCardContent>
      </IonCard>
    );
  }
);

export default ProfileHeader;
