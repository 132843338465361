import { IonList, IonProgressBar } from "@ionic/react";
import React, { useEffect, useState } from "react";
import Checkbox from "../../../../common/Checkbox";
import { useAppDispatch } from "../../../../../store/store";
import useDelayCallback from "../../../../../hooks/useDelayCallback";
import { loadSplits } from "../../../../../store/actions/splits.actions";
import BusinessDropdown from "../../../../common/BusinessDropdown/BusinessDropdown";
import { Business } from "../../../../../types/DBTypes";
import { t } from "i18next";

interface IProps {
  inset?: boolean;
}

const Filter = ({ inset = true }: IProps) => {
  const [success, setSuccess] = useState(false);
  const [failed, setFailed] = useState(true);
  const [owner, setOwner] = useState("");

  const [selectedBusiness, setSelectedBusiness] = useState<Business | null>(
    null
  );

  const call = useAppDispatch();
  const { loading } = useDelayCallback(() => {
    call(loadSplits({ success, failed, owner }));
  }, [success, failed, owner]);

  useEffect(() => {
    if (!failed) setSuccess(true);
  }, [failed]);

  useEffect(() => {
    if (!success) setFailed(true);
  }, [success]);

  useEffect(() => {
    if (selectedBusiness?.owner) setOwner(selectedBusiness.owner);
    else setOwner("");
  }, [selectedBusiness]);

  return (
    <IonList lines="inset" inset={inset}>
      <Checkbox checked={success} setChecked={setSuccess}>
        {t("Success")}
      </Checkbox>

      <Checkbox checked={failed} setChecked={setFailed}>
        {t("Failed")}
      </Checkbox>

      <BusinessDropdown
        value={selectedBusiness}
        onSelect={setSelectedBusiness}
      />

      {loading && <IonProgressBar type="indeterminate" />}
    </IonList>
  );
};

export default Filter;
