import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import TabPageHeader from "../../../../layout/TabPageHeader/TabPageHeader";
import {
  IonButton,
  IonChip,
  IonCol,
  IonContent,
  IonGrid,
  IonList,
  IonPage,
  IonRow,
  useIonModal,
} from "@ionic/react";
import { Business, ProfileEnterprise } from "../../../../../types/DBTypes";
import { API } from "aws-amplify";
import {
  getProfileEnterprise,
  searchBusinesss,
} from "../../../../../graphql/queries";
import FieldItem from "../../components/FieldItem/FieldItem";
import Header from "../../../../common/Header/Header";
import AddOutletModal from "../../components/AddOutletModal/AddOutletModal";
import BusinessItem from "../../../../common/BusinessItem/BusinessItem";
import EmptyState from "../../../../common/EmptyState/EmptyState";
import { t } from "i18next";

const EnterpriseProfile = () => {
  const { id } = useParams<{ id: string }>();
  const [profile, setProfile] = useState<ProfileEnterprise | null>(null);
  const [profileBusinesses, setProfileBusinesses] = useState<Business[]>([]);

  useEffect(() => {
    if (profile?.id === id) return;

    setProfile(null);
    API.graphql<any>({
      query: getProfileEnterprise,
      variables: { id },
    })
      .then(({ data }) => data.getProfileEnterprise)
      .then(handleProfileChange);
  }, [id, profile]);

  const handleProfileChange = (profile: ProfileEnterprise) => {
    setProfile(profile);

    if (!profile) return;
    API.graphql<any>({
      query: searchBusinesss,
      variables: {
        filter: {
          or: profile?.businesses?.map((id) => ({ id: { eq: id } })),
        },
      },
    })
      .then(({ data }) => data.searchBusinesss.items)
      .then(setProfileBusinesses);
  };

  const [showModal, dismissModal] = useIonModal(AddOutletModal, {
    id,
    onDismiss: () => dismissModal(),
    businesses: profileBusinesses,
    setBusinesses: setProfileBusinesses,
  });

  if (!profile) return null;

  const { name, address, active, contactDetails, businesses } = profile;

  return (
    <IonPage className="page--outlet-profile">
      <TabPageHeader defaultBackHref="/enterprise" title={name}>
        <IonChip slot="end" color={active ? "success" : "danger"}>
          {active ? t("Active") : t("Inactive")}
        </IonChip>
      </TabPageHeader>
      <IonContent>
        <IonGrid>
          <IonRow>
            <IonCol sizeMd="6" size="12">
              <div className="section-inset">
                <Header title={t("Address")} />
                <IonList>
                  {Object.entries(address)
                    .filter(([_, value]) => value)
                    .map(([key, value]) => (
                      <FieldItem key={key} title={key}>
                        {value}
                      </FieldItem>
                    ))}
                </IonList>
              </div>
            </IonCol>

            <IonCol sizeMd="6" size="12">
              <div className="section-inset">
                <Header title={t("Contact information")} />

                <IonList>
                  {Object.entries(contactDetails)
                    .filter(([_, value]) => value)
                    .map(([key, value]) => (
                      <FieldItem key={key} title={key}>
                        {value}
                      </FieldItem>
                    ))}
                </IonList>
              </div>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol sizeMd="6" size="12">
              <div className="section-inset">
                <Header title={t("Connected venues")}>
                  <IonButton onClick={() => showModal()}>Edit</IonButton>
                </Header>

                {profileBusinesses?.length > 0 ? (
                  <IonList>
                    {profileBusinesses?.map((item: Business) => (
                      <BusinessItem item={item} key={item.id} />
                    ))}
                  </IonList>
                ) : (
                  <EmptyState
                    onClick={() => showModal()}
                    callToAction={t("Add outlets to this profile")}
                  >
                    {t("No outlets connected")}
                  </EmptyState>
                )}
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default EnterpriseProfile;
