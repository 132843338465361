import React, { useContext } from "react";
import { IonInput, IonItem } from "@ionic/react";
import { FieldContext } from "./EnterpriseUserCreate";
import { t } from "i18next";

const Step1 = () => {
  const context = useContext(FieldContext);
  if (!context) return null;
  const { fields, setField, setTitle } = context;
  setTitle(t("Company details"));
  return (
    <>
      <IonItem>
        <IonInput
          placeholder={t("The full legal name of the enterprise customer (e.g., Nandos UK Limited).")}
          label={t("Company name")}
          labelPlacement="floating"
          onIonChange={setField("name")}
          value={fields.name}
        />
      </IonItem>

      <IonItem>
        <IonInput
          placeholder={t("The registered company number for legal identification.")}
          label={t("Company Number")}
          labelPlacement="floating"
          onIonChange={setField("companyNumber")}
          value={fields.companyNumber}
        />
      </IonItem>
    </>
  );
};

export default Step1;
