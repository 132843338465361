import React from "react";
import {
  IonButton,
  IonChip,
  IonCol,
  IonContent,
  IonIcon,
  IonList,
  IonPage,
  IonPopover,
  IonRow,
  IonSearchbar,
} from "@ionic/react";
import TabHeader from "../../../../layout/TabHeader/TabHeader";
import { connect } from "react-redux";
import { TRootState } from "../../../../../store/store";
import { Business, Profile } from "../../../../../types/DBTypes";
import Header from "../../../../common/Header/Header";
import { IS_MOBILE } from "../../../../../utils";
import { filterOutline } from "ionicons/icons";
import {
  getOutlets,
  getOutletsTotal,
} from "../../../../../store/selectors/outlets.selectors";
import ItemOutlet from "../../components/ItemOutlet/ItemOutlet";
import ItemOutletSkeleton from "../../components/ItemOutlet/ItemOutletSkeleton";
import Filter from "../../components/Filter/Filter";
import "./Outlets.css";
import dataToExcel from "../../../../../utils/DataToExcel";
import useSearch from "../../../../../hooks/useSearch";
import StatisticCard from "../../../../common/StatisticCard/StatisticCard";
import { StatisticsCardLoading } from "../../components/StatisticsRow/StatisticsLoading";
import { t } from "i18next";

interface IProps {
  outlets?: Profile[];
  total?: number;
}
const mapState = (state: TRootState) => ({
  total: getOutletsTotal(state),
  outlets: getOutlets(state),
});

const OutletsPage = ({ outlets, total }: IProps) => {
  const [handleSearch, data] = useSearch<Profile>(outlets as Profile[]);

  return (
    <IonPage>
      <TabHeader title={t("Outlets")}>
        <IonSearchbar
          mode="ios"
          className="searchbar-left-aligned"
          onIonChange={handleSearch}
        />
      </TabHeader>

      <IonContent>
        <IonRow>
          <IonCol size="12">
            <IonRow>
              <IonCol sizeMd="4">
                {total ? (
                  <StatisticCard title={t("Total")} value={total} />
                ) : (
                  <StatisticsCardLoading />
                )}
              </IonCol>
            </IonRow>
          </IonCol>
          <IonCol sizeMd="8" size="12">
            <div className="section-inset">
              <Header title={t("Users")}>
                {IS_MOBILE ? (
                  <IonChip id="outlet-filter-trigger">
                    <IonIcon icon={filterOutline} />
                  </IonChip>
                ) : (
                  <IonButton
                    onClick={() =>
                      dataToExcel(outlets as Profile[], t("Outlets"))
                    }
                  >
                    {t("Export to excel")}
                  </IonButton>
                )}
              </Header>
              <IonList lines="inset">
                {!!data?.length
                  ? data
                      .sort((a, b) => b?.createdAt?.localeCompare(a?.createdAt))
                      .map((user) => (
                        <ItemOutlet
                          key={user.id}
                          outlet={user.business as Business}
                          owner={user}
                        />
                      ))
                  : [
                      <ItemOutletSkeleton />,
                      <ItemOutletSkeleton />,
                      <ItemOutletSkeleton />,
                      <ItemOutletSkeleton />,
                      <ItemOutletSkeleton />,
                      <ItemOutletSkeleton />,
                    ]}
              </IonList>
            </div>
          </IonCol>

          {IS_MOBILE ? (
            <IonPopover
              keepContentsMounted
              trigger="outlet-filter-trigger"
              triggerAction="click"
            >
              <Filter />
            </IonPopover>
          ) : (
            <IonCol sizeMd="4" size="12">
              <div className="section-inset">
                <Header title={t("Filters")} />

                <Filter />
              </div>
            </IonCol>
          )}
        </IonRow>
      </IonContent>
    </IonPage>
  );
};

export default connect(mapState)(OutletsPage);
