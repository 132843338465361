import React, { useContext } from "react";
import { IonInput, IonItem, IonItemGroup } from "@ionic/react";
import { FieldContext } from "./EnterpriseUserCreate";
import { t } from "i18next";

const Step3 = () => {
  const context = useContext(FieldContext);
  if (!context) return null;
  const { fields, setField, setTitle } = context;
  setTitle(t("Company contact details"));
  return (
    <>
      <IonItem>
        <IonInput
          label={t("Name")}
          placeholder={t("The main contact person within the enterprise, typically a manager or representative.")}
          labelPlacement="floating"
          value={fields.contactDetails.name}
          onIonInput={setField("contactDetails.name")}
        />
      </IonItem>
      <IonItem>
        <IonInput
          label={t("Job role")}
          placeholder={t("The main contact persons job role within the organisation.")}
          labelPlacement="floating"
          value={fields.contactDetails.role}
          onIonInput={setField("contactDetails.role")}
        />
      </IonItem>
      <IonItemGroup>
        <IonItem>
          <IonInput
            label={t("Email")}
            placeholder={t("The main contact persons email.")}
            labelPlacement="floating"
            value={fields.contactDetails.email}
            onIonInput={setField("contactDetails.email")}
          />
        </IonItem>
        <IonItem>
          <IonInput
            label={t("Phone")}
            placeholder={t("The main contact persons phone number.")}
            labelPlacement="floating"
            value={fields.contactDetails.phone}
            onIonInput={setField("contactDetails.phone")}
          />
        </IonItem>
      </IonItemGroup>
      <IonItem>
        <IonInput
          label={t("Email for account")}
          placeholder={t("The primary email address of the enterprise for important communications and password retrieval.")}
          labelPlacement="floating"
          value={fields.email}
          onIonInput={setField("email")}
        />
      </IonItem>
    </>
  );
};

export default Step3;
