import React, { useState } from "react";
import { connect } from "react-redux";
import TabHeader from "../../../../layout/TabHeader/TabHeader";
import {
  IonContent,
  IonDatetime,
  IonDatetimeButton,
  IonItem,
  IonItemGroup,
  IonLabel,
  IonList,
  IonModal,
  IonPage,
} from "@ionic/react";
import { Business, TipTransaction } from "../../../../../types/DBTypes";
import BusinessDropdown from "../../../../common/BusinessDropdown/BusinessDropdown";
import "./TipsOverview.css";
import useDelayCallback from "../../../../../hooks/useDelayCallback";
import { API } from "aws-amplify";
import { listTipTransactions } from "../../../../../graphql/queries";
import Skeleton from "../../../../common/Skeleton/Skeleton";
import { showToast } from "../../../../../store/actions/ui.actions";
import { t } from "i18next";

const FROM_DEFAULT = new Date().toISOString().split("T")[0] + "T00:00:00.000Z";
const TODAY = new Date().toISOString().split("T")[0] + "T23:59:59.999Z";

interface TProps {
  showToast: (text: string) => void;
}

const mapDispatch = (dispatch: any) => ({
  showToast: (text: string) => dispatch(showToast({ text })),
});

const LoadingPlaceholder = <Skeleton width="5ch" height="1rem" />;

const TipsOverview = ({ showToast }: TProps) => {
  const [outlet, setOutlet] = useState<Business | null>(null);
  const [from, setFrom] = useState<string>(FROM_DEFAULT);
  const [to, setTo] = useState<string>(TODAY);

  const [total, setTotal] = useState<number>(0);
  const [count, setCount] = useState<number>(0);

  const { loading } = useDelayCallback(async () => {
    showToast(t("Calculating..."));
    let total = 0;
    let count = 0;
    let nextToken = null;
    do {
      // @ts-ignore
      const { data } = await API.graphql({
        query: listTipTransactions,
        variables: {
          filter: {
            // if outlet is not selected, show all tips
            receiverId: outlet ? { eq: outlet.id } : undefined,
            createdAt: { between: [from, to] },
          },
          nextToken,
        },
      });

      nextToken = data.listTipTransactions?.nextToken;
      const items = data.listTipTransactions?.items;

      count += items?.length || 0;
      total += items?.reduce(
        (acc: number, item: TipTransaction) => acc + +item.amount,
        0
      );
      showToast(t("Processed {{count}} tips, Continue...", { count }));
    } while (nextToken !== null);

    setTotal(total);
    setCount(count);
  }, [outlet, from, to]);

  return (
    <IonPage>
      <TabHeader title={t("Overview")} />

      <IonContent fullscreen>
        <BusinessDropdown onSelect={setOutlet} value={outlet} />

        <IonItemGroup className="overview--date-period-picker">
          <IonItem>
            <IonLabel>{t("From")}</IonLabel>
            <IonDatetimeButton datetime="period-from" />
          </IonItem>

          <IonItem>
            <IonLabel>{t("To")}</IonLabel>
            <IonDatetimeButton datetime="period-to" />
          </IonItem>
        </IonItemGroup>

        <IonList>
          <IonItem>
            <IonLabel>{t("Tips")}</IonLabel>
            <IonLabel slot="end">
              {loading ? LoadingPlaceholder : t("{{amount}}", { amount: total / 100 })}
            </IonLabel>
          </IonItem>
          <IonItem>
            <IonLabel>{t("Number of tips")}</IonLabel>
            <IonLabel slot="end">
              {loading ? LoadingPlaceholder : count}
            </IonLabel>
          </IonItem>
          <IonItem>
            <IonLabel>{t("Average tip size")}</IonLabel>
            <IonLabel slot="end">
              {loading
                ? LoadingPlaceholder
                : t("{{amount}}", { amount: !count ? 0 : total / count / 100 })}
            </IonLabel>{" "}
          </IonItem>
        </IonList>

        <IonModal keepContentsMounted>
          <IonDatetime
            id="period-from"
            presentation="date"
            value={from}
            max={to || TODAY}
            onIonChange={({ detail }) => setFrom(detail.value as string)}
          />
        </IonModal>
        <IonModal keepContentsMounted>
          <IonDatetime
            id="period-to"
            presentation="date"
            value={to}
            min={from || undefined}
            max={TODAY}
            onIonChange={({ detail }) => setTo(detail.value as string)}
          />
        </IonModal>
      </IonContent>
    </IonPage>
  );
};

export default connect(null, mapDispatch)(TipsOverview);
