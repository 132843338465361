import React from "react";
import {
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonText,
} from "@ionic/react";
import "./Receipt.css";
import { t } from "i18next";

interface TProps {
  amount: number;
  fee: number;
  feeIncluded: boolean;
  transactionId: string;
  createdAt: string;
}

const Receipt = ({
  amount,
  fee,
  transactionId,
  feeIncluded,
  createdAt,
}: TProps) => {
  return (
    <div className="receipt">
      <IonList lines="none">
        <IonListHeader>
          <IonLabel>{t("Tip Receipt")}</IonLabel>
        </IonListHeader>

        <IonItem>
          <IonLabel>{t("Tip Total:")}</IonLabel>
          <IonText slot="end">
            {t("{{amount}}", { amount: amount + (feeIncluded ? fee : 0 ) })}
          </IonText>
        </IonItem>
        <IonItem>
          <IonLabel className="ion-text-center" color="dark">
            <p>{t("{{datetime}}", { datetime: createdAt })}</p>
          </IonLabel>
        </IonItem>
      </IonList>
      <IonItem lines="none">
        <IonLabel className="ion-text-center" color="dark">
          <p>{transactionId}</p>
        </IonLabel>
      </IonItem>
    </div>
  );
};

export default Receipt;
