import React from "react";
import { IonItem, IonList, useIonModal } from "@ionic/react";
import MerchantIdSetup from "../MerchantIdSetup/MerchantIdSetup";
import { Business } from "../../../../../types/types";
import { t } from "i18next";
import { checkmarkCircle, chevronForward } from "ionicons/icons";

interface IProps {
  outlet: Business;
}

const SolutionsController = ({ outlet }: IProps) => {
  const [showTerminalSetup, dismiss] = useIonModal(MerchantIdSetup, {
    outlet,
    dismiss: () => dismiss(),
  });

  return (
    <IonList>
      <IonItem
        // checked={!!outlet?.tipping?.merchantId}
        onClick={() => showTerminalSetup()}
        detail
        button
        detailIcon={
          !!outlet?.tipping?.merchantId ? checkmarkCircle : chevronForward
        }
      >
        {t("Tipinc Terminal")}
      </IonItem>
      <IonItem detail button detailIcon={checkmarkCircle}>
        {t("Tipinc QR")}
      </IonItem>
      <IonItem disabled>{t("Tipinc Contactless")}</IonItem>
    </IonList>
  );
};

export default SolutionsController;
