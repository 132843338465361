import { useState } from "react";
import {
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonItem,
  IonList,
  IonPopover,
  IonTextarea,
  IonTitle,
  IonToolbar,
  useIonModal,
} from "@ionic/react";
import {
  Business,
  Profile,
  UpdateBusinessInput,
  // UpdateProfileInput,
} from "../../../../../types/DBTypes";
import useConfirmationModal from "../../../../../hooks/useConfirmationModal";
import { updateBusiness as updateBusinessAction } from "../../../../../store/actions/outlets.actions";
import { connect } from "react-redux";
import {
  // updateProfile,
  updateProfileSlug as updateProfileSlugAction,
  resetPasswordByAdmin as resetPasswordAction,
} from "../../../../../store/actions/individuals.actions";
import {
  TUpdateProfileSlugPayload,
  TResetPasswordPayload
} from "../../../../../store/types/individuals.types";
import { t } from "i18next";
// import { onUpdateBusinessBrands } from "../../../../../graphql/subscriptions";
// import { showToast } from "../../../../../store/actions/ui.actions";

interface IProps {
  profile: Profile;
  business: Business;
  updateBusiness: (input: UpdateBusinessInput) => void;
  // updateProfile: (input: UpdateProfileInput) => void;
  updateProfileSlug: (payload: TUpdateProfileSlugPayload) => void;
  resetPassword: (payload: TResetPasswordPayload) => void;
}

const mapProps = (dispatch: any) => ({
  updateBusiness: (input: UpdateBusinessInput) =>
    dispatch(updateBusinessAction({ input })),
  // updateProfile: (input: UpdateProfileInput) =>
  //   dispatch(updateProfile({ input })),
  updateProfileSlug: (payload: TUpdateProfileSlugPayload) =>
    dispatch(updateProfileSlugAction(payload)),
  resetPassword: (payload: TResetPasswordPayload) =>
    dispatch(resetPasswordAction(payload)),
});
const OutletPopoverController = ({
  profile,
  business,
  updateBusiness,
  // updateProfile,
  updateProfileSlug,
  resetPassword
}: IProps) => {
  const { verified } = business;
  const { slug } = profile;

  const [tippingPolicy, setTippingPolicy] = useState<string>(
    business?.tipping?.policy || ""
  );

  const handleVerify = useConfirmationModal(
    () => {
      updateBusiness({ id: business.id, verified: !verified });
    },
    verified
      ? t("Would you like to remove verification from this business?")
      : t("Would you like to verify this business?")
  );

  const handleResetPassword = useConfirmationModal(
    () => {
      resetPassword({ id: business.id });
    },
    t("Are you sure you want to reset the business owner's password?")
  );

  const handleEditSlug = useConfirmationModal(
    async ({ slug }) => {
      const newSlug = slug.trim().toLowerCase();
      updateProfileSlug({ slug: newSlug, id: profile.id });
    },
    t("Enter new slug: "),
    [{ name: "slug", type: "text", value: slug, min: 6, max: 6 }]
  );

  const [handleEditTP, dismiss] = useIonModal(
    <>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Edit Tipinc Policy</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <IonTextarea
          label="Content"
          labelPlacement="floating"
          value={tippingPolicy}
          onIonChange={(e) => setTippingPolicy(e.detail.value!)}
          autoGrow
          counter
          maxlength={4000}
          counterFormatter={(inputLength, maxLength) =>
            `${maxLength - inputLength} characters remaining`
          }
        />
      </IonContent>
      <IonFooter className="ion-padding">
        <IonButtons className="ion-justify-content-between">
          <IonButton onClick={() => dismiss()}>Cancel</IonButton>
          <IonButton
            onClick={() => {
              updateBusiness({
                id: business.id,
                tipping: {
                  ...business.tipping,
                  policy: tippingPolicy,
                },
              });
              dismiss();
            }}
          >
            Save
          </IonButton>
        </IonButtons>
      </IonFooter>
    </>
  );
  return (
    <IonPopover trigger="profile-popover" dismissOnSelect={true} side="bottom">
      <IonContent>
        <IonList>
          <IonItem onClick={handleVerify}>
            {verified ? t("Unverify") : t("Verify")}
          </IonItem>
          <IonItem onClick={() => handleEditSlug()}>{t("Edit slug")}</IonItem>
          <IonItem onClick={() => handleEditTP()}>
            {t("Edit Tipping Policy")}
          </IonItem>
          <IonItem onClick={() => handleResetPassword()}>
            {t("Reset Password")}
          </IonItem>
        </IonList>
      </IonContent>
    </IonPopover>
  );
};

export default connect(null, mapProps)(OutletPopoverController);
