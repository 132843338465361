const getProfilesBySlug = /* GraphQL */ `
  query GetProfilesBySlug(
    $slug: String
    $sortDirection: ModelSortDirection
    $filter: ModelProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getProfilesBySlug(
      slug: $slug
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        slug
        firstName
        lastName
        picture
        business {
          id
          verified
          name
          tipping {
            merchant
            merchantId
            policy
            type
            active
            __typename
          }
          marketing {
            customerId
            subscriptionId
            active
            expirationDate
            sequenceId
            cancelled
            cancelledAt
            __typename
          }
          createdAt
          updatedAt
          owner
          code
          answers {
            questionId
            value
            __typename
          }
          isOutlet
          details {
            id
            contactName
            jobRole
            phone
            email
            website
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          ownerProfile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchant
                merchantId
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                location {
                  searchName
                  address
                  address2
                  city
                  region
                  postcode
                  country
                  latitude
                  longitude
                  __typename
                }
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                location {
                  searchName
                  address
                  address2
                  city
                  region
                  postcode
                  country
                  latitude
                  longitude
                  __typename
                }
                createdAt
                updatedAt
                business {
                  id
                  verified
                  name
                  createdAt
                  updatedAt
                  owner
                  code
                  isOutlet
                  __typename
                }
                archived
                details {
                  id
                  accountType
                  isOnboardingComplete
                  email
                  feedRegion
                  phone_number
                  phoneNumberConfirmed
                  pushNotificationToken
                  pushNotificationReadDate
                  createdAt
                  updatedAt
                  __typename
                }
                employers {
                  nextToken
                  __typename
                }
                experiences {
                  nextToken
                  __typename
                }
                jobs {
                  nextToken
                  __typename
                }
                __typename
              }
              invites {
                items {
                  id
                  businessId
                  receiver
                  createdAt
                  updatedAt
                  __typename
                }
                nextToken
                __typename
              }
              employees {
                items {
                  id
                  jobRole
                  businessId
                  profileId
                  employeeId
                  tippingPolicyAcknowledgedDate
                  createdAt
                  updatedAt
                  confirmed
                  __typename
                }
                nextToken
                __typename
              }
              brands {
                items {
                  id
                  businessId
                  brandId
                  status
                  createdAt
                  updatedAt
                  profileId
                  __typename
                }
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                missingRequirements {
                  key
                  description
                  __typename
                }
                pendingValidation
                bankDetails {
                  name
                  sortCode
                  last4Digits
                  __typename
                }
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                business {
                  id
                  verified
                  name
                  createdAt
                  updatedAt
                  owner
                  code
                  isOutlet
                  __typename
                }
                profile {
                  id
                  slug
                  firstName
                  lastName
                  bio
                  picture
                  createdAt
                  updatedAt
                  archived
                  __typename
                }
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                business {
                  id
                  verified
                  name
                  createdAt
                  updatedAt
                  owner
                  code
                  isOutlet
                  __typename
                }
                profile {
                  id
                  slug
                  firstName
                  lastName
                  bio
                  picture
                  createdAt
                  updatedAt
                  archived
                  __typename
                }
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                profile {
                  id
                  slug
                  firstName
                  lastName
                  bio
                  picture
                  createdAt
                  updatedAt
                  archived
                  __typename
                }
                job {
                  id
                  owner
                  jobType
                  businessId
                  jobRole
                  title
                  description
                  region
                  requireCV
                  requireCoverLetter
                  createdAt
                  archived
                  startDate
                  endDate
                  updatedAt
                  __typename
                }
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          invites {
            items {
              id
              businessId
              receiver
              createdAt
              updatedAt
              business {
                id
                verified
                name
                tipping {
                  merchantId
                  merchant
                  policy
                  type
                  active
                  __typename
                }
                marketing {
                  customerId
                  subscriptionId
                  active
                  expirationDate
                  sequenceId
                  cancelled
                  cancelledAt
                  __typename
                }
                createdAt
                updatedAt
                owner
                code
                answers {
                  questionId
                  value
                  __typename
                }
                isOutlet
                details {
                  id
                  contactName
                  jobRole
                  phone
                  email
                  website
                  createdAt
                  updatedAt
                  __typename
                }
                ownerProfile {
                  id
                  slug
                  firstName
                  lastName
                  bio
                  picture
                  createdAt
                  updatedAt
                  archived
                  __typename
                }
                invites {
                  nextToken
                  __typename
                }
                employees {
                  nextToken
                  __typename
                }
                brands {
                  nextToken
                  __typename
                }
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          employees {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                tipping {
                  merchant
                  merchantId
                  policy
                  type
                  active
                  __typename
                }
                marketing {
                  customerId
                  subscriptionId
                  active
                  expirationDate
                  sequenceId
                  cancelled
                  cancelledAt
                  __typename
                }
                createdAt
                updatedAt
                owner
                code
                answers {
                  questionId
                  value
                  __typename
                }
                isOutlet
                details {
                  id
                  contactName
                  jobRole
                  phone
                  email
                  website
                  createdAt
                  updatedAt
                  __typename
                }
                ownerProfile {
                  id
                  slug
                  firstName
                  lastName
                  bio
                  picture
                  createdAt
                  updatedAt
                  archived
                  __typename
                }
                invites {
                  nextToken
                  __typename
                }
                employees {
                  nextToken
                  __typename
                }
                brands {
                  nextToken
                  __typename
                }
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                location {
                  searchName
                  address
                  address2
                  city
                  region
                  postcode
                  country
                  latitude
                  longitude
                  __typename
                }
                createdAt
                updatedAt
                business {
                  id
                  verified
                  name
                  createdAt
                  updatedAt
                  owner
                  code
                  isOutlet
                  __typename
                }
                archived
                details {
                  id
                  accountType
                  isOnboardingComplete
                  email
                  feedRegion
                  phone_number
                  phoneNumberConfirmed
                  pushNotificationToken
                  pushNotificationReadDate
                  createdAt
                  updatedAt
                  tippingAccount {
                    verified
                  }
                  __typename
                }
                employers {
                  nextToken
                  __typename
                }
                experiences {
                  nextToken
                  __typename
                }
                jobs {
                  nextToken
                  __typename
                }
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          brands {
            items {
              id
              businessId
              brandId
              status
              createdAt
              updatedAt
              business {
                id
                verified
                name
                tipping {
                  merchant
                  merchantId
                  policy
                  type
                  active
                  __typename
                }
                marketing {
                  customerId
                  subscriptionId
                  active
                  expirationDate
                  sequenceId
                  cancelled
                  cancelledAt
                  __typename
                }
                createdAt
                updatedAt
                owner
                code
                answers {
                  questionId
                  value
                  __typename
                }
                isOutlet
                details {
                  id
                  contactName
                  jobRole
                  phone
                  email
                  website
                  createdAt
                  updatedAt
                  __typename
                }
                ownerProfile {
                  id
                  slug
                  firstName
                  lastName
                  bio
                  picture
                  createdAt
                  updatedAt
                  archived
                  __typename
                }
                invites {
                  nextToken
                  __typename
                }
                employees {
                  nextToken
                  __typename
                }
                brands {
                  nextToken
                  __typename
                }
                __typename
              }
              profileId
              brand {
                id
                name
                logo
                active
                productType
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        details {
          id
          accountType
          isOnboardingComplete
          email
          feedRegion
          tippingAccount {
            code
            merchant
            accountId
            verified
            missingRequirements {
              key
              description
              __typename
            }
            pendingValidation
            bankDetails {
              name
              sortCode
              last4Digits
              __typename
            }
            __typename
          }
          phone_number
          phoneNumberConfirmed
          pushNotificationToken
          pushNotificationReadDate
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export default getProfilesBySlug;
