import {
  IonButton,
  IonContent,
  IonFooter,
  IonHeader,
  IonInput,
  IonItem,
  IonList,
  IonSelect,
  IonSelectOption,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import "./MerchantIdSetup.css";
import { useEffect, useState } from "react";
import {
  InputInputEventDetail,
  IonInputCustomEvent,
} from "@ionic/core/dist/types/components";
import useConfirmationModal from "../../../../../hooks/useConfirmationModal";
import { connect } from "react-redux";
import { UpdateBusinessInput } from "../../../../../types/DBTypes";
import { updateBusiness as updateBusinessAction } from "../../../../../store/actions/outlets.actions";
import { Business } from "../../../../../types/types";
import { t } from "i18next";
import { Storage } from "aws-amplify";

const mapProps = (dispatch: any) => ({
  updateBusiness: (input: UpdateBusinessInput) =>
    dispatch(updateBusinessAction({ input })),
});

interface IProps {
  updateBusiness: (input: UpdateBusinessInput) => void;
  outlet: Business;
  slug: string;
  dismiss: (data?: any, role?: string) => void;
}
const MerchantIdSetup = ({ updateBusiness, outlet, dismiss }: IProps) => {
  const [identifier, setIdentifier] = useState<string>(
    outlet?.tipping?.merchantId || ""
  );
  const [merchant, setMerchant] = useState<string>(
    outlet?.tipping?.merchant || ""
  );
  const [merchantList, setMerchantList] = useState<string[]>([]);

  useEffect(() => {
    Storage.get("merchants.json", {
      bucket: "merchants-transactions-dev",
      // download: true,
      customPrefix: { public: "" },
      expires: 60,
    })
      .then(async (data) => {
        const response = await fetch(data as string);
        return response.json();
      })
      .then((obj) => Object.keys(obj))
      .then(setMerchantList);
  }, []);

  const setMerchantId = useConfirmationModal((merchantId: string) => {
    updateBusiness({
      id: outlet?.id,
      tipping: {
        ...outlet.tipping,
        merchantId,
        merchant,
      },
    });

    setTimeout(() => {
      setIdentifier("");
      dismiss();
    }, 1000);
  }, t("Are you sure you want to link this account with specified merchant?"));

  const handleIdentifierChange = (
    e: IonInputCustomEvent<InputInputEventDetail>
  ) => {
    const { value } = e.detail;
    setIdentifier(value || "");

    // start of temporary disable code TIP-974
    // if (
    //   value?.length === VAT_LENGTH ||
    //   value?.length === MID_LENGTH ||
    //   value?.length === INC_NUMBER_LENGTH
    // )
    //  getMerchantOutlets(value).then(setMerchant);
    // end of temporary disable code TIP-974
  };

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonTitle>{t("Set up terminal")}</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent className="merchant-id-content">
        <IonList>
          <IonItem>
            <IonInput
              label={t("Merchant ID")}
              labelPlacement="start"
              onIonInput={handleIdentifierChange}
              value={identifier}
            />
          </IonItem>
          <IonItem>
            <IonSelect
              label={t("Merchant")}
              placeholder={t("Select")}
              value={merchant}
              onIonChange={(e) => setMerchant(e.detail.value!)}
            >
              {merchantList.map((merchant) => (
                <IonSelectOption key={merchant} value={merchant}>
                  {merchant}
                </IonSelectOption>
              ))}
            </IonSelect>
          </IonItem>
        </IonList>
      </IonContent>
      <IonFooter>
        <IonButton
          fill="clear"
          color="dark"
          disabled={!identifier || !merchant}
          onClick={() => setMerchantId(identifier)}
        >
          {t("Assign")}
        </IonButton>
      </IonFooter>
    </>
  );
};

export default connect(null, mapProps)(MerchantIdSetup);
