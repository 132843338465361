/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../types/DBTypes";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const deleteBusinessStatistics = /* GraphQL */ `mutation DeleteBusinessStatistics(
  $input: DeleteBusinessStatisticsInput!
  $condition: ModelBusinessStatisticsConditionInput
) {
  deleteBusinessStatistics(input: $input, condition: $condition) {
    id
    data {
      date
      totalTips
      totalTipsCount
      __typename
    }
    updatedAt
    owner
    lastSplitDate
    createdAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteBusinessStatisticsMutationVariables,
  APITypes.DeleteBusinessStatisticsMutation
>;
export const deleteProfile = /* GraphQL */ `mutation DeleteProfile(
  $input: DeleteProfileInput!
  $condition: ModelProfileConditionInput
) {
  deleteProfile(input: $input, condition: $condition) {
    id
    slug
    firstName
    lastName
    bio
    picture
    location {
      searchName
      address
      address2
      city
      region
      postcode
      country
      latitude
      longitude
      __typename
    }
    createdAt
    updatedAt
    business {
      id
      verified
      name
      tipping {
        merchantId
        merchant
        policy
        type
        active
        __typename
      }
      marketing {
        customerId
        subscriptionId
        active
        expirationDate
        sequenceId
        cancelled
        cancelledAt
        __typename
      }
      createdAt
      updatedAt
      owner
      code
      answers {
        questionId
        value
        __typename
      }
      isOutlet
      details {
        id
        contactName
        jobRole
        phone
        email
        website
        location {
          searchName
          address
          address2
          city
          region
          postcode
          country
          latitude
          longitude
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      ownerProfile {
        id
        slug
        firstName
        lastName
        bio
        picture
        location {
          searchName
          address
          address2
          city
          region
          postcode
          country
          latitude
          longitude
          __typename
        }
        createdAt
        updatedAt
        business {
          id
          verified
          name
          tipping {
            merchantId
            merchant
            policy
            type
            active
            __typename
          }
          marketing {
            customerId
            subscriptionId
            active
            expirationDate
            sequenceId
            cancelled
            cancelledAt
            __typename
          }
          createdAt
          updatedAt
          owner
          code
          answers {
            questionId
            value
            __typename
          }
          isOutlet
          details {
            id
            contactName
            jobRole
            phone
            email
            website
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          ownerProfile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          invites {
            items {
              id
              businessId
              receiver
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          employees {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          brands {
            items {
              id
              businessId
              brandId
              status
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profileId
              brand {
                id
                name
                logo
                active
                productType
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        archived
        details {
          id
          accountType
          isOnboardingComplete
          email
          feedRegion
          tippingAccount {
            code
            merchant
            accountId
            verified
            missingRequirements {
              key
              description
              __typename
            }
            pendingValidation
            bankDetails {
              name
              sortCode
              last4Digits
              __typename
            }
            __typename
          }
          phone_number
          phoneNumberConfirmed
          pushNotificationToken
          pushNotificationReadDate
          createdAt
          updatedAt
          __typename
        }
        employers {
          items {
            id
            jobRole
            businessId
            profileId
            employeeId
            tippingPolicyAcknowledgedDate
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            confirmed
            __typename
          }
          nextToken
          __typename
        }
        experiences {
          items {
            id
            jobTitle
            businessId
            companyName
            profileId
            startDate
            endDate
            employmentType
            location
            description
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        jobs {
          items {
            id
            profileId
            jobId
            coverLetter
            saved
            savedDate
            applied
            appliedDate
            createdAt
            updatedAt
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            job {
              id
              owner
              jobType
              businessId
              jobRole
              title
              description
              salary {
                type
                from
                to
                __typename
              }
              region
              requireCV
              requireCoverLetter
              createdAt
              archived
              startDate
              endDate
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              reported {
                nextToken
                __typename
              }
              __typename
            }
            rejected
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      invites {
        items {
          id
          businessId
          receiver
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      employees {
        items {
          id
          jobRole
          businessId
          profileId
          employeeId
          tippingPolicyAcknowledgedDate
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          confirmed
          __typename
        }
        nextToken
        __typename
      }
      brands {
        items {
          id
          businessId
          brandId
          status
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profileId
          brand {
            id
            name
            logo
            active
            productType
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    archived
    details {
      id
      accountType
      isOnboardingComplete
      email
      feedRegion
      tippingAccount {
        code
        merchant
        accountId
        verified
        missingRequirements {
          key
          description
          __typename
        }
        pendingValidation
        bankDetails {
          name
          sortCode
          last4Digits
          __typename
        }
        __typename
      }
      phone_number
      phoneNumberConfirmed
      pushNotificationToken
      pushNotificationReadDate
      createdAt
      updatedAt
      __typename
    }
    employers {
      items {
        id
        jobRole
        businessId
        profileId
        employeeId
        tippingPolicyAcknowledgedDate
        createdAt
        updatedAt
        business {
          id
          verified
          name
          tipping {
            merchantId
            merchant
            policy
            type
            active
            __typename
          }
          marketing {
            customerId
            subscriptionId
            active
            expirationDate
            sequenceId
            cancelled
            cancelledAt
            __typename
          }
          createdAt
          updatedAt
          owner
          code
          answers {
            questionId
            value
            __typename
          }
          isOutlet
          details {
            id
            contactName
            jobRole
            phone
            email
            website
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          ownerProfile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          invites {
            items {
              id
              businessId
              receiver
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          employees {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          brands {
            items {
              id
              businessId
              brandId
              status
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profileId
              brand {
                id
                name
                logo
                active
                productType
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        profile {
          id
          slug
          firstName
          lastName
          bio
          picture
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          archived
          details {
            id
            accountType
            isOnboardingComplete
            email
            feedRegion
            tippingAccount {
              code
              merchant
              accountId
              verified
              missingRequirements {
                key
                description
                __typename
              }
              pendingValidation
              bankDetails {
                name
                sortCode
                last4Digits
                __typename
              }
              __typename
            }
            phone_number
            phoneNumberConfirmed
            pushNotificationToken
            pushNotificationReadDate
            createdAt
            updatedAt
            __typename
          }
          employers {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          experiences {
            items {
              id
              jobTitle
              businessId
              companyName
              profileId
              startDate
              endDate
              employmentType
              location
              description
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          jobs {
            items {
              id
              profileId
              jobId
              coverLetter
              saved
              savedDate
              applied
              appliedDate
              createdAt
              updatedAt
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              job {
                id
                owner
                jobType
                businessId
                jobRole
                title
                description
                region
                requireCV
                requireCoverLetter
                createdAt
                archived
                startDate
                endDate
                updatedAt
                __typename
              }
              rejected
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        confirmed
        __typename
      }
      nextToken
      __typename
    }
    experiences {
      items {
        id
        jobTitle
        businessId
        companyName
        profileId
        startDate
        endDate
        employmentType
        location
        description
        createdAt
        updatedAt
        business {
          id
          verified
          name
          tipping {
            merchantId
            merchant
            policy
            type
            active
            __typename
          }
          marketing {
            customerId
            subscriptionId
            active
            expirationDate
            sequenceId
            cancelled
            cancelledAt
            __typename
          }
          createdAt
          updatedAt
          owner
          code
          answers {
            questionId
            value
            __typename
          }
          isOutlet
          details {
            id
            contactName
            jobRole
            phone
            email
            website
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          ownerProfile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          invites {
            items {
              id
              businessId
              receiver
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          employees {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          brands {
            items {
              id
              businessId
              brandId
              status
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profileId
              brand {
                id
                name
                logo
                active
                productType
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        profile {
          id
          slug
          firstName
          lastName
          bio
          picture
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          archived
          details {
            id
            accountType
            isOnboardingComplete
            email
            feedRegion
            tippingAccount {
              code
              merchant
              accountId
              verified
              missingRequirements {
                key
                description
                __typename
              }
              pendingValidation
              bankDetails {
                name
                sortCode
                last4Digits
                __typename
              }
              __typename
            }
            phone_number
            phoneNumberConfirmed
            pushNotificationToken
            pushNotificationReadDate
            createdAt
            updatedAt
            __typename
          }
          employers {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          experiences {
            items {
              id
              jobTitle
              businessId
              companyName
              profileId
              startDate
              endDate
              employmentType
              location
              description
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          jobs {
            items {
              id
              profileId
              jobId
              coverLetter
              saved
              savedDate
              applied
              appliedDate
              createdAt
              updatedAt
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              job {
                id
                owner
                jobType
                businessId
                jobRole
                title
                description
                region
                requireCV
                requireCoverLetter
                createdAt
                archived
                startDate
                endDate
                updatedAt
                __typename
              }
              rejected
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    jobs {
      items {
        id
        profileId
        jobId
        coverLetter
        saved
        savedDate
        applied
        appliedDate
        createdAt
        updatedAt
        profile {
          id
          slug
          firstName
          lastName
          bio
          picture
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          archived
          details {
            id
            accountType
            isOnboardingComplete
            email
            feedRegion
            tippingAccount {
              code
              merchant
              accountId
              verified
              missingRequirements {
                key
                description
                __typename
              }
              pendingValidation
              bankDetails {
                name
                sortCode
                last4Digits
                __typename
              }
              __typename
            }
            phone_number
            phoneNumberConfirmed
            pushNotificationToken
            pushNotificationReadDate
            createdAt
            updatedAt
            __typename
          }
          employers {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          experiences {
            items {
              id
              jobTitle
              businessId
              companyName
              profileId
              startDate
              endDate
              employmentType
              location
              description
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          jobs {
            items {
              id
              profileId
              jobId
              coverLetter
              saved
              savedDate
              applied
              appliedDate
              createdAt
              updatedAt
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              job {
                id
                owner
                jobType
                businessId
                jobRole
                title
                description
                region
                requireCV
                requireCoverLetter
                createdAt
                archived
                startDate
                endDate
                updatedAt
                __typename
              }
              rejected
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        job {
          id
          owner
          jobType
          businessId
          jobRole
          title
          description
          salary {
            type
            from
            to
            __typename
          }
          region
          requireCV
          requireCoverLetter
          createdAt
          archived
          startDate
          endDate
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          reported {
            items {
              id
              postId
              userId
              commentId
              jobId
              creatorId
              createdAt
              message
              updatedAt
              creator {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        rejected
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteProfileMutationVariables,
  APITypes.DeleteProfileMutation
>;
export const deleteProfileDetails = /* GraphQL */ `mutation DeleteProfileDetails(
  $input: DeleteProfileDetailsInput!
  $condition: ModelProfileDetailsConditionInput
) {
  deleteProfileDetails(input: $input, condition: $condition) {
    id
    accountType
    isOnboardingComplete
    email
    feedRegion
    tippingAccount {
      code
      merchant
      accountId
      verified
      missingRequirements {
        key
        description
        __typename
      }
      pendingValidation
      bankDetails {
        name
        sortCode
        last4Digits
        __typename
      }
      __typename
    }
    phone_number
    phoneNumberConfirmed
    pushNotificationToken
    pushNotificationReadDate
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteProfileDetailsMutationVariables,
  APITypes.DeleteProfileDetailsMutation
>;
export const updateBusinessInvite = /* GraphQL */ `mutation UpdateBusinessInvite(
  $input: UpdateBusinessInviteInput!
  $condition: ModelBusinessInviteConditionInput
) {
  updateBusinessInvite(input: $input, condition: $condition) {
    id
    businessId
    receiver
    createdAt
    updatedAt
    business {
      id
      verified
      name
      tipping {
        merchantId
        merchant
        policy
        type
        active
        __typename
      }
      marketing {
        customerId
        subscriptionId
        active
        expirationDate
        sequenceId
        cancelled
        cancelledAt
        __typename
      }
      createdAt
      updatedAt
      owner
      code
      answers {
        questionId
        value
        __typename
      }
      isOutlet
      details {
        id
        contactName
        jobRole
        phone
        email
        website
        location {
          searchName
          address
          address2
          city
          region
          postcode
          country
          latitude
          longitude
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      ownerProfile {
        id
        slug
        firstName
        lastName
        bio
        picture
        location {
          searchName
          address
          address2
          city
          region
          postcode
          country
          latitude
          longitude
          __typename
        }
        createdAt
        updatedAt
        business {
          id
          verified
          name
          tipping {
            merchantId
            merchant
            policy
            type
            active
            __typename
          }
          marketing {
            customerId
            subscriptionId
            active
            expirationDate
            sequenceId
            cancelled
            cancelledAt
            __typename
          }
          createdAt
          updatedAt
          owner
          code
          answers {
            questionId
            value
            __typename
          }
          isOutlet
          details {
            id
            contactName
            jobRole
            phone
            email
            website
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          ownerProfile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          invites {
            items {
              id
              businessId
              receiver
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          employees {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          brands {
            items {
              id
              businessId
              brandId
              status
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profileId
              brand {
                id
                name
                logo
                active
                productType
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        archived
        details {
          id
          accountType
          isOnboardingComplete
          email
          feedRegion
          tippingAccount {
            code
            merchant
            accountId
            verified
            missingRequirements {
              key
              description
              __typename
            }
            pendingValidation
            bankDetails {
              name
              sortCode
              last4Digits
              __typename
            }
            __typename
          }
          phone_number
          phoneNumberConfirmed
          pushNotificationToken
          pushNotificationReadDate
          createdAt
          updatedAt
          __typename
        }
        employers {
          items {
            id
            jobRole
            businessId
            profileId
            employeeId
            tippingPolicyAcknowledgedDate
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            confirmed
            __typename
          }
          nextToken
          __typename
        }
        experiences {
          items {
            id
            jobTitle
            businessId
            companyName
            profileId
            startDate
            endDate
            employmentType
            location
            description
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        jobs {
          items {
            id
            profileId
            jobId
            coverLetter
            saved
            savedDate
            applied
            appliedDate
            createdAt
            updatedAt
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            job {
              id
              owner
              jobType
              businessId
              jobRole
              title
              description
              salary {
                type
                from
                to
                __typename
              }
              region
              requireCV
              requireCoverLetter
              createdAt
              archived
              startDate
              endDate
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              reported {
                nextToken
                __typename
              }
              __typename
            }
            rejected
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      invites {
        items {
          id
          businessId
          receiver
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      employees {
        items {
          id
          jobRole
          businessId
          profileId
          employeeId
          tippingPolicyAcknowledgedDate
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          confirmed
          __typename
        }
        nextToken
        __typename
      }
      brands {
        items {
          id
          businessId
          brandId
          status
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profileId
          brand {
            id
            name
            logo
            active
            productType
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateBusinessInviteMutationVariables,
  APITypes.UpdateBusinessInviteMutation
>;
export const deleteBusinessInvite = /* GraphQL */ `mutation DeleteBusinessInvite(
  $input: DeleteBusinessInviteInput!
  $condition: ModelBusinessInviteConditionInput
) {
  deleteBusinessInvite(input: $input, condition: $condition) {
    id
    businessId
    receiver
    createdAt
    updatedAt
    business {
      id
      verified
      name
      tipping {
        merchantId
        merchant
        policy
        type
        active
        __typename
      }
      marketing {
        customerId
        subscriptionId
        active
        expirationDate
        sequenceId
        cancelled
        cancelledAt
        __typename
      }
      createdAt
      updatedAt
      owner
      code
      answers {
        questionId
        value
        __typename
      }
      isOutlet
      details {
        id
        contactName
        jobRole
        phone
        email
        website
        location {
          searchName
          address
          address2
          city
          region
          postcode
          country
          latitude
          longitude
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      ownerProfile {
        id
        slug
        firstName
        lastName
        bio
        picture
        location {
          searchName
          address
          address2
          city
          region
          postcode
          country
          latitude
          longitude
          __typename
        }
        createdAt
        updatedAt
        business {
          id
          verified
          name
          tipping {
            merchantId
            merchant
            policy
            type
            active
            __typename
          }
          marketing {
            customerId
            subscriptionId
            active
            expirationDate
            sequenceId
            cancelled
            cancelledAt
            __typename
          }
          createdAt
          updatedAt
          owner
          code
          answers {
            questionId
            value
            __typename
          }
          isOutlet
          details {
            id
            contactName
            jobRole
            phone
            email
            website
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          ownerProfile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          invites {
            items {
              id
              businessId
              receiver
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          employees {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          brands {
            items {
              id
              businessId
              brandId
              status
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profileId
              brand {
                id
                name
                logo
                active
                productType
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        archived
        details {
          id
          accountType
          isOnboardingComplete
          email
          feedRegion
          tippingAccount {
            code
            merchant
            accountId
            verified
            missingRequirements {
              key
              description
              __typename
            }
            pendingValidation
            bankDetails {
              name
              sortCode
              last4Digits
              __typename
            }
            __typename
          }
          phone_number
          phoneNumberConfirmed
          pushNotificationToken
          pushNotificationReadDate
          createdAt
          updatedAt
          __typename
        }
        employers {
          items {
            id
            jobRole
            businessId
            profileId
            employeeId
            tippingPolicyAcknowledgedDate
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            confirmed
            __typename
          }
          nextToken
          __typename
        }
        experiences {
          items {
            id
            jobTitle
            businessId
            companyName
            profileId
            startDate
            endDate
            employmentType
            location
            description
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        jobs {
          items {
            id
            profileId
            jobId
            coverLetter
            saved
            savedDate
            applied
            appliedDate
            createdAt
            updatedAt
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            job {
              id
              owner
              jobType
              businessId
              jobRole
              title
              description
              salary {
                type
                from
                to
                __typename
              }
              region
              requireCV
              requireCoverLetter
              createdAt
              archived
              startDate
              endDate
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              reported {
                nextToken
                __typename
              }
              __typename
            }
            rejected
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      invites {
        items {
          id
          businessId
          receiver
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      employees {
        items {
          id
          jobRole
          businessId
          profileId
          employeeId
          tippingPolicyAcknowledgedDate
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          confirmed
          __typename
        }
        nextToken
        __typename
      }
      brands {
        items {
          id
          businessId
          brandId
          status
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profileId
          brand {
            id
            name
            logo
            active
            productType
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteBusinessInviteMutationVariables,
  APITypes.DeleteBusinessInviteMutation
>;
export const updateBusinessBrands = /* GraphQL */ `mutation UpdateBusinessBrands(
  $input: UpdateBusinessBrandsInput!
  $condition: ModelBusinessBrandsConditionInput
) {
  updateBusinessBrands(input: $input, condition: $condition) {
    id
    businessId
    brandId
    status
    createdAt
    updatedAt
    business {
      id
      verified
      name
      tipping {
        merchantId
        merchant
        policy
        type
        active
        __typename
      }
      marketing {
        customerId
        subscriptionId
        active
        expirationDate
        sequenceId
        cancelled
        cancelledAt
        __typename
      }
      createdAt
      updatedAt
      owner
      code
      answers {
        questionId
        value
        __typename
      }
      isOutlet
      details {
        id
        contactName
        jobRole
        phone
        email
        website
        location {
          searchName
          address
          address2
          city
          region
          postcode
          country
          latitude
          longitude
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      ownerProfile {
        id
        slug
        firstName
        lastName
        bio
        picture
        location {
          searchName
          address
          address2
          city
          region
          postcode
          country
          latitude
          longitude
          __typename
        }
        createdAt
        updatedAt
        business {
          id
          verified
          name
          tipping {
            merchantId
            merchant
            policy
            type
            active
            __typename
          }
          marketing {
            customerId
            subscriptionId
            active
            expirationDate
            sequenceId
            cancelled
            cancelledAt
            __typename
          }
          createdAt
          updatedAt
          owner
          code
          answers {
            questionId
            value
            __typename
          }
          isOutlet
          details {
            id
            contactName
            jobRole
            phone
            email
            website
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          ownerProfile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          invites {
            items {
              id
              businessId
              receiver
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          employees {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          brands {
            items {
              id
              businessId
              brandId
              status
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profileId
              brand {
                id
                name
                logo
                active
                productType
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        archived
        details {
          id
          accountType
          isOnboardingComplete
          email
          feedRegion
          tippingAccount {
            code
            merchant
            accountId
            verified
            missingRequirements {
              key
              description
              __typename
            }
            pendingValidation
            bankDetails {
              name
              sortCode
              last4Digits
              __typename
            }
            __typename
          }
          phone_number
          phoneNumberConfirmed
          pushNotificationToken
          pushNotificationReadDate
          createdAt
          updatedAt
          __typename
        }
        employers {
          items {
            id
            jobRole
            businessId
            profileId
            employeeId
            tippingPolicyAcknowledgedDate
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            confirmed
            __typename
          }
          nextToken
          __typename
        }
        experiences {
          items {
            id
            jobTitle
            businessId
            companyName
            profileId
            startDate
            endDate
            employmentType
            location
            description
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        jobs {
          items {
            id
            profileId
            jobId
            coverLetter
            saved
            savedDate
            applied
            appliedDate
            createdAt
            updatedAt
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            job {
              id
              owner
              jobType
              businessId
              jobRole
              title
              description
              salary {
                type
                from
                to
                __typename
              }
              region
              requireCV
              requireCoverLetter
              createdAt
              archived
              startDate
              endDate
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              reported {
                nextToken
                __typename
              }
              __typename
            }
            rejected
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      invites {
        items {
          id
          businessId
          receiver
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      employees {
        items {
          id
          jobRole
          businessId
          profileId
          employeeId
          tippingPolicyAcknowledgedDate
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          confirmed
          __typename
        }
        nextToken
        __typename
      }
      brands {
        items {
          id
          businessId
          brandId
          status
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profileId
          brand {
            id
            name
            logo
            active
            productType
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    profileId
    brand {
      id
      name
      logo
      active
      productType
      createdAt
      updatedAt
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateBusinessBrandsMutationVariables,
  APITypes.UpdateBusinessBrandsMutation
>;
export const deleteBusinessBrands = /* GraphQL */ `mutation DeleteBusinessBrands(
  $input: DeleteBusinessBrandsInput!
  $condition: ModelBusinessBrandsConditionInput
) {
  deleteBusinessBrands(input: $input, condition: $condition) {
    id
    businessId
    brandId
    status
    createdAt
    updatedAt
    business {
      id
      verified
      name
      tipping {
        merchantId
        merchant
        policy
        type
        active
        __typename
      }
      marketing {
        customerId
        subscriptionId
        active
        expirationDate
        sequenceId
        cancelled
        cancelledAt
        __typename
      }
      createdAt
      updatedAt
      owner
      code
      answers {
        questionId
        value
        __typename
      }
      isOutlet
      details {
        id
        contactName
        jobRole
        phone
        email
        website
        location {
          searchName
          address
          address2
          city
          region
          postcode
          country
          latitude
          longitude
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      ownerProfile {
        id
        slug
        firstName
        lastName
        bio
        picture
        location {
          searchName
          address
          address2
          city
          region
          postcode
          country
          latitude
          longitude
          __typename
        }
        createdAt
        updatedAt
        business {
          id
          verified
          name
          tipping {
            merchantId
            merchant
            policy
            type
            active
            __typename
          }
          marketing {
            customerId
            subscriptionId
            active
            expirationDate
            sequenceId
            cancelled
            cancelledAt
            __typename
          }
          createdAt
          updatedAt
          owner
          code
          answers {
            questionId
            value
            __typename
          }
          isOutlet
          details {
            id
            contactName
            jobRole
            phone
            email
            website
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          ownerProfile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          invites {
            items {
              id
              businessId
              receiver
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          employees {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          brands {
            items {
              id
              businessId
              brandId
              status
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profileId
              brand {
                id
                name
                logo
                active
                productType
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        archived
        details {
          id
          accountType
          isOnboardingComplete
          email
          feedRegion
          tippingAccount {
            code
            merchant
            accountId
            verified
            missingRequirements {
              key
              description
              __typename
            }
            pendingValidation
            bankDetails {
              name
              sortCode
              last4Digits
              __typename
            }
            __typename
          }
          phone_number
          phoneNumberConfirmed
          pushNotificationToken
          pushNotificationReadDate
          createdAt
          updatedAt
          __typename
        }
        employers {
          items {
            id
            jobRole
            businessId
            profileId
            employeeId
            tippingPolicyAcknowledgedDate
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            confirmed
            __typename
          }
          nextToken
          __typename
        }
        experiences {
          items {
            id
            jobTitle
            businessId
            companyName
            profileId
            startDate
            endDate
            employmentType
            location
            description
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        jobs {
          items {
            id
            profileId
            jobId
            coverLetter
            saved
            savedDate
            applied
            appliedDate
            createdAt
            updatedAt
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            job {
              id
              owner
              jobType
              businessId
              jobRole
              title
              description
              salary {
                type
                from
                to
                __typename
              }
              region
              requireCV
              requireCoverLetter
              createdAt
              archived
              startDate
              endDate
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              reported {
                nextToken
                __typename
              }
              __typename
            }
            rejected
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      invites {
        items {
          id
          businessId
          receiver
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      employees {
        items {
          id
          jobRole
          businessId
          profileId
          employeeId
          tippingPolicyAcknowledgedDate
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          confirmed
          __typename
        }
        nextToken
        __typename
      }
      brands {
        items {
          id
          businessId
          brandId
          status
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profileId
          brand {
            id
            name
            logo
            active
            productType
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    profileId
    brand {
      id
      name
      logo
      active
      productType
      createdAt
      updatedAt
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteBusinessBrandsMutationVariables,
  APITypes.DeleteBusinessBrandsMutation
>;
export const deleteBrand = /* GraphQL */ `mutation DeleteBrand(
  $input: DeleteBrandInput!
  $condition: ModelBrandConditionInput
) {
  deleteBrand(input: $input, condition: $condition) {
    id
    name
    logo
    active
    productType
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteBrandMutationVariables,
  APITypes.DeleteBrandMutation
>;
export const deleteExperience = /* GraphQL */ `mutation DeleteExperience(
  $input: DeleteExperienceInput!
  $condition: ModelExperienceConditionInput
) {
  deleteExperience(input: $input, condition: $condition) {
    id
    jobTitle
    businessId
    companyName
    profileId
    startDate
    endDate
    employmentType
    location
    description
    createdAt
    updatedAt
    business {
      id
      verified
      name
      tipping {
        merchantId
        merchant
        policy
        type
        active
        __typename
      }
      marketing {
        customerId
        subscriptionId
        active
        expirationDate
        sequenceId
        cancelled
        cancelledAt
        __typename
      }
      createdAt
      updatedAt
      owner
      code
      answers {
        questionId
        value
        __typename
      }
      isOutlet
      details {
        id
        contactName
        jobRole
        phone
        email
        website
        location {
          searchName
          address
          address2
          city
          region
          postcode
          country
          latitude
          longitude
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      ownerProfile {
        id
        slug
        firstName
        lastName
        bio
        picture
        location {
          searchName
          address
          address2
          city
          region
          postcode
          country
          latitude
          longitude
          __typename
        }
        createdAt
        updatedAt
        business {
          id
          verified
          name
          tipping {
            merchantId
            merchant
            policy
            type
            active
            __typename
          }
          marketing {
            customerId
            subscriptionId
            active
            expirationDate
            sequenceId
            cancelled
            cancelledAt
            __typename
          }
          createdAt
          updatedAt
          owner
          code
          answers {
            questionId
            value
            __typename
          }
          isOutlet
          details {
            id
            contactName
            jobRole
            phone
            email
            website
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          ownerProfile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          invites {
            items {
              id
              businessId
              receiver
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          employees {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          brands {
            items {
              id
              businessId
              brandId
              status
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profileId
              brand {
                id
                name
                logo
                active
                productType
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        archived
        details {
          id
          accountType
          isOnboardingComplete
          email
          feedRegion
          tippingAccount {
            code
            merchant
            accountId
            verified
            missingRequirements {
              key
              description
              __typename
            }
            pendingValidation
            bankDetails {
              name
              sortCode
              last4Digits
              __typename
            }
            __typename
          }
          phone_number
          phoneNumberConfirmed
          pushNotificationToken
          pushNotificationReadDate
          createdAt
          updatedAt
          __typename
        }
        employers {
          items {
            id
            jobRole
            businessId
            profileId
            employeeId
            tippingPolicyAcknowledgedDate
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            confirmed
            __typename
          }
          nextToken
          __typename
        }
        experiences {
          items {
            id
            jobTitle
            businessId
            companyName
            profileId
            startDate
            endDate
            employmentType
            location
            description
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        jobs {
          items {
            id
            profileId
            jobId
            coverLetter
            saved
            savedDate
            applied
            appliedDate
            createdAt
            updatedAt
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            job {
              id
              owner
              jobType
              businessId
              jobRole
              title
              description
              salary {
                type
                from
                to
                __typename
              }
              region
              requireCV
              requireCoverLetter
              createdAt
              archived
              startDate
              endDate
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              reported {
                nextToken
                __typename
              }
              __typename
            }
            rejected
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      invites {
        items {
          id
          businessId
          receiver
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      employees {
        items {
          id
          jobRole
          businessId
          profileId
          employeeId
          tippingPolicyAcknowledgedDate
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          confirmed
          __typename
        }
        nextToken
        __typename
      }
      brands {
        items {
          id
          businessId
          brandId
          status
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profileId
          brand {
            id
            name
            logo
            active
            productType
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    profile {
      id
      slug
      firstName
      lastName
      bio
      picture
      location {
        searchName
        address
        address2
        city
        region
        postcode
        country
        latitude
        longitude
        __typename
      }
      createdAt
      updatedAt
      business {
        id
        verified
        name
        tipping {
          merchantId
          merchant
          policy
          type
          active
          __typename
        }
        marketing {
          customerId
          subscriptionId
          active
          expirationDate
          sequenceId
          cancelled
          cancelledAt
          __typename
        }
        createdAt
        updatedAt
        owner
        code
        answers {
          questionId
          value
          __typename
        }
        isOutlet
        details {
          id
          contactName
          jobRole
          phone
          email
          website
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        ownerProfile {
          id
          slug
          firstName
          lastName
          bio
          picture
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          archived
          details {
            id
            accountType
            isOnboardingComplete
            email
            feedRegion
            tippingAccount {
              code
              merchant
              accountId
              verified
              missingRequirements {
                key
                description
                __typename
              }
              pendingValidation
              bankDetails {
                name
                sortCode
                last4Digits
                __typename
              }
              __typename
            }
            phone_number
            phoneNumberConfirmed
            pushNotificationToken
            pushNotificationReadDate
            createdAt
            updatedAt
            __typename
          }
          employers {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          experiences {
            items {
              id
              jobTitle
              businessId
              companyName
              profileId
              startDate
              endDate
              employmentType
              location
              description
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          jobs {
            items {
              id
              profileId
              jobId
              coverLetter
              saved
              savedDate
              applied
              appliedDate
              createdAt
              updatedAt
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              job {
                id
                owner
                jobType
                businessId
                jobRole
                title
                description
                region
                requireCV
                requireCoverLetter
                createdAt
                archived
                startDate
                endDate
                updatedAt
                __typename
              }
              rejected
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        invites {
          items {
            id
            businessId
            receiver
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        employees {
          items {
            id
            jobRole
            businessId
            profileId
            employeeId
            tippingPolicyAcknowledgedDate
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            confirmed
            __typename
          }
          nextToken
          __typename
        }
        brands {
          items {
            id
            businessId
            brandId
            status
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profileId
            brand {
              id
              name
              logo
              active
              productType
              createdAt
              updatedAt
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      archived
      details {
        id
        accountType
        isOnboardingComplete
        email
        feedRegion
        tippingAccount {
          code
          merchant
          accountId
          verified
          missingRequirements {
            key
            description
            __typename
          }
          pendingValidation
          bankDetails {
            name
            sortCode
            last4Digits
            __typename
          }
          __typename
        }
        phone_number
        phoneNumberConfirmed
        pushNotificationToken
        pushNotificationReadDate
        createdAt
        updatedAt
        __typename
      }
      employers {
        items {
          id
          jobRole
          businessId
          profileId
          employeeId
          tippingPolicyAcknowledgedDate
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          confirmed
          __typename
        }
        nextToken
        __typename
      }
      experiences {
        items {
          id
          jobTitle
          businessId
          companyName
          profileId
          startDate
          endDate
          employmentType
          location
          description
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      jobs {
        items {
          id
          profileId
          jobId
          coverLetter
          saved
          savedDate
          applied
          appliedDate
          createdAt
          updatedAt
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          job {
            id
            owner
            jobType
            businessId
            jobRole
            title
            description
            salary {
              type
              from
              to
              __typename
            }
            region
            requireCV
            requireCoverLetter
            createdAt
            archived
            startDate
            endDate
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            reported {
              items {
                id
                postId
                userId
                commentId
                jobId
                creatorId
                createdAt
                message
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          rejected
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteExperienceMutationVariables,
  APITypes.DeleteExperienceMutation
>;
export const deleteJob = /* GraphQL */ `mutation DeleteJob(
  $input: DeleteJobInput!
  $condition: ModelJobConditionInput
) {
  deleteJob(input: $input, condition: $condition) {
    id
    owner
    jobType
    businessId
    jobRole
    title
    description
    salary {
      type
      from
      to
      __typename
    }
    region
    requireCV
    requireCoverLetter
    createdAt
    archived
    startDate
    endDate
    updatedAt
    business {
      id
      verified
      name
      tipping {
        merchantId
        merchant
        policy
        type
        active
        __typename
      }
      marketing {
        customerId
        subscriptionId
        active
        expirationDate
        sequenceId
        cancelled
        cancelledAt
        __typename
      }
      createdAt
      updatedAt
      owner
      code
      answers {
        questionId
        value
        __typename
      }
      isOutlet
      details {
        id
        contactName
        jobRole
        phone
        email
        website
        location {
          searchName
          address
          address2
          city
          region
          postcode
          country
          latitude
          longitude
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      ownerProfile {
        id
        slug
        firstName
        lastName
        bio
        picture
        location {
          searchName
          address
          address2
          city
          region
          postcode
          country
          latitude
          longitude
          __typename
        }
        createdAt
        updatedAt
        business {
          id
          verified
          name
          tipping {
            merchantId
            merchant
            policy
            type
            active
            __typename
          }
          marketing {
            customerId
            subscriptionId
            active
            expirationDate
            sequenceId
            cancelled
            cancelledAt
            __typename
          }
          createdAt
          updatedAt
          owner
          code
          answers {
            questionId
            value
            __typename
          }
          isOutlet
          details {
            id
            contactName
            jobRole
            phone
            email
            website
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          ownerProfile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          invites {
            items {
              id
              businessId
              receiver
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          employees {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          brands {
            items {
              id
              businessId
              brandId
              status
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profileId
              brand {
                id
                name
                logo
                active
                productType
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        archived
        details {
          id
          accountType
          isOnboardingComplete
          email
          feedRegion
          tippingAccount {
            code
            merchant
            accountId
            verified
            missingRequirements {
              key
              description
              __typename
            }
            pendingValidation
            bankDetails {
              name
              sortCode
              last4Digits
              __typename
            }
            __typename
          }
          phone_number
          phoneNumberConfirmed
          pushNotificationToken
          pushNotificationReadDate
          createdAt
          updatedAt
          __typename
        }
        employers {
          items {
            id
            jobRole
            businessId
            profileId
            employeeId
            tippingPolicyAcknowledgedDate
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            confirmed
            __typename
          }
          nextToken
          __typename
        }
        experiences {
          items {
            id
            jobTitle
            businessId
            companyName
            profileId
            startDate
            endDate
            employmentType
            location
            description
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        jobs {
          items {
            id
            profileId
            jobId
            coverLetter
            saved
            savedDate
            applied
            appliedDate
            createdAt
            updatedAt
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            job {
              id
              owner
              jobType
              businessId
              jobRole
              title
              description
              salary {
                type
                from
                to
                __typename
              }
              region
              requireCV
              requireCoverLetter
              createdAt
              archived
              startDate
              endDate
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              reported {
                nextToken
                __typename
              }
              __typename
            }
            rejected
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      invites {
        items {
          id
          businessId
          receiver
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      employees {
        items {
          id
          jobRole
          businessId
          profileId
          employeeId
          tippingPolicyAcknowledgedDate
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          confirmed
          __typename
        }
        nextToken
        __typename
      }
      brands {
        items {
          id
          businessId
          brandId
          status
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profileId
          brand {
            id
            name
            logo
            active
            productType
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    reported {
      items {
        id
        postId
        userId
        commentId
        jobId
        creatorId
        createdAt
        message
        updatedAt
        creator {
          id
          slug
          firstName
          lastName
          bio
          picture
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          archived
          details {
            id
            accountType
            isOnboardingComplete
            email
            feedRegion
            tippingAccount {
              code
              merchant
              accountId
              verified
              missingRequirements {
                key
                description
                __typename
              }
              pendingValidation
              bankDetails {
                name
                sortCode
                last4Digits
                __typename
              }
              __typename
            }
            phone_number
            phoneNumberConfirmed
            pushNotificationToken
            pushNotificationReadDate
            createdAt
            updatedAt
            __typename
          }
          employers {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          experiences {
            items {
              id
              jobTitle
              businessId
              companyName
              profileId
              startDate
              endDate
              employmentType
              location
              description
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          jobs {
            items {
              id
              profileId
              jobId
              coverLetter
              saved
              savedDate
              applied
              appliedDate
              createdAt
              updatedAt
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              job {
                id
                owner
                jobType
                businessId
                jobRole
                title
                description
                region
                requireCV
                requireCoverLetter
                createdAt
                archived
                startDate
                endDate
                updatedAt
                __typename
              }
              rejected
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteJobMutationVariables,
  APITypes.DeleteJobMutation
>;
export const deleteProfileJob = /* GraphQL */ `mutation DeleteProfileJob(
  $input: DeleteProfileJobInput!
  $condition: ModelProfileJobConditionInput
) {
  deleteProfileJob(input: $input, condition: $condition) {
    id
    profileId
    jobId
    coverLetter
    saved
    savedDate
    applied
    appliedDate
    createdAt
    updatedAt
    profile {
      id
      slug
      firstName
      lastName
      bio
      picture
      location {
        searchName
        address
        address2
        city
        region
        postcode
        country
        latitude
        longitude
        __typename
      }
      createdAt
      updatedAt
      business {
        id
        verified
        name
        tipping {
          merchantId
          merchant
          policy
          type
          active
          __typename
        }
        marketing {
          customerId
          subscriptionId
          active
          expirationDate
          sequenceId
          cancelled
          cancelledAt
          __typename
        }
        createdAt
        updatedAt
        owner
        code
        answers {
          questionId
          value
          __typename
        }
        isOutlet
        details {
          id
          contactName
          jobRole
          phone
          email
          website
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        ownerProfile {
          id
          slug
          firstName
          lastName
          bio
          picture
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          archived
          details {
            id
            accountType
            isOnboardingComplete
            email
            feedRegion
            tippingAccount {
              code
              merchant
              accountId
              verified
              missingRequirements {
                key
                description
                __typename
              }
              pendingValidation
              bankDetails {
                name
                sortCode
                last4Digits
                __typename
              }
              __typename
            }
            phone_number
            phoneNumberConfirmed
            pushNotificationToken
            pushNotificationReadDate
            createdAt
            updatedAt
            __typename
          }
          employers {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          experiences {
            items {
              id
              jobTitle
              businessId
              companyName
              profileId
              startDate
              endDate
              employmentType
              location
              description
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          jobs {
            items {
              id
              profileId
              jobId
              coverLetter
              saved
              savedDate
              applied
              appliedDate
              createdAt
              updatedAt
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              job {
                id
                owner
                jobType
                businessId
                jobRole
                title
                description
                region
                requireCV
                requireCoverLetter
                createdAt
                archived
                startDate
                endDate
                updatedAt
                __typename
              }
              rejected
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        invites {
          items {
            id
            businessId
            receiver
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        employees {
          items {
            id
            jobRole
            businessId
            profileId
            employeeId
            tippingPolicyAcknowledgedDate
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            confirmed
            __typename
          }
          nextToken
          __typename
        }
        brands {
          items {
            id
            businessId
            brandId
            status
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profileId
            brand {
              id
              name
              logo
              active
              productType
              createdAt
              updatedAt
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      archived
      details {
        id
        accountType
        isOnboardingComplete
        email
        feedRegion
        tippingAccount {
          code
          merchant
          accountId
          verified
          missingRequirements {
            key
            description
            __typename
          }
          pendingValidation
          bankDetails {
            name
            sortCode
            last4Digits
            __typename
          }
          __typename
        }
        phone_number
        phoneNumberConfirmed
        pushNotificationToken
        pushNotificationReadDate
        createdAt
        updatedAt
        __typename
      }
      employers {
        items {
          id
          jobRole
          businessId
          profileId
          employeeId
          tippingPolicyAcknowledgedDate
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          confirmed
          __typename
        }
        nextToken
        __typename
      }
      experiences {
        items {
          id
          jobTitle
          businessId
          companyName
          profileId
          startDate
          endDate
          employmentType
          location
          description
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      jobs {
        items {
          id
          profileId
          jobId
          coverLetter
          saved
          savedDate
          applied
          appliedDate
          createdAt
          updatedAt
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          job {
            id
            owner
            jobType
            businessId
            jobRole
            title
            description
            salary {
              type
              from
              to
              __typename
            }
            region
            requireCV
            requireCoverLetter
            createdAt
            archived
            startDate
            endDate
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            reported {
              items {
                id
                postId
                userId
                commentId
                jobId
                creatorId
                createdAt
                message
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          rejected
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    job {
      id
      owner
      jobType
      businessId
      jobRole
      title
      description
      salary {
        type
        from
        to
        __typename
      }
      region
      requireCV
      requireCoverLetter
      createdAt
      archived
      startDate
      endDate
      updatedAt
      business {
        id
        verified
        name
        tipping {
          merchantId
          merchant
          policy
          type
          active
          __typename
        }
        marketing {
          customerId
          subscriptionId
          active
          expirationDate
          sequenceId
          cancelled
          cancelledAt
          __typename
        }
        createdAt
        updatedAt
        owner
        code
        answers {
          questionId
          value
          __typename
        }
        isOutlet
        details {
          id
          contactName
          jobRole
          phone
          email
          website
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        ownerProfile {
          id
          slug
          firstName
          lastName
          bio
          picture
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          archived
          details {
            id
            accountType
            isOnboardingComplete
            email
            feedRegion
            tippingAccount {
              code
              merchant
              accountId
              verified
              missingRequirements {
                key
                description
                __typename
              }
              pendingValidation
              bankDetails {
                name
                sortCode
                last4Digits
                __typename
              }
              __typename
            }
            phone_number
            phoneNumberConfirmed
            pushNotificationToken
            pushNotificationReadDate
            createdAt
            updatedAt
            __typename
          }
          employers {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          experiences {
            items {
              id
              jobTitle
              businessId
              companyName
              profileId
              startDate
              endDate
              employmentType
              location
              description
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          jobs {
            items {
              id
              profileId
              jobId
              coverLetter
              saved
              savedDate
              applied
              appliedDate
              createdAt
              updatedAt
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              job {
                id
                owner
                jobType
                businessId
                jobRole
                title
                description
                region
                requireCV
                requireCoverLetter
                createdAt
                archived
                startDate
                endDate
                updatedAt
                __typename
              }
              rejected
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        invites {
          items {
            id
            businessId
            receiver
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        employees {
          items {
            id
            jobRole
            businessId
            profileId
            employeeId
            tippingPolicyAcknowledgedDate
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            confirmed
            __typename
          }
          nextToken
          __typename
        }
        brands {
          items {
            id
            businessId
            brandId
            status
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profileId
            brand {
              id
              name
              logo
              active
              productType
              createdAt
              updatedAt
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      reported {
        items {
          id
          postId
          userId
          commentId
          jobId
          creatorId
          createdAt
          message
          updatedAt
          creator {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    rejected
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteProfileJobMutationVariables,
  APITypes.DeleteProfileJobMutation
>;
export const deleteUserNotification = /* GraphQL */ `mutation DeleteUserNotification(
  $input: DeleteUserNotificationInput!
  $condition: ModelUserNotificationConditionInput
) {
  deleteUserNotification(input: $input, condition: $condition) {
    id
    receiverId
    senderId
    type
    targetId
    subTargetId
    additionalTargetId
    read
    title
    message
    createdAt
    updatedAt
    sender {
      id
      slug
      firstName
      lastName
      bio
      picture
      location {
        searchName
        address
        address2
        city
        region
        postcode
        country
        latitude
        longitude
        __typename
      }
      createdAt
      updatedAt
      business {
        id
        verified
        name
        tipping {
          merchantId
          merchant
          policy
          type
          active
          __typename
        }
        marketing {
          customerId
          subscriptionId
          active
          expirationDate
          sequenceId
          cancelled
          cancelledAt
          __typename
        }
        createdAt
        updatedAt
        owner
        code
        answers {
          questionId
          value
          __typename
        }
        isOutlet
        details {
          id
          contactName
          jobRole
          phone
          email
          website
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        ownerProfile {
          id
          slug
          firstName
          lastName
          bio
          picture
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          archived
          details {
            id
            accountType
            isOnboardingComplete
            email
            feedRegion
            tippingAccount {
              code
              merchant
              accountId
              verified
              missingRequirements {
                key
                description
                __typename
              }
              pendingValidation
              bankDetails {
                name
                sortCode
                last4Digits
                __typename
              }
              __typename
            }
            phone_number
            phoneNumberConfirmed
            pushNotificationToken
            pushNotificationReadDate
            createdAt
            updatedAt
            __typename
          }
          employers {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          experiences {
            items {
              id
              jobTitle
              businessId
              companyName
              profileId
              startDate
              endDate
              employmentType
              location
              description
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          jobs {
            items {
              id
              profileId
              jobId
              coverLetter
              saved
              savedDate
              applied
              appliedDate
              createdAt
              updatedAt
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              job {
                id
                owner
                jobType
                businessId
                jobRole
                title
                description
                region
                requireCV
                requireCoverLetter
                createdAt
                archived
                startDate
                endDate
                updatedAt
                __typename
              }
              rejected
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        invites {
          items {
            id
            businessId
            receiver
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        employees {
          items {
            id
            jobRole
            businessId
            profileId
            employeeId
            tippingPolicyAcknowledgedDate
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            confirmed
            __typename
          }
          nextToken
          __typename
        }
        brands {
          items {
            id
            businessId
            brandId
            status
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profileId
            brand {
              id
              name
              logo
              active
              productType
              createdAt
              updatedAt
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      archived
      details {
        id
        accountType
        isOnboardingComplete
        email
        feedRegion
        tippingAccount {
          code
          merchant
          accountId
          verified
          missingRequirements {
            key
            description
            __typename
          }
          pendingValidation
          bankDetails {
            name
            sortCode
            last4Digits
            __typename
          }
          __typename
        }
        phone_number
        phoneNumberConfirmed
        pushNotificationToken
        pushNotificationReadDate
        createdAt
        updatedAt
        __typename
      }
      employers {
        items {
          id
          jobRole
          businessId
          profileId
          employeeId
          tippingPolicyAcknowledgedDate
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          confirmed
          __typename
        }
        nextToken
        __typename
      }
      experiences {
        items {
          id
          jobTitle
          businessId
          companyName
          profileId
          startDate
          endDate
          employmentType
          location
          description
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      jobs {
        items {
          id
          profileId
          jobId
          coverLetter
          saved
          savedDate
          applied
          appliedDate
          createdAt
          updatedAt
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          job {
            id
            owner
            jobType
            businessId
            jobRole
            title
            description
            salary {
              type
              from
              to
              __typename
            }
            region
            requireCV
            requireCoverLetter
            createdAt
            archived
            startDate
            endDate
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            reported {
              items {
                id
                postId
                userId
                commentId
                jobId
                creatorId
                createdAt
                message
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          rejected
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteUserNotificationMutationVariables,
  APITypes.DeleteUserNotificationMutation
>;
export const updatePublicEmail = /* GraphQL */ `mutation UpdatePublicEmail(
  $input: UpdatePublicEmailInput!
  $condition: ModelPublicEmailConditionInput
) {
  updatePublicEmail(input: $input, condition: $condition) {
    id
    email
    allowMarketing
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdatePublicEmailMutationVariables,
  APITypes.UpdatePublicEmailMutation
>;
export const deletePublicEmail = /* GraphQL */ `mutation DeletePublicEmail(
  $input: DeletePublicEmailInput!
  $condition: ModelPublicEmailConditionInput
) {
  deletePublicEmail(input: $input, condition: $condition) {
    id
    email
    allowMarketing
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeletePublicEmailMutationVariables,
  APITypes.DeletePublicEmailMutation
>;
export const deleteTipSplit = /* GraphQL */ `mutation DeleteTipSplit(
  $input: DeleteTipSplitInput!
  $condition: ModelTipSplitConditionInput
) {
  deleteTipSplit(input: $input, condition: $condition) {
    id
    amount
    owner
    status
    from
    to
    createdAt
    updatedAt
    ownerProfile {
      id
      slug
      firstName
      lastName
      bio
      picture
      location {
        searchName
        address
        address2
        city
        region
        postcode
        country
        latitude
        longitude
        __typename
      }
      createdAt
      updatedAt
      business {
        id
        verified
        name
        tipping {
          merchantId
          merchant
          policy
          type
          active
          __typename
        }
        marketing {
          customerId
          subscriptionId
          active
          expirationDate
          sequenceId
          cancelled
          cancelledAt
          __typename
        }
        createdAt
        updatedAt
        owner
        code
        answers {
          questionId
          value
          __typename
        }
        isOutlet
        details {
          id
          contactName
          jobRole
          phone
          email
          website
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        ownerProfile {
          id
          slug
          firstName
          lastName
          bio
          picture
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          archived
          details {
            id
            accountType
            isOnboardingComplete
            email
            feedRegion
            tippingAccount {
              code
              merchant
              accountId
              verified
              missingRequirements {
                key
                description
                __typename
              }
              pendingValidation
              bankDetails {
                name
                sortCode
                last4Digits
                __typename
              }
              __typename
            }
            phone_number
            phoneNumberConfirmed
            pushNotificationToken
            pushNotificationReadDate
            createdAt
            updatedAt
            __typename
          }
          employers {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          experiences {
            items {
              id
              jobTitle
              businessId
              companyName
              profileId
              startDate
              endDate
              employmentType
              location
              description
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          jobs {
            items {
              id
              profileId
              jobId
              coverLetter
              saved
              savedDate
              applied
              appliedDate
              createdAt
              updatedAt
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              job {
                id
                owner
                jobType
                businessId
                jobRole
                title
                description
                region
                requireCV
                requireCoverLetter
                createdAt
                archived
                startDate
                endDate
                updatedAt
                __typename
              }
              rejected
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        invites {
          items {
            id
            businessId
            receiver
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        employees {
          items {
            id
            jobRole
            businessId
            profileId
            employeeId
            tippingPolicyAcknowledgedDate
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            confirmed
            __typename
          }
          nextToken
          __typename
        }
        brands {
          items {
            id
            businessId
            brandId
            status
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profileId
            brand {
              id
              name
              logo
              active
              productType
              createdAt
              updatedAt
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      archived
      details {
        id
        accountType
        isOnboardingComplete
        email
        feedRegion
        tippingAccount {
          code
          merchant
          accountId
          verified
          missingRequirements {
            key
            description
            __typename
          }
          pendingValidation
          bankDetails {
            name
            sortCode
            last4Digits
            __typename
          }
          __typename
        }
        phone_number
        phoneNumberConfirmed
        pushNotificationToken
        pushNotificationReadDate
        createdAt
        updatedAt
        __typename
      }
      employers {
        items {
          id
          jobRole
          businessId
          profileId
          employeeId
          tippingPolicyAcknowledgedDate
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          confirmed
          __typename
        }
        nextToken
        __typename
      }
      experiences {
        items {
          id
          jobTitle
          businessId
          companyName
          profileId
          startDate
          endDate
          employmentType
          location
          description
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      jobs {
        items {
          id
          profileId
          jobId
          coverLetter
          saved
          savedDate
          applied
          appliedDate
          createdAt
          updatedAt
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          job {
            id
            owner
            jobType
            businessId
            jobRole
            title
            description
            salary {
              type
              from
              to
              __typename
            }
            region
            requireCV
            requireCoverLetter
            createdAt
            archived
            startDate
            endDate
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            reported {
              items {
                id
                postId
                userId
                commentId
                jobId
                creatorId
                createdAt
                message
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          rejected
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    transfers {
      items {
        id
        splitId
        employeeId
        amount
        status
        createdAt
        updatedAt
        employee {
          id
          slug
          firstName
          lastName
          bio
          picture
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          archived
          details {
            id
            accountType
            isOnboardingComplete
            email
            feedRegion
            tippingAccount {
              code
              merchant
              accountId
              verified
              missingRequirements {
                key
                description
                __typename
              }
              pendingValidation
              bankDetails {
                name
                sortCode
                last4Digits
                __typename
              }
              __typename
            }
            phone_number
            phoneNumberConfirmed
            pushNotificationToken
            pushNotificationReadDate
            createdAt
            updatedAt
            __typename
          }
          employers {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          experiences {
            items {
              id
              jobTitle
              businessId
              companyName
              profileId
              startDate
              endDate
              employmentType
              location
              description
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          jobs {
            items {
              id
              profileId
              jobId
              coverLetter
              saved
              savedDate
              applied
              appliedDate
              createdAt
              updatedAt
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              job {
                id
                owner
                jobType
                businessId
                jobRole
                title
                description
                region
                requireCV
                requireCoverLetter
                createdAt
                archived
                startDate
                endDate
                updatedAt
                __typename
              }
              rejected
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    transactions {
      items {
        id
        splitId
        receiverId
        amount
        fee
        currency
        createdAt
        status
        type
        source
        payoutInvoiceId
        transactionId
        updatedAt
        receiver {
          id
          slug
          firstName
          lastName
          bio
          picture
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          archived
          details {
            id
            accountType
            isOnboardingComplete
            email
            feedRegion
            tippingAccount {
              code
              merchant
              accountId
              verified
              missingRequirements {
                key
                description
                __typename
              }
              pendingValidation
              bankDetails {
                name
                sortCode
                last4Digits
                __typename
              }
              __typename
            }
            phone_number
            phoneNumberConfirmed
            pushNotificationToken
            pushNotificationReadDate
            createdAt
            updatedAt
            __typename
          }
          employers {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          experiences {
            items {
              id
              jobTitle
              businessId
              companyName
              profileId
              startDate
              endDate
              employmentType
              location
              description
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          jobs {
            items {
              id
              profileId
              jobId
              coverLetter
              saved
              savedDate
              applied
              appliedDate
              createdAt
              updatedAt
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              job {
                id
                owner
                jobType
                businessId
                jobRole
                title
                description
                region
                requireCV
                requireCoverLetter
                createdAt
                archived
                startDate
                endDate
                updatedAt
                __typename
              }
              rejected
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteTipSplitMutationVariables,
  APITypes.DeleteTipSplitMutation
>;
export const deleteTipTransaction = /* GraphQL */ `mutation DeleteTipTransaction(
  $input: DeleteTipTransactionInput!
  $condition: ModelTipTransactionConditionInput
) {
  deleteTipTransaction(input: $input, condition: $condition) {
    id
    splitId
    receiverId
    amount
    fee
    currency
    createdAt
    status
    type
    source
    payoutInvoiceId
    transactionId
    updatedAt
    receiver {
      id
      slug
      firstName
      lastName
      bio
      picture
      location {
        searchName
        address
        address2
        city
        region
        postcode
        country
        latitude
        longitude
        __typename
      }
      createdAt
      updatedAt
      business {
        id
        verified
        name
        tipping {
          merchantId
          merchant
          policy
          type
          active
          __typename
        }
        marketing {
          customerId
          subscriptionId
          active
          expirationDate
          sequenceId
          cancelled
          cancelledAt
          __typename
        }
        createdAt
        updatedAt
        owner
        code
        answers {
          questionId
          value
          __typename
        }
        isOutlet
        details {
          id
          contactName
          jobRole
          phone
          email
          website
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        ownerProfile {
          id
          slug
          firstName
          lastName
          bio
          picture
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          archived
          details {
            id
            accountType
            isOnboardingComplete
            email
            feedRegion
            tippingAccount {
              code
              merchant
              accountId
              verified
              missingRequirements {
                key
                description
                __typename
              }
              pendingValidation
              bankDetails {
                name
                sortCode
                last4Digits
                __typename
              }
              __typename
            }
            phone_number
            phoneNumberConfirmed
            pushNotificationToken
            pushNotificationReadDate
            createdAt
            updatedAt
            __typename
          }
          employers {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          experiences {
            items {
              id
              jobTitle
              businessId
              companyName
              profileId
              startDate
              endDate
              employmentType
              location
              description
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          jobs {
            items {
              id
              profileId
              jobId
              coverLetter
              saved
              savedDate
              applied
              appliedDate
              createdAt
              updatedAt
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              job {
                id
                owner
                jobType
                businessId
                jobRole
                title
                description
                region
                requireCV
                requireCoverLetter
                createdAt
                archived
                startDate
                endDate
                updatedAt
                __typename
              }
              rejected
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        invites {
          items {
            id
            businessId
            receiver
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        employees {
          items {
            id
            jobRole
            businessId
            profileId
            employeeId
            tippingPolicyAcknowledgedDate
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            confirmed
            __typename
          }
          nextToken
          __typename
        }
        brands {
          items {
            id
            businessId
            brandId
            status
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profileId
            brand {
              id
              name
              logo
              active
              productType
              createdAt
              updatedAt
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      archived
      details {
        id
        accountType
        isOnboardingComplete
        email
        feedRegion
        tippingAccount {
          code
          merchant
          accountId
          verified
          missingRequirements {
            key
            description
            __typename
          }
          pendingValidation
          bankDetails {
            name
            sortCode
            last4Digits
            __typename
          }
          __typename
        }
        phone_number
        phoneNumberConfirmed
        pushNotificationToken
        pushNotificationReadDate
        createdAt
        updatedAt
        __typename
      }
      employers {
        items {
          id
          jobRole
          businessId
          profileId
          employeeId
          tippingPolicyAcknowledgedDate
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          confirmed
          __typename
        }
        nextToken
        __typename
      }
      experiences {
        items {
          id
          jobTitle
          businessId
          companyName
          profileId
          startDate
          endDate
          employmentType
          location
          description
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      jobs {
        items {
          id
          profileId
          jobId
          coverLetter
          saved
          savedDate
          applied
          appliedDate
          createdAt
          updatedAt
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          job {
            id
            owner
            jobType
            businessId
            jobRole
            title
            description
            salary {
              type
              from
              to
              __typename
            }
            region
            requireCV
            requireCoverLetter
            createdAt
            archived
            startDate
            endDate
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            reported {
              items {
                id
                postId
                userId
                commentId
                jobId
                creatorId
                createdAt
                message
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          rejected
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteTipTransactionMutationVariables,
  APITypes.DeleteTipTransactionMutation
>;
export const updateUserMessage = /* GraphQL */ `mutation UpdateUserMessage(
  $input: UpdateUserMessageInput!
  $condition: ModelUserMessageConditionInput
) {
  updateUserMessage(input: $input, condition: $condition) {
    id
    senderId
    receiverId
    content
    createdAt
    updatedAt
    sender {
      id
      slug
      firstName
      lastName
      bio
      picture
      location {
        searchName
        address
        address2
        city
        region
        postcode
        country
        latitude
        longitude
        __typename
      }
      createdAt
      updatedAt
      business {
        id
        verified
        name
        tipping {
          merchantId
          merchant
          policy
          type
          active
          __typename
        }
        marketing {
          customerId
          subscriptionId
          active
          expirationDate
          sequenceId
          cancelled
          cancelledAt
          __typename
        }
        createdAt
        updatedAt
        owner
        code
        answers {
          questionId
          value
          __typename
        }
        isOutlet
        details {
          id
          contactName
          jobRole
          phone
          email
          website
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        ownerProfile {
          id
          slug
          firstName
          lastName
          bio
          picture
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          archived
          details {
            id
            accountType
            isOnboardingComplete
            email
            feedRegion
            tippingAccount {
              code
              merchant
              accountId
              verified
              missingRequirements {
                key
                description
                __typename
              }
              pendingValidation
              bankDetails {
                name
                sortCode
                last4Digits
                __typename
              }
              __typename
            }
            phone_number
            phoneNumberConfirmed
            pushNotificationToken
            pushNotificationReadDate
            createdAt
            updatedAt
            __typename
          }
          employers {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          experiences {
            items {
              id
              jobTitle
              businessId
              companyName
              profileId
              startDate
              endDate
              employmentType
              location
              description
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          jobs {
            items {
              id
              profileId
              jobId
              coverLetter
              saved
              savedDate
              applied
              appliedDate
              createdAt
              updatedAt
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              job {
                id
                owner
                jobType
                businessId
                jobRole
                title
                description
                region
                requireCV
                requireCoverLetter
                createdAt
                archived
                startDate
                endDate
                updatedAt
                __typename
              }
              rejected
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        invites {
          items {
            id
            businessId
            receiver
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        employees {
          items {
            id
            jobRole
            businessId
            profileId
            employeeId
            tippingPolicyAcknowledgedDate
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            confirmed
            __typename
          }
          nextToken
          __typename
        }
        brands {
          items {
            id
            businessId
            brandId
            status
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profileId
            brand {
              id
              name
              logo
              active
              productType
              createdAt
              updatedAt
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      archived
      details {
        id
        accountType
        isOnboardingComplete
        email
        feedRegion
        tippingAccount {
          code
          merchant
          accountId
          verified
          missingRequirements {
            key
            description
            __typename
          }
          pendingValidation
          bankDetails {
            name
            sortCode
            last4Digits
            __typename
          }
          __typename
        }
        phone_number
        phoneNumberConfirmed
        pushNotificationToken
        pushNotificationReadDate
        createdAt
        updatedAt
        __typename
      }
      employers {
        items {
          id
          jobRole
          businessId
          profileId
          employeeId
          tippingPolicyAcknowledgedDate
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          confirmed
          __typename
        }
        nextToken
        __typename
      }
      experiences {
        items {
          id
          jobTitle
          businessId
          companyName
          profileId
          startDate
          endDate
          employmentType
          location
          description
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      jobs {
        items {
          id
          profileId
          jobId
          coverLetter
          saved
          savedDate
          applied
          appliedDate
          createdAt
          updatedAt
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          job {
            id
            owner
            jobType
            businessId
            jobRole
            title
            description
            salary {
              type
              from
              to
              __typename
            }
            region
            requireCV
            requireCoverLetter
            createdAt
            archived
            startDate
            endDate
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            reported {
              items {
                id
                postId
                userId
                commentId
                jobId
                creatorId
                createdAt
                message
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          rejected
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserMessageMutationVariables,
  APITypes.UpdateUserMessageMutation
>;
export const deleteUserMessage = /* GraphQL */ `mutation DeleteUserMessage(
  $input: DeleteUserMessageInput!
  $condition: ModelUserMessageConditionInput
) {
  deleteUserMessage(input: $input, condition: $condition) {
    id
    senderId
    receiverId
    content
    createdAt
    updatedAt
    sender {
      id
      slug
      firstName
      lastName
      bio
      picture
      location {
        searchName
        address
        address2
        city
        region
        postcode
        country
        latitude
        longitude
        __typename
      }
      createdAt
      updatedAt
      business {
        id
        verified
        name
        tipping {
          merchantId
          merchant
          policy
          type
          active
          __typename
        }
        marketing {
          customerId
          subscriptionId
          active
          expirationDate
          sequenceId
          cancelled
          cancelledAt
          __typename
        }
        createdAt
        updatedAt
        owner
        code
        answers {
          questionId
          value
          __typename
        }
        isOutlet
        details {
          id
          contactName
          jobRole
          phone
          email
          website
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        ownerProfile {
          id
          slug
          firstName
          lastName
          bio
          picture
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          archived
          details {
            id
            accountType
            isOnboardingComplete
            email
            feedRegion
            tippingAccount {
              code
              merchant
              accountId
              verified
              missingRequirements {
                key
                description
                __typename
              }
              pendingValidation
              bankDetails {
                name
                sortCode
                last4Digits
                __typename
              }
              __typename
            }
            phone_number
            phoneNumberConfirmed
            pushNotificationToken
            pushNotificationReadDate
            createdAt
            updatedAt
            __typename
          }
          employers {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          experiences {
            items {
              id
              jobTitle
              businessId
              companyName
              profileId
              startDate
              endDate
              employmentType
              location
              description
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          jobs {
            items {
              id
              profileId
              jobId
              coverLetter
              saved
              savedDate
              applied
              appliedDate
              createdAt
              updatedAt
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              job {
                id
                owner
                jobType
                businessId
                jobRole
                title
                description
                region
                requireCV
                requireCoverLetter
                createdAt
                archived
                startDate
                endDate
                updatedAt
                __typename
              }
              rejected
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        invites {
          items {
            id
            businessId
            receiver
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        employees {
          items {
            id
            jobRole
            businessId
            profileId
            employeeId
            tippingPolicyAcknowledgedDate
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            confirmed
            __typename
          }
          nextToken
          __typename
        }
        brands {
          items {
            id
            businessId
            brandId
            status
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profileId
            brand {
              id
              name
              logo
              active
              productType
              createdAt
              updatedAt
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      archived
      details {
        id
        accountType
        isOnboardingComplete
        email
        feedRegion
        tippingAccount {
          code
          merchant
          accountId
          verified
          missingRequirements {
            key
            description
            __typename
          }
          pendingValidation
          bankDetails {
            name
            sortCode
            last4Digits
            __typename
          }
          __typename
        }
        phone_number
        phoneNumberConfirmed
        pushNotificationToken
        pushNotificationReadDate
        createdAt
        updatedAt
        __typename
      }
      employers {
        items {
          id
          jobRole
          businessId
          profileId
          employeeId
          tippingPolicyAcknowledgedDate
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          confirmed
          __typename
        }
        nextToken
        __typename
      }
      experiences {
        items {
          id
          jobTitle
          businessId
          companyName
          profileId
          startDate
          endDate
          employmentType
          location
          description
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      jobs {
        items {
          id
          profileId
          jobId
          coverLetter
          saved
          savedDate
          applied
          appliedDate
          createdAt
          updatedAt
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          job {
            id
            owner
            jobType
            businessId
            jobRole
            title
            description
            salary {
              type
              from
              to
              __typename
            }
            region
            requireCV
            requireCoverLetter
            createdAt
            archived
            startDate
            endDate
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            reported {
              items {
                id
                postId
                userId
                commentId
                jobId
                creatorId
                createdAt
                message
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          rejected
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteUserMessageMutationVariables,
  APITypes.DeleteUserMessageMutation
>;
export const createProfileEnterprise = /* GraphQL */ `mutation CreateProfileEnterprise(
  $input: CreateProfileEnterpriseInput!
  $condition: ModelProfileEnterpriseConditionInput
) {
  createProfileEnterprise(input: $input, condition: $condition) {
    id
    name
    address {
      searchName
      address
      address2
      city
      region
      postcode
      country
      latitude
      longitude
      __typename
    }
    companyNumber
    businesses
    active
    createdAt
    updatedAt
    owner
    picture
    contactDetails {
      name
      role
      phone
      email
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateProfileEnterpriseMutationVariables,
  APITypes.CreateProfileEnterpriseMutation
>;
export const updateProfileEnterprise = /* GraphQL */ `mutation UpdateProfileEnterprise(
  $input: UpdateProfileEnterpriseInput!
  $condition: ModelProfileEnterpriseConditionInput
) {
  updateProfileEnterprise(input: $input, condition: $condition) {
    id
    name
    address {
      searchName
      address
      address2
      city
      region
      postcode
      country
      latitude
      longitude
      __typename
    }
    companyNumber
    businesses
    active
    createdAt
    updatedAt
    owner
    picture
    contactDetails {
      name
      role
      phone
      email
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateProfileEnterpriseMutationVariables,
  APITypes.UpdateProfileEnterpriseMutation
>;
export const deleteProfileEnterprise = /* GraphQL */ `mutation DeleteProfileEnterprise(
  $input: DeleteProfileEnterpriseInput!
  $condition: ModelProfileEnterpriseConditionInput
) {
  deleteProfileEnterprise(input: $input, condition: $condition) {
    id
    name
    address {
      searchName
      address
      address2
      city
      region
      postcode
      country
      latitude
      longitude
      __typename
    }
    companyNumber
    businesses
    active
    createdAt
    updatedAt
    owner
    picture
    contactDetails {
      name
      role
      phone
      email
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteProfileEnterpriseMutationVariables,
  APITypes.DeleteProfileEnterpriseMutation
>;
export const createBusinessStatistics = /* GraphQL */ `mutation CreateBusinessStatistics(
  $input: CreateBusinessStatisticsInput!
  $condition: ModelBusinessStatisticsConditionInput
) {
  createBusinessStatistics(input: $input, condition: $condition) {
    id
    data {
      date
      totalTips
      totalTipsCount
      __typename
    }
    updatedAt
    owner
    lastSplitDate
    createdAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateBusinessStatisticsMutationVariables,
  APITypes.CreateBusinessStatisticsMutation
>;
export const updateBusinessStatistics = /* GraphQL */ `mutation UpdateBusinessStatistics(
  $input: UpdateBusinessStatisticsInput!
  $condition: ModelBusinessStatisticsConditionInput
) {
  updateBusinessStatistics(input: $input, condition: $condition) {
    id
    data {
      date
      totalTips
      totalTipsCount
      __typename
    }
    updatedAt
    owner
    lastSplitDate
    createdAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateBusinessStatisticsMutationVariables,
  APITypes.UpdateBusinessStatisticsMutation
>;
export const createBusiness = /* GraphQL */ `mutation CreateBusiness(
  $input: CreateBusinessInput!
  $condition: ModelBusinessConditionInput
) {
  createBusiness(input: $input, condition: $condition) {
    id
    verified
    name
    tipping {
      merchantId
      merchant
      policy
      type
      active
      __typename
    }
    marketing {
      customerId
      subscriptionId
      active
      expirationDate
      sequenceId
      cancelled
      cancelledAt
      __typename
    }
    createdAt
    updatedAt
    owner
    code
    answers {
      questionId
      value
      __typename
    }
    isOutlet
    details {
      id
      contactName
      jobRole
      phone
      email
      website
      location {
        searchName
        address
        address2
        city
        region
        postcode
        country
        latitude
        longitude
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    ownerProfile {
      id
      slug
      firstName
      lastName
      bio
      picture
      location {
        searchName
        address
        address2
        city
        region
        postcode
        country
        latitude
        longitude
        __typename
      }
      createdAt
      updatedAt
      business {
        id
        verified
        name
        tipping {
          merchantId
          merchant
          policy
          type
          active
          __typename
        }
        marketing {
          customerId
          subscriptionId
          active
          expirationDate
          sequenceId
          cancelled
          cancelledAt
          __typename
        }
        createdAt
        updatedAt
        owner
        code
        answers {
          questionId
          value
          __typename
        }
        isOutlet
        details {
          id
          contactName
          jobRole
          phone
          email
          website
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        ownerProfile {
          id
          slug
          firstName
          lastName
          bio
          picture
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          archived
          details {
            id
            accountType
            isOnboardingComplete
            email
            feedRegion
            tippingAccount {
              code
              merchant
              accountId
              verified
              missingRequirements {
                key
                description
                __typename
              }
              pendingValidation
              bankDetails {
                name
                sortCode
                last4Digits
                __typename
              }
              __typename
            }
            phone_number
            phoneNumberConfirmed
            pushNotificationToken
            pushNotificationReadDate
            createdAt
            updatedAt
            __typename
          }
          employers {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          experiences {
            items {
              id
              jobTitle
              businessId
              companyName
              profileId
              startDate
              endDate
              employmentType
              location
              description
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          jobs {
            items {
              id
              profileId
              jobId
              coverLetter
              saved
              savedDate
              applied
              appliedDate
              createdAt
              updatedAt
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              job {
                id
                owner
                jobType
                businessId
                jobRole
                title
                description
                region
                requireCV
                requireCoverLetter
                createdAt
                archived
                startDate
                endDate
                updatedAt
                __typename
              }
              rejected
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        invites {
          items {
            id
            businessId
            receiver
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        employees {
          items {
            id
            jobRole
            businessId
            profileId
            employeeId
            tippingPolicyAcknowledgedDate
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            confirmed
            __typename
          }
          nextToken
          __typename
        }
        brands {
          items {
            id
            businessId
            brandId
            status
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profileId
            brand {
              id
              name
              logo
              active
              productType
              createdAt
              updatedAt
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      archived
      details {
        id
        accountType
        isOnboardingComplete
        email
        feedRegion
        tippingAccount {
          code
          merchant
          accountId
          verified
          missingRequirements {
            key
            description
            __typename
          }
          pendingValidation
          bankDetails {
            name
            sortCode
            last4Digits
            __typename
          }
          __typename
        }
        phone_number
        phoneNumberConfirmed
        pushNotificationToken
        pushNotificationReadDate
        createdAt
        updatedAt
        __typename
      }
      employers {
        items {
          id
          jobRole
          businessId
          profileId
          employeeId
          tippingPolicyAcknowledgedDate
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          confirmed
          __typename
        }
        nextToken
        __typename
      }
      experiences {
        items {
          id
          jobTitle
          businessId
          companyName
          profileId
          startDate
          endDate
          employmentType
          location
          description
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      jobs {
        items {
          id
          profileId
          jobId
          coverLetter
          saved
          savedDate
          applied
          appliedDate
          createdAt
          updatedAt
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          job {
            id
            owner
            jobType
            businessId
            jobRole
            title
            description
            salary {
              type
              from
              to
              __typename
            }
            region
            requireCV
            requireCoverLetter
            createdAt
            archived
            startDate
            endDate
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            reported {
              items {
                id
                postId
                userId
                commentId
                jobId
                creatorId
                createdAt
                message
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          rejected
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    invites {
      items {
        id
        businessId
        receiver
        createdAt
        updatedAt
        business {
          id
          verified
          name
          tipping {
            merchantId
            merchant
            policy
            type
            active
            __typename
          }
          marketing {
            customerId
            subscriptionId
            active
            expirationDate
            sequenceId
            cancelled
            cancelledAt
            __typename
          }
          createdAt
          updatedAt
          owner
          code
          answers {
            questionId
            value
            __typename
          }
          isOutlet
          details {
            id
            contactName
            jobRole
            phone
            email
            website
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          ownerProfile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          invites {
            items {
              id
              businessId
              receiver
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          employees {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          brands {
            items {
              id
              businessId
              brandId
              status
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profileId
              brand {
                id
                name
                logo
                active
                productType
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    employees {
      items {
        id
        jobRole
        businessId
        profileId
        employeeId
        tippingPolicyAcknowledgedDate
        createdAt
        updatedAt
        business {
          id
          verified
          name
          tipping {
            merchantId
            merchant
            policy
            type
            active
            __typename
          }
          marketing {
            customerId
            subscriptionId
            active
            expirationDate
            sequenceId
            cancelled
            cancelledAt
            __typename
          }
          createdAt
          updatedAt
          owner
          code
          answers {
            questionId
            value
            __typename
          }
          isOutlet
          details {
            id
            contactName
            jobRole
            phone
            email
            website
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          ownerProfile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          invites {
            items {
              id
              businessId
              receiver
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          employees {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          brands {
            items {
              id
              businessId
              brandId
              status
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profileId
              brand {
                id
                name
                logo
                active
                productType
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        profile {
          id
          slug
          firstName
          lastName
          bio
          picture
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          archived
          details {
            id
            accountType
            isOnboardingComplete
            email
            feedRegion
            tippingAccount {
              code
              merchant
              accountId
              verified
              missingRequirements {
                key
                description
                __typename
              }
              pendingValidation
              bankDetails {
                name
                sortCode
                last4Digits
                __typename
              }
              __typename
            }
            phone_number
            phoneNumberConfirmed
            pushNotificationToken
            pushNotificationReadDate
            createdAt
            updatedAt
            __typename
          }
          employers {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          experiences {
            items {
              id
              jobTitle
              businessId
              companyName
              profileId
              startDate
              endDate
              employmentType
              location
              description
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          jobs {
            items {
              id
              profileId
              jobId
              coverLetter
              saved
              savedDate
              applied
              appliedDate
              createdAt
              updatedAt
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              job {
                id
                owner
                jobType
                businessId
                jobRole
                title
                description
                region
                requireCV
                requireCoverLetter
                createdAt
                archived
                startDate
                endDate
                updatedAt
                __typename
              }
              rejected
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        confirmed
        __typename
      }
      nextToken
      __typename
    }
    brands {
      items {
        id
        businessId
        brandId
        status
        createdAt
        updatedAt
        business {
          id
          verified
          name
          tipping {
            merchantId
            merchant
            policy
            type
            active
            __typename
          }
          marketing {
            customerId
            subscriptionId
            active
            expirationDate
            sequenceId
            cancelled
            cancelledAt
            __typename
          }
          createdAt
          updatedAt
          owner
          code
          answers {
            questionId
            value
            __typename
          }
          isOutlet
          details {
            id
            contactName
            jobRole
            phone
            email
            website
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          ownerProfile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          invites {
            items {
              id
              businessId
              receiver
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          employees {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          brands {
            items {
              id
              businessId
              brandId
              status
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profileId
              brand {
                id
                name
                logo
                active
                productType
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        profileId
        brand {
          id
          name
          logo
          active
          productType
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateBusinessMutationVariables,
  APITypes.CreateBusinessMutation
>;
export const updateBusiness = /* GraphQL */ `mutation UpdateBusiness(
  $input: UpdateBusinessInput!
  $condition: ModelBusinessConditionInput
) {
  updateBusiness(input: $input, condition: $condition) {
    id
    verified
    name
    tipping {
      merchantId
      merchant
      policy
      type
      active
      __typename
    }
    marketing {
      customerId
      subscriptionId
      active
      expirationDate
      sequenceId
      cancelled
      cancelledAt
      __typename
    }
    createdAt
    updatedAt
    owner
    code
    answers {
      questionId
      value
      __typename
    }
    isOutlet
    details {
      id
      contactName
      jobRole
      phone
      email
      website
      location {
        searchName
        address
        address2
        city
        region
        postcode
        country
        latitude
        longitude
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    ownerProfile {
      id
      slug
      firstName
      lastName
      bio
      picture
      location {
        searchName
        address
        address2
        city
        region
        postcode
        country
        latitude
        longitude
        __typename
      }
      createdAt
      updatedAt
      business {
        id
        verified
        name
        tipping {
          merchantId
          merchant
          policy
          type
          active
          __typename
        }
        marketing {
          customerId
          subscriptionId
          active
          expirationDate
          sequenceId
          cancelled
          cancelledAt
          __typename
        }
        createdAt
        updatedAt
        owner
        code
        answers {
          questionId
          value
          __typename
        }
        isOutlet
        details {
          id
          contactName
          jobRole
          phone
          email
          website
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        ownerProfile {
          id
          slug
          firstName
          lastName
          bio
          picture
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          archived
          details {
            id
            accountType
            isOnboardingComplete
            email
            feedRegion
            tippingAccount {
              code
              merchant
              accountId
              verified
              missingRequirements {
                key
                description
                __typename
              }
              pendingValidation
              bankDetails {
                name
                sortCode
                last4Digits
                __typename
              }
              __typename
            }
            phone_number
            phoneNumberConfirmed
            pushNotificationToken
            pushNotificationReadDate
            createdAt
            updatedAt
            __typename
          }
          employers {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          experiences {
            items {
              id
              jobTitle
              businessId
              companyName
              profileId
              startDate
              endDate
              employmentType
              location
              description
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          jobs {
            items {
              id
              profileId
              jobId
              coverLetter
              saved
              savedDate
              applied
              appliedDate
              createdAt
              updatedAt
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              job {
                id
                owner
                jobType
                businessId
                jobRole
                title
                description
                region
                requireCV
                requireCoverLetter
                createdAt
                archived
                startDate
                endDate
                updatedAt
                __typename
              }
              rejected
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        invites {
          items {
            id
            businessId
            receiver
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        employees {
          items {
            id
            jobRole
            businessId
            profileId
            employeeId
            tippingPolicyAcknowledgedDate
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            confirmed
            __typename
          }
          nextToken
          __typename
        }
        brands {
          items {
            id
            businessId
            brandId
            status
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profileId
            brand {
              id
              name
              logo
              active
              productType
              createdAt
              updatedAt
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      archived
      details {
        id
        accountType
        isOnboardingComplete
        email
        feedRegion
        tippingAccount {
          code
          merchant
          accountId
          verified
          missingRequirements {
            key
            description
            __typename
          }
          pendingValidation
          bankDetails {
            name
            sortCode
            last4Digits
            __typename
          }
          __typename
        }
        phone_number
        phoneNumberConfirmed
        pushNotificationToken
        pushNotificationReadDate
        createdAt
        updatedAt
        __typename
      }
      employers {
        items {
          id
          jobRole
          businessId
          profileId
          employeeId
          tippingPolicyAcknowledgedDate
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          confirmed
          __typename
        }
        nextToken
        __typename
      }
      experiences {
        items {
          id
          jobTitle
          businessId
          companyName
          profileId
          startDate
          endDate
          employmentType
          location
          description
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      jobs {
        items {
          id
          profileId
          jobId
          coverLetter
          saved
          savedDate
          applied
          appliedDate
          createdAt
          updatedAt
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          job {
            id
            owner
            jobType
            businessId
            jobRole
            title
            description
            salary {
              type
              from
              to
              __typename
            }
            region
            requireCV
            requireCoverLetter
            createdAt
            archived
            startDate
            endDate
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            reported {
              items {
                id
                postId
                userId
                commentId
                jobId
                creatorId
                createdAt
                message
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          rejected
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    invites {
      items {
        id
        businessId
        receiver
        createdAt
        updatedAt
        business {
          id
          verified
          name
          tipping {
            merchantId
            merchant
            policy
            type
            active
            __typename
          }
          marketing {
            customerId
            subscriptionId
            active
            expirationDate
            sequenceId
            cancelled
            cancelledAt
            __typename
          }
          createdAt
          updatedAt
          owner
          code
          answers {
            questionId
            value
            __typename
          }
          isOutlet
          details {
            id
            contactName
            jobRole
            phone
            email
            website
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          ownerProfile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          invites {
            items {
              id
              businessId
              receiver
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          employees {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          brands {
            items {
              id
              businessId
              brandId
              status
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profileId
              brand {
                id
                name
                logo
                active
                productType
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    employees {
      items {
        id
        jobRole
        businessId
        profileId
        employeeId
        tippingPolicyAcknowledgedDate
        createdAt
        updatedAt
        business {
          id
          verified
          name
          tipping {
            merchantId
            merchant
            policy
            type
            active
            __typename
          }
          marketing {
            customerId
            subscriptionId
            active
            expirationDate
            sequenceId
            cancelled
            cancelledAt
            __typename
          }
          createdAt
          updatedAt
          owner
          code
          answers {
            questionId
            value
            __typename
          }
          isOutlet
          details {
            id
            contactName
            jobRole
            phone
            email
            website
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          ownerProfile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          invites {
            items {
              id
              businessId
              receiver
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          employees {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          brands {
            items {
              id
              businessId
              brandId
              status
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profileId
              brand {
                id
                name
                logo
                active
                productType
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        profile {
          id
          slug
          firstName
          lastName
          bio
          picture
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          archived
          details {
            id
            accountType
            isOnboardingComplete
            email
            feedRegion
            tippingAccount {
              code
              merchant
              accountId
              verified
              missingRequirements {
                key
                description
                __typename
              }
              pendingValidation
              bankDetails {
                name
                sortCode
                last4Digits
                __typename
              }
              __typename
            }
            phone_number
            phoneNumberConfirmed
            pushNotificationToken
            pushNotificationReadDate
            createdAt
            updatedAt
            __typename
          }
          employers {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          experiences {
            items {
              id
              jobTitle
              businessId
              companyName
              profileId
              startDate
              endDate
              employmentType
              location
              description
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          jobs {
            items {
              id
              profileId
              jobId
              coverLetter
              saved
              savedDate
              applied
              appliedDate
              createdAt
              updatedAt
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              job {
                id
                owner
                jobType
                businessId
                jobRole
                title
                description
                region
                requireCV
                requireCoverLetter
                createdAt
                archived
                startDate
                endDate
                updatedAt
                __typename
              }
              rejected
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        confirmed
        __typename
      }
      nextToken
      __typename
    }
    brands {
      items {
        id
        businessId
        brandId
        status
        createdAt
        updatedAt
        business {
          id
          verified
          name
          tipping {
            merchantId
            merchant
            policy
            type
            active
            __typename
          }
          marketing {
            customerId
            subscriptionId
            active
            expirationDate
            sequenceId
            cancelled
            cancelledAt
            __typename
          }
          createdAt
          updatedAt
          owner
          code
          answers {
            questionId
            value
            __typename
          }
          isOutlet
          details {
            id
            contactName
            jobRole
            phone
            email
            website
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          ownerProfile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          invites {
            items {
              id
              businessId
              receiver
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          employees {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          brands {
            items {
              id
              businessId
              brandId
              status
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profileId
              brand {
                id
                name
                logo
                active
                productType
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        profileId
        brand {
          id
          name
          logo
          active
          productType
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateBusinessMutationVariables,
  APITypes.UpdateBusinessMutation
>;
export const deleteBusiness = /* GraphQL */ `mutation DeleteBusiness(
  $input: DeleteBusinessInput!
  $condition: ModelBusinessConditionInput
) {
  deleteBusiness(input: $input, condition: $condition) {
    id
    verified
    name
    tipping {
      merchantId
      merchant
      policy
      type
      active
      __typename
    }
    marketing {
      customerId
      subscriptionId
      active
      expirationDate
      sequenceId
      cancelled
      cancelledAt
      __typename
    }
    createdAt
    updatedAt
    owner
    code
    answers {
      questionId
      value
      __typename
    }
    isOutlet
    details {
      id
      contactName
      jobRole
      phone
      email
      website
      location {
        searchName
        address
        address2
        city
        region
        postcode
        country
        latitude
        longitude
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    ownerProfile {
      id
      slug
      firstName
      lastName
      bio
      picture
      location {
        searchName
        address
        address2
        city
        region
        postcode
        country
        latitude
        longitude
        __typename
      }
      createdAt
      updatedAt
      business {
        id
        verified
        name
        tipping {
          merchantId
          merchant
          policy
          type
          active
          __typename
        }
        marketing {
          customerId
          subscriptionId
          active
          expirationDate
          sequenceId
          cancelled
          cancelledAt
          __typename
        }
        createdAt
        updatedAt
        owner
        code
        answers {
          questionId
          value
          __typename
        }
        isOutlet
        details {
          id
          contactName
          jobRole
          phone
          email
          website
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        ownerProfile {
          id
          slug
          firstName
          lastName
          bio
          picture
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          archived
          details {
            id
            accountType
            isOnboardingComplete
            email
            feedRegion
            tippingAccount {
              code
              merchant
              accountId
              verified
              missingRequirements {
                key
                description
                __typename
              }
              pendingValidation
              bankDetails {
                name
                sortCode
                last4Digits
                __typename
              }
              __typename
            }
            phone_number
            phoneNumberConfirmed
            pushNotificationToken
            pushNotificationReadDate
            createdAt
            updatedAt
            __typename
          }
          employers {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          experiences {
            items {
              id
              jobTitle
              businessId
              companyName
              profileId
              startDate
              endDate
              employmentType
              location
              description
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          jobs {
            items {
              id
              profileId
              jobId
              coverLetter
              saved
              savedDate
              applied
              appliedDate
              createdAt
              updatedAt
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              job {
                id
                owner
                jobType
                businessId
                jobRole
                title
                description
                region
                requireCV
                requireCoverLetter
                createdAt
                archived
                startDate
                endDate
                updatedAt
                __typename
              }
              rejected
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        invites {
          items {
            id
            businessId
            receiver
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        employees {
          items {
            id
            jobRole
            businessId
            profileId
            employeeId
            tippingPolicyAcknowledgedDate
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            confirmed
            __typename
          }
          nextToken
          __typename
        }
        brands {
          items {
            id
            businessId
            brandId
            status
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profileId
            brand {
              id
              name
              logo
              active
              productType
              createdAt
              updatedAt
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      archived
      details {
        id
        accountType
        isOnboardingComplete
        email
        feedRegion
        tippingAccount {
          code
          merchant
          accountId
          verified
          missingRequirements {
            key
            description
            __typename
          }
          pendingValidation
          bankDetails {
            name
            sortCode
            last4Digits
            __typename
          }
          __typename
        }
        phone_number
        phoneNumberConfirmed
        pushNotificationToken
        pushNotificationReadDate
        createdAt
        updatedAt
        __typename
      }
      employers {
        items {
          id
          jobRole
          businessId
          profileId
          employeeId
          tippingPolicyAcknowledgedDate
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          confirmed
          __typename
        }
        nextToken
        __typename
      }
      experiences {
        items {
          id
          jobTitle
          businessId
          companyName
          profileId
          startDate
          endDate
          employmentType
          location
          description
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      jobs {
        items {
          id
          profileId
          jobId
          coverLetter
          saved
          savedDate
          applied
          appliedDate
          createdAt
          updatedAt
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          job {
            id
            owner
            jobType
            businessId
            jobRole
            title
            description
            salary {
              type
              from
              to
              __typename
            }
            region
            requireCV
            requireCoverLetter
            createdAt
            archived
            startDate
            endDate
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            reported {
              items {
                id
                postId
                userId
                commentId
                jobId
                creatorId
                createdAt
                message
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          rejected
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    invites {
      items {
        id
        businessId
        receiver
        createdAt
        updatedAt
        business {
          id
          verified
          name
          tipping {
            merchantId
            merchant
            policy
            type
            active
            __typename
          }
          marketing {
            customerId
            subscriptionId
            active
            expirationDate
            sequenceId
            cancelled
            cancelledAt
            __typename
          }
          createdAt
          updatedAt
          owner
          code
          answers {
            questionId
            value
            __typename
          }
          isOutlet
          details {
            id
            contactName
            jobRole
            phone
            email
            website
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          ownerProfile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          invites {
            items {
              id
              businessId
              receiver
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          employees {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          brands {
            items {
              id
              businessId
              brandId
              status
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profileId
              brand {
                id
                name
                logo
                active
                productType
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    employees {
      items {
        id
        jobRole
        businessId
        profileId
        employeeId
        tippingPolicyAcknowledgedDate
        createdAt
        updatedAt
        business {
          id
          verified
          name
          tipping {
            merchantId
            merchant
            policy
            type
            active
            __typename
          }
          marketing {
            customerId
            subscriptionId
            active
            expirationDate
            sequenceId
            cancelled
            cancelledAt
            __typename
          }
          createdAt
          updatedAt
          owner
          code
          answers {
            questionId
            value
            __typename
          }
          isOutlet
          details {
            id
            contactName
            jobRole
            phone
            email
            website
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          ownerProfile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          invites {
            items {
              id
              businessId
              receiver
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          employees {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          brands {
            items {
              id
              businessId
              brandId
              status
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profileId
              brand {
                id
                name
                logo
                active
                productType
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        profile {
          id
          slug
          firstName
          lastName
          bio
          picture
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          archived
          details {
            id
            accountType
            isOnboardingComplete
            email
            feedRegion
            tippingAccount {
              code
              merchant
              accountId
              verified
              missingRequirements {
                key
                description
                __typename
              }
              pendingValidation
              bankDetails {
                name
                sortCode
                last4Digits
                __typename
              }
              __typename
            }
            phone_number
            phoneNumberConfirmed
            pushNotificationToken
            pushNotificationReadDate
            createdAt
            updatedAt
            __typename
          }
          employers {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          experiences {
            items {
              id
              jobTitle
              businessId
              companyName
              profileId
              startDate
              endDate
              employmentType
              location
              description
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          jobs {
            items {
              id
              profileId
              jobId
              coverLetter
              saved
              savedDate
              applied
              appliedDate
              createdAt
              updatedAt
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              job {
                id
                owner
                jobType
                businessId
                jobRole
                title
                description
                region
                requireCV
                requireCoverLetter
                createdAt
                archived
                startDate
                endDate
                updatedAt
                __typename
              }
              rejected
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        confirmed
        __typename
      }
      nextToken
      __typename
    }
    brands {
      items {
        id
        businessId
        brandId
        status
        createdAt
        updatedAt
        business {
          id
          verified
          name
          tipping {
            merchantId
            merchant
            policy
            type
            active
            __typename
          }
          marketing {
            customerId
            subscriptionId
            active
            expirationDate
            sequenceId
            cancelled
            cancelledAt
            __typename
          }
          createdAt
          updatedAt
          owner
          code
          answers {
            questionId
            value
            __typename
          }
          isOutlet
          details {
            id
            contactName
            jobRole
            phone
            email
            website
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          ownerProfile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          invites {
            items {
              id
              businessId
              receiver
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          employees {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          brands {
            items {
              id
              businessId
              brandId
              status
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profileId
              brand {
                id
                name
                logo
                active
                productType
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        profileId
        brand {
          id
          name
          logo
          active
          productType
          createdAt
          updatedAt
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteBusinessMutationVariables,
  APITypes.DeleteBusinessMutation
>;
export const createBusinessDetails = /* GraphQL */ `mutation CreateBusinessDetails(
  $input: CreateBusinessDetailsInput!
  $condition: ModelBusinessDetailsConditionInput
) {
  createBusinessDetails(input: $input, condition: $condition) {
    id
    contactName
    jobRole
    phone
    email
    website
    location {
      searchName
      address
      address2
      city
      region
      postcode
      country
      latitude
      longitude
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateBusinessDetailsMutationVariables,
  APITypes.CreateBusinessDetailsMutation
>;
export const updateBusinessDetails = /* GraphQL */ `mutation UpdateBusinessDetails(
  $input: UpdateBusinessDetailsInput!
  $condition: ModelBusinessDetailsConditionInput
) {
  updateBusinessDetails(input: $input, condition: $condition) {
    id
    contactName
    jobRole
    phone
    email
    website
    location {
      searchName
      address
      address2
      city
      region
      postcode
      country
      latitude
      longitude
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateBusinessDetailsMutationVariables,
  APITypes.UpdateBusinessDetailsMutation
>;
export const deleteBusinessDetails = /* GraphQL */ `mutation DeleteBusinessDetails(
  $input: DeleteBusinessDetailsInput!
  $condition: ModelBusinessDetailsConditionInput
) {
  deleteBusinessDetails(input: $input, condition: $condition) {
    id
    contactName
    jobRole
    phone
    email
    website
    location {
      searchName
      address
      address2
      city
      region
      postcode
      country
      latitude
      longitude
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteBusinessDetailsMutationVariables,
  APITypes.DeleteBusinessDetailsMutation
>;
export const createProfile = /* GraphQL */ `mutation CreateProfile(
  $input: CreateProfileInput!
  $condition: ModelProfileConditionInput
) {
  createProfile(input: $input, condition: $condition) {
    id
    slug
    firstName
    lastName
    bio
    picture
    location {
      searchName
      address
      address2
      city
      region
      postcode
      country
      latitude
      longitude
      __typename
    }
    createdAt
    updatedAt
    business {
      id
      verified
      name
      tipping {
        merchantId
        merchant
        policy
        type
        active
        __typename
      }
      marketing {
        customerId
        subscriptionId
        active
        expirationDate
        sequenceId
        cancelled
        cancelledAt
        __typename
      }
      createdAt
      updatedAt
      owner
      code
      answers {
        questionId
        value
        __typename
      }
      isOutlet
      details {
        id
        contactName
        jobRole
        phone
        email
        website
        location {
          searchName
          address
          address2
          city
          region
          postcode
          country
          latitude
          longitude
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      ownerProfile {
        id
        slug
        firstName
        lastName
        bio
        picture
        location {
          searchName
          address
          address2
          city
          region
          postcode
          country
          latitude
          longitude
          __typename
        }
        createdAt
        updatedAt
        business {
          id
          verified
          name
          tipping {
            merchantId
            merchant
            policy
            type
            active
            __typename
          }
          marketing {
            customerId
            subscriptionId
            active
            expirationDate
            sequenceId
            cancelled
            cancelledAt
            __typename
          }
          createdAt
          updatedAt
          owner
          code
          answers {
            questionId
            value
            __typename
          }
          isOutlet
          details {
            id
            contactName
            jobRole
            phone
            email
            website
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          ownerProfile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          invites {
            items {
              id
              businessId
              receiver
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          employees {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          brands {
            items {
              id
              businessId
              brandId
              status
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profileId
              brand {
                id
                name
                logo
                active
                productType
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        archived
        details {
          id
          accountType
          isOnboardingComplete
          email
          feedRegion
          tippingAccount {
            code
            merchant
            accountId
            verified
            missingRequirements {
              key
              description
              __typename
            }
            pendingValidation
            bankDetails {
              name
              sortCode
              last4Digits
              __typename
            }
            __typename
          }
          phone_number
          phoneNumberConfirmed
          pushNotificationToken
          pushNotificationReadDate
          createdAt
          updatedAt
          __typename
        }
        employers {
          items {
            id
            jobRole
            businessId
            profileId
            employeeId
            tippingPolicyAcknowledgedDate
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            confirmed
            __typename
          }
          nextToken
          __typename
        }
        experiences {
          items {
            id
            jobTitle
            businessId
            companyName
            profileId
            startDate
            endDate
            employmentType
            location
            description
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        jobs {
          items {
            id
            profileId
            jobId
            coverLetter
            saved
            savedDate
            applied
            appliedDate
            createdAt
            updatedAt
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            job {
              id
              owner
              jobType
              businessId
              jobRole
              title
              description
              salary {
                type
                from
                to
                __typename
              }
              region
              requireCV
              requireCoverLetter
              createdAt
              archived
              startDate
              endDate
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              reported {
                nextToken
                __typename
              }
              __typename
            }
            rejected
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      invites {
        items {
          id
          businessId
          receiver
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      employees {
        items {
          id
          jobRole
          businessId
          profileId
          employeeId
          tippingPolicyAcknowledgedDate
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          confirmed
          __typename
        }
        nextToken
        __typename
      }
      brands {
        items {
          id
          businessId
          brandId
          status
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profileId
          brand {
            id
            name
            logo
            active
            productType
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    archived
    details {
      id
      accountType
      isOnboardingComplete
      email
      feedRegion
      tippingAccount {
        code
        merchant
        accountId
        verified
        missingRequirements {
          key
          description
          __typename
        }
        pendingValidation
        bankDetails {
          name
          sortCode
          last4Digits
          __typename
        }
        __typename
      }
      phone_number
      phoneNumberConfirmed
      pushNotificationToken
      pushNotificationReadDate
      createdAt
      updatedAt
      __typename
    }
    employers {
      items {
        id
        jobRole
        businessId
        profileId
        employeeId
        tippingPolicyAcknowledgedDate
        createdAt
        updatedAt
        business {
          id
          verified
          name
          tipping {
            merchantId
            merchant
            policy
            type
            active
            __typename
          }
          marketing {
            customerId
            subscriptionId
            active
            expirationDate
            sequenceId
            cancelled
            cancelledAt
            __typename
          }
          createdAt
          updatedAt
          owner
          code
          answers {
            questionId
            value
            __typename
          }
          isOutlet
          details {
            id
            contactName
            jobRole
            phone
            email
            website
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          ownerProfile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          invites {
            items {
              id
              businessId
              receiver
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          employees {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          brands {
            items {
              id
              businessId
              brandId
              status
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profileId
              brand {
                id
                name
                logo
                active
                productType
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        profile {
          id
          slug
          firstName
          lastName
          bio
          picture
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          archived
          details {
            id
            accountType
            isOnboardingComplete
            email
            feedRegion
            tippingAccount {
              code
              merchant
              accountId
              verified
              missingRequirements {
                key
                description
                __typename
              }
              pendingValidation
              bankDetails {
                name
                sortCode
                last4Digits
                __typename
              }
              __typename
            }
            phone_number
            phoneNumberConfirmed
            pushNotificationToken
            pushNotificationReadDate
            createdAt
            updatedAt
            __typename
          }
          employers {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          experiences {
            items {
              id
              jobTitle
              businessId
              companyName
              profileId
              startDate
              endDate
              employmentType
              location
              description
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          jobs {
            items {
              id
              profileId
              jobId
              coverLetter
              saved
              savedDate
              applied
              appliedDate
              createdAt
              updatedAt
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              job {
                id
                owner
                jobType
                businessId
                jobRole
                title
                description
                region
                requireCV
                requireCoverLetter
                createdAt
                archived
                startDate
                endDate
                updatedAt
                __typename
              }
              rejected
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        confirmed
        __typename
      }
      nextToken
      __typename
    }
    experiences {
      items {
        id
        jobTitle
        businessId
        companyName
        profileId
        startDate
        endDate
        employmentType
        location
        description
        createdAt
        updatedAt
        business {
          id
          verified
          name
          tipping {
            merchantId
            merchant
            policy
            type
            active
            __typename
          }
          marketing {
            customerId
            subscriptionId
            active
            expirationDate
            sequenceId
            cancelled
            cancelledAt
            __typename
          }
          createdAt
          updatedAt
          owner
          code
          answers {
            questionId
            value
            __typename
          }
          isOutlet
          details {
            id
            contactName
            jobRole
            phone
            email
            website
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          ownerProfile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          invites {
            items {
              id
              businessId
              receiver
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          employees {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          brands {
            items {
              id
              businessId
              brandId
              status
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profileId
              brand {
                id
                name
                logo
                active
                productType
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        profile {
          id
          slug
          firstName
          lastName
          bio
          picture
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          archived
          details {
            id
            accountType
            isOnboardingComplete
            email
            feedRegion
            tippingAccount {
              code
              merchant
              accountId
              verified
              missingRequirements {
                key
                description
                __typename
              }
              pendingValidation
              bankDetails {
                name
                sortCode
                last4Digits
                __typename
              }
              __typename
            }
            phone_number
            phoneNumberConfirmed
            pushNotificationToken
            pushNotificationReadDate
            createdAt
            updatedAt
            __typename
          }
          employers {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          experiences {
            items {
              id
              jobTitle
              businessId
              companyName
              profileId
              startDate
              endDate
              employmentType
              location
              description
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          jobs {
            items {
              id
              profileId
              jobId
              coverLetter
              saved
              savedDate
              applied
              appliedDate
              createdAt
              updatedAt
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              job {
                id
                owner
                jobType
                businessId
                jobRole
                title
                description
                region
                requireCV
                requireCoverLetter
                createdAt
                archived
                startDate
                endDate
                updatedAt
                __typename
              }
              rejected
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    jobs {
      items {
        id
        profileId
        jobId
        coverLetter
        saved
        savedDate
        applied
        appliedDate
        createdAt
        updatedAt
        profile {
          id
          slug
          firstName
          lastName
          bio
          picture
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          archived
          details {
            id
            accountType
            isOnboardingComplete
            email
            feedRegion
            tippingAccount {
              code
              merchant
              accountId
              verified
              missingRequirements {
                key
                description
                __typename
              }
              pendingValidation
              bankDetails {
                name
                sortCode
                last4Digits
                __typename
              }
              __typename
            }
            phone_number
            phoneNumberConfirmed
            pushNotificationToken
            pushNotificationReadDate
            createdAt
            updatedAt
            __typename
          }
          employers {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          experiences {
            items {
              id
              jobTitle
              businessId
              companyName
              profileId
              startDate
              endDate
              employmentType
              location
              description
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          jobs {
            items {
              id
              profileId
              jobId
              coverLetter
              saved
              savedDate
              applied
              appliedDate
              createdAt
              updatedAt
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              job {
                id
                owner
                jobType
                businessId
                jobRole
                title
                description
                region
                requireCV
                requireCoverLetter
                createdAt
                archived
                startDate
                endDate
                updatedAt
                __typename
              }
              rejected
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        job {
          id
          owner
          jobType
          businessId
          jobRole
          title
          description
          salary {
            type
            from
            to
            __typename
          }
          region
          requireCV
          requireCoverLetter
          createdAt
          archived
          startDate
          endDate
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          reported {
            items {
              id
              postId
              userId
              commentId
              jobId
              creatorId
              createdAt
              message
              updatedAt
              creator {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        rejected
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateProfileMutationVariables,
  APITypes.CreateProfileMutation
>;
export const updateProfile = /* GraphQL */ `mutation UpdateProfile(
  $input: UpdateProfileInput!
  $condition: ModelProfileConditionInput
) {
  updateProfile(input: $input, condition: $condition) {
    id
    slug
    firstName
    lastName
    bio
    picture
    location {
      searchName
      address
      address2
      city
      region
      postcode
      country
      latitude
      longitude
      __typename
    }
    createdAt
    updatedAt
    business {
      id
      verified
      name
      tipping {
        merchantId
        merchant
        policy
        type
        active
        __typename
      }
      marketing {
        customerId
        subscriptionId
        active
        expirationDate
        sequenceId
        cancelled
        cancelledAt
        __typename
      }
      createdAt
      updatedAt
      owner
      code
      answers {
        questionId
        value
        __typename
      }
      isOutlet
      details {
        id
        contactName
        jobRole
        phone
        email
        website
        location {
          searchName
          address
          address2
          city
          region
          postcode
          country
          latitude
          longitude
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      ownerProfile {
        id
        slug
        firstName
        lastName
        bio
        picture
        location {
          searchName
          address
          address2
          city
          region
          postcode
          country
          latitude
          longitude
          __typename
        }
        createdAt
        updatedAt
        business {
          id
          verified
          name
          tipping {
            merchantId
            merchant
            policy
            type
            active
            __typename
          }
          marketing {
            customerId
            subscriptionId
            active
            expirationDate
            sequenceId
            cancelled
            cancelledAt
            __typename
          }
          createdAt
          updatedAt
          owner
          code
          answers {
            questionId
            value
            __typename
          }
          isOutlet
          details {
            id
            contactName
            jobRole
            phone
            email
            website
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          ownerProfile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          invites {
            items {
              id
              businessId
              receiver
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          employees {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          brands {
            items {
              id
              businessId
              brandId
              status
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profileId
              brand {
                id
                name
                logo
                active
                productType
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        archived
        details {
          id
          accountType
          isOnboardingComplete
          email
          feedRegion
          tippingAccount {
            code
            merchant
            accountId
            verified
            missingRequirements {
              key
              description
              __typename
            }
            pendingValidation
            bankDetails {
              name
              sortCode
              last4Digits
              __typename
            }
            __typename
          }
          phone_number
          phoneNumberConfirmed
          pushNotificationToken
          pushNotificationReadDate
          createdAt
          updatedAt
          __typename
        }
        employers {
          items {
            id
            jobRole
            businessId
            profileId
            employeeId
            tippingPolicyAcknowledgedDate
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            confirmed
            __typename
          }
          nextToken
          __typename
        }
        experiences {
          items {
            id
            jobTitle
            businessId
            companyName
            profileId
            startDate
            endDate
            employmentType
            location
            description
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        jobs {
          items {
            id
            profileId
            jobId
            coverLetter
            saved
            savedDate
            applied
            appliedDate
            createdAt
            updatedAt
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            job {
              id
              owner
              jobType
              businessId
              jobRole
              title
              description
              salary {
                type
                from
                to
                __typename
              }
              region
              requireCV
              requireCoverLetter
              createdAt
              archived
              startDate
              endDate
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              reported {
                nextToken
                __typename
              }
              __typename
            }
            rejected
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      invites {
        items {
          id
          businessId
          receiver
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      employees {
        items {
          id
          jobRole
          businessId
          profileId
          employeeId
          tippingPolicyAcknowledgedDate
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          confirmed
          __typename
        }
        nextToken
        __typename
      }
      brands {
        items {
          id
          businessId
          brandId
          status
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profileId
          brand {
            id
            name
            logo
            active
            productType
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    archived
    details {
      id
      accountType
      isOnboardingComplete
      email
      feedRegion
      tippingAccount {
        code
        merchant
        accountId
        verified
        missingRequirements {
          key
          description
          __typename
        }
        pendingValidation
        bankDetails {
          name
          sortCode
          last4Digits
          __typename
        }
        __typename
      }
      phone_number
      phoneNumberConfirmed
      pushNotificationToken
      pushNotificationReadDate
      createdAt
      updatedAt
      __typename
    }
    employers {
      items {
        id
        jobRole
        businessId
        profileId
        employeeId
        tippingPolicyAcknowledgedDate
        createdAt
        updatedAt
        business {
          id
          verified
          name
          tipping {
            merchantId
            merchant
            policy
            type
            active
            __typename
          }
          marketing {
            customerId
            subscriptionId
            active
            expirationDate
            sequenceId
            cancelled
            cancelledAt
            __typename
          }
          createdAt
          updatedAt
          owner
          code
          answers {
            questionId
            value
            __typename
          }
          isOutlet
          details {
            id
            contactName
            jobRole
            phone
            email
            website
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          ownerProfile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          invites {
            items {
              id
              businessId
              receiver
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          employees {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          brands {
            items {
              id
              businessId
              brandId
              status
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profileId
              brand {
                id
                name
                logo
                active
                productType
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        profile {
          id
          slug
          firstName
          lastName
          bio
          picture
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          archived
          details {
            id
            accountType
            isOnboardingComplete
            email
            feedRegion
            tippingAccount {
              code
              merchant
              accountId
              verified
              missingRequirements {
                key
                description
                __typename
              }
              pendingValidation
              bankDetails {
                name
                sortCode
                last4Digits
                __typename
              }
              __typename
            }
            phone_number
            phoneNumberConfirmed
            pushNotificationToken
            pushNotificationReadDate
            createdAt
            updatedAt
            __typename
          }
          employers {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          experiences {
            items {
              id
              jobTitle
              businessId
              companyName
              profileId
              startDate
              endDate
              employmentType
              location
              description
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          jobs {
            items {
              id
              profileId
              jobId
              coverLetter
              saved
              savedDate
              applied
              appliedDate
              createdAt
              updatedAt
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              job {
                id
                owner
                jobType
                businessId
                jobRole
                title
                description
                region
                requireCV
                requireCoverLetter
                createdAt
                archived
                startDate
                endDate
                updatedAt
                __typename
              }
              rejected
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        confirmed
        __typename
      }
      nextToken
      __typename
    }
    experiences {
      items {
        id
        jobTitle
        businessId
        companyName
        profileId
        startDate
        endDate
        employmentType
        location
        description
        createdAt
        updatedAt
        business {
          id
          verified
          name
          tipping {
            merchantId
            merchant
            policy
            type
            active
            __typename
          }
          marketing {
            customerId
            subscriptionId
            active
            expirationDate
            sequenceId
            cancelled
            cancelledAt
            __typename
          }
          createdAt
          updatedAt
          owner
          code
          answers {
            questionId
            value
            __typename
          }
          isOutlet
          details {
            id
            contactName
            jobRole
            phone
            email
            website
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          ownerProfile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          invites {
            items {
              id
              businessId
              receiver
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          employees {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          brands {
            items {
              id
              businessId
              brandId
              status
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profileId
              brand {
                id
                name
                logo
                active
                productType
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        profile {
          id
          slug
          firstName
          lastName
          bio
          picture
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          archived
          details {
            id
            accountType
            isOnboardingComplete
            email
            feedRegion
            tippingAccount {
              code
              merchant
              accountId
              verified
              missingRequirements {
                key
                description
                __typename
              }
              pendingValidation
              bankDetails {
                name
                sortCode
                last4Digits
                __typename
              }
              __typename
            }
            phone_number
            phoneNumberConfirmed
            pushNotificationToken
            pushNotificationReadDate
            createdAt
            updatedAt
            __typename
          }
          employers {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          experiences {
            items {
              id
              jobTitle
              businessId
              companyName
              profileId
              startDate
              endDate
              employmentType
              location
              description
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          jobs {
            items {
              id
              profileId
              jobId
              coverLetter
              saved
              savedDate
              applied
              appliedDate
              createdAt
              updatedAt
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              job {
                id
                owner
                jobType
                businessId
                jobRole
                title
                description
                region
                requireCV
                requireCoverLetter
                createdAt
                archived
                startDate
                endDate
                updatedAt
                __typename
              }
              rejected
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    jobs {
      items {
        id
        profileId
        jobId
        coverLetter
        saved
        savedDate
        applied
        appliedDate
        createdAt
        updatedAt
        profile {
          id
          slug
          firstName
          lastName
          bio
          picture
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          archived
          details {
            id
            accountType
            isOnboardingComplete
            email
            feedRegion
            tippingAccount {
              code
              merchant
              accountId
              verified
              missingRequirements {
                key
                description
                __typename
              }
              pendingValidation
              bankDetails {
                name
                sortCode
                last4Digits
                __typename
              }
              __typename
            }
            phone_number
            phoneNumberConfirmed
            pushNotificationToken
            pushNotificationReadDate
            createdAt
            updatedAt
            __typename
          }
          employers {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          experiences {
            items {
              id
              jobTitle
              businessId
              companyName
              profileId
              startDate
              endDate
              employmentType
              location
              description
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          jobs {
            items {
              id
              profileId
              jobId
              coverLetter
              saved
              savedDate
              applied
              appliedDate
              createdAt
              updatedAt
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              job {
                id
                owner
                jobType
                businessId
                jobRole
                title
                description
                region
                requireCV
                requireCoverLetter
                createdAt
                archived
                startDate
                endDate
                updatedAt
                __typename
              }
              rejected
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        job {
          id
          owner
          jobType
          businessId
          jobRole
          title
          description
          salary {
            type
            from
            to
            __typename
          }
          region
          requireCV
          requireCoverLetter
          createdAt
          archived
          startDate
          endDate
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          reported {
            items {
              id
              postId
              userId
              commentId
              jobId
              creatorId
              createdAt
              message
              updatedAt
              creator {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        rejected
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateProfileMutationVariables,
  APITypes.UpdateProfileMutation
>;
export const createProfileDetails = /* GraphQL */ `mutation CreateProfileDetails(
  $input: CreateProfileDetailsInput!
  $condition: ModelProfileDetailsConditionInput
) {
  createProfileDetails(input: $input, condition: $condition) {
    id
    accountType
    isOnboardingComplete
    email
    feedRegion
    tippingAccount {
      code
      merchant
      accountId
      verified
      missingRequirements {
        key
        description
        __typename
      }
      pendingValidation
      bankDetails {
        name
        sortCode
        last4Digits
        __typename
      }
      __typename
    }
    phone_number
    phoneNumberConfirmed
    pushNotificationToken
    pushNotificationReadDate
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateProfileDetailsMutationVariables,
  APITypes.CreateProfileDetailsMutation
>;
export const updateProfileDetails = /* GraphQL */ `mutation UpdateProfileDetails(
  $input: UpdateProfileDetailsInput!
  $condition: ModelProfileDetailsConditionInput
) {
  updateProfileDetails(input: $input, condition: $condition) {
    id
    accountType
    isOnboardingComplete
    email
    feedRegion
    tippingAccount {
      code
      merchant
      accountId
      verified
      missingRequirements {
        key
        description
        __typename
      }
      pendingValidation
      bankDetails {
        name
        sortCode
        last4Digits
        __typename
      }
      __typename
    }
    phone_number
    phoneNumberConfirmed
    pushNotificationToken
    pushNotificationReadDate
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateProfileDetailsMutationVariables,
  APITypes.UpdateProfileDetailsMutation
>;
export const createBusinessInvite = /* GraphQL */ `mutation CreateBusinessInvite(
  $input: CreateBusinessInviteInput!
  $condition: ModelBusinessInviteConditionInput
) {
  createBusinessInvite(input: $input, condition: $condition) {
    id
    businessId
    receiver
    createdAt
    updatedAt
    business {
      id
      verified
      name
      tipping {
        merchantId
        merchant
        policy
        type
        active
        __typename
      }
      marketing {
        customerId
        subscriptionId
        active
        expirationDate
        sequenceId
        cancelled
        cancelledAt
        __typename
      }
      createdAt
      updatedAt
      owner
      code
      answers {
        questionId
        value
        __typename
      }
      isOutlet
      details {
        id
        contactName
        jobRole
        phone
        email
        website
        location {
          searchName
          address
          address2
          city
          region
          postcode
          country
          latitude
          longitude
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      ownerProfile {
        id
        slug
        firstName
        lastName
        bio
        picture
        location {
          searchName
          address
          address2
          city
          region
          postcode
          country
          latitude
          longitude
          __typename
        }
        createdAt
        updatedAt
        business {
          id
          verified
          name
          tipping {
            merchantId
            merchant
            policy
            type
            active
            __typename
          }
          marketing {
            customerId
            subscriptionId
            active
            expirationDate
            sequenceId
            cancelled
            cancelledAt
            __typename
          }
          createdAt
          updatedAt
          owner
          code
          answers {
            questionId
            value
            __typename
          }
          isOutlet
          details {
            id
            contactName
            jobRole
            phone
            email
            website
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          ownerProfile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          invites {
            items {
              id
              businessId
              receiver
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          employees {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          brands {
            items {
              id
              businessId
              brandId
              status
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profileId
              brand {
                id
                name
                logo
                active
                productType
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        archived
        details {
          id
          accountType
          isOnboardingComplete
          email
          feedRegion
          tippingAccount {
            code
            merchant
            accountId
            verified
            missingRequirements {
              key
              description
              __typename
            }
            pendingValidation
            bankDetails {
              name
              sortCode
              last4Digits
              __typename
            }
            __typename
          }
          phone_number
          phoneNumberConfirmed
          pushNotificationToken
          pushNotificationReadDate
          createdAt
          updatedAt
          __typename
        }
        employers {
          items {
            id
            jobRole
            businessId
            profileId
            employeeId
            tippingPolicyAcknowledgedDate
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            confirmed
            __typename
          }
          nextToken
          __typename
        }
        experiences {
          items {
            id
            jobTitle
            businessId
            companyName
            profileId
            startDate
            endDate
            employmentType
            location
            description
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        jobs {
          items {
            id
            profileId
            jobId
            coverLetter
            saved
            savedDate
            applied
            appliedDate
            createdAt
            updatedAt
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            job {
              id
              owner
              jobType
              businessId
              jobRole
              title
              description
              salary {
                type
                from
                to
                __typename
              }
              region
              requireCV
              requireCoverLetter
              createdAt
              archived
              startDate
              endDate
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              reported {
                nextToken
                __typename
              }
              __typename
            }
            rejected
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      invites {
        items {
          id
          businessId
          receiver
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      employees {
        items {
          id
          jobRole
          businessId
          profileId
          employeeId
          tippingPolicyAcknowledgedDate
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          confirmed
          __typename
        }
        nextToken
        __typename
      }
      brands {
        items {
          id
          businessId
          brandId
          status
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profileId
          brand {
            id
            name
            logo
            active
            productType
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateBusinessInviteMutationVariables,
  APITypes.CreateBusinessInviteMutation
>;
export const createBusinessEmployee = /* GraphQL */ `mutation CreateBusinessEmployee(
  $input: CreateBusinessEmployeeInput!
  $condition: ModelBusinessEmployeeConditionInput
) {
  createBusinessEmployee(input: $input, condition: $condition) {
    id
    jobRole
    businessId
    profileId
    employeeId
    tippingPolicyAcknowledgedDate
    createdAt
    updatedAt
    business {
      id
      verified
      name
      tipping {
        merchantId
        merchant
        policy
        type
        active
        __typename
      }
      marketing {
        customerId
        subscriptionId
        active
        expirationDate
        sequenceId
        cancelled
        cancelledAt
        __typename
      }
      createdAt
      updatedAt
      owner
      code
      answers {
        questionId
        value
        __typename
      }
      isOutlet
      details {
        id
        contactName
        jobRole
        phone
        email
        website
        location {
          searchName
          address
          address2
          city
          region
          postcode
          country
          latitude
          longitude
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      ownerProfile {
        id
        slug
        firstName
        lastName
        bio
        picture
        location {
          searchName
          address
          address2
          city
          region
          postcode
          country
          latitude
          longitude
          __typename
        }
        createdAt
        updatedAt
        business {
          id
          verified
          name
          tipping {
            merchantId
            merchant
            policy
            type
            active
            __typename
          }
          marketing {
            customerId
            subscriptionId
            active
            expirationDate
            sequenceId
            cancelled
            cancelledAt
            __typename
          }
          createdAt
          updatedAt
          owner
          code
          answers {
            questionId
            value
            __typename
          }
          isOutlet
          details {
            id
            contactName
            jobRole
            phone
            email
            website
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          ownerProfile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          invites {
            items {
              id
              businessId
              receiver
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          employees {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          brands {
            items {
              id
              businessId
              brandId
              status
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profileId
              brand {
                id
                name
                logo
                active
                productType
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        archived
        details {
          id
          accountType
          isOnboardingComplete
          email
          feedRegion
          tippingAccount {
            code
            merchant
            accountId
            verified
            missingRequirements {
              key
              description
              __typename
            }
            pendingValidation
            bankDetails {
              name
              sortCode
              last4Digits
              __typename
            }
            __typename
          }
          phone_number
          phoneNumberConfirmed
          pushNotificationToken
          pushNotificationReadDate
          createdAt
          updatedAt
          __typename
        }
        employers {
          items {
            id
            jobRole
            businessId
            profileId
            employeeId
            tippingPolicyAcknowledgedDate
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            confirmed
            __typename
          }
          nextToken
          __typename
        }
        experiences {
          items {
            id
            jobTitle
            businessId
            companyName
            profileId
            startDate
            endDate
            employmentType
            location
            description
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        jobs {
          items {
            id
            profileId
            jobId
            coverLetter
            saved
            savedDate
            applied
            appliedDate
            createdAt
            updatedAt
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            job {
              id
              owner
              jobType
              businessId
              jobRole
              title
              description
              salary {
                type
                from
                to
                __typename
              }
              region
              requireCV
              requireCoverLetter
              createdAt
              archived
              startDate
              endDate
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              reported {
                nextToken
                __typename
              }
              __typename
            }
            rejected
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      invites {
        items {
          id
          businessId
          receiver
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      employees {
        items {
          id
          jobRole
          businessId
          profileId
          employeeId
          tippingPolicyAcknowledgedDate
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          confirmed
          __typename
        }
        nextToken
        __typename
      }
      brands {
        items {
          id
          businessId
          brandId
          status
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profileId
          brand {
            id
            name
            logo
            active
            productType
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    profile {
      id
      slug
      firstName
      lastName
      bio
      picture
      location {
        searchName
        address
        address2
        city
        region
        postcode
        country
        latitude
        longitude
        __typename
      }
      createdAt
      updatedAt
      business {
        id
        verified
        name
        tipping {
          merchantId
          merchant
          policy
          type
          active
          __typename
        }
        marketing {
          customerId
          subscriptionId
          active
          expirationDate
          sequenceId
          cancelled
          cancelledAt
          __typename
        }
        createdAt
        updatedAt
        owner
        code
        answers {
          questionId
          value
          __typename
        }
        isOutlet
        details {
          id
          contactName
          jobRole
          phone
          email
          website
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        ownerProfile {
          id
          slug
          firstName
          lastName
          bio
          picture
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          archived
          details {
            id
            accountType
            isOnboardingComplete
            email
            feedRegion
            tippingAccount {
              code
              merchant
              accountId
              verified
              missingRequirements {
                key
                description
                __typename
              }
              pendingValidation
              bankDetails {
                name
                sortCode
                last4Digits
                __typename
              }
              __typename
            }
            phone_number
            phoneNumberConfirmed
            pushNotificationToken
            pushNotificationReadDate
            createdAt
            updatedAt
            __typename
          }
          employers {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          experiences {
            items {
              id
              jobTitle
              businessId
              companyName
              profileId
              startDate
              endDate
              employmentType
              location
              description
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          jobs {
            items {
              id
              profileId
              jobId
              coverLetter
              saved
              savedDate
              applied
              appliedDate
              createdAt
              updatedAt
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              job {
                id
                owner
                jobType
                businessId
                jobRole
                title
                description
                region
                requireCV
                requireCoverLetter
                createdAt
                archived
                startDate
                endDate
                updatedAt
                __typename
              }
              rejected
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        invites {
          items {
            id
            businessId
            receiver
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        employees {
          items {
            id
            jobRole
            businessId
            profileId
            employeeId
            tippingPolicyAcknowledgedDate
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            confirmed
            __typename
          }
          nextToken
          __typename
        }
        brands {
          items {
            id
            businessId
            brandId
            status
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profileId
            brand {
              id
              name
              logo
              active
              productType
              createdAt
              updatedAt
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      archived
      details {
        id
        accountType
        isOnboardingComplete
        email
        feedRegion
        tippingAccount {
          code
          merchant
          accountId
          verified
          missingRequirements {
            key
            description
            __typename
          }
          pendingValidation
          bankDetails {
            name
            sortCode
            last4Digits
            __typename
          }
          __typename
        }
        phone_number
        phoneNumberConfirmed
        pushNotificationToken
        pushNotificationReadDate
        createdAt
        updatedAt
        __typename
      }
      employers {
        items {
          id
          jobRole
          businessId
          profileId
          employeeId
          tippingPolicyAcknowledgedDate
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          confirmed
          __typename
        }
        nextToken
        __typename
      }
      experiences {
        items {
          id
          jobTitle
          businessId
          companyName
          profileId
          startDate
          endDate
          employmentType
          location
          description
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      jobs {
        items {
          id
          profileId
          jobId
          coverLetter
          saved
          savedDate
          applied
          appliedDate
          createdAt
          updatedAt
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          job {
            id
            owner
            jobType
            businessId
            jobRole
            title
            description
            salary {
              type
              from
              to
              __typename
            }
            region
            requireCV
            requireCoverLetter
            createdAt
            archived
            startDate
            endDate
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            reported {
              items {
                id
                postId
                userId
                commentId
                jobId
                creatorId
                createdAt
                message
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          rejected
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    confirmed
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateBusinessEmployeeMutationVariables,
  APITypes.CreateBusinessEmployeeMutation
>;
export const updateBusinessEmployee = /* GraphQL */ `mutation UpdateBusinessEmployee(
  $input: UpdateBusinessEmployeeInput!
  $condition: ModelBusinessEmployeeConditionInput
) {
  updateBusinessEmployee(input: $input, condition: $condition) {
    id
    jobRole
    businessId
    profileId
    employeeId
    tippingPolicyAcknowledgedDate
    createdAt
    updatedAt
    business {
      id
      verified
      name
      tipping {
        merchantId
        merchant
        policy
        type
        active
        __typename
      }
      marketing {
        customerId
        subscriptionId
        active
        expirationDate
        sequenceId
        cancelled
        cancelledAt
        __typename
      }
      createdAt
      updatedAt
      owner
      code
      answers {
        questionId
        value
        __typename
      }
      isOutlet
      details {
        id
        contactName
        jobRole
        phone
        email
        website
        location {
          searchName
          address
          address2
          city
          region
          postcode
          country
          latitude
          longitude
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      ownerProfile {
        id
        slug
        firstName
        lastName
        bio
        picture
        location {
          searchName
          address
          address2
          city
          region
          postcode
          country
          latitude
          longitude
          __typename
        }
        createdAt
        updatedAt
        business {
          id
          verified
          name
          tipping {
            merchantId
            merchant
            policy
            type
            active
            __typename
          }
          marketing {
            customerId
            subscriptionId
            active
            expirationDate
            sequenceId
            cancelled
            cancelledAt
            __typename
          }
          createdAt
          updatedAt
          owner
          code
          answers {
            questionId
            value
            __typename
          }
          isOutlet
          details {
            id
            contactName
            jobRole
            phone
            email
            website
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          ownerProfile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          invites {
            items {
              id
              businessId
              receiver
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          employees {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          brands {
            items {
              id
              businessId
              brandId
              status
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profileId
              brand {
                id
                name
                logo
                active
                productType
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        archived
        details {
          id
          accountType
          isOnboardingComplete
          email
          feedRegion
          tippingAccount {
            code
            merchant
            accountId
            verified
            missingRequirements {
              key
              description
              __typename
            }
            pendingValidation
            bankDetails {
              name
              sortCode
              last4Digits
              __typename
            }
            __typename
          }
          phone_number
          phoneNumberConfirmed
          pushNotificationToken
          pushNotificationReadDate
          createdAt
          updatedAt
          __typename
        }
        employers {
          items {
            id
            jobRole
            businessId
            profileId
            employeeId
            tippingPolicyAcknowledgedDate
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            confirmed
            __typename
          }
          nextToken
          __typename
        }
        experiences {
          items {
            id
            jobTitle
            businessId
            companyName
            profileId
            startDate
            endDate
            employmentType
            location
            description
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        jobs {
          items {
            id
            profileId
            jobId
            coverLetter
            saved
            savedDate
            applied
            appliedDate
            createdAt
            updatedAt
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            job {
              id
              owner
              jobType
              businessId
              jobRole
              title
              description
              salary {
                type
                from
                to
                __typename
              }
              region
              requireCV
              requireCoverLetter
              createdAt
              archived
              startDate
              endDate
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              reported {
                nextToken
                __typename
              }
              __typename
            }
            rejected
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      invites {
        items {
          id
          businessId
          receiver
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      employees {
        items {
          id
          jobRole
          businessId
          profileId
          employeeId
          tippingPolicyAcknowledgedDate
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          confirmed
          __typename
        }
        nextToken
        __typename
      }
      brands {
        items {
          id
          businessId
          brandId
          status
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profileId
          brand {
            id
            name
            logo
            active
            productType
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    profile {
      id
      slug
      firstName
      lastName
      bio
      picture
      location {
        searchName
        address
        address2
        city
        region
        postcode
        country
        latitude
        longitude
        __typename
      }
      createdAt
      updatedAt
      business {
        id
        verified
        name
        tipping {
          merchantId
          merchant
          policy
          type
          active
          __typename
        }
        marketing {
          customerId
          subscriptionId
          active
          expirationDate
          sequenceId
          cancelled
          cancelledAt
          __typename
        }
        createdAt
        updatedAt
        owner
        code
        answers {
          questionId
          value
          __typename
        }
        isOutlet
        details {
          id
          contactName
          jobRole
          phone
          email
          website
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        ownerProfile {
          id
          slug
          firstName
          lastName
          bio
          picture
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          archived
          details {
            id
            accountType
            isOnboardingComplete
            email
            feedRegion
            tippingAccount {
              code
              merchant
              accountId
              verified
              missingRequirements {
                key
                description
                __typename
              }
              pendingValidation
              bankDetails {
                name
                sortCode
                last4Digits
                __typename
              }
              __typename
            }
            phone_number
            phoneNumberConfirmed
            pushNotificationToken
            pushNotificationReadDate
            createdAt
            updatedAt
            __typename
          }
          employers {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          experiences {
            items {
              id
              jobTitle
              businessId
              companyName
              profileId
              startDate
              endDate
              employmentType
              location
              description
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          jobs {
            items {
              id
              profileId
              jobId
              coverLetter
              saved
              savedDate
              applied
              appliedDate
              createdAt
              updatedAt
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              job {
                id
                owner
                jobType
                businessId
                jobRole
                title
                description
                region
                requireCV
                requireCoverLetter
                createdAt
                archived
                startDate
                endDate
                updatedAt
                __typename
              }
              rejected
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        invites {
          items {
            id
            businessId
            receiver
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        employees {
          items {
            id
            jobRole
            businessId
            profileId
            employeeId
            tippingPolicyAcknowledgedDate
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            confirmed
            __typename
          }
          nextToken
          __typename
        }
        brands {
          items {
            id
            businessId
            brandId
            status
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profileId
            brand {
              id
              name
              logo
              active
              productType
              createdAt
              updatedAt
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      archived
      details {
        id
        accountType
        isOnboardingComplete
        email
        feedRegion
        tippingAccount {
          code
          merchant
          accountId
          verified
          missingRequirements {
            key
            description
            __typename
          }
          pendingValidation
          bankDetails {
            name
            sortCode
            last4Digits
            __typename
          }
          __typename
        }
        phone_number
        phoneNumberConfirmed
        pushNotificationToken
        pushNotificationReadDate
        createdAt
        updatedAt
        __typename
      }
      employers {
        items {
          id
          jobRole
          businessId
          profileId
          employeeId
          tippingPolicyAcknowledgedDate
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          confirmed
          __typename
        }
        nextToken
        __typename
      }
      experiences {
        items {
          id
          jobTitle
          businessId
          companyName
          profileId
          startDate
          endDate
          employmentType
          location
          description
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      jobs {
        items {
          id
          profileId
          jobId
          coverLetter
          saved
          savedDate
          applied
          appliedDate
          createdAt
          updatedAt
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          job {
            id
            owner
            jobType
            businessId
            jobRole
            title
            description
            salary {
              type
              from
              to
              __typename
            }
            region
            requireCV
            requireCoverLetter
            createdAt
            archived
            startDate
            endDate
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            reported {
              items {
                id
                postId
                userId
                commentId
                jobId
                creatorId
                createdAt
                message
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          rejected
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    confirmed
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateBusinessEmployeeMutationVariables,
  APITypes.UpdateBusinessEmployeeMutation
>;
export const deleteBusinessEmployee = /* GraphQL */ `mutation DeleteBusinessEmployee(
  $input: DeleteBusinessEmployeeInput!
  $condition: ModelBusinessEmployeeConditionInput
) {
  deleteBusinessEmployee(input: $input, condition: $condition) {
    id
    jobRole
    businessId
    profileId
    employeeId
    tippingPolicyAcknowledgedDate
    createdAt
    updatedAt
    business {
      id
      verified
      name
      tipping {
        merchantId
        merchant
        policy
        type
        active
        __typename
      }
      marketing {
        customerId
        subscriptionId
        active
        expirationDate
        sequenceId
        cancelled
        cancelledAt
        __typename
      }
      createdAt
      updatedAt
      owner
      code
      answers {
        questionId
        value
        __typename
      }
      isOutlet
      details {
        id
        contactName
        jobRole
        phone
        email
        website
        location {
          searchName
          address
          address2
          city
          region
          postcode
          country
          latitude
          longitude
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      ownerProfile {
        id
        slug
        firstName
        lastName
        bio
        picture
        location {
          searchName
          address
          address2
          city
          region
          postcode
          country
          latitude
          longitude
          __typename
        }
        createdAt
        updatedAt
        business {
          id
          verified
          name
          tipping {
            merchantId
            merchant
            policy
            type
            active
            __typename
          }
          marketing {
            customerId
            subscriptionId
            active
            expirationDate
            sequenceId
            cancelled
            cancelledAt
            __typename
          }
          createdAt
          updatedAt
          owner
          code
          answers {
            questionId
            value
            __typename
          }
          isOutlet
          details {
            id
            contactName
            jobRole
            phone
            email
            website
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          ownerProfile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          invites {
            items {
              id
              businessId
              receiver
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          employees {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          brands {
            items {
              id
              businessId
              brandId
              status
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profileId
              brand {
                id
                name
                logo
                active
                productType
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        archived
        details {
          id
          accountType
          isOnboardingComplete
          email
          feedRegion
          tippingAccount {
            code
            merchant
            accountId
            verified
            missingRequirements {
              key
              description
              __typename
            }
            pendingValidation
            bankDetails {
              name
              sortCode
              last4Digits
              __typename
            }
            __typename
          }
          phone_number
          phoneNumberConfirmed
          pushNotificationToken
          pushNotificationReadDate
          createdAt
          updatedAt
          __typename
        }
        employers {
          items {
            id
            jobRole
            businessId
            profileId
            employeeId
            tippingPolicyAcknowledgedDate
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            confirmed
            __typename
          }
          nextToken
          __typename
        }
        experiences {
          items {
            id
            jobTitle
            businessId
            companyName
            profileId
            startDate
            endDate
            employmentType
            location
            description
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        jobs {
          items {
            id
            profileId
            jobId
            coverLetter
            saved
            savedDate
            applied
            appliedDate
            createdAt
            updatedAt
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            job {
              id
              owner
              jobType
              businessId
              jobRole
              title
              description
              salary {
                type
                from
                to
                __typename
              }
              region
              requireCV
              requireCoverLetter
              createdAt
              archived
              startDate
              endDate
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              reported {
                nextToken
                __typename
              }
              __typename
            }
            rejected
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      invites {
        items {
          id
          businessId
          receiver
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      employees {
        items {
          id
          jobRole
          businessId
          profileId
          employeeId
          tippingPolicyAcknowledgedDate
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          confirmed
          __typename
        }
        nextToken
        __typename
      }
      brands {
        items {
          id
          businessId
          brandId
          status
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profileId
          brand {
            id
            name
            logo
            active
            productType
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    profile {
      id
      slug
      firstName
      lastName
      bio
      picture
      location {
        searchName
        address
        address2
        city
        region
        postcode
        country
        latitude
        longitude
        __typename
      }
      createdAt
      updatedAt
      business {
        id
        verified
        name
        tipping {
          merchantId
          merchant
          policy
          type
          active
          __typename
        }
        marketing {
          customerId
          subscriptionId
          active
          expirationDate
          sequenceId
          cancelled
          cancelledAt
          __typename
        }
        createdAt
        updatedAt
        owner
        code
        answers {
          questionId
          value
          __typename
        }
        isOutlet
        details {
          id
          contactName
          jobRole
          phone
          email
          website
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        ownerProfile {
          id
          slug
          firstName
          lastName
          bio
          picture
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          archived
          details {
            id
            accountType
            isOnboardingComplete
            email
            feedRegion
            tippingAccount {
              code
              merchant
              accountId
              verified
              missingRequirements {
                key
                description
                __typename
              }
              pendingValidation
              bankDetails {
                name
                sortCode
                last4Digits
                __typename
              }
              __typename
            }
            phone_number
            phoneNumberConfirmed
            pushNotificationToken
            pushNotificationReadDate
            createdAt
            updatedAt
            __typename
          }
          employers {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          experiences {
            items {
              id
              jobTitle
              businessId
              companyName
              profileId
              startDate
              endDate
              employmentType
              location
              description
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          jobs {
            items {
              id
              profileId
              jobId
              coverLetter
              saved
              savedDate
              applied
              appliedDate
              createdAt
              updatedAt
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              job {
                id
                owner
                jobType
                businessId
                jobRole
                title
                description
                region
                requireCV
                requireCoverLetter
                createdAt
                archived
                startDate
                endDate
                updatedAt
                __typename
              }
              rejected
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        invites {
          items {
            id
            businessId
            receiver
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        employees {
          items {
            id
            jobRole
            businessId
            profileId
            employeeId
            tippingPolicyAcknowledgedDate
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            confirmed
            __typename
          }
          nextToken
          __typename
        }
        brands {
          items {
            id
            businessId
            brandId
            status
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profileId
            brand {
              id
              name
              logo
              active
              productType
              createdAt
              updatedAt
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      archived
      details {
        id
        accountType
        isOnboardingComplete
        email
        feedRegion
        tippingAccount {
          code
          merchant
          accountId
          verified
          missingRequirements {
            key
            description
            __typename
          }
          pendingValidation
          bankDetails {
            name
            sortCode
            last4Digits
            __typename
          }
          __typename
        }
        phone_number
        phoneNumberConfirmed
        pushNotificationToken
        pushNotificationReadDate
        createdAt
        updatedAt
        __typename
      }
      employers {
        items {
          id
          jobRole
          businessId
          profileId
          employeeId
          tippingPolicyAcknowledgedDate
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          confirmed
          __typename
        }
        nextToken
        __typename
      }
      experiences {
        items {
          id
          jobTitle
          businessId
          companyName
          profileId
          startDate
          endDate
          employmentType
          location
          description
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      jobs {
        items {
          id
          profileId
          jobId
          coverLetter
          saved
          savedDate
          applied
          appliedDate
          createdAt
          updatedAt
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          job {
            id
            owner
            jobType
            businessId
            jobRole
            title
            description
            salary {
              type
              from
              to
              __typename
            }
            region
            requireCV
            requireCoverLetter
            createdAt
            archived
            startDate
            endDate
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            reported {
              items {
                id
                postId
                userId
                commentId
                jobId
                creatorId
                createdAt
                message
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          rejected
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    confirmed
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteBusinessEmployeeMutationVariables,
  APITypes.DeleteBusinessEmployeeMutation
>;
export const createBusinessBrands = /* GraphQL */ `mutation CreateBusinessBrands(
  $input: CreateBusinessBrandsInput!
  $condition: ModelBusinessBrandsConditionInput
) {
  createBusinessBrands(input: $input, condition: $condition) {
    id
    businessId
    brandId
    status
    createdAt
    updatedAt
    business {
      id
      verified
      name
      tipping {
        merchantId
        merchant
        policy
        type
        active
        __typename
      }
      marketing {
        customerId
        subscriptionId
        active
        expirationDate
        sequenceId
        cancelled
        cancelledAt
        __typename
      }
      createdAt
      updatedAt
      owner
      code
      answers {
        questionId
        value
        __typename
      }
      isOutlet
      details {
        id
        contactName
        jobRole
        phone
        email
        website
        location {
          searchName
          address
          address2
          city
          region
          postcode
          country
          latitude
          longitude
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      ownerProfile {
        id
        slug
        firstName
        lastName
        bio
        picture
        location {
          searchName
          address
          address2
          city
          region
          postcode
          country
          latitude
          longitude
          __typename
        }
        createdAt
        updatedAt
        business {
          id
          verified
          name
          tipping {
            merchantId
            merchant
            policy
            type
            active
            __typename
          }
          marketing {
            customerId
            subscriptionId
            active
            expirationDate
            sequenceId
            cancelled
            cancelledAt
            __typename
          }
          createdAt
          updatedAt
          owner
          code
          answers {
            questionId
            value
            __typename
          }
          isOutlet
          details {
            id
            contactName
            jobRole
            phone
            email
            website
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          ownerProfile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          invites {
            items {
              id
              businessId
              receiver
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          employees {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          brands {
            items {
              id
              businessId
              brandId
              status
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profileId
              brand {
                id
                name
                logo
                active
                productType
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        archived
        details {
          id
          accountType
          isOnboardingComplete
          email
          feedRegion
          tippingAccount {
            code
            merchant
            accountId
            verified
            missingRequirements {
              key
              description
              __typename
            }
            pendingValidation
            bankDetails {
              name
              sortCode
              last4Digits
              __typename
            }
            __typename
          }
          phone_number
          phoneNumberConfirmed
          pushNotificationToken
          pushNotificationReadDate
          createdAt
          updatedAt
          __typename
        }
        employers {
          items {
            id
            jobRole
            businessId
            profileId
            employeeId
            tippingPolicyAcknowledgedDate
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            confirmed
            __typename
          }
          nextToken
          __typename
        }
        experiences {
          items {
            id
            jobTitle
            businessId
            companyName
            profileId
            startDate
            endDate
            employmentType
            location
            description
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        jobs {
          items {
            id
            profileId
            jobId
            coverLetter
            saved
            savedDate
            applied
            appliedDate
            createdAt
            updatedAt
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            job {
              id
              owner
              jobType
              businessId
              jobRole
              title
              description
              salary {
                type
                from
                to
                __typename
              }
              region
              requireCV
              requireCoverLetter
              createdAt
              archived
              startDate
              endDate
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              reported {
                nextToken
                __typename
              }
              __typename
            }
            rejected
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      invites {
        items {
          id
          businessId
          receiver
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      employees {
        items {
          id
          jobRole
          businessId
          profileId
          employeeId
          tippingPolicyAcknowledgedDate
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          confirmed
          __typename
        }
        nextToken
        __typename
      }
      brands {
        items {
          id
          businessId
          brandId
          status
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profileId
          brand {
            id
            name
            logo
            active
            productType
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    profileId
    brand {
      id
      name
      logo
      active
      productType
      createdAt
      updatedAt
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateBusinessBrandsMutationVariables,
  APITypes.CreateBusinessBrandsMutation
>;
export const createBrand = /* GraphQL */ `mutation CreateBrand(
  $input: CreateBrandInput!
  $condition: ModelBrandConditionInput
) {
  createBrand(input: $input, condition: $condition) {
    id
    name
    logo
    active
    productType
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateBrandMutationVariables,
  APITypes.CreateBrandMutation
>;
export const updateBrand = /* GraphQL */ `mutation UpdateBrand(
  $input: UpdateBrandInput!
  $condition: ModelBrandConditionInput
) {
  updateBrand(input: $input, condition: $condition) {
    id
    name
    logo
    active
    productType
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateBrandMutationVariables,
  APITypes.UpdateBrandMutation
>;
export const createExperience = /* GraphQL */ `mutation CreateExperience(
  $input: CreateExperienceInput!
  $condition: ModelExperienceConditionInput
) {
  createExperience(input: $input, condition: $condition) {
    id
    jobTitle
    businessId
    companyName
    profileId
    startDate
    endDate
    employmentType
    location
    description
    createdAt
    updatedAt
    business {
      id
      verified
      name
      tipping {
        merchantId
        merchant
        policy
        type
        active
        __typename
      }
      marketing {
        customerId
        subscriptionId
        active
        expirationDate
        sequenceId
        cancelled
        cancelledAt
        __typename
      }
      createdAt
      updatedAt
      owner
      code
      answers {
        questionId
        value
        __typename
      }
      isOutlet
      details {
        id
        contactName
        jobRole
        phone
        email
        website
        location {
          searchName
          address
          address2
          city
          region
          postcode
          country
          latitude
          longitude
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      ownerProfile {
        id
        slug
        firstName
        lastName
        bio
        picture
        location {
          searchName
          address
          address2
          city
          region
          postcode
          country
          latitude
          longitude
          __typename
        }
        createdAt
        updatedAt
        business {
          id
          verified
          name
          tipping {
            merchantId
            merchant
            policy
            type
            active
            __typename
          }
          marketing {
            customerId
            subscriptionId
            active
            expirationDate
            sequenceId
            cancelled
            cancelledAt
            __typename
          }
          createdAt
          updatedAt
          owner
          code
          answers {
            questionId
            value
            __typename
          }
          isOutlet
          details {
            id
            contactName
            jobRole
            phone
            email
            website
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          ownerProfile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          invites {
            items {
              id
              businessId
              receiver
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          employees {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          brands {
            items {
              id
              businessId
              brandId
              status
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profileId
              brand {
                id
                name
                logo
                active
                productType
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        archived
        details {
          id
          accountType
          isOnboardingComplete
          email
          feedRegion
          tippingAccount {
            code
            merchant
            accountId
            verified
            missingRequirements {
              key
              description
              __typename
            }
            pendingValidation
            bankDetails {
              name
              sortCode
              last4Digits
              __typename
            }
            __typename
          }
          phone_number
          phoneNumberConfirmed
          pushNotificationToken
          pushNotificationReadDate
          createdAt
          updatedAt
          __typename
        }
        employers {
          items {
            id
            jobRole
            businessId
            profileId
            employeeId
            tippingPolicyAcknowledgedDate
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            confirmed
            __typename
          }
          nextToken
          __typename
        }
        experiences {
          items {
            id
            jobTitle
            businessId
            companyName
            profileId
            startDate
            endDate
            employmentType
            location
            description
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        jobs {
          items {
            id
            profileId
            jobId
            coverLetter
            saved
            savedDate
            applied
            appliedDate
            createdAt
            updatedAt
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            job {
              id
              owner
              jobType
              businessId
              jobRole
              title
              description
              salary {
                type
                from
                to
                __typename
              }
              region
              requireCV
              requireCoverLetter
              createdAt
              archived
              startDate
              endDate
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              reported {
                nextToken
                __typename
              }
              __typename
            }
            rejected
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      invites {
        items {
          id
          businessId
          receiver
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      employees {
        items {
          id
          jobRole
          businessId
          profileId
          employeeId
          tippingPolicyAcknowledgedDate
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          confirmed
          __typename
        }
        nextToken
        __typename
      }
      brands {
        items {
          id
          businessId
          brandId
          status
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profileId
          brand {
            id
            name
            logo
            active
            productType
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    profile {
      id
      slug
      firstName
      lastName
      bio
      picture
      location {
        searchName
        address
        address2
        city
        region
        postcode
        country
        latitude
        longitude
        __typename
      }
      createdAt
      updatedAt
      business {
        id
        verified
        name
        tipping {
          merchantId
          merchant
          policy
          type
          active
          __typename
        }
        marketing {
          customerId
          subscriptionId
          active
          expirationDate
          sequenceId
          cancelled
          cancelledAt
          __typename
        }
        createdAt
        updatedAt
        owner
        code
        answers {
          questionId
          value
          __typename
        }
        isOutlet
        details {
          id
          contactName
          jobRole
          phone
          email
          website
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        ownerProfile {
          id
          slug
          firstName
          lastName
          bio
          picture
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          archived
          details {
            id
            accountType
            isOnboardingComplete
            email
            feedRegion
            tippingAccount {
              code
              merchant
              accountId
              verified
              missingRequirements {
                key
                description
                __typename
              }
              pendingValidation
              bankDetails {
                name
                sortCode
                last4Digits
                __typename
              }
              __typename
            }
            phone_number
            phoneNumberConfirmed
            pushNotificationToken
            pushNotificationReadDate
            createdAt
            updatedAt
            __typename
          }
          employers {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          experiences {
            items {
              id
              jobTitle
              businessId
              companyName
              profileId
              startDate
              endDate
              employmentType
              location
              description
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          jobs {
            items {
              id
              profileId
              jobId
              coverLetter
              saved
              savedDate
              applied
              appliedDate
              createdAt
              updatedAt
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              job {
                id
                owner
                jobType
                businessId
                jobRole
                title
                description
                region
                requireCV
                requireCoverLetter
                createdAt
                archived
                startDate
                endDate
                updatedAt
                __typename
              }
              rejected
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        invites {
          items {
            id
            businessId
            receiver
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        employees {
          items {
            id
            jobRole
            businessId
            profileId
            employeeId
            tippingPolicyAcknowledgedDate
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            confirmed
            __typename
          }
          nextToken
          __typename
        }
        brands {
          items {
            id
            businessId
            brandId
            status
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profileId
            brand {
              id
              name
              logo
              active
              productType
              createdAt
              updatedAt
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      archived
      details {
        id
        accountType
        isOnboardingComplete
        email
        feedRegion
        tippingAccount {
          code
          merchant
          accountId
          verified
          missingRequirements {
            key
            description
            __typename
          }
          pendingValidation
          bankDetails {
            name
            sortCode
            last4Digits
            __typename
          }
          __typename
        }
        phone_number
        phoneNumberConfirmed
        pushNotificationToken
        pushNotificationReadDate
        createdAt
        updatedAt
        __typename
      }
      employers {
        items {
          id
          jobRole
          businessId
          profileId
          employeeId
          tippingPolicyAcknowledgedDate
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          confirmed
          __typename
        }
        nextToken
        __typename
      }
      experiences {
        items {
          id
          jobTitle
          businessId
          companyName
          profileId
          startDate
          endDate
          employmentType
          location
          description
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      jobs {
        items {
          id
          profileId
          jobId
          coverLetter
          saved
          savedDate
          applied
          appliedDate
          createdAt
          updatedAt
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          job {
            id
            owner
            jobType
            businessId
            jobRole
            title
            description
            salary {
              type
              from
              to
              __typename
            }
            region
            requireCV
            requireCoverLetter
            createdAt
            archived
            startDate
            endDate
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            reported {
              items {
                id
                postId
                userId
                commentId
                jobId
                creatorId
                createdAt
                message
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          rejected
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateExperienceMutationVariables,
  APITypes.CreateExperienceMutation
>;
export const updateExperience = /* GraphQL */ `mutation UpdateExperience(
  $input: UpdateExperienceInput!
  $condition: ModelExperienceConditionInput
) {
  updateExperience(input: $input, condition: $condition) {
    id
    jobTitle
    businessId
    companyName
    profileId
    startDate
    endDate
    employmentType
    location
    description
    createdAt
    updatedAt
    business {
      id
      verified
      name
      tipping {
        merchantId
        merchant
        policy
        type
        active
        __typename
      }
      marketing {
        customerId
        subscriptionId
        active
        expirationDate
        sequenceId
        cancelled
        cancelledAt
        __typename
      }
      createdAt
      updatedAt
      owner
      code
      answers {
        questionId
        value
        __typename
      }
      isOutlet
      details {
        id
        contactName
        jobRole
        phone
        email
        website
        location {
          searchName
          address
          address2
          city
          region
          postcode
          country
          latitude
          longitude
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      ownerProfile {
        id
        slug
        firstName
        lastName
        bio
        picture
        location {
          searchName
          address
          address2
          city
          region
          postcode
          country
          latitude
          longitude
          __typename
        }
        createdAt
        updatedAt
        business {
          id
          verified
          name
          tipping {
            merchantId
            merchant
            policy
            type
            active
            __typename
          }
          marketing {
            customerId
            subscriptionId
            active
            expirationDate
            sequenceId
            cancelled
            cancelledAt
            __typename
          }
          createdAt
          updatedAt
          owner
          code
          answers {
            questionId
            value
            __typename
          }
          isOutlet
          details {
            id
            contactName
            jobRole
            phone
            email
            website
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          ownerProfile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          invites {
            items {
              id
              businessId
              receiver
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          employees {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          brands {
            items {
              id
              businessId
              brandId
              status
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profileId
              brand {
                id
                name
                logo
                active
                productType
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        archived
        details {
          id
          accountType
          isOnboardingComplete
          email
          feedRegion
          tippingAccount {
            code
            merchant
            accountId
            verified
            missingRequirements {
              key
              description
              __typename
            }
            pendingValidation
            bankDetails {
              name
              sortCode
              last4Digits
              __typename
            }
            __typename
          }
          phone_number
          phoneNumberConfirmed
          pushNotificationToken
          pushNotificationReadDate
          createdAt
          updatedAt
          __typename
        }
        employers {
          items {
            id
            jobRole
            businessId
            profileId
            employeeId
            tippingPolicyAcknowledgedDate
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            confirmed
            __typename
          }
          nextToken
          __typename
        }
        experiences {
          items {
            id
            jobTitle
            businessId
            companyName
            profileId
            startDate
            endDate
            employmentType
            location
            description
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        jobs {
          items {
            id
            profileId
            jobId
            coverLetter
            saved
            savedDate
            applied
            appliedDate
            createdAt
            updatedAt
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            job {
              id
              owner
              jobType
              businessId
              jobRole
              title
              description
              salary {
                type
                from
                to
                __typename
              }
              region
              requireCV
              requireCoverLetter
              createdAt
              archived
              startDate
              endDate
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              reported {
                nextToken
                __typename
              }
              __typename
            }
            rejected
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      invites {
        items {
          id
          businessId
          receiver
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      employees {
        items {
          id
          jobRole
          businessId
          profileId
          employeeId
          tippingPolicyAcknowledgedDate
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          confirmed
          __typename
        }
        nextToken
        __typename
      }
      brands {
        items {
          id
          businessId
          brandId
          status
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profileId
          brand {
            id
            name
            logo
            active
            productType
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    profile {
      id
      slug
      firstName
      lastName
      bio
      picture
      location {
        searchName
        address
        address2
        city
        region
        postcode
        country
        latitude
        longitude
        __typename
      }
      createdAt
      updatedAt
      business {
        id
        verified
        name
        tipping {
          merchantId
          merchant
          policy
          type
          active
          __typename
        }
        marketing {
          customerId
          subscriptionId
          active
          expirationDate
          sequenceId
          cancelled
          cancelledAt
          __typename
        }
        createdAt
        updatedAt
        owner
        code
        answers {
          questionId
          value
          __typename
        }
        isOutlet
        details {
          id
          contactName
          jobRole
          phone
          email
          website
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        ownerProfile {
          id
          slug
          firstName
          lastName
          bio
          picture
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          archived
          details {
            id
            accountType
            isOnboardingComplete
            email
            feedRegion
            tippingAccount {
              code
              merchant
              accountId
              verified
              missingRequirements {
                key
                description
                __typename
              }
              pendingValidation
              bankDetails {
                name
                sortCode
                last4Digits
                __typename
              }
              __typename
            }
            phone_number
            phoneNumberConfirmed
            pushNotificationToken
            pushNotificationReadDate
            createdAt
            updatedAt
            __typename
          }
          employers {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          experiences {
            items {
              id
              jobTitle
              businessId
              companyName
              profileId
              startDate
              endDate
              employmentType
              location
              description
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          jobs {
            items {
              id
              profileId
              jobId
              coverLetter
              saved
              savedDate
              applied
              appliedDate
              createdAt
              updatedAt
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              job {
                id
                owner
                jobType
                businessId
                jobRole
                title
                description
                region
                requireCV
                requireCoverLetter
                createdAt
                archived
                startDate
                endDate
                updatedAt
                __typename
              }
              rejected
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        invites {
          items {
            id
            businessId
            receiver
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        employees {
          items {
            id
            jobRole
            businessId
            profileId
            employeeId
            tippingPolicyAcknowledgedDate
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            confirmed
            __typename
          }
          nextToken
          __typename
        }
        brands {
          items {
            id
            businessId
            brandId
            status
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profileId
            brand {
              id
              name
              logo
              active
              productType
              createdAt
              updatedAt
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      archived
      details {
        id
        accountType
        isOnboardingComplete
        email
        feedRegion
        tippingAccount {
          code
          merchant
          accountId
          verified
          missingRequirements {
            key
            description
            __typename
          }
          pendingValidation
          bankDetails {
            name
            sortCode
            last4Digits
            __typename
          }
          __typename
        }
        phone_number
        phoneNumberConfirmed
        pushNotificationToken
        pushNotificationReadDate
        createdAt
        updatedAt
        __typename
      }
      employers {
        items {
          id
          jobRole
          businessId
          profileId
          employeeId
          tippingPolicyAcknowledgedDate
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          confirmed
          __typename
        }
        nextToken
        __typename
      }
      experiences {
        items {
          id
          jobTitle
          businessId
          companyName
          profileId
          startDate
          endDate
          employmentType
          location
          description
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      jobs {
        items {
          id
          profileId
          jobId
          coverLetter
          saved
          savedDate
          applied
          appliedDate
          createdAt
          updatedAt
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          job {
            id
            owner
            jobType
            businessId
            jobRole
            title
            description
            salary {
              type
              from
              to
              __typename
            }
            region
            requireCV
            requireCoverLetter
            createdAt
            archived
            startDate
            endDate
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            reported {
              items {
                id
                postId
                userId
                commentId
                jobId
                creatorId
                createdAt
                message
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          rejected
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateExperienceMutationVariables,
  APITypes.UpdateExperienceMutation
>;
export const createPost = /* GraphQL */ `mutation CreatePost(
  $input: CreatePostInput!
  $condition: ModelPostConditionInput
) {
  createPost(input: $input, condition: $condition) {
    id
    hubId
    creatorId
    content
    createdAt
    archived
    feedRegion
    regions
    attachments {
      mediaType
      source
      alt
      __typename
    }
    updatedAt
    creator {
      id
      slug
      firstName
      lastName
      bio
      picture
      location {
        searchName
        address
        address2
        city
        region
        postcode
        country
        latitude
        longitude
        __typename
      }
      createdAt
      updatedAt
      business {
        id
        verified
        name
        tipping {
          merchantId
          merchant
          policy
          type
          active
          __typename
        }
        marketing {
          customerId
          subscriptionId
          active
          expirationDate
          sequenceId
          cancelled
          cancelledAt
          __typename
        }
        createdAt
        updatedAt
        owner
        code
        answers {
          questionId
          value
          __typename
        }
        isOutlet
        details {
          id
          contactName
          jobRole
          phone
          email
          website
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        ownerProfile {
          id
          slug
          firstName
          lastName
          bio
          picture
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          archived
          details {
            id
            accountType
            isOnboardingComplete
            email
            feedRegion
            tippingAccount {
              code
              merchant
              accountId
              verified
              missingRequirements {
                key
                description
                __typename
              }
              pendingValidation
              bankDetails {
                name
                sortCode
                last4Digits
                __typename
              }
              __typename
            }
            phone_number
            phoneNumberConfirmed
            pushNotificationToken
            pushNotificationReadDate
            createdAt
            updatedAt
            __typename
          }
          employers {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          experiences {
            items {
              id
              jobTitle
              businessId
              companyName
              profileId
              startDate
              endDate
              employmentType
              location
              description
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          jobs {
            items {
              id
              profileId
              jobId
              coverLetter
              saved
              savedDate
              applied
              appliedDate
              createdAt
              updatedAt
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              job {
                id
                owner
                jobType
                businessId
                jobRole
                title
                description
                region
                requireCV
                requireCoverLetter
                createdAt
                archived
                startDate
                endDate
                updatedAt
                __typename
              }
              rejected
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        invites {
          items {
            id
            businessId
            receiver
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        employees {
          items {
            id
            jobRole
            businessId
            profileId
            employeeId
            tippingPolicyAcknowledgedDate
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            confirmed
            __typename
          }
          nextToken
          __typename
        }
        brands {
          items {
            id
            businessId
            brandId
            status
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profileId
            brand {
              id
              name
              logo
              active
              productType
              createdAt
              updatedAt
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      archived
      details {
        id
        accountType
        isOnboardingComplete
        email
        feedRegion
        tippingAccount {
          code
          merchant
          accountId
          verified
          missingRequirements {
            key
            description
            __typename
          }
          pendingValidation
          bankDetails {
            name
            sortCode
            last4Digits
            __typename
          }
          __typename
        }
        phone_number
        phoneNumberConfirmed
        pushNotificationToken
        pushNotificationReadDate
        createdAt
        updatedAt
        __typename
      }
      employers {
        items {
          id
          jobRole
          businessId
          profileId
          employeeId
          tippingPolicyAcknowledgedDate
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          confirmed
          __typename
        }
        nextToken
        __typename
      }
      experiences {
        items {
          id
          jobTitle
          businessId
          companyName
          profileId
          startDate
          endDate
          employmentType
          location
          description
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      jobs {
        items {
          id
          profileId
          jobId
          coverLetter
          saved
          savedDate
          applied
          appliedDate
          createdAt
          updatedAt
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          job {
            id
            owner
            jobType
            businessId
            jobRole
            title
            description
            salary {
              type
              from
              to
              __typename
            }
            region
            requireCV
            requireCoverLetter
            createdAt
            archived
            startDate
            endDate
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            reported {
              items {
                id
                postId
                userId
                commentId
                jobId
                creatorId
                createdAt
                message
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          rejected
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    comments {
      items {
        id
        postId
        commentId
        content
        creatorId
        createdAt
        archived
        attachments {
          mediaType
          source
          alt
          __typename
        }
        updatedAt
        creator {
          id
          slug
          firstName
          lastName
          bio
          picture
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          archived
          details {
            id
            accountType
            isOnboardingComplete
            email
            feedRegion
            tippingAccount {
              code
              merchant
              accountId
              verified
              missingRequirements {
                key
                description
                __typename
              }
              pendingValidation
              bankDetails {
                name
                sortCode
                last4Digits
                __typename
              }
              __typename
            }
            phone_number
            phoneNumberConfirmed
            pushNotificationToken
            pushNotificationReadDate
            createdAt
            updatedAt
            __typename
          }
          employers {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          experiences {
            items {
              id
              jobTitle
              businessId
              companyName
              profileId
              startDate
              endDate
              employmentType
              location
              description
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          jobs {
            items {
              id
              profileId
              jobId
              coverLetter
              saved
              savedDate
              applied
              appliedDate
              createdAt
              updatedAt
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              job {
                id
                owner
                jobType
                businessId
                jobRole
                title
                description
                region
                requireCV
                requireCoverLetter
                createdAt
                archived
                startDate
                endDate
                updatedAt
                __typename
              }
              rejected
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        post {
          id
          hubId
          creatorId
          content
          createdAt
          archived
          feedRegion
          regions
          attachments {
            mediaType
            source
            alt
            __typename
          }
          updatedAt
          creator {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          comments {
            items {
              id
              postId
              commentId
              content
              creatorId
              createdAt
              archived
              attachments {
                mediaType
                source
                alt
                __typename
              }
              updatedAt
              creator {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              post {
                id
                hubId
                creatorId
                content
                createdAt
                archived
                feedRegion
                regions
                updatedAt
                __typename
              }
              comments {
                nextToken
                __typename
              }
              reported {
                nextToken
                __typename
              }
              likes {
                nextToken
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          reported {
            items {
              id
              postId
              userId
              commentId
              jobId
              creatorId
              createdAt
              message
              updatedAt
              creator {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          likes {
            items {
              id
              postId
              profileId
              createdAt
              updatedAt
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        comments {
          items {
            id
            postId
            commentId
            content
            creatorId
            createdAt
            archived
            attachments {
              mediaType
              source
              alt
              __typename
            }
            updatedAt
            creator {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            post {
              id
              hubId
              creatorId
              content
              createdAt
              archived
              feedRegion
              regions
              attachments {
                mediaType
                source
                alt
                __typename
              }
              updatedAt
              creator {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              comments {
                nextToken
                __typename
              }
              reported {
                nextToken
                __typename
              }
              likes {
                nextToken
                __typename
              }
              __typename
            }
            comments {
              items {
                id
                postId
                commentId
                content
                creatorId
                createdAt
                archived
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            reported {
              items {
                id
                postId
                userId
                commentId
                jobId
                creatorId
                createdAt
                message
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            likes {
              items {
                id
                commentId
                profileId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        reported {
          items {
            id
            postId
            userId
            commentId
            jobId
            creatorId
            createdAt
            message
            updatedAt
            creator {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        likes {
          items {
            id
            commentId
            profileId
            createdAt
            updatedAt
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    reported {
      items {
        id
        postId
        userId
        commentId
        jobId
        creatorId
        createdAt
        message
        updatedAt
        creator {
          id
          slug
          firstName
          lastName
          bio
          picture
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          archived
          details {
            id
            accountType
            isOnboardingComplete
            email
            feedRegion
            tippingAccount {
              code
              merchant
              accountId
              verified
              missingRequirements {
                key
                description
                __typename
              }
              pendingValidation
              bankDetails {
                name
                sortCode
                last4Digits
                __typename
              }
              __typename
            }
            phone_number
            phoneNumberConfirmed
            pushNotificationToken
            pushNotificationReadDate
            createdAt
            updatedAt
            __typename
          }
          employers {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          experiences {
            items {
              id
              jobTitle
              businessId
              companyName
              profileId
              startDate
              endDate
              employmentType
              location
              description
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          jobs {
            items {
              id
              profileId
              jobId
              coverLetter
              saved
              savedDate
              applied
              appliedDate
              createdAt
              updatedAt
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              job {
                id
                owner
                jobType
                businessId
                jobRole
                title
                description
                region
                requireCV
                requireCoverLetter
                createdAt
                archived
                startDate
                endDate
                updatedAt
                __typename
              }
              rejected
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    likes {
      items {
        id
        postId
        profileId
        createdAt
        updatedAt
        profile {
          id
          slug
          firstName
          lastName
          bio
          picture
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          archived
          details {
            id
            accountType
            isOnboardingComplete
            email
            feedRegion
            tippingAccount {
              code
              merchant
              accountId
              verified
              missingRequirements {
                key
                description
                __typename
              }
              pendingValidation
              bankDetails {
                name
                sortCode
                last4Digits
                __typename
              }
              __typename
            }
            phone_number
            phoneNumberConfirmed
            pushNotificationToken
            pushNotificationReadDate
            createdAt
            updatedAt
            __typename
          }
          employers {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          experiences {
            items {
              id
              jobTitle
              businessId
              companyName
              profileId
              startDate
              endDate
              employmentType
              location
              description
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          jobs {
            items {
              id
              profileId
              jobId
              coverLetter
              saved
              savedDate
              applied
              appliedDate
              createdAt
              updatedAt
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              job {
                id
                owner
                jobType
                businessId
                jobRole
                title
                description
                region
                requireCV
                requireCoverLetter
                createdAt
                archived
                startDate
                endDate
                updatedAt
                __typename
              }
              rejected
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePostMutationVariables,
  APITypes.CreatePostMutation
>;
export const updatePost = /* GraphQL */ `mutation UpdatePost(
  $input: UpdatePostInput!
  $condition: ModelPostConditionInput
) {
  updatePost(input: $input, condition: $condition) {
    id
    hubId
    creatorId
    content
    createdAt
    archived
    feedRegion
    regions
    attachments {
      mediaType
      source
      alt
      __typename
    }
    updatedAt
    creator {
      id
      slug
      firstName
      lastName
      bio
      picture
      location {
        searchName
        address
        address2
        city
        region
        postcode
        country
        latitude
        longitude
        __typename
      }
      createdAt
      updatedAt
      business {
        id
        verified
        name
        tipping {
          merchantId
          merchant
          policy
          type
          active
          __typename
        }
        marketing {
          customerId
          subscriptionId
          active
          expirationDate
          sequenceId
          cancelled
          cancelledAt
          __typename
        }
        createdAt
        updatedAt
        owner
        code
        answers {
          questionId
          value
          __typename
        }
        isOutlet
        details {
          id
          contactName
          jobRole
          phone
          email
          website
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        ownerProfile {
          id
          slug
          firstName
          lastName
          bio
          picture
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          archived
          details {
            id
            accountType
            isOnboardingComplete
            email
            feedRegion
            tippingAccount {
              code
              merchant
              accountId
              verified
              missingRequirements {
                key
                description
                __typename
              }
              pendingValidation
              bankDetails {
                name
                sortCode
                last4Digits
                __typename
              }
              __typename
            }
            phone_number
            phoneNumberConfirmed
            pushNotificationToken
            pushNotificationReadDate
            createdAt
            updatedAt
            __typename
          }
          employers {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          experiences {
            items {
              id
              jobTitle
              businessId
              companyName
              profileId
              startDate
              endDate
              employmentType
              location
              description
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          jobs {
            items {
              id
              profileId
              jobId
              coverLetter
              saved
              savedDate
              applied
              appliedDate
              createdAt
              updatedAt
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              job {
                id
                owner
                jobType
                businessId
                jobRole
                title
                description
                region
                requireCV
                requireCoverLetter
                createdAt
                archived
                startDate
                endDate
                updatedAt
                __typename
              }
              rejected
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        invites {
          items {
            id
            businessId
            receiver
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        employees {
          items {
            id
            jobRole
            businessId
            profileId
            employeeId
            tippingPolicyAcknowledgedDate
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            confirmed
            __typename
          }
          nextToken
          __typename
        }
        brands {
          items {
            id
            businessId
            brandId
            status
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profileId
            brand {
              id
              name
              logo
              active
              productType
              createdAt
              updatedAt
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      archived
      details {
        id
        accountType
        isOnboardingComplete
        email
        feedRegion
        tippingAccount {
          code
          merchant
          accountId
          verified
          missingRequirements {
            key
            description
            __typename
          }
          pendingValidation
          bankDetails {
            name
            sortCode
            last4Digits
            __typename
          }
          __typename
        }
        phone_number
        phoneNumberConfirmed
        pushNotificationToken
        pushNotificationReadDate
        createdAt
        updatedAt
        __typename
      }
      employers {
        items {
          id
          jobRole
          businessId
          profileId
          employeeId
          tippingPolicyAcknowledgedDate
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          confirmed
          __typename
        }
        nextToken
        __typename
      }
      experiences {
        items {
          id
          jobTitle
          businessId
          companyName
          profileId
          startDate
          endDate
          employmentType
          location
          description
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      jobs {
        items {
          id
          profileId
          jobId
          coverLetter
          saved
          savedDate
          applied
          appliedDate
          createdAt
          updatedAt
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          job {
            id
            owner
            jobType
            businessId
            jobRole
            title
            description
            salary {
              type
              from
              to
              __typename
            }
            region
            requireCV
            requireCoverLetter
            createdAt
            archived
            startDate
            endDate
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            reported {
              items {
                id
                postId
                userId
                commentId
                jobId
                creatorId
                createdAt
                message
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          rejected
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    comments {
      items {
        id
        postId
        commentId
        content
        creatorId
        createdAt
        archived
        attachments {
          mediaType
          source
          alt
          __typename
        }
        updatedAt
        creator {
          id
          slug
          firstName
          lastName
          bio
          picture
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          archived
          details {
            id
            accountType
            isOnboardingComplete
            email
            feedRegion
            tippingAccount {
              code
              merchant
              accountId
              verified
              missingRequirements {
                key
                description
                __typename
              }
              pendingValidation
              bankDetails {
                name
                sortCode
                last4Digits
                __typename
              }
              __typename
            }
            phone_number
            phoneNumberConfirmed
            pushNotificationToken
            pushNotificationReadDate
            createdAt
            updatedAt
            __typename
          }
          employers {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          experiences {
            items {
              id
              jobTitle
              businessId
              companyName
              profileId
              startDate
              endDate
              employmentType
              location
              description
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          jobs {
            items {
              id
              profileId
              jobId
              coverLetter
              saved
              savedDate
              applied
              appliedDate
              createdAt
              updatedAt
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              job {
                id
                owner
                jobType
                businessId
                jobRole
                title
                description
                region
                requireCV
                requireCoverLetter
                createdAt
                archived
                startDate
                endDate
                updatedAt
                __typename
              }
              rejected
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        post {
          id
          hubId
          creatorId
          content
          createdAt
          archived
          feedRegion
          regions
          attachments {
            mediaType
            source
            alt
            __typename
          }
          updatedAt
          creator {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          comments {
            items {
              id
              postId
              commentId
              content
              creatorId
              createdAt
              archived
              attachments {
                mediaType
                source
                alt
                __typename
              }
              updatedAt
              creator {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              post {
                id
                hubId
                creatorId
                content
                createdAt
                archived
                feedRegion
                regions
                updatedAt
                __typename
              }
              comments {
                nextToken
                __typename
              }
              reported {
                nextToken
                __typename
              }
              likes {
                nextToken
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          reported {
            items {
              id
              postId
              userId
              commentId
              jobId
              creatorId
              createdAt
              message
              updatedAt
              creator {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          likes {
            items {
              id
              postId
              profileId
              createdAt
              updatedAt
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        comments {
          items {
            id
            postId
            commentId
            content
            creatorId
            createdAt
            archived
            attachments {
              mediaType
              source
              alt
              __typename
            }
            updatedAt
            creator {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            post {
              id
              hubId
              creatorId
              content
              createdAt
              archived
              feedRegion
              regions
              attachments {
                mediaType
                source
                alt
                __typename
              }
              updatedAt
              creator {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              comments {
                nextToken
                __typename
              }
              reported {
                nextToken
                __typename
              }
              likes {
                nextToken
                __typename
              }
              __typename
            }
            comments {
              items {
                id
                postId
                commentId
                content
                creatorId
                createdAt
                archived
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            reported {
              items {
                id
                postId
                userId
                commentId
                jobId
                creatorId
                createdAt
                message
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            likes {
              items {
                id
                commentId
                profileId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        reported {
          items {
            id
            postId
            userId
            commentId
            jobId
            creatorId
            createdAt
            message
            updatedAt
            creator {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        likes {
          items {
            id
            commentId
            profileId
            createdAt
            updatedAt
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    reported {
      items {
        id
        postId
        userId
        commentId
        jobId
        creatorId
        createdAt
        message
        updatedAt
        creator {
          id
          slug
          firstName
          lastName
          bio
          picture
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          archived
          details {
            id
            accountType
            isOnboardingComplete
            email
            feedRegion
            tippingAccount {
              code
              merchant
              accountId
              verified
              missingRequirements {
                key
                description
                __typename
              }
              pendingValidation
              bankDetails {
                name
                sortCode
                last4Digits
                __typename
              }
              __typename
            }
            phone_number
            phoneNumberConfirmed
            pushNotificationToken
            pushNotificationReadDate
            createdAt
            updatedAt
            __typename
          }
          employers {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          experiences {
            items {
              id
              jobTitle
              businessId
              companyName
              profileId
              startDate
              endDate
              employmentType
              location
              description
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          jobs {
            items {
              id
              profileId
              jobId
              coverLetter
              saved
              savedDate
              applied
              appliedDate
              createdAt
              updatedAt
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              job {
                id
                owner
                jobType
                businessId
                jobRole
                title
                description
                region
                requireCV
                requireCoverLetter
                createdAt
                archived
                startDate
                endDate
                updatedAt
                __typename
              }
              rejected
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    likes {
      items {
        id
        postId
        profileId
        createdAt
        updatedAt
        profile {
          id
          slug
          firstName
          lastName
          bio
          picture
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          archived
          details {
            id
            accountType
            isOnboardingComplete
            email
            feedRegion
            tippingAccount {
              code
              merchant
              accountId
              verified
              missingRequirements {
                key
                description
                __typename
              }
              pendingValidation
              bankDetails {
                name
                sortCode
                last4Digits
                __typename
              }
              __typename
            }
            phone_number
            phoneNumberConfirmed
            pushNotificationToken
            pushNotificationReadDate
            createdAt
            updatedAt
            __typename
          }
          employers {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          experiences {
            items {
              id
              jobTitle
              businessId
              companyName
              profileId
              startDate
              endDate
              employmentType
              location
              description
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          jobs {
            items {
              id
              profileId
              jobId
              coverLetter
              saved
              savedDate
              applied
              appliedDate
              createdAt
              updatedAt
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              job {
                id
                owner
                jobType
                businessId
                jobRole
                title
                description
                region
                requireCV
                requireCoverLetter
                createdAt
                archived
                startDate
                endDate
                updatedAt
                __typename
              }
              rejected
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdatePostMutationVariables,
  APITypes.UpdatePostMutation
>;
export const deletePost = /* GraphQL */ `mutation DeletePost(
  $input: DeletePostInput!
  $condition: ModelPostConditionInput
) {
  deletePost(input: $input, condition: $condition) {
    id
    hubId
    creatorId
    content
    createdAt
    archived
    feedRegion
    regions
    attachments {
      mediaType
      source
      alt
      __typename
    }
    updatedAt
    creator {
      id
      slug
      firstName
      lastName
      bio
      picture
      location {
        searchName
        address
        address2
        city
        region
        postcode
        country
        latitude
        longitude
        __typename
      }
      createdAt
      updatedAt
      business {
        id
        verified
        name
        tipping {
          merchantId
          merchant
          policy
          type
          active
          __typename
        }
        marketing {
          customerId
          subscriptionId
          active
          expirationDate
          sequenceId
          cancelled
          cancelledAt
          __typename
        }
        createdAt
        updatedAt
        owner
        code
        answers {
          questionId
          value
          __typename
        }
        isOutlet
        details {
          id
          contactName
          jobRole
          phone
          email
          website
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        ownerProfile {
          id
          slug
          firstName
          lastName
          bio
          picture
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          archived
          details {
            id
            accountType
            isOnboardingComplete
            email
            feedRegion
            tippingAccount {
              code
              merchant
              accountId
              verified
              missingRequirements {
                key
                description
                __typename
              }
              pendingValidation
              bankDetails {
                name
                sortCode
                last4Digits
                __typename
              }
              __typename
            }
            phone_number
            phoneNumberConfirmed
            pushNotificationToken
            pushNotificationReadDate
            createdAt
            updatedAt
            __typename
          }
          employers {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          experiences {
            items {
              id
              jobTitle
              businessId
              companyName
              profileId
              startDate
              endDate
              employmentType
              location
              description
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          jobs {
            items {
              id
              profileId
              jobId
              coverLetter
              saved
              savedDate
              applied
              appliedDate
              createdAt
              updatedAt
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              job {
                id
                owner
                jobType
                businessId
                jobRole
                title
                description
                region
                requireCV
                requireCoverLetter
                createdAt
                archived
                startDate
                endDate
                updatedAt
                __typename
              }
              rejected
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        invites {
          items {
            id
            businessId
            receiver
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        employees {
          items {
            id
            jobRole
            businessId
            profileId
            employeeId
            tippingPolicyAcknowledgedDate
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            confirmed
            __typename
          }
          nextToken
          __typename
        }
        brands {
          items {
            id
            businessId
            brandId
            status
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profileId
            brand {
              id
              name
              logo
              active
              productType
              createdAt
              updatedAt
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      archived
      details {
        id
        accountType
        isOnboardingComplete
        email
        feedRegion
        tippingAccount {
          code
          merchant
          accountId
          verified
          missingRequirements {
            key
            description
            __typename
          }
          pendingValidation
          bankDetails {
            name
            sortCode
            last4Digits
            __typename
          }
          __typename
        }
        phone_number
        phoneNumberConfirmed
        pushNotificationToken
        pushNotificationReadDate
        createdAt
        updatedAt
        __typename
      }
      employers {
        items {
          id
          jobRole
          businessId
          profileId
          employeeId
          tippingPolicyAcknowledgedDate
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          confirmed
          __typename
        }
        nextToken
        __typename
      }
      experiences {
        items {
          id
          jobTitle
          businessId
          companyName
          profileId
          startDate
          endDate
          employmentType
          location
          description
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      jobs {
        items {
          id
          profileId
          jobId
          coverLetter
          saved
          savedDate
          applied
          appliedDate
          createdAt
          updatedAt
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          job {
            id
            owner
            jobType
            businessId
            jobRole
            title
            description
            salary {
              type
              from
              to
              __typename
            }
            region
            requireCV
            requireCoverLetter
            createdAt
            archived
            startDate
            endDate
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            reported {
              items {
                id
                postId
                userId
                commentId
                jobId
                creatorId
                createdAt
                message
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          rejected
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    comments {
      items {
        id
        postId
        commentId
        content
        creatorId
        createdAt
        archived
        attachments {
          mediaType
          source
          alt
          __typename
        }
        updatedAt
        creator {
          id
          slug
          firstName
          lastName
          bio
          picture
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          archived
          details {
            id
            accountType
            isOnboardingComplete
            email
            feedRegion
            tippingAccount {
              code
              merchant
              accountId
              verified
              missingRequirements {
                key
                description
                __typename
              }
              pendingValidation
              bankDetails {
                name
                sortCode
                last4Digits
                __typename
              }
              __typename
            }
            phone_number
            phoneNumberConfirmed
            pushNotificationToken
            pushNotificationReadDate
            createdAt
            updatedAt
            __typename
          }
          employers {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          experiences {
            items {
              id
              jobTitle
              businessId
              companyName
              profileId
              startDate
              endDate
              employmentType
              location
              description
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          jobs {
            items {
              id
              profileId
              jobId
              coverLetter
              saved
              savedDate
              applied
              appliedDate
              createdAt
              updatedAt
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              job {
                id
                owner
                jobType
                businessId
                jobRole
                title
                description
                region
                requireCV
                requireCoverLetter
                createdAt
                archived
                startDate
                endDate
                updatedAt
                __typename
              }
              rejected
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        post {
          id
          hubId
          creatorId
          content
          createdAt
          archived
          feedRegion
          regions
          attachments {
            mediaType
            source
            alt
            __typename
          }
          updatedAt
          creator {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          comments {
            items {
              id
              postId
              commentId
              content
              creatorId
              createdAt
              archived
              attachments {
                mediaType
                source
                alt
                __typename
              }
              updatedAt
              creator {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              post {
                id
                hubId
                creatorId
                content
                createdAt
                archived
                feedRegion
                regions
                updatedAt
                __typename
              }
              comments {
                nextToken
                __typename
              }
              reported {
                nextToken
                __typename
              }
              likes {
                nextToken
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          reported {
            items {
              id
              postId
              userId
              commentId
              jobId
              creatorId
              createdAt
              message
              updatedAt
              creator {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          likes {
            items {
              id
              postId
              profileId
              createdAt
              updatedAt
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        comments {
          items {
            id
            postId
            commentId
            content
            creatorId
            createdAt
            archived
            attachments {
              mediaType
              source
              alt
              __typename
            }
            updatedAt
            creator {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            post {
              id
              hubId
              creatorId
              content
              createdAt
              archived
              feedRegion
              regions
              attachments {
                mediaType
                source
                alt
                __typename
              }
              updatedAt
              creator {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              comments {
                nextToken
                __typename
              }
              reported {
                nextToken
                __typename
              }
              likes {
                nextToken
                __typename
              }
              __typename
            }
            comments {
              items {
                id
                postId
                commentId
                content
                creatorId
                createdAt
                archived
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            reported {
              items {
                id
                postId
                userId
                commentId
                jobId
                creatorId
                createdAt
                message
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            likes {
              items {
                id
                commentId
                profileId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        reported {
          items {
            id
            postId
            userId
            commentId
            jobId
            creatorId
            createdAt
            message
            updatedAt
            creator {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        likes {
          items {
            id
            commentId
            profileId
            createdAt
            updatedAt
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    reported {
      items {
        id
        postId
        userId
        commentId
        jobId
        creatorId
        createdAt
        message
        updatedAt
        creator {
          id
          slug
          firstName
          lastName
          bio
          picture
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          archived
          details {
            id
            accountType
            isOnboardingComplete
            email
            feedRegion
            tippingAccount {
              code
              merchant
              accountId
              verified
              missingRequirements {
                key
                description
                __typename
              }
              pendingValidation
              bankDetails {
                name
                sortCode
                last4Digits
                __typename
              }
              __typename
            }
            phone_number
            phoneNumberConfirmed
            pushNotificationToken
            pushNotificationReadDate
            createdAt
            updatedAt
            __typename
          }
          employers {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          experiences {
            items {
              id
              jobTitle
              businessId
              companyName
              profileId
              startDate
              endDate
              employmentType
              location
              description
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          jobs {
            items {
              id
              profileId
              jobId
              coverLetter
              saved
              savedDate
              applied
              appliedDate
              createdAt
              updatedAt
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              job {
                id
                owner
                jobType
                businessId
                jobRole
                title
                description
                region
                requireCV
                requireCoverLetter
                createdAt
                archived
                startDate
                endDate
                updatedAt
                __typename
              }
              rejected
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    likes {
      items {
        id
        postId
        profileId
        createdAt
        updatedAt
        profile {
          id
          slug
          firstName
          lastName
          bio
          picture
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          archived
          details {
            id
            accountType
            isOnboardingComplete
            email
            feedRegion
            tippingAccount {
              code
              merchant
              accountId
              verified
              missingRequirements {
                key
                description
                __typename
              }
              pendingValidation
              bankDetails {
                name
                sortCode
                last4Digits
                __typename
              }
              __typename
            }
            phone_number
            phoneNumberConfirmed
            pushNotificationToken
            pushNotificationReadDate
            createdAt
            updatedAt
            __typename
          }
          employers {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          experiences {
            items {
              id
              jobTitle
              businessId
              companyName
              profileId
              startDate
              endDate
              employmentType
              location
              description
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          jobs {
            items {
              id
              profileId
              jobId
              coverLetter
              saved
              savedDate
              applied
              appliedDate
              createdAt
              updatedAt
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              job {
                id
                owner
                jobType
                businessId
                jobRole
                title
                description
                region
                requireCV
                requireCoverLetter
                createdAt
                archived
                startDate
                endDate
                updatedAt
                __typename
              }
              rejected
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeletePostMutationVariables,
  APITypes.DeletePostMutation
>;
export const createJob = /* GraphQL */ `mutation CreateJob(
  $input: CreateJobInput!
  $condition: ModelJobConditionInput
) {
  createJob(input: $input, condition: $condition) {
    id
    owner
    jobType
    businessId
    jobRole
    title
    description
    salary {
      type
      from
      to
      __typename
    }
    region
    requireCV
    requireCoverLetter
    createdAt
    archived
    startDate
    endDate
    updatedAt
    business {
      id
      verified
      name
      tipping {
        merchantId
        merchant
        policy
        type
        active
        __typename
      }
      marketing {
        customerId
        subscriptionId
        active
        expirationDate
        sequenceId
        cancelled
        cancelledAt
        __typename
      }
      createdAt
      updatedAt
      owner
      code
      answers {
        questionId
        value
        __typename
      }
      isOutlet
      details {
        id
        contactName
        jobRole
        phone
        email
        website
        location {
          searchName
          address
          address2
          city
          region
          postcode
          country
          latitude
          longitude
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      ownerProfile {
        id
        slug
        firstName
        lastName
        bio
        picture
        location {
          searchName
          address
          address2
          city
          region
          postcode
          country
          latitude
          longitude
          __typename
        }
        createdAt
        updatedAt
        business {
          id
          verified
          name
          tipping {
            merchantId
            merchant
            policy
            type
            active
            __typename
          }
          marketing {
            customerId
            subscriptionId
            active
            expirationDate
            sequenceId
            cancelled
            cancelledAt
            __typename
          }
          createdAt
          updatedAt
          owner
          code
          answers {
            questionId
            value
            __typename
          }
          isOutlet
          details {
            id
            contactName
            jobRole
            phone
            email
            website
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          ownerProfile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          invites {
            items {
              id
              businessId
              receiver
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          employees {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          brands {
            items {
              id
              businessId
              brandId
              status
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profileId
              brand {
                id
                name
                logo
                active
                productType
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        archived
        details {
          id
          accountType
          isOnboardingComplete
          email
          feedRegion
          tippingAccount {
            code
            merchant
            accountId
            verified
            missingRequirements {
              key
              description
              __typename
            }
            pendingValidation
            bankDetails {
              name
              sortCode
              last4Digits
              __typename
            }
            __typename
          }
          phone_number
          phoneNumberConfirmed
          pushNotificationToken
          pushNotificationReadDate
          createdAt
          updatedAt
          __typename
        }
        employers {
          items {
            id
            jobRole
            businessId
            profileId
            employeeId
            tippingPolicyAcknowledgedDate
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            confirmed
            __typename
          }
          nextToken
          __typename
        }
        experiences {
          items {
            id
            jobTitle
            businessId
            companyName
            profileId
            startDate
            endDate
            employmentType
            location
            description
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        jobs {
          items {
            id
            profileId
            jobId
            coverLetter
            saved
            savedDate
            applied
            appliedDate
            createdAt
            updatedAt
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            job {
              id
              owner
              jobType
              businessId
              jobRole
              title
              description
              salary {
                type
                from
                to
                __typename
              }
              region
              requireCV
              requireCoverLetter
              createdAt
              archived
              startDate
              endDate
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              reported {
                nextToken
                __typename
              }
              __typename
            }
            rejected
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      invites {
        items {
          id
          businessId
          receiver
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      employees {
        items {
          id
          jobRole
          businessId
          profileId
          employeeId
          tippingPolicyAcknowledgedDate
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          confirmed
          __typename
        }
        nextToken
        __typename
      }
      brands {
        items {
          id
          businessId
          brandId
          status
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profileId
          brand {
            id
            name
            logo
            active
            productType
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    reported {
      items {
        id
        postId
        userId
        commentId
        jobId
        creatorId
        createdAt
        message
        updatedAt
        creator {
          id
          slug
          firstName
          lastName
          bio
          picture
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          archived
          details {
            id
            accountType
            isOnboardingComplete
            email
            feedRegion
            tippingAccount {
              code
              merchant
              accountId
              verified
              missingRequirements {
                key
                description
                __typename
              }
              pendingValidation
              bankDetails {
                name
                sortCode
                last4Digits
                __typename
              }
              __typename
            }
            phone_number
            phoneNumberConfirmed
            pushNotificationToken
            pushNotificationReadDate
            createdAt
            updatedAt
            __typename
          }
          employers {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          experiences {
            items {
              id
              jobTitle
              businessId
              companyName
              profileId
              startDate
              endDate
              employmentType
              location
              description
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          jobs {
            items {
              id
              profileId
              jobId
              coverLetter
              saved
              savedDate
              applied
              appliedDate
              createdAt
              updatedAt
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              job {
                id
                owner
                jobType
                businessId
                jobRole
                title
                description
                region
                requireCV
                requireCoverLetter
                createdAt
                archived
                startDate
                endDate
                updatedAt
                __typename
              }
              rejected
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateJobMutationVariables,
  APITypes.CreateJobMutation
>;
export const updateJob = /* GraphQL */ `mutation UpdateJob(
  $input: UpdateJobInput!
  $condition: ModelJobConditionInput
) {
  updateJob(input: $input, condition: $condition) {
    id
    owner
    jobType
    businessId
    jobRole
    title
    description
    salary {
      type
      from
      to
      __typename
    }
    region
    requireCV
    requireCoverLetter
    createdAt
    archived
    startDate
    endDate
    updatedAt
    business {
      id
      verified
      name
      tipping {
        merchantId
        merchant
        policy
        type
        active
        __typename
      }
      marketing {
        customerId
        subscriptionId
        active
        expirationDate
        sequenceId
        cancelled
        cancelledAt
        __typename
      }
      createdAt
      updatedAt
      owner
      code
      answers {
        questionId
        value
        __typename
      }
      isOutlet
      details {
        id
        contactName
        jobRole
        phone
        email
        website
        location {
          searchName
          address
          address2
          city
          region
          postcode
          country
          latitude
          longitude
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      ownerProfile {
        id
        slug
        firstName
        lastName
        bio
        picture
        location {
          searchName
          address
          address2
          city
          region
          postcode
          country
          latitude
          longitude
          __typename
        }
        createdAt
        updatedAt
        business {
          id
          verified
          name
          tipping {
            merchantId
            merchant
            policy
            type
            active
            __typename
          }
          marketing {
            customerId
            subscriptionId
            active
            expirationDate
            sequenceId
            cancelled
            cancelledAt
            __typename
          }
          createdAt
          updatedAt
          owner
          code
          answers {
            questionId
            value
            __typename
          }
          isOutlet
          details {
            id
            contactName
            jobRole
            phone
            email
            website
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          ownerProfile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          invites {
            items {
              id
              businessId
              receiver
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          employees {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          brands {
            items {
              id
              businessId
              brandId
              status
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profileId
              brand {
                id
                name
                logo
                active
                productType
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        archived
        details {
          id
          accountType
          isOnboardingComplete
          email
          feedRegion
          tippingAccount {
            code
            merchant
            accountId
            verified
            missingRequirements {
              key
              description
              __typename
            }
            pendingValidation
            bankDetails {
              name
              sortCode
              last4Digits
              __typename
            }
            __typename
          }
          phone_number
          phoneNumberConfirmed
          pushNotificationToken
          pushNotificationReadDate
          createdAt
          updatedAt
          __typename
        }
        employers {
          items {
            id
            jobRole
            businessId
            profileId
            employeeId
            tippingPolicyAcknowledgedDate
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            confirmed
            __typename
          }
          nextToken
          __typename
        }
        experiences {
          items {
            id
            jobTitle
            businessId
            companyName
            profileId
            startDate
            endDate
            employmentType
            location
            description
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        jobs {
          items {
            id
            profileId
            jobId
            coverLetter
            saved
            savedDate
            applied
            appliedDate
            createdAt
            updatedAt
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            job {
              id
              owner
              jobType
              businessId
              jobRole
              title
              description
              salary {
                type
                from
                to
                __typename
              }
              region
              requireCV
              requireCoverLetter
              createdAt
              archived
              startDate
              endDate
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              reported {
                nextToken
                __typename
              }
              __typename
            }
            rejected
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      invites {
        items {
          id
          businessId
          receiver
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      employees {
        items {
          id
          jobRole
          businessId
          profileId
          employeeId
          tippingPolicyAcknowledgedDate
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          confirmed
          __typename
        }
        nextToken
        __typename
      }
      brands {
        items {
          id
          businessId
          brandId
          status
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profileId
          brand {
            id
            name
            logo
            active
            productType
            createdAt
            updatedAt
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    reported {
      items {
        id
        postId
        userId
        commentId
        jobId
        creatorId
        createdAt
        message
        updatedAt
        creator {
          id
          slug
          firstName
          lastName
          bio
          picture
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          archived
          details {
            id
            accountType
            isOnboardingComplete
            email
            feedRegion
            tippingAccount {
              code
              merchant
              accountId
              verified
              missingRequirements {
                key
                description
                __typename
              }
              pendingValidation
              bankDetails {
                name
                sortCode
                last4Digits
                __typename
              }
              __typename
            }
            phone_number
            phoneNumberConfirmed
            pushNotificationToken
            pushNotificationReadDate
            createdAt
            updatedAt
            __typename
          }
          employers {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          experiences {
            items {
              id
              jobTitle
              businessId
              companyName
              profileId
              startDate
              endDate
              employmentType
              location
              description
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          jobs {
            items {
              id
              profileId
              jobId
              coverLetter
              saved
              savedDate
              applied
              appliedDate
              createdAt
              updatedAt
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              job {
                id
                owner
                jobType
                businessId
                jobRole
                title
                description
                region
                requireCV
                requireCoverLetter
                createdAt
                archived
                startDate
                endDate
                updatedAt
                __typename
              }
              rejected
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateJobMutationVariables,
  APITypes.UpdateJobMutation
>;
export const createProfileJob = /* GraphQL */ `mutation CreateProfileJob(
  $input: CreateProfileJobInput!
  $condition: ModelProfileJobConditionInput
) {
  createProfileJob(input: $input, condition: $condition) {
    id
    profileId
    jobId
    coverLetter
    saved
    savedDate
    applied
    appliedDate
    createdAt
    updatedAt
    profile {
      id
      slug
      firstName
      lastName
      bio
      picture
      location {
        searchName
        address
        address2
        city
        region
        postcode
        country
        latitude
        longitude
        __typename
      }
      createdAt
      updatedAt
      business {
        id
        verified
        name
        tipping {
          merchantId
          merchant
          policy
          type
          active
          __typename
        }
        marketing {
          customerId
          subscriptionId
          active
          expirationDate
          sequenceId
          cancelled
          cancelledAt
          __typename
        }
        createdAt
        updatedAt
        owner
        code
        answers {
          questionId
          value
          __typename
        }
        isOutlet
        details {
          id
          contactName
          jobRole
          phone
          email
          website
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        ownerProfile {
          id
          slug
          firstName
          lastName
          bio
          picture
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          archived
          details {
            id
            accountType
            isOnboardingComplete
            email
            feedRegion
            tippingAccount {
              code
              merchant
              accountId
              verified
              missingRequirements {
                key
                description
                __typename
              }
              pendingValidation
              bankDetails {
                name
                sortCode
                last4Digits
                __typename
              }
              __typename
            }
            phone_number
            phoneNumberConfirmed
            pushNotificationToken
            pushNotificationReadDate
            createdAt
            updatedAt
            __typename
          }
          employers {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          experiences {
            items {
              id
              jobTitle
              businessId
              companyName
              profileId
              startDate
              endDate
              employmentType
              location
              description
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          jobs {
            items {
              id
              profileId
              jobId
              coverLetter
              saved
              savedDate
              applied
              appliedDate
              createdAt
              updatedAt
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              job {
                id
                owner
                jobType
                businessId
                jobRole
                title
                description
                region
                requireCV
                requireCoverLetter
                createdAt
                archived
                startDate
                endDate
                updatedAt
                __typename
              }
              rejected
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        invites {
          items {
            id
            businessId
            receiver
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        employees {
          items {
            id
            jobRole
            businessId
            profileId
            employeeId
            tippingPolicyAcknowledgedDate
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            confirmed
            __typename
          }
          nextToken
          __typename
        }
        brands {
          items {
            id
            businessId
            brandId
            status
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profileId
            brand {
              id
              name
              logo
              active
              productType
              createdAt
              updatedAt
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      archived
      details {
        id
        accountType
        isOnboardingComplete
        email
        feedRegion
        tippingAccount {
          code
          merchant
          accountId
          verified
          missingRequirements {
            key
            description
            __typename
          }
          pendingValidation
          bankDetails {
            name
            sortCode
            last4Digits
            __typename
          }
          __typename
        }
        phone_number
        phoneNumberConfirmed
        pushNotificationToken
        pushNotificationReadDate
        createdAt
        updatedAt
        __typename
      }
      employers {
        items {
          id
          jobRole
          businessId
          profileId
          employeeId
          tippingPolicyAcknowledgedDate
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          confirmed
          __typename
        }
        nextToken
        __typename
      }
      experiences {
        items {
          id
          jobTitle
          businessId
          companyName
          profileId
          startDate
          endDate
          employmentType
          location
          description
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      jobs {
        items {
          id
          profileId
          jobId
          coverLetter
          saved
          savedDate
          applied
          appliedDate
          createdAt
          updatedAt
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          job {
            id
            owner
            jobType
            businessId
            jobRole
            title
            description
            salary {
              type
              from
              to
              __typename
            }
            region
            requireCV
            requireCoverLetter
            createdAt
            archived
            startDate
            endDate
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            reported {
              items {
                id
                postId
                userId
                commentId
                jobId
                creatorId
                createdAt
                message
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          rejected
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    job {
      id
      owner
      jobType
      businessId
      jobRole
      title
      description
      salary {
        type
        from
        to
        __typename
      }
      region
      requireCV
      requireCoverLetter
      createdAt
      archived
      startDate
      endDate
      updatedAt
      business {
        id
        verified
        name
        tipping {
          merchantId
          merchant
          policy
          type
          active
          __typename
        }
        marketing {
          customerId
          subscriptionId
          active
          expirationDate
          sequenceId
          cancelled
          cancelledAt
          __typename
        }
        createdAt
        updatedAt
        owner
        code
        answers {
          questionId
          value
          __typename
        }
        isOutlet
        details {
          id
          contactName
          jobRole
          phone
          email
          website
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        ownerProfile {
          id
          slug
          firstName
          lastName
          bio
          picture
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          archived
          details {
            id
            accountType
            isOnboardingComplete
            email
            feedRegion
            tippingAccount {
              code
              merchant
              accountId
              verified
              missingRequirements {
                key
                description
                __typename
              }
              pendingValidation
              bankDetails {
                name
                sortCode
                last4Digits
                __typename
              }
              __typename
            }
            phone_number
            phoneNumberConfirmed
            pushNotificationToken
            pushNotificationReadDate
            createdAt
            updatedAt
            __typename
          }
          employers {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          experiences {
            items {
              id
              jobTitle
              businessId
              companyName
              profileId
              startDate
              endDate
              employmentType
              location
              description
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          jobs {
            items {
              id
              profileId
              jobId
              coverLetter
              saved
              savedDate
              applied
              appliedDate
              createdAt
              updatedAt
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              job {
                id
                owner
                jobType
                businessId
                jobRole
                title
                description
                region
                requireCV
                requireCoverLetter
                createdAt
                archived
                startDate
                endDate
                updatedAt
                __typename
              }
              rejected
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        invites {
          items {
            id
            businessId
            receiver
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        employees {
          items {
            id
            jobRole
            businessId
            profileId
            employeeId
            tippingPolicyAcknowledgedDate
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            confirmed
            __typename
          }
          nextToken
          __typename
        }
        brands {
          items {
            id
            businessId
            brandId
            status
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profileId
            brand {
              id
              name
              logo
              active
              productType
              createdAt
              updatedAt
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      reported {
        items {
          id
          postId
          userId
          commentId
          jobId
          creatorId
          createdAt
          message
          updatedAt
          creator {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    rejected
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateProfileJobMutationVariables,
  APITypes.CreateProfileJobMutation
>;
export const updateProfileJob = /* GraphQL */ `mutation UpdateProfileJob(
  $input: UpdateProfileJobInput!
  $condition: ModelProfileJobConditionInput
) {
  updateProfileJob(input: $input, condition: $condition) {
    id
    profileId
    jobId
    coverLetter
    saved
    savedDate
    applied
    appliedDate
    createdAt
    updatedAt
    profile {
      id
      slug
      firstName
      lastName
      bio
      picture
      location {
        searchName
        address
        address2
        city
        region
        postcode
        country
        latitude
        longitude
        __typename
      }
      createdAt
      updatedAt
      business {
        id
        verified
        name
        tipping {
          merchantId
          merchant
          policy
          type
          active
          __typename
        }
        marketing {
          customerId
          subscriptionId
          active
          expirationDate
          sequenceId
          cancelled
          cancelledAt
          __typename
        }
        createdAt
        updatedAt
        owner
        code
        answers {
          questionId
          value
          __typename
        }
        isOutlet
        details {
          id
          contactName
          jobRole
          phone
          email
          website
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        ownerProfile {
          id
          slug
          firstName
          lastName
          bio
          picture
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          archived
          details {
            id
            accountType
            isOnboardingComplete
            email
            feedRegion
            tippingAccount {
              code
              merchant
              accountId
              verified
              missingRequirements {
                key
                description
                __typename
              }
              pendingValidation
              bankDetails {
                name
                sortCode
                last4Digits
                __typename
              }
              __typename
            }
            phone_number
            phoneNumberConfirmed
            pushNotificationToken
            pushNotificationReadDate
            createdAt
            updatedAt
            __typename
          }
          employers {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          experiences {
            items {
              id
              jobTitle
              businessId
              companyName
              profileId
              startDate
              endDate
              employmentType
              location
              description
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          jobs {
            items {
              id
              profileId
              jobId
              coverLetter
              saved
              savedDate
              applied
              appliedDate
              createdAt
              updatedAt
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              job {
                id
                owner
                jobType
                businessId
                jobRole
                title
                description
                region
                requireCV
                requireCoverLetter
                createdAt
                archived
                startDate
                endDate
                updatedAt
                __typename
              }
              rejected
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        invites {
          items {
            id
            businessId
            receiver
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        employees {
          items {
            id
            jobRole
            businessId
            profileId
            employeeId
            tippingPolicyAcknowledgedDate
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            confirmed
            __typename
          }
          nextToken
          __typename
        }
        brands {
          items {
            id
            businessId
            brandId
            status
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profileId
            brand {
              id
              name
              logo
              active
              productType
              createdAt
              updatedAt
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      archived
      details {
        id
        accountType
        isOnboardingComplete
        email
        feedRegion
        tippingAccount {
          code
          merchant
          accountId
          verified
          missingRequirements {
            key
            description
            __typename
          }
          pendingValidation
          bankDetails {
            name
            sortCode
            last4Digits
            __typename
          }
          __typename
        }
        phone_number
        phoneNumberConfirmed
        pushNotificationToken
        pushNotificationReadDate
        createdAt
        updatedAt
        __typename
      }
      employers {
        items {
          id
          jobRole
          businessId
          profileId
          employeeId
          tippingPolicyAcknowledgedDate
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          confirmed
          __typename
        }
        nextToken
        __typename
      }
      experiences {
        items {
          id
          jobTitle
          businessId
          companyName
          profileId
          startDate
          endDate
          employmentType
          location
          description
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      jobs {
        items {
          id
          profileId
          jobId
          coverLetter
          saved
          savedDate
          applied
          appliedDate
          createdAt
          updatedAt
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          job {
            id
            owner
            jobType
            businessId
            jobRole
            title
            description
            salary {
              type
              from
              to
              __typename
            }
            region
            requireCV
            requireCoverLetter
            createdAt
            archived
            startDate
            endDate
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            reported {
              items {
                id
                postId
                userId
                commentId
                jobId
                creatorId
                createdAt
                message
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          rejected
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    job {
      id
      owner
      jobType
      businessId
      jobRole
      title
      description
      salary {
        type
        from
        to
        __typename
      }
      region
      requireCV
      requireCoverLetter
      createdAt
      archived
      startDate
      endDate
      updatedAt
      business {
        id
        verified
        name
        tipping {
          merchantId
          merchant
          policy
          type
          active
          __typename
        }
        marketing {
          customerId
          subscriptionId
          active
          expirationDate
          sequenceId
          cancelled
          cancelledAt
          __typename
        }
        createdAt
        updatedAt
        owner
        code
        answers {
          questionId
          value
          __typename
        }
        isOutlet
        details {
          id
          contactName
          jobRole
          phone
          email
          website
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        ownerProfile {
          id
          slug
          firstName
          lastName
          bio
          picture
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          archived
          details {
            id
            accountType
            isOnboardingComplete
            email
            feedRegion
            tippingAccount {
              code
              merchant
              accountId
              verified
              missingRequirements {
                key
                description
                __typename
              }
              pendingValidation
              bankDetails {
                name
                sortCode
                last4Digits
                __typename
              }
              __typename
            }
            phone_number
            phoneNumberConfirmed
            pushNotificationToken
            pushNotificationReadDate
            createdAt
            updatedAt
            __typename
          }
          employers {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          experiences {
            items {
              id
              jobTitle
              businessId
              companyName
              profileId
              startDate
              endDate
              employmentType
              location
              description
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          jobs {
            items {
              id
              profileId
              jobId
              coverLetter
              saved
              savedDate
              applied
              appliedDate
              createdAt
              updatedAt
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              job {
                id
                owner
                jobType
                businessId
                jobRole
                title
                description
                region
                requireCV
                requireCoverLetter
                createdAt
                archived
                startDate
                endDate
                updatedAt
                __typename
              }
              rejected
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        invites {
          items {
            id
            businessId
            receiver
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        employees {
          items {
            id
            jobRole
            businessId
            profileId
            employeeId
            tippingPolicyAcknowledgedDate
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            confirmed
            __typename
          }
          nextToken
          __typename
        }
        brands {
          items {
            id
            businessId
            brandId
            status
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profileId
            brand {
              id
              name
              logo
              active
              productType
              createdAt
              updatedAt
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      reported {
        items {
          id
          postId
          userId
          commentId
          jobId
          creatorId
          createdAt
          message
          updatedAt
          creator {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    rejected
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateProfileJobMutationVariables,
  APITypes.UpdateProfileJobMutation
>;
export const createComment = /* GraphQL */ `mutation CreateComment(
  $input: CreateCommentInput!
  $condition: ModelCommentConditionInput
) {
  createComment(input: $input, condition: $condition) {
    id
    postId
    commentId
    content
    creatorId
    createdAt
    archived
    attachments {
      mediaType
      source
      alt
      __typename
    }
    updatedAt
    creator {
      id
      slug
      firstName
      lastName
      bio
      picture
      location {
        searchName
        address
        address2
        city
        region
        postcode
        country
        latitude
        longitude
        __typename
      }
      createdAt
      updatedAt
      business {
        id
        verified
        name
        tipping {
          merchantId
          merchant
          policy
          type
          active
          __typename
        }
        marketing {
          customerId
          subscriptionId
          active
          expirationDate
          sequenceId
          cancelled
          cancelledAt
          __typename
        }
        createdAt
        updatedAt
        owner
        code
        answers {
          questionId
          value
          __typename
        }
        isOutlet
        details {
          id
          contactName
          jobRole
          phone
          email
          website
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        ownerProfile {
          id
          slug
          firstName
          lastName
          bio
          picture
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          archived
          details {
            id
            accountType
            isOnboardingComplete
            email
            feedRegion
            tippingAccount {
              code
              merchant
              accountId
              verified
              missingRequirements {
                key
                description
                __typename
              }
              pendingValidation
              bankDetails {
                name
                sortCode
                last4Digits
                __typename
              }
              __typename
            }
            phone_number
            phoneNumberConfirmed
            pushNotificationToken
            pushNotificationReadDate
            createdAt
            updatedAt
            __typename
          }
          employers {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          experiences {
            items {
              id
              jobTitle
              businessId
              companyName
              profileId
              startDate
              endDate
              employmentType
              location
              description
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          jobs {
            items {
              id
              profileId
              jobId
              coverLetter
              saved
              savedDate
              applied
              appliedDate
              createdAt
              updatedAt
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              job {
                id
                owner
                jobType
                businessId
                jobRole
                title
                description
                region
                requireCV
                requireCoverLetter
                createdAt
                archived
                startDate
                endDate
                updatedAt
                __typename
              }
              rejected
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        invites {
          items {
            id
            businessId
            receiver
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        employees {
          items {
            id
            jobRole
            businessId
            profileId
            employeeId
            tippingPolicyAcknowledgedDate
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            confirmed
            __typename
          }
          nextToken
          __typename
        }
        brands {
          items {
            id
            businessId
            brandId
            status
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profileId
            brand {
              id
              name
              logo
              active
              productType
              createdAt
              updatedAt
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      archived
      details {
        id
        accountType
        isOnboardingComplete
        email
        feedRegion
        tippingAccount {
          code
          merchant
          accountId
          verified
          missingRequirements {
            key
            description
            __typename
          }
          pendingValidation
          bankDetails {
            name
            sortCode
            last4Digits
            __typename
          }
          __typename
        }
        phone_number
        phoneNumberConfirmed
        pushNotificationToken
        pushNotificationReadDate
        createdAt
        updatedAt
        __typename
      }
      employers {
        items {
          id
          jobRole
          businessId
          profileId
          employeeId
          tippingPolicyAcknowledgedDate
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          confirmed
          __typename
        }
        nextToken
        __typename
      }
      experiences {
        items {
          id
          jobTitle
          businessId
          companyName
          profileId
          startDate
          endDate
          employmentType
          location
          description
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      jobs {
        items {
          id
          profileId
          jobId
          coverLetter
          saved
          savedDate
          applied
          appliedDate
          createdAt
          updatedAt
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          job {
            id
            owner
            jobType
            businessId
            jobRole
            title
            description
            salary {
              type
              from
              to
              __typename
            }
            region
            requireCV
            requireCoverLetter
            createdAt
            archived
            startDate
            endDate
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            reported {
              items {
                id
                postId
                userId
                commentId
                jobId
                creatorId
                createdAt
                message
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          rejected
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    post {
      id
      hubId
      creatorId
      content
      createdAt
      archived
      feedRegion
      regions
      attachments {
        mediaType
        source
        alt
        __typename
      }
      updatedAt
      creator {
        id
        slug
        firstName
        lastName
        bio
        picture
        location {
          searchName
          address
          address2
          city
          region
          postcode
          country
          latitude
          longitude
          __typename
        }
        createdAt
        updatedAt
        business {
          id
          verified
          name
          tipping {
            merchantId
            merchant
            policy
            type
            active
            __typename
          }
          marketing {
            customerId
            subscriptionId
            active
            expirationDate
            sequenceId
            cancelled
            cancelledAt
            __typename
          }
          createdAt
          updatedAt
          owner
          code
          answers {
            questionId
            value
            __typename
          }
          isOutlet
          details {
            id
            contactName
            jobRole
            phone
            email
            website
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          ownerProfile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          invites {
            items {
              id
              businessId
              receiver
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          employees {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          brands {
            items {
              id
              businessId
              brandId
              status
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profileId
              brand {
                id
                name
                logo
                active
                productType
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        archived
        details {
          id
          accountType
          isOnboardingComplete
          email
          feedRegion
          tippingAccount {
            code
            merchant
            accountId
            verified
            missingRequirements {
              key
              description
              __typename
            }
            pendingValidation
            bankDetails {
              name
              sortCode
              last4Digits
              __typename
            }
            __typename
          }
          phone_number
          phoneNumberConfirmed
          pushNotificationToken
          pushNotificationReadDate
          createdAt
          updatedAt
          __typename
        }
        employers {
          items {
            id
            jobRole
            businessId
            profileId
            employeeId
            tippingPolicyAcknowledgedDate
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            confirmed
            __typename
          }
          nextToken
          __typename
        }
        experiences {
          items {
            id
            jobTitle
            businessId
            companyName
            profileId
            startDate
            endDate
            employmentType
            location
            description
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        jobs {
          items {
            id
            profileId
            jobId
            coverLetter
            saved
            savedDate
            applied
            appliedDate
            createdAt
            updatedAt
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            job {
              id
              owner
              jobType
              businessId
              jobRole
              title
              description
              salary {
                type
                from
                to
                __typename
              }
              region
              requireCV
              requireCoverLetter
              createdAt
              archived
              startDate
              endDate
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              reported {
                nextToken
                __typename
              }
              __typename
            }
            rejected
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      comments {
        items {
          id
          postId
          commentId
          content
          creatorId
          createdAt
          archived
          attachments {
            mediaType
            source
            alt
            __typename
          }
          updatedAt
          creator {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          post {
            id
            hubId
            creatorId
            content
            createdAt
            archived
            feedRegion
            regions
            attachments {
              mediaType
              source
              alt
              __typename
            }
            updatedAt
            creator {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            comments {
              items {
                id
                postId
                commentId
                content
                creatorId
                createdAt
                archived
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            reported {
              items {
                id
                postId
                userId
                commentId
                jobId
                creatorId
                createdAt
                message
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            likes {
              items {
                id
                postId
                profileId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          comments {
            items {
              id
              postId
              commentId
              content
              creatorId
              createdAt
              archived
              attachments {
                mediaType
                source
                alt
                __typename
              }
              updatedAt
              creator {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              post {
                id
                hubId
                creatorId
                content
                createdAt
                archived
                feedRegion
                regions
                updatedAt
                __typename
              }
              comments {
                nextToken
                __typename
              }
              reported {
                nextToken
                __typename
              }
              likes {
                nextToken
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          reported {
            items {
              id
              postId
              userId
              commentId
              jobId
              creatorId
              createdAt
              message
              updatedAt
              creator {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          likes {
            items {
              id
              commentId
              profileId
              createdAt
              updatedAt
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      reported {
        items {
          id
          postId
          userId
          commentId
          jobId
          creatorId
          createdAt
          message
          updatedAt
          creator {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      likes {
        items {
          id
          postId
          profileId
          createdAt
          updatedAt
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    comments {
      items {
        id
        postId
        commentId
        content
        creatorId
        createdAt
        archived
        attachments {
          mediaType
          source
          alt
          __typename
        }
        updatedAt
        creator {
          id
          slug
          firstName
          lastName
          bio
          picture
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          archived
          details {
            id
            accountType
            isOnboardingComplete
            email
            feedRegion
            tippingAccount {
              code
              merchant
              accountId
              verified
              missingRequirements {
                key
                description
                __typename
              }
              pendingValidation
              bankDetails {
                name
                sortCode
                last4Digits
                __typename
              }
              __typename
            }
            phone_number
            phoneNumberConfirmed
            pushNotificationToken
            pushNotificationReadDate
            createdAt
            updatedAt
            __typename
          }
          employers {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          experiences {
            items {
              id
              jobTitle
              businessId
              companyName
              profileId
              startDate
              endDate
              employmentType
              location
              description
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          jobs {
            items {
              id
              profileId
              jobId
              coverLetter
              saved
              savedDate
              applied
              appliedDate
              createdAt
              updatedAt
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              job {
                id
                owner
                jobType
                businessId
                jobRole
                title
                description
                region
                requireCV
                requireCoverLetter
                createdAt
                archived
                startDate
                endDate
                updatedAt
                __typename
              }
              rejected
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        post {
          id
          hubId
          creatorId
          content
          createdAt
          archived
          feedRegion
          regions
          attachments {
            mediaType
            source
            alt
            __typename
          }
          updatedAt
          creator {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          comments {
            items {
              id
              postId
              commentId
              content
              creatorId
              createdAt
              archived
              attachments {
                mediaType
                source
                alt
                __typename
              }
              updatedAt
              creator {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              post {
                id
                hubId
                creatorId
                content
                createdAt
                archived
                feedRegion
                regions
                updatedAt
                __typename
              }
              comments {
                nextToken
                __typename
              }
              reported {
                nextToken
                __typename
              }
              likes {
                nextToken
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          reported {
            items {
              id
              postId
              userId
              commentId
              jobId
              creatorId
              createdAt
              message
              updatedAt
              creator {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          likes {
            items {
              id
              postId
              profileId
              createdAt
              updatedAt
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        comments {
          items {
            id
            postId
            commentId
            content
            creatorId
            createdAt
            archived
            attachments {
              mediaType
              source
              alt
              __typename
            }
            updatedAt
            creator {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            post {
              id
              hubId
              creatorId
              content
              createdAt
              archived
              feedRegion
              regions
              attachments {
                mediaType
                source
                alt
                __typename
              }
              updatedAt
              creator {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              comments {
                nextToken
                __typename
              }
              reported {
                nextToken
                __typename
              }
              likes {
                nextToken
                __typename
              }
              __typename
            }
            comments {
              items {
                id
                postId
                commentId
                content
                creatorId
                createdAt
                archived
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            reported {
              items {
                id
                postId
                userId
                commentId
                jobId
                creatorId
                createdAt
                message
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            likes {
              items {
                id
                commentId
                profileId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        reported {
          items {
            id
            postId
            userId
            commentId
            jobId
            creatorId
            createdAt
            message
            updatedAt
            creator {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        likes {
          items {
            id
            commentId
            profileId
            createdAt
            updatedAt
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    reported {
      items {
        id
        postId
        userId
        commentId
        jobId
        creatorId
        createdAt
        message
        updatedAt
        creator {
          id
          slug
          firstName
          lastName
          bio
          picture
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          archived
          details {
            id
            accountType
            isOnboardingComplete
            email
            feedRegion
            tippingAccount {
              code
              merchant
              accountId
              verified
              missingRequirements {
                key
                description
                __typename
              }
              pendingValidation
              bankDetails {
                name
                sortCode
                last4Digits
                __typename
              }
              __typename
            }
            phone_number
            phoneNumberConfirmed
            pushNotificationToken
            pushNotificationReadDate
            createdAt
            updatedAt
            __typename
          }
          employers {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          experiences {
            items {
              id
              jobTitle
              businessId
              companyName
              profileId
              startDate
              endDate
              employmentType
              location
              description
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          jobs {
            items {
              id
              profileId
              jobId
              coverLetter
              saved
              savedDate
              applied
              appliedDate
              createdAt
              updatedAt
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              job {
                id
                owner
                jobType
                businessId
                jobRole
                title
                description
                region
                requireCV
                requireCoverLetter
                createdAt
                archived
                startDate
                endDate
                updatedAt
                __typename
              }
              rejected
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    likes {
      items {
        id
        commentId
        profileId
        createdAt
        updatedAt
        profile {
          id
          slug
          firstName
          lastName
          bio
          picture
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          archived
          details {
            id
            accountType
            isOnboardingComplete
            email
            feedRegion
            tippingAccount {
              code
              merchant
              accountId
              verified
              missingRequirements {
                key
                description
                __typename
              }
              pendingValidation
              bankDetails {
                name
                sortCode
                last4Digits
                __typename
              }
              __typename
            }
            phone_number
            phoneNumberConfirmed
            pushNotificationToken
            pushNotificationReadDate
            createdAt
            updatedAt
            __typename
          }
          employers {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          experiences {
            items {
              id
              jobTitle
              businessId
              companyName
              profileId
              startDate
              endDate
              employmentType
              location
              description
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          jobs {
            items {
              id
              profileId
              jobId
              coverLetter
              saved
              savedDate
              applied
              appliedDate
              createdAt
              updatedAt
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              job {
                id
                owner
                jobType
                businessId
                jobRole
                title
                description
                region
                requireCV
                requireCoverLetter
                createdAt
                archived
                startDate
                endDate
                updatedAt
                __typename
              }
              rejected
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCommentMutationVariables,
  APITypes.CreateCommentMutation
>;
export const updateComment = /* GraphQL */ `mutation UpdateComment(
  $input: UpdateCommentInput!
  $condition: ModelCommentConditionInput
) {
  updateComment(input: $input, condition: $condition) {
    id
    postId
    commentId
    content
    creatorId
    createdAt
    archived
    attachments {
      mediaType
      source
      alt
      __typename
    }
    updatedAt
    creator {
      id
      slug
      firstName
      lastName
      bio
      picture
      location {
        searchName
        address
        address2
        city
        region
        postcode
        country
        latitude
        longitude
        __typename
      }
      createdAt
      updatedAt
      business {
        id
        verified
        name
        tipping {
          merchantId
          merchant
          policy
          type
          active
          __typename
        }
        marketing {
          customerId
          subscriptionId
          active
          expirationDate
          sequenceId
          cancelled
          cancelledAt
          __typename
        }
        createdAt
        updatedAt
        owner
        code
        answers {
          questionId
          value
          __typename
        }
        isOutlet
        details {
          id
          contactName
          jobRole
          phone
          email
          website
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        ownerProfile {
          id
          slug
          firstName
          lastName
          bio
          picture
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          archived
          details {
            id
            accountType
            isOnboardingComplete
            email
            feedRegion
            tippingAccount {
              code
              merchant
              accountId
              verified
              missingRequirements {
                key
                description
                __typename
              }
              pendingValidation
              bankDetails {
                name
                sortCode
                last4Digits
                __typename
              }
              __typename
            }
            phone_number
            phoneNumberConfirmed
            pushNotificationToken
            pushNotificationReadDate
            createdAt
            updatedAt
            __typename
          }
          employers {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          experiences {
            items {
              id
              jobTitle
              businessId
              companyName
              profileId
              startDate
              endDate
              employmentType
              location
              description
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          jobs {
            items {
              id
              profileId
              jobId
              coverLetter
              saved
              savedDate
              applied
              appliedDate
              createdAt
              updatedAt
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              job {
                id
                owner
                jobType
                businessId
                jobRole
                title
                description
                region
                requireCV
                requireCoverLetter
                createdAt
                archived
                startDate
                endDate
                updatedAt
                __typename
              }
              rejected
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        invites {
          items {
            id
            businessId
            receiver
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        employees {
          items {
            id
            jobRole
            businessId
            profileId
            employeeId
            tippingPolicyAcknowledgedDate
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            confirmed
            __typename
          }
          nextToken
          __typename
        }
        brands {
          items {
            id
            businessId
            brandId
            status
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profileId
            brand {
              id
              name
              logo
              active
              productType
              createdAt
              updatedAt
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      archived
      details {
        id
        accountType
        isOnboardingComplete
        email
        feedRegion
        tippingAccount {
          code
          merchant
          accountId
          verified
          missingRequirements {
            key
            description
            __typename
          }
          pendingValidation
          bankDetails {
            name
            sortCode
            last4Digits
            __typename
          }
          __typename
        }
        phone_number
        phoneNumberConfirmed
        pushNotificationToken
        pushNotificationReadDate
        createdAt
        updatedAt
        __typename
      }
      employers {
        items {
          id
          jobRole
          businessId
          profileId
          employeeId
          tippingPolicyAcknowledgedDate
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          confirmed
          __typename
        }
        nextToken
        __typename
      }
      experiences {
        items {
          id
          jobTitle
          businessId
          companyName
          profileId
          startDate
          endDate
          employmentType
          location
          description
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      jobs {
        items {
          id
          profileId
          jobId
          coverLetter
          saved
          savedDate
          applied
          appliedDate
          createdAt
          updatedAt
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          job {
            id
            owner
            jobType
            businessId
            jobRole
            title
            description
            salary {
              type
              from
              to
              __typename
            }
            region
            requireCV
            requireCoverLetter
            createdAt
            archived
            startDate
            endDate
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            reported {
              items {
                id
                postId
                userId
                commentId
                jobId
                creatorId
                createdAt
                message
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          rejected
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    post {
      id
      hubId
      creatorId
      content
      createdAt
      archived
      feedRegion
      regions
      attachments {
        mediaType
        source
        alt
        __typename
      }
      updatedAt
      creator {
        id
        slug
        firstName
        lastName
        bio
        picture
        location {
          searchName
          address
          address2
          city
          region
          postcode
          country
          latitude
          longitude
          __typename
        }
        createdAt
        updatedAt
        business {
          id
          verified
          name
          tipping {
            merchantId
            merchant
            policy
            type
            active
            __typename
          }
          marketing {
            customerId
            subscriptionId
            active
            expirationDate
            sequenceId
            cancelled
            cancelledAt
            __typename
          }
          createdAt
          updatedAt
          owner
          code
          answers {
            questionId
            value
            __typename
          }
          isOutlet
          details {
            id
            contactName
            jobRole
            phone
            email
            website
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          ownerProfile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          invites {
            items {
              id
              businessId
              receiver
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          employees {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          brands {
            items {
              id
              businessId
              brandId
              status
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profileId
              brand {
                id
                name
                logo
                active
                productType
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        archived
        details {
          id
          accountType
          isOnboardingComplete
          email
          feedRegion
          tippingAccount {
            code
            merchant
            accountId
            verified
            missingRequirements {
              key
              description
              __typename
            }
            pendingValidation
            bankDetails {
              name
              sortCode
              last4Digits
              __typename
            }
            __typename
          }
          phone_number
          phoneNumberConfirmed
          pushNotificationToken
          pushNotificationReadDate
          createdAt
          updatedAt
          __typename
        }
        employers {
          items {
            id
            jobRole
            businessId
            profileId
            employeeId
            tippingPolicyAcknowledgedDate
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            confirmed
            __typename
          }
          nextToken
          __typename
        }
        experiences {
          items {
            id
            jobTitle
            businessId
            companyName
            profileId
            startDate
            endDate
            employmentType
            location
            description
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        jobs {
          items {
            id
            profileId
            jobId
            coverLetter
            saved
            savedDate
            applied
            appliedDate
            createdAt
            updatedAt
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            job {
              id
              owner
              jobType
              businessId
              jobRole
              title
              description
              salary {
                type
                from
                to
                __typename
              }
              region
              requireCV
              requireCoverLetter
              createdAt
              archived
              startDate
              endDate
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              reported {
                nextToken
                __typename
              }
              __typename
            }
            rejected
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      comments {
        items {
          id
          postId
          commentId
          content
          creatorId
          createdAt
          archived
          attachments {
            mediaType
            source
            alt
            __typename
          }
          updatedAt
          creator {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          post {
            id
            hubId
            creatorId
            content
            createdAt
            archived
            feedRegion
            regions
            attachments {
              mediaType
              source
              alt
              __typename
            }
            updatedAt
            creator {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            comments {
              items {
                id
                postId
                commentId
                content
                creatorId
                createdAt
                archived
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            reported {
              items {
                id
                postId
                userId
                commentId
                jobId
                creatorId
                createdAt
                message
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            likes {
              items {
                id
                postId
                profileId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          comments {
            items {
              id
              postId
              commentId
              content
              creatorId
              createdAt
              archived
              attachments {
                mediaType
                source
                alt
                __typename
              }
              updatedAt
              creator {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              post {
                id
                hubId
                creatorId
                content
                createdAt
                archived
                feedRegion
                regions
                updatedAt
                __typename
              }
              comments {
                nextToken
                __typename
              }
              reported {
                nextToken
                __typename
              }
              likes {
                nextToken
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          reported {
            items {
              id
              postId
              userId
              commentId
              jobId
              creatorId
              createdAt
              message
              updatedAt
              creator {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          likes {
            items {
              id
              commentId
              profileId
              createdAt
              updatedAt
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      reported {
        items {
          id
          postId
          userId
          commentId
          jobId
          creatorId
          createdAt
          message
          updatedAt
          creator {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      likes {
        items {
          id
          postId
          profileId
          createdAt
          updatedAt
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    comments {
      items {
        id
        postId
        commentId
        content
        creatorId
        createdAt
        archived
        attachments {
          mediaType
          source
          alt
          __typename
        }
        updatedAt
        creator {
          id
          slug
          firstName
          lastName
          bio
          picture
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          archived
          details {
            id
            accountType
            isOnboardingComplete
            email
            feedRegion
            tippingAccount {
              code
              merchant
              accountId
              verified
              missingRequirements {
                key
                description
                __typename
              }
              pendingValidation
              bankDetails {
                name
                sortCode
                last4Digits
                __typename
              }
              __typename
            }
            phone_number
            phoneNumberConfirmed
            pushNotificationToken
            pushNotificationReadDate
            createdAt
            updatedAt
            __typename
          }
          employers {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          experiences {
            items {
              id
              jobTitle
              businessId
              companyName
              profileId
              startDate
              endDate
              employmentType
              location
              description
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          jobs {
            items {
              id
              profileId
              jobId
              coverLetter
              saved
              savedDate
              applied
              appliedDate
              createdAt
              updatedAt
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              job {
                id
                owner
                jobType
                businessId
                jobRole
                title
                description
                region
                requireCV
                requireCoverLetter
                createdAt
                archived
                startDate
                endDate
                updatedAt
                __typename
              }
              rejected
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        post {
          id
          hubId
          creatorId
          content
          createdAt
          archived
          feedRegion
          regions
          attachments {
            mediaType
            source
            alt
            __typename
          }
          updatedAt
          creator {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          comments {
            items {
              id
              postId
              commentId
              content
              creatorId
              createdAt
              archived
              attachments {
                mediaType
                source
                alt
                __typename
              }
              updatedAt
              creator {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              post {
                id
                hubId
                creatorId
                content
                createdAt
                archived
                feedRegion
                regions
                updatedAt
                __typename
              }
              comments {
                nextToken
                __typename
              }
              reported {
                nextToken
                __typename
              }
              likes {
                nextToken
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          reported {
            items {
              id
              postId
              userId
              commentId
              jobId
              creatorId
              createdAt
              message
              updatedAt
              creator {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          likes {
            items {
              id
              postId
              profileId
              createdAt
              updatedAt
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        comments {
          items {
            id
            postId
            commentId
            content
            creatorId
            createdAt
            archived
            attachments {
              mediaType
              source
              alt
              __typename
            }
            updatedAt
            creator {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            post {
              id
              hubId
              creatorId
              content
              createdAt
              archived
              feedRegion
              regions
              attachments {
                mediaType
                source
                alt
                __typename
              }
              updatedAt
              creator {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              comments {
                nextToken
                __typename
              }
              reported {
                nextToken
                __typename
              }
              likes {
                nextToken
                __typename
              }
              __typename
            }
            comments {
              items {
                id
                postId
                commentId
                content
                creatorId
                createdAt
                archived
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            reported {
              items {
                id
                postId
                userId
                commentId
                jobId
                creatorId
                createdAt
                message
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            likes {
              items {
                id
                commentId
                profileId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        reported {
          items {
            id
            postId
            userId
            commentId
            jobId
            creatorId
            createdAt
            message
            updatedAt
            creator {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        likes {
          items {
            id
            commentId
            profileId
            createdAt
            updatedAt
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    reported {
      items {
        id
        postId
        userId
        commentId
        jobId
        creatorId
        createdAt
        message
        updatedAt
        creator {
          id
          slug
          firstName
          lastName
          bio
          picture
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          archived
          details {
            id
            accountType
            isOnboardingComplete
            email
            feedRegion
            tippingAccount {
              code
              merchant
              accountId
              verified
              missingRequirements {
                key
                description
                __typename
              }
              pendingValidation
              bankDetails {
                name
                sortCode
                last4Digits
                __typename
              }
              __typename
            }
            phone_number
            phoneNumberConfirmed
            pushNotificationToken
            pushNotificationReadDate
            createdAt
            updatedAt
            __typename
          }
          employers {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          experiences {
            items {
              id
              jobTitle
              businessId
              companyName
              profileId
              startDate
              endDate
              employmentType
              location
              description
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          jobs {
            items {
              id
              profileId
              jobId
              coverLetter
              saved
              savedDate
              applied
              appliedDate
              createdAt
              updatedAt
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              job {
                id
                owner
                jobType
                businessId
                jobRole
                title
                description
                region
                requireCV
                requireCoverLetter
                createdAt
                archived
                startDate
                endDate
                updatedAt
                __typename
              }
              rejected
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    likes {
      items {
        id
        commentId
        profileId
        createdAt
        updatedAt
        profile {
          id
          slug
          firstName
          lastName
          bio
          picture
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          archived
          details {
            id
            accountType
            isOnboardingComplete
            email
            feedRegion
            tippingAccount {
              code
              merchant
              accountId
              verified
              missingRequirements {
                key
                description
                __typename
              }
              pendingValidation
              bankDetails {
                name
                sortCode
                last4Digits
                __typename
              }
              __typename
            }
            phone_number
            phoneNumberConfirmed
            pushNotificationToken
            pushNotificationReadDate
            createdAt
            updatedAt
            __typename
          }
          employers {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          experiences {
            items {
              id
              jobTitle
              businessId
              companyName
              profileId
              startDate
              endDate
              employmentType
              location
              description
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          jobs {
            items {
              id
              profileId
              jobId
              coverLetter
              saved
              savedDate
              applied
              appliedDate
              createdAt
              updatedAt
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              job {
                id
                owner
                jobType
                businessId
                jobRole
                title
                description
                region
                requireCV
                requireCoverLetter
                createdAt
                archived
                startDate
                endDate
                updatedAt
                __typename
              }
              rejected
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCommentMutationVariables,
  APITypes.UpdateCommentMutation
>;
export const deleteComment = /* GraphQL */ `mutation DeleteComment(
  $input: DeleteCommentInput!
  $condition: ModelCommentConditionInput
) {
  deleteComment(input: $input, condition: $condition) {
    id
    postId
    commentId
    content
    creatorId
    createdAt
    archived
    attachments {
      mediaType
      source
      alt
      __typename
    }
    updatedAt
    creator {
      id
      slug
      firstName
      lastName
      bio
      picture
      location {
        searchName
        address
        address2
        city
        region
        postcode
        country
        latitude
        longitude
        __typename
      }
      createdAt
      updatedAt
      business {
        id
        verified
        name
        tipping {
          merchantId
          merchant
          policy
          type
          active
          __typename
        }
        marketing {
          customerId
          subscriptionId
          active
          expirationDate
          sequenceId
          cancelled
          cancelledAt
          __typename
        }
        createdAt
        updatedAt
        owner
        code
        answers {
          questionId
          value
          __typename
        }
        isOutlet
        details {
          id
          contactName
          jobRole
          phone
          email
          website
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        ownerProfile {
          id
          slug
          firstName
          lastName
          bio
          picture
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          archived
          details {
            id
            accountType
            isOnboardingComplete
            email
            feedRegion
            tippingAccount {
              code
              merchant
              accountId
              verified
              missingRequirements {
                key
                description
                __typename
              }
              pendingValidation
              bankDetails {
                name
                sortCode
                last4Digits
                __typename
              }
              __typename
            }
            phone_number
            phoneNumberConfirmed
            pushNotificationToken
            pushNotificationReadDate
            createdAt
            updatedAt
            __typename
          }
          employers {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          experiences {
            items {
              id
              jobTitle
              businessId
              companyName
              profileId
              startDate
              endDate
              employmentType
              location
              description
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          jobs {
            items {
              id
              profileId
              jobId
              coverLetter
              saved
              savedDate
              applied
              appliedDate
              createdAt
              updatedAt
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              job {
                id
                owner
                jobType
                businessId
                jobRole
                title
                description
                region
                requireCV
                requireCoverLetter
                createdAt
                archived
                startDate
                endDate
                updatedAt
                __typename
              }
              rejected
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        invites {
          items {
            id
            businessId
            receiver
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        employees {
          items {
            id
            jobRole
            businessId
            profileId
            employeeId
            tippingPolicyAcknowledgedDate
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            confirmed
            __typename
          }
          nextToken
          __typename
        }
        brands {
          items {
            id
            businessId
            brandId
            status
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profileId
            brand {
              id
              name
              logo
              active
              productType
              createdAt
              updatedAt
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      archived
      details {
        id
        accountType
        isOnboardingComplete
        email
        feedRegion
        tippingAccount {
          code
          merchant
          accountId
          verified
          missingRequirements {
            key
            description
            __typename
          }
          pendingValidation
          bankDetails {
            name
            sortCode
            last4Digits
            __typename
          }
          __typename
        }
        phone_number
        phoneNumberConfirmed
        pushNotificationToken
        pushNotificationReadDate
        createdAt
        updatedAt
        __typename
      }
      employers {
        items {
          id
          jobRole
          businessId
          profileId
          employeeId
          tippingPolicyAcknowledgedDate
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          confirmed
          __typename
        }
        nextToken
        __typename
      }
      experiences {
        items {
          id
          jobTitle
          businessId
          companyName
          profileId
          startDate
          endDate
          employmentType
          location
          description
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      jobs {
        items {
          id
          profileId
          jobId
          coverLetter
          saved
          savedDate
          applied
          appliedDate
          createdAt
          updatedAt
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          job {
            id
            owner
            jobType
            businessId
            jobRole
            title
            description
            salary {
              type
              from
              to
              __typename
            }
            region
            requireCV
            requireCoverLetter
            createdAt
            archived
            startDate
            endDate
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            reported {
              items {
                id
                postId
                userId
                commentId
                jobId
                creatorId
                createdAt
                message
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          rejected
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    post {
      id
      hubId
      creatorId
      content
      createdAt
      archived
      feedRegion
      regions
      attachments {
        mediaType
        source
        alt
        __typename
      }
      updatedAt
      creator {
        id
        slug
        firstName
        lastName
        bio
        picture
        location {
          searchName
          address
          address2
          city
          region
          postcode
          country
          latitude
          longitude
          __typename
        }
        createdAt
        updatedAt
        business {
          id
          verified
          name
          tipping {
            merchantId
            merchant
            policy
            type
            active
            __typename
          }
          marketing {
            customerId
            subscriptionId
            active
            expirationDate
            sequenceId
            cancelled
            cancelledAt
            __typename
          }
          createdAt
          updatedAt
          owner
          code
          answers {
            questionId
            value
            __typename
          }
          isOutlet
          details {
            id
            contactName
            jobRole
            phone
            email
            website
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            __typename
          }
          ownerProfile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          invites {
            items {
              id
              businessId
              receiver
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          employees {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          brands {
            items {
              id
              businessId
              brandId
              status
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profileId
              brand {
                id
                name
                logo
                active
                productType
                createdAt
                updatedAt
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        archived
        details {
          id
          accountType
          isOnboardingComplete
          email
          feedRegion
          tippingAccount {
            code
            merchant
            accountId
            verified
            missingRequirements {
              key
              description
              __typename
            }
            pendingValidation
            bankDetails {
              name
              sortCode
              last4Digits
              __typename
            }
            __typename
          }
          phone_number
          phoneNumberConfirmed
          pushNotificationToken
          pushNotificationReadDate
          createdAt
          updatedAt
          __typename
        }
        employers {
          items {
            id
            jobRole
            businessId
            profileId
            employeeId
            tippingPolicyAcknowledgedDate
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            confirmed
            __typename
          }
          nextToken
          __typename
        }
        experiences {
          items {
            id
            jobTitle
            businessId
            companyName
            profileId
            startDate
            endDate
            employmentType
            location
            description
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        jobs {
          items {
            id
            profileId
            jobId
            coverLetter
            saved
            savedDate
            applied
            appliedDate
            createdAt
            updatedAt
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            job {
              id
              owner
              jobType
              businessId
              jobRole
              title
              description
              salary {
                type
                from
                to
                __typename
              }
              region
              requireCV
              requireCoverLetter
              createdAt
              archived
              startDate
              endDate
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              reported {
                nextToken
                __typename
              }
              __typename
            }
            rejected
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      comments {
        items {
          id
          postId
          commentId
          content
          creatorId
          createdAt
          archived
          attachments {
            mediaType
            source
            alt
            __typename
          }
          updatedAt
          creator {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          post {
            id
            hubId
            creatorId
            content
            createdAt
            archived
            feedRegion
            regions
            attachments {
              mediaType
              source
              alt
              __typename
            }
            updatedAt
            creator {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            comments {
              items {
                id
                postId
                commentId
                content
                creatorId
                createdAt
                archived
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            reported {
              items {
                id
                postId
                userId
                commentId
                jobId
                creatorId
                createdAt
                message
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            likes {
              items {
                id
                postId
                profileId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          comments {
            items {
              id
              postId
              commentId
              content
              creatorId
              createdAt
              archived
              attachments {
                mediaType
                source
                alt
                __typename
              }
              updatedAt
              creator {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              post {
                id
                hubId
                creatorId
                content
                createdAt
                archived
                feedRegion
                regions
                updatedAt
                __typename
              }
              comments {
                nextToken
                __typename
              }
              reported {
                nextToken
                __typename
              }
              likes {
                nextToken
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          reported {
            items {
              id
              postId
              userId
              commentId
              jobId
              creatorId
              createdAt
              message
              updatedAt
              creator {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          likes {
            items {
              id
              commentId
              profileId
              createdAt
              updatedAt
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      reported {
        items {
          id
          postId
          userId
          commentId
          jobId
          creatorId
          createdAt
          message
          updatedAt
          creator {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      likes {
        items {
          id
          postId
          profileId
          createdAt
          updatedAt
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    comments {
      items {
        id
        postId
        commentId
        content
        creatorId
        createdAt
        archived
        attachments {
          mediaType
          source
          alt
          __typename
        }
        updatedAt
        creator {
          id
          slug
          firstName
          lastName
          bio
          picture
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          archived
          details {
            id
            accountType
            isOnboardingComplete
            email
            feedRegion
            tippingAccount {
              code
              merchant
              accountId
              verified
              missingRequirements {
                key
                description
                __typename
              }
              pendingValidation
              bankDetails {
                name
                sortCode
                last4Digits
                __typename
              }
              __typename
            }
            phone_number
            phoneNumberConfirmed
            pushNotificationToken
            pushNotificationReadDate
            createdAt
            updatedAt
            __typename
          }
          employers {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          experiences {
            items {
              id
              jobTitle
              businessId
              companyName
              profileId
              startDate
              endDate
              employmentType
              location
              description
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          jobs {
            items {
              id
              profileId
              jobId
              coverLetter
              saved
              savedDate
              applied
              appliedDate
              createdAt
              updatedAt
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              job {
                id
                owner
                jobType
                businessId
                jobRole
                title
                description
                region
                requireCV
                requireCoverLetter
                createdAt
                archived
                startDate
                endDate
                updatedAt
                __typename
              }
              rejected
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        post {
          id
          hubId
          creatorId
          content
          createdAt
          archived
          feedRegion
          regions
          attachments {
            mediaType
            source
            alt
            __typename
          }
          updatedAt
          creator {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          comments {
            items {
              id
              postId
              commentId
              content
              creatorId
              createdAt
              archived
              attachments {
                mediaType
                source
                alt
                __typename
              }
              updatedAt
              creator {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              post {
                id
                hubId
                creatorId
                content
                createdAt
                archived
                feedRegion
                regions
                updatedAt
                __typename
              }
              comments {
                nextToken
                __typename
              }
              reported {
                nextToken
                __typename
              }
              likes {
                nextToken
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          reported {
            items {
              id
              postId
              userId
              commentId
              jobId
              creatorId
              createdAt
              message
              updatedAt
              creator {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          likes {
            items {
              id
              postId
              profileId
              createdAt
              updatedAt
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        comments {
          items {
            id
            postId
            commentId
            content
            creatorId
            createdAt
            archived
            attachments {
              mediaType
              source
              alt
              __typename
            }
            updatedAt
            creator {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            post {
              id
              hubId
              creatorId
              content
              createdAt
              archived
              feedRegion
              regions
              attachments {
                mediaType
                source
                alt
                __typename
              }
              updatedAt
              creator {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              comments {
                nextToken
                __typename
              }
              reported {
                nextToken
                __typename
              }
              likes {
                nextToken
                __typename
              }
              __typename
            }
            comments {
              items {
                id
                postId
                commentId
                content
                creatorId
                createdAt
                archived
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            reported {
              items {
                id
                postId
                userId
                commentId
                jobId
                creatorId
                createdAt
                message
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            likes {
              items {
                id
                commentId
                profileId
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        reported {
          items {
            id
            postId
            userId
            commentId
            jobId
            creatorId
            createdAt
            message
            updatedAt
            creator {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        likes {
          items {
            id
            commentId
            profileId
            createdAt
            updatedAt
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    reported {
      items {
        id
        postId
        userId
        commentId
        jobId
        creatorId
        createdAt
        message
        updatedAt
        creator {
          id
          slug
          firstName
          lastName
          bio
          picture
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          archived
          details {
            id
            accountType
            isOnboardingComplete
            email
            feedRegion
            tippingAccount {
              code
              merchant
              accountId
              verified
              missingRequirements {
                key
                description
                __typename
              }
              pendingValidation
              bankDetails {
                name
                sortCode
                last4Digits
                __typename
              }
              __typename
            }
            phone_number
            phoneNumberConfirmed
            pushNotificationToken
            pushNotificationReadDate
            createdAt
            updatedAt
            __typename
          }
          employers {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          experiences {
            items {
              id
              jobTitle
              businessId
              companyName
              profileId
              startDate
              endDate
              employmentType
              location
              description
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          jobs {
            items {
              id
              profileId
              jobId
              coverLetter
              saved
              savedDate
              applied
              appliedDate
              createdAt
              updatedAt
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              job {
                id
                owner
                jobType
                businessId
                jobRole
                title
                description
                region
                requireCV
                requireCoverLetter
                createdAt
                archived
                startDate
                endDate
                updatedAt
                __typename
              }
              rejected
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    likes {
      items {
        id
        commentId
        profileId
        createdAt
        updatedAt
        profile {
          id
          slug
          firstName
          lastName
          bio
          picture
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          archived
          details {
            id
            accountType
            isOnboardingComplete
            email
            feedRegion
            tippingAccount {
              code
              merchant
              accountId
              verified
              missingRequirements {
                key
                description
                __typename
              }
              pendingValidation
              bankDetails {
                name
                sortCode
                last4Digits
                __typename
              }
              __typename
            }
            phone_number
            phoneNumberConfirmed
            pushNotificationToken
            pushNotificationReadDate
            createdAt
            updatedAt
            __typename
          }
          employers {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          experiences {
            items {
              id
              jobTitle
              businessId
              companyName
              profileId
              startDate
              endDate
              employmentType
              location
              description
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          jobs {
            items {
              id
              profileId
              jobId
              coverLetter
              saved
              savedDate
              applied
              appliedDate
              createdAt
              updatedAt
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              job {
                id
                owner
                jobType
                businessId
                jobRole
                title
                description
                region
                requireCV
                requireCoverLetter
                createdAt
                archived
                startDate
                endDate
                updatedAt
                __typename
              }
              rejected
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCommentMutationVariables,
  APITypes.DeleteCommentMutation
>;
export const createReportInfo = /* GraphQL */ `mutation CreateReportInfo(
  $input: CreateReportInfoInput!
  $condition: ModelReportInfoConditionInput
) {
  createReportInfo(input: $input, condition: $condition) {
    id
    postId
    userId
    commentId
    jobId
    creatorId
    createdAt
    message
    updatedAt
    creator {
      id
      slug
      firstName
      lastName
      bio
      picture
      location {
        searchName
        address
        address2
        city
        region
        postcode
        country
        latitude
        longitude
        __typename
      }
      createdAt
      updatedAt
      business {
        id
        verified
        name
        tipping {
          merchantId
          merchant
          policy
          type
          active
          __typename
        }
        marketing {
          customerId
          subscriptionId
          active
          expirationDate
          sequenceId
          cancelled
          cancelledAt
          __typename
        }
        createdAt
        updatedAt
        owner
        code
        answers {
          questionId
          value
          __typename
        }
        isOutlet
        details {
          id
          contactName
          jobRole
          phone
          email
          website
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        ownerProfile {
          id
          slug
          firstName
          lastName
          bio
          picture
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          archived
          details {
            id
            accountType
            isOnboardingComplete
            email
            feedRegion
            tippingAccount {
              code
              merchant
              accountId
              verified
              missingRequirements {
                key
                description
                __typename
              }
              pendingValidation
              bankDetails {
                name
                sortCode
                last4Digits
                __typename
              }
              __typename
            }
            phone_number
            phoneNumberConfirmed
            pushNotificationToken
            pushNotificationReadDate
            createdAt
            updatedAt
            __typename
          }
          employers {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          experiences {
            items {
              id
              jobTitle
              businessId
              companyName
              profileId
              startDate
              endDate
              employmentType
              location
              description
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          jobs {
            items {
              id
              profileId
              jobId
              coverLetter
              saved
              savedDate
              applied
              appliedDate
              createdAt
              updatedAt
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              job {
                id
                owner
                jobType
                businessId
                jobRole
                title
                description
                region
                requireCV
                requireCoverLetter
                createdAt
                archived
                startDate
                endDate
                updatedAt
                __typename
              }
              rejected
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        invites {
          items {
            id
            businessId
            receiver
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        employees {
          items {
            id
            jobRole
            businessId
            profileId
            employeeId
            tippingPolicyAcknowledgedDate
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            confirmed
            __typename
          }
          nextToken
          __typename
        }
        brands {
          items {
            id
            businessId
            brandId
            status
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profileId
            brand {
              id
              name
              logo
              active
              productType
              createdAt
              updatedAt
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      archived
      details {
        id
        accountType
        isOnboardingComplete
        email
        feedRegion
        tippingAccount {
          code
          merchant
          accountId
          verified
          missingRequirements {
            key
            description
            __typename
          }
          pendingValidation
          bankDetails {
            name
            sortCode
            last4Digits
            __typename
          }
          __typename
        }
        phone_number
        phoneNumberConfirmed
        pushNotificationToken
        pushNotificationReadDate
        createdAt
        updatedAt
        __typename
      }
      employers {
        items {
          id
          jobRole
          businessId
          profileId
          employeeId
          tippingPolicyAcknowledgedDate
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          confirmed
          __typename
        }
        nextToken
        __typename
      }
      experiences {
        items {
          id
          jobTitle
          businessId
          companyName
          profileId
          startDate
          endDate
          employmentType
          location
          description
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      jobs {
        items {
          id
          profileId
          jobId
          coverLetter
          saved
          savedDate
          applied
          appliedDate
          createdAt
          updatedAt
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          job {
            id
            owner
            jobType
            businessId
            jobRole
            title
            description
            salary {
              type
              from
              to
              __typename
            }
            region
            requireCV
            requireCoverLetter
            createdAt
            archived
            startDate
            endDate
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            reported {
              items {
                id
                postId
                userId
                commentId
                jobId
                creatorId
                createdAt
                message
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          rejected
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateReportInfoMutationVariables,
  APITypes.CreateReportInfoMutation
>;
export const updateReportInfo = /* GraphQL */ `mutation UpdateReportInfo(
  $input: UpdateReportInfoInput!
  $condition: ModelReportInfoConditionInput
) {
  updateReportInfo(input: $input, condition: $condition) {
    id
    postId
    userId
    commentId
    jobId
    creatorId
    createdAt
    message
    updatedAt
    creator {
      id
      slug
      firstName
      lastName
      bio
      picture
      location {
        searchName
        address
        address2
        city
        region
        postcode
        country
        latitude
        longitude
        __typename
      }
      createdAt
      updatedAt
      business {
        id
        verified
        name
        tipping {
          merchantId
          merchant
          policy
          type
          active
          __typename
        }
        marketing {
          customerId
          subscriptionId
          active
          expirationDate
          sequenceId
          cancelled
          cancelledAt
          __typename
        }
        createdAt
        updatedAt
        owner
        code
        answers {
          questionId
          value
          __typename
        }
        isOutlet
        details {
          id
          contactName
          jobRole
          phone
          email
          website
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        ownerProfile {
          id
          slug
          firstName
          lastName
          bio
          picture
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          archived
          details {
            id
            accountType
            isOnboardingComplete
            email
            feedRegion
            tippingAccount {
              code
              merchant
              accountId
              verified
              missingRequirements {
                key
                description
                __typename
              }
              pendingValidation
              bankDetails {
                name
                sortCode
                last4Digits
                __typename
              }
              __typename
            }
            phone_number
            phoneNumberConfirmed
            pushNotificationToken
            pushNotificationReadDate
            createdAt
            updatedAt
            __typename
          }
          employers {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          experiences {
            items {
              id
              jobTitle
              businessId
              companyName
              profileId
              startDate
              endDate
              employmentType
              location
              description
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          jobs {
            items {
              id
              profileId
              jobId
              coverLetter
              saved
              savedDate
              applied
              appliedDate
              createdAt
              updatedAt
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              job {
                id
                owner
                jobType
                businessId
                jobRole
                title
                description
                region
                requireCV
                requireCoverLetter
                createdAt
                archived
                startDate
                endDate
                updatedAt
                __typename
              }
              rejected
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        invites {
          items {
            id
            businessId
            receiver
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        employees {
          items {
            id
            jobRole
            businessId
            profileId
            employeeId
            tippingPolicyAcknowledgedDate
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            confirmed
            __typename
          }
          nextToken
          __typename
        }
        brands {
          items {
            id
            businessId
            brandId
            status
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profileId
            brand {
              id
              name
              logo
              active
              productType
              createdAt
              updatedAt
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      archived
      details {
        id
        accountType
        isOnboardingComplete
        email
        feedRegion
        tippingAccount {
          code
          merchant
          accountId
          verified
          missingRequirements {
            key
            description
            __typename
          }
          pendingValidation
          bankDetails {
            name
            sortCode
            last4Digits
            __typename
          }
          __typename
        }
        phone_number
        phoneNumberConfirmed
        pushNotificationToken
        pushNotificationReadDate
        createdAt
        updatedAt
        __typename
      }
      employers {
        items {
          id
          jobRole
          businessId
          profileId
          employeeId
          tippingPolicyAcknowledgedDate
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          confirmed
          __typename
        }
        nextToken
        __typename
      }
      experiences {
        items {
          id
          jobTitle
          businessId
          companyName
          profileId
          startDate
          endDate
          employmentType
          location
          description
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      jobs {
        items {
          id
          profileId
          jobId
          coverLetter
          saved
          savedDate
          applied
          appliedDate
          createdAt
          updatedAt
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          job {
            id
            owner
            jobType
            businessId
            jobRole
            title
            description
            salary {
              type
              from
              to
              __typename
            }
            region
            requireCV
            requireCoverLetter
            createdAt
            archived
            startDate
            endDate
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            reported {
              items {
                id
                postId
                userId
                commentId
                jobId
                creatorId
                createdAt
                message
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          rejected
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateReportInfoMutationVariables,
  APITypes.UpdateReportInfoMutation
>;
export const deleteReportInfo = /* GraphQL */ `mutation DeleteReportInfo(
  $input: DeleteReportInfoInput!
  $condition: ModelReportInfoConditionInput
) {
  deleteReportInfo(input: $input, condition: $condition) {
    id
    postId
    userId
    commentId
    jobId
    creatorId
    createdAt
    message
    updatedAt
    creator {
      id
      slug
      firstName
      lastName
      bio
      picture
      location {
        searchName
        address
        address2
        city
        region
        postcode
        country
        latitude
        longitude
        __typename
      }
      createdAt
      updatedAt
      business {
        id
        verified
        name
        tipping {
          merchantId
          merchant
          policy
          type
          active
          __typename
        }
        marketing {
          customerId
          subscriptionId
          active
          expirationDate
          sequenceId
          cancelled
          cancelledAt
          __typename
        }
        createdAt
        updatedAt
        owner
        code
        answers {
          questionId
          value
          __typename
        }
        isOutlet
        details {
          id
          contactName
          jobRole
          phone
          email
          website
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        ownerProfile {
          id
          slug
          firstName
          lastName
          bio
          picture
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          archived
          details {
            id
            accountType
            isOnboardingComplete
            email
            feedRegion
            tippingAccount {
              code
              merchant
              accountId
              verified
              missingRequirements {
                key
                description
                __typename
              }
              pendingValidation
              bankDetails {
                name
                sortCode
                last4Digits
                __typename
              }
              __typename
            }
            phone_number
            phoneNumberConfirmed
            pushNotificationToken
            pushNotificationReadDate
            createdAt
            updatedAt
            __typename
          }
          employers {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          experiences {
            items {
              id
              jobTitle
              businessId
              companyName
              profileId
              startDate
              endDate
              employmentType
              location
              description
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          jobs {
            items {
              id
              profileId
              jobId
              coverLetter
              saved
              savedDate
              applied
              appliedDate
              createdAt
              updatedAt
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              job {
                id
                owner
                jobType
                businessId
                jobRole
                title
                description
                region
                requireCV
                requireCoverLetter
                createdAt
                archived
                startDate
                endDate
                updatedAt
                __typename
              }
              rejected
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        invites {
          items {
            id
            businessId
            receiver
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        employees {
          items {
            id
            jobRole
            businessId
            profileId
            employeeId
            tippingPolicyAcknowledgedDate
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            confirmed
            __typename
          }
          nextToken
          __typename
        }
        brands {
          items {
            id
            businessId
            brandId
            status
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profileId
            brand {
              id
              name
              logo
              active
              productType
              createdAt
              updatedAt
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      archived
      details {
        id
        accountType
        isOnboardingComplete
        email
        feedRegion
        tippingAccount {
          code
          merchant
          accountId
          verified
          missingRequirements {
            key
            description
            __typename
          }
          pendingValidation
          bankDetails {
            name
            sortCode
            last4Digits
            __typename
          }
          __typename
        }
        phone_number
        phoneNumberConfirmed
        pushNotificationToken
        pushNotificationReadDate
        createdAt
        updatedAt
        __typename
      }
      employers {
        items {
          id
          jobRole
          businessId
          profileId
          employeeId
          tippingPolicyAcknowledgedDate
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          confirmed
          __typename
        }
        nextToken
        __typename
      }
      experiences {
        items {
          id
          jobTitle
          businessId
          companyName
          profileId
          startDate
          endDate
          employmentType
          location
          description
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      jobs {
        items {
          id
          profileId
          jobId
          coverLetter
          saved
          savedDate
          applied
          appliedDate
          createdAt
          updatedAt
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          job {
            id
            owner
            jobType
            businessId
            jobRole
            title
            description
            salary {
              type
              from
              to
              __typename
            }
            region
            requireCV
            requireCoverLetter
            createdAt
            archived
            startDate
            endDate
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            reported {
              items {
                id
                postId
                userId
                commentId
                jobId
                creatorId
                createdAt
                message
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          rejected
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteReportInfoMutationVariables,
  APITypes.DeleteReportInfoMutation
>;
export const createPostLike = /* GraphQL */ `mutation CreatePostLike(
  $input: CreatePostLikeInput!
  $condition: ModelPostLikeConditionInput
) {
  createPostLike(input: $input, condition: $condition) {
    id
    postId
    profileId
    createdAt
    updatedAt
    profile {
      id
      slug
      firstName
      lastName
      bio
      picture
      location {
        searchName
        address
        address2
        city
        region
        postcode
        country
        latitude
        longitude
        __typename
      }
      createdAt
      updatedAt
      business {
        id
        verified
        name
        tipping {
          merchantId
          merchant
          policy
          type
          active
          __typename
        }
        marketing {
          customerId
          subscriptionId
          active
          expirationDate
          sequenceId
          cancelled
          cancelledAt
          __typename
        }
        createdAt
        updatedAt
        owner
        code
        answers {
          questionId
          value
          __typename
        }
        isOutlet
        details {
          id
          contactName
          jobRole
          phone
          email
          website
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        ownerProfile {
          id
          slug
          firstName
          lastName
          bio
          picture
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          archived
          details {
            id
            accountType
            isOnboardingComplete
            email
            feedRegion
            tippingAccount {
              code
              merchant
              accountId
              verified
              missingRequirements {
                key
                description
                __typename
              }
              pendingValidation
              bankDetails {
                name
                sortCode
                last4Digits
                __typename
              }
              __typename
            }
            phone_number
            phoneNumberConfirmed
            pushNotificationToken
            pushNotificationReadDate
            createdAt
            updatedAt
            __typename
          }
          employers {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          experiences {
            items {
              id
              jobTitle
              businessId
              companyName
              profileId
              startDate
              endDate
              employmentType
              location
              description
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          jobs {
            items {
              id
              profileId
              jobId
              coverLetter
              saved
              savedDate
              applied
              appliedDate
              createdAt
              updatedAt
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              job {
                id
                owner
                jobType
                businessId
                jobRole
                title
                description
                region
                requireCV
                requireCoverLetter
                createdAt
                archived
                startDate
                endDate
                updatedAt
                __typename
              }
              rejected
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        invites {
          items {
            id
            businessId
            receiver
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        employees {
          items {
            id
            jobRole
            businessId
            profileId
            employeeId
            tippingPolicyAcknowledgedDate
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            confirmed
            __typename
          }
          nextToken
          __typename
        }
        brands {
          items {
            id
            businessId
            brandId
            status
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profileId
            brand {
              id
              name
              logo
              active
              productType
              createdAt
              updatedAt
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      archived
      details {
        id
        accountType
        isOnboardingComplete
        email
        feedRegion
        tippingAccount {
          code
          merchant
          accountId
          verified
          missingRequirements {
            key
            description
            __typename
          }
          pendingValidation
          bankDetails {
            name
            sortCode
            last4Digits
            __typename
          }
          __typename
        }
        phone_number
        phoneNumberConfirmed
        pushNotificationToken
        pushNotificationReadDate
        createdAt
        updatedAt
        __typename
      }
      employers {
        items {
          id
          jobRole
          businessId
          profileId
          employeeId
          tippingPolicyAcknowledgedDate
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          confirmed
          __typename
        }
        nextToken
        __typename
      }
      experiences {
        items {
          id
          jobTitle
          businessId
          companyName
          profileId
          startDate
          endDate
          employmentType
          location
          description
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      jobs {
        items {
          id
          profileId
          jobId
          coverLetter
          saved
          savedDate
          applied
          appliedDate
          createdAt
          updatedAt
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          job {
            id
            owner
            jobType
            businessId
            jobRole
            title
            description
            salary {
              type
              from
              to
              __typename
            }
            region
            requireCV
            requireCoverLetter
            createdAt
            archived
            startDate
            endDate
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            reported {
              items {
                id
                postId
                userId
                commentId
                jobId
                creatorId
                createdAt
                message
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          rejected
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePostLikeMutationVariables,
  APITypes.CreatePostLikeMutation
>;
export const updatePostLike = /* GraphQL */ `mutation UpdatePostLike(
  $input: UpdatePostLikeInput!
  $condition: ModelPostLikeConditionInput
) {
  updatePostLike(input: $input, condition: $condition) {
    id
    postId
    profileId
    createdAt
    updatedAt
    profile {
      id
      slug
      firstName
      lastName
      bio
      picture
      location {
        searchName
        address
        address2
        city
        region
        postcode
        country
        latitude
        longitude
        __typename
      }
      createdAt
      updatedAt
      business {
        id
        verified
        name
        tipping {
          merchantId
          merchant
          policy
          type
          active
          __typename
        }
        marketing {
          customerId
          subscriptionId
          active
          expirationDate
          sequenceId
          cancelled
          cancelledAt
          __typename
        }
        createdAt
        updatedAt
        owner
        code
        answers {
          questionId
          value
          __typename
        }
        isOutlet
        details {
          id
          contactName
          jobRole
          phone
          email
          website
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        ownerProfile {
          id
          slug
          firstName
          lastName
          bio
          picture
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          archived
          details {
            id
            accountType
            isOnboardingComplete
            email
            feedRegion
            tippingAccount {
              code
              merchant
              accountId
              verified
              missingRequirements {
                key
                description
                __typename
              }
              pendingValidation
              bankDetails {
                name
                sortCode
                last4Digits
                __typename
              }
              __typename
            }
            phone_number
            phoneNumberConfirmed
            pushNotificationToken
            pushNotificationReadDate
            createdAt
            updatedAt
            __typename
          }
          employers {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          experiences {
            items {
              id
              jobTitle
              businessId
              companyName
              profileId
              startDate
              endDate
              employmentType
              location
              description
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          jobs {
            items {
              id
              profileId
              jobId
              coverLetter
              saved
              savedDate
              applied
              appliedDate
              createdAt
              updatedAt
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              job {
                id
                owner
                jobType
                businessId
                jobRole
                title
                description
                region
                requireCV
                requireCoverLetter
                createdAt
                archived
                startDate
                endDate
                updatedAt
                __typename
              }
              rejected
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        invites {
          items {
            id
            businessId
            receiver
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        employees {
          items {
            id
            jobRole
            businessId
            profileId
            employeeId
            tippingPolicyAcknowledgedDate
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            confirmed
            __typename
          }
          nextToken
          __typename
        }
        brands {
          items {
            id
            businessId
            brandId
            status
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profileId
            brand {
              id
              name
              logo
              active
              productType
              createdAt
              updatedAt
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      archived
      details {
        id
        accountType
        isOnboardingComplete
        email
        feedRegion
        tippingAccount {
          code
          merchant
          accountId
          verified
          missingRequirements {
            key
            description
            __typename
          }
          pendingValidation
          bankDetails {
            name
            sortCode
            last4Digits
            __typename
          }
          __typename
        }
        phone_number
        phoneNumberConfirmed
        pushNotificationToken
        pushNotificationReadDate
        createdAt
        updatedAt
        __typename
      }
      employers {
        items {
          id
          jobRole
          businessId
          profileId
          employeeId
          tippingPolicyAcknowledgedDate
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          confirmed
          __typename
        }
        nextToken
        __typename
      }
      experiences {
        items {
          id
          jobTitle
          businessId
          companyName
          profileId
          startDate
          endDate
          employmentType
          location
          description
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      jobs {
        items {
          id
          profileId
          jobId
          coverLetter
          saved
          savedDate
          applied
          appliedDate
          createdAt
          updatedAt
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          job {
            id
            owner
            jobType
            businessId
            jobRole
            title
            description
            salary {
              type
              from
              to
              __typename
            }
            region
            requireCV
            requireCoverLetter
            createdAt
            archived
            startDate
            endDate
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            reported {
              items {
                id
                postId
                userId
                commentId
                jobId
                creatorId
                createdAt
                message
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          rejected
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdatePostLikeMutationVariables,
  APITypes.UpdatePostLikeMutation
>;
export const deletePostLike = /* GraphQL */ `mutation DeletePostLike(
  $input: DeletePostLikeInput!
  $condition: ModelPostLikeConditionInput
) {
  deletePostLike(input: $input, condition: $condition) {
    id
    postId
    profileId
    createdAt
    updatedAt
    profile {
      id
      slug
      firstName
      lastName
      bio
      picture
      location {
        searchName
        address
        address2
        city
        region
        postcode
        country
        latitude
        longitude
        __typename
      }
      createdAt
      updatedAt
      business {
        id
        verified
        name
        tipping {
          merchantId
          merchant
          policy
          type
          active
          __typename
        }
        marketing {
          customerId
          subscriptionId
          active
          expirationDate
          sequenceId
          cancelled
          cancelledAt
          __typename
        }
        createdAt
        updatedAt
        owner
        code
        answers {
          questionId
          value
          __typename
        }
        isOutlet
        details {
          id
          contactName
          jobRole
          phone
          email
          website
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        ownerProfile {
          id
          slug
          firstName
          lastName
          bio
          picture
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          archived
          details {
            id
            accountType
            isOnboardingComplete
            email
            feedRegion
            tippingAccount {
              code
              merchant
              accountId
              verified
              missingRequirements {
                key
                description
                __typename
              }
              pendingValidation
              bankDetails {
                name
                sortCode
                last4Digits
                __typename
              }
              __typename
            }
            phone_number
            phoneNumberConfirmed
            pushNotificationToken
            pushNotificationReadDate
            createdAt
            updatedAt
            __typename
          }
          employers {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          experiences {
            items {
              id
              jobTitle
              businessId
              companyName
              profileId
              startDate
              endDate
              employmentType
              location
              description
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          jobs {
            items {
              id
              profileId
              jobId
              coverLetter
              saved
              savedDate
              applied
              appliedDate
              createdAt
              updatedAt
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              job {
                id
                owner
                jobType
                businessId
                jobRole
                title
                description
                region
                requireCV
                requireCoverLetter
                createdAt
                archived
                startDate
                endDate
                updatedAt
                __typename
              }
              rejected
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        invites {
          items {
            id
            businessId
            receiver
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        employees {
          items {
            id
            jobRole
            businessId
            profileId
            employeeId
            tippingPolicyAcknowledgedDate
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            confirmed
            __typename
          }
          nextToken
          __typename
        }
        brands {
          items {
            id
            businessId
            brandId
            status
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profileId
            brand {
              id
              name
              logo
              active
              productType
              createdAt
              updatedAt
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      archived
      details {
        id
        accountType
        isOnboardingComplete
        email
        feedRegion
        tippingAccount {
          code
          merchant
          accountId
          verified
          missingRequirements {
            key
            description
            __typename
          }
          pendingValidation
          bankDetails {
            name
            sortCode
            last4Digits
            __typename
          }
          __typename
        }
        phone_number
        phoneNumberConfirmed
        pushNotificationToken
        pushNotificationReadDate
        createdAt
        updatedAt
        __typename
      }
      employers {
        items {
          id
          jobRole
          businessId
          profileId
          employeeId
          tippingPolicyAcknowledgedDate
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          confirmed
          __typename
        }
        nextToken
        __typename
      }
      experiences {
        items {
          id
          jobTitle
          businessId
          companyName
          profileId
          startDate
          endDate
          employmentType
          location
          description
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      jobs {
        items {
          id
          profileId
          jobId
          coverLetter
          saved
          savedDate
          applied
          appliedDate
          createdAt
          updatedAt
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          job {
            id
            owner
            jobType
            businessId
            jobRole
            title
            description
            salary {
              type
              from
              to
              __typename
            }
            region
            requireCV
            requireCoverLetter
            createdAt
            archived
            startDate
            endDate
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            reported {
              items {
                id
                postId
                userId
                commentId
                jobId
                creatorId
                createdAt
                message
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          rejected
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeletePostLikeMutationVariables,
  APITypes.DeletePostLikeMutation
>;
export const createCommentLike = /* GraphQL */ `mutation CreateCommentLike(
  $input: CreateCommentLikeInput!
  $condition: ModelCommentLikeConditionInput
) {
  createCommentLike(input: $input, condition: $condition) {
    id
    commentId
    profileId
    createdAt
    updatedAt
    profile {
      id
      slug
      firstName
      lastName
      bio
      picture
      location {
        searchName
        address
        address2
        city
        region
        postcode
        country
        latitude
        longitude
        __typename
      }
      createdAt
      updatedAt
      business {
        id
        verified
        name
        tipping {
          merchantId
          merchant
          policy
          type
          active
          __typename
        }
        marketing {
          customerId
          subscriptionId
          active
          expirationDate
          sequenceId
          cancelled
          cancelledAt
          __typename
        }
        createdAt
        updatedAt
        owner
        code
        answers {
          questionId
          value
          __typename
        }
        isOutlet
        details {
          id
          contactName
          jobRole
          phone
          email
          website
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        ownerProfile {
          id
          slug
          firstName
          lastName
          bio
          picture
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          archived
          details {
            id
            accountType
            isOnboardingComplete
            email
            feedRegion
            tippingAccount {
              code
              merchant
              accountId
              verified
              missingRequirements {
                key
                description
                __typename
              }
              pendingValidation
              bankDetails {
                name
                sortCode
                last4Digits
                __typename
              }
              __typename
            }
            phone_number
            phoneNumberConfirmed
            pushNotificationToken
            pushNotificationReadDate
            createdAt
            updatedAt
            __typename
          }
          employers {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          experiences {
            items {
              id
              jobTitle
              businessId
              companyName
              profileId
              startDate
              endDate
              employmentType
              location
              description
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          jobs {
            items {
              id
              profileId
              jobId
              coverLetter
              saved
              savedDate
              applied
              appliedDate
              createdAt
              updatedAt
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              job {
                id
                owner
                jobType
                businessId
                jobRole
                title
                description
                region
                requireCV
                requireCoverLetter
                createdAt
                archived
                startDate
                endDate
                updatedAt
                __typename
              }
              rejected
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        invites {
          items {
            id
            businessId
            receiver
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        employees {
          items {
            id
            jobRole
            businessId
            profileId
            employeeId
            tippingPolicyAcknowledgedDate
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            confirmed
            __typename
          }
          nextToken
          __typename
        }
        brands {
          items {
            id
            businessId
            brandId
            status
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profileId
            brand {
              id
              name
              logo
              active
              productType
              createdAt
              updatedAt
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      archived
      details {
        id
        accountType
        isOnboardingComplete
        email
        feedRegion
        tippingAccount {
          code
          merchant
          accountId
          verified
          missingRequirements {
            key
            description
            __typename
          }
          pendingValidation
          bankDetails {
            name
            sortCode
            last4Digits
            __typename
          }
          __typename
        }
        phone_number
        phoneNumberConfirmed
        pushNotificationToken
        pushNotificationReadDate
        createdAt
        updatedAt
        __typename
      }
      employers {
        items {
          id
          jobRole
          businessId
          profileId
          employeeId
          tippingPolicyAcknowledgedDate
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          confirmed
          __typename
        }
        nextToken
        __typename
      }
      experiences {
        items {
          id
          jobTitle
          businessId
          companyName
          profileId
          startDate
          endDate
          employmentType
          location
          description
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      jobs {
        items {
          id
          profileId
          jobId
          coverLetter
          saved
          savedDate
          applied
          appliedDate
          createdAt
          updatedAt
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          job {
            id
            owner
            jobType
            businessId
            jobRole
            title
            description
            salary {
              type
              from
              to
              __typename
            }
            region
            requireCV
            requireCoverLetter
            createdAt
            archived
            startDate
            endDate
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            reported {
              items {
                id
                postId
                userId
                commentId
                jobId
                creatorId
                createdAt
                message
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          rejected
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCommentLikeMutationVariables,
  APITypes.CreateCommentLikeMutation
>;
export const updateCommentLike = /* GraphQL */ `mutation UpdateCommentLike(
  $input: UpdateCommentLikeInput!
  $condition: ModelCommentLikeConditionInput
) {
  updateCommentLike(input: $input, condition: $condition) {
    id
    commentId
    profileId
    createdAt
    updatedAt
    profile {
      id
      slug
      firstName
      lastName
      bio
      picture
      location {
        searchName
        address
        address2
        city
        region
        postcode
        country
        latitude
        longitude
        __typename
      }
      createdAt
      updatedAt
      business {
        id
        verified
        name
        tipping {
          merchantId
          merchant
          policy
          type
          active
          __typename
        }
        marketing {
          customerId
          subscriptionId
          active
          expirationDate
          sequenceId
          cancelled
          cancelledAt
          __typename
        }
        createdAt
        updatedAt
        owner
        code
        answers {
          questionId
          value
          __typename
        }
        isOutlet
        details {
          id
          contactName
          jobRole
          phone
          email
          website
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        ownerProfile {
          id
          slug
          firstName
          lastName
          bio
          picture
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          archived
          details {
            id
            accountType
            isOnboardingComplete
            email
            feedRegion
            tippingAccount {
              code
              merchant
              accountId
              verified
              missingRequirements {
                key
                description
                __typename
              }
              pendingValidation
              bankDetails {
                name
                sortCode
                last4Digits
                __typename
              }
              __typename
            }
            phone_number
            phoneNumberConfirmed
            pushNotificationToken
            pushNotificationReadDate
            createdAt
            updatedAt
            __typename
          }
          employers {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          experiences {
            items {
              id
              jobTitle
              businessId
              companyName
              profileId
              startDate
              endDate
              employmentType
              location
              description
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          jobs {
            items {
              id
              profileId
              jobId
              coverLetter
              saved
              savedDate
              applied
              appliedDate
              createdAt
              updatedAt
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              job {
                id
                owner
                jobType
                businessId
                jobRole
                title
                description
                region
                requireCV
                requireCoverLetter
                createdAt
                archived
                startDate
                endDate
                updatedAt
                __typename
              }
              rejected
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        invites {
          items {
            id
            businessId
            receiver
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        employees {
          items {
            id
            jobRole
            businessId
            profileId
            employeeId
            tippingPolicyAcknowledgedDate
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            confirmed
            __typename
          }
          nextToken
          __typename
        }
        brands {
          items {
            id
            businessId
            brandId
            status
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profileId
            brand {
              id
              name
              logo
              active
              productType
              createdAt
              updatedAt
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      archived
      details {
        id
        accountType
        isOnboardingComplete
        email
        feedRegion
        tippingAccount {
          code
          merchant
          accountId
          verified
          missingRequirements {
            key
            description
            __typename
          }
          pendingValidation
          bankDetails {
            name
            sortCode
            last4Digits
            __typename
          }
          __typename
        }
        phone_number
        phoneNumberConfirmed
        pushNotificationToken
        pushNotificationReadDate
        createdAt
        updatedAt
        __typename
      }
      employers {
        items {
          id
          jobRole
          businessId
          profileId
          employeeId
          tippingPolicyAcknowledgedDate
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          confirmed
          __typename
        }
        nextToken
        __typename
      }
      experiences {
        items {
          id
          jobTitle
          businessId
          companyName
          profileId
          startDate
          endDate
          employmentType
          location
          description
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      jobs {
        items {
          id
          profileId
          jobId
          coverLetter
          saved
          savedDate
          applied
          appliedDate
          createdAt
          updatedAt
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          job {
            id
            owner
            jobType
            businessId
            jobRole
            title
            description
            salary {
              type
              from
              to
              __typename
            }
            region
            requireCV
            requireCoverLetter
            createdAt
            archived
            startDate
            endDate
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            reported {
              items {
                id
                postId
                userId
                commentId
                jobId
                creatorId
                createdAt
                message
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          rejected
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCommentLikeMutationVariables,
  APITypes.UpdateCommentLikeMutation
>;
export const deleteCommentLike = /* GraphQL */ `mutation DeleteCommentLike(
  $input: DeleteCommentLikeInput!
  $condition: ModelCommentLikeConditionInput
) {
  deleteCommentLike(input: $input, condition: $condition) {
    id
    commentId
    profileId
    createdAt
    updatedAt
    profile {
      id
      slug
      firstName
      lastName
      bio
      picture
      location {
        searchName
        address
        address2
        city
        region
        postcode
        country
        latitude
        longitude
        __typename
      }
      createdAt
      updatedAt
      business {
        id
        verified
        name
        tipping {
          merchantId
          merchant
          policy
          type
          active
          __typename
        }
        marketing {
          customerId
          subscriptionId
          active
          expirationDate
          sequenceId
          cancelled
          cancelledAt
          __typename
        }
        createdAt
        updatedAt
        owner
        code
        answers {
          questionId
          value
          __typename
        }
        isOutlet
        details {
          id
          contactName
          jobRole
          phone
          email
          website
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        ownerProfile {
          id
          slug
          firstName
          lastName
          bio
          picture
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          archived
          details {
            id
            accountType
            isOnboardingComplete
            email
            feedRegion
            tippingAccount {
              code
              merchant
              accountId
              verified
              missingRequirements {
                key
                description
                __typename
              }
              pendingValidation
              bankDetails {
                name
                sortCode
                last4Digits
                __typename
              }
              __typename
            }
            phone_number
            phoneNumberConfirmed
            pushNotificationToken
            pushNotificationReadDate
            createdAt
            updatedAt
            __typename
          }
          employers {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          experiences {
            items {
              id
              jobTitle
              businessId
              companyName
              profileId
              startDate
              endDate
              employmentType
              location
              description
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          jobs {
            items {
              id
              profileId
              jobId
              coverLetter
              saved
              savedDate
              applied
              appliedDate
              createdAt
              updatedAt
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              job {
                id
                owner
                jobType
                businessId
                jobRole
                title
                description
                region
                requireCV
                requireCoverLetter
                createdAt
                archived
                startDate
                endDate
                updatedAt
                __typename
              }
              rejected
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        invites {
          items {
            id
            businessId
            receiver
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        employees {
          items {
            id
            jobRole
            businessId
            profileId
            employeeId
            tippingPolicyAcknowledgedDate
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            confirmed
            __typename
          }
          nextToken
          __typename
        }
        brands {
          items {
            id
            businessId
            brandId
            status
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profileId
            brand {
              id
              name
              logo
              active
              productType
              createdAt
              updatedAt
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      archived
      details {
        id
        accountType
        isOnboardingComplete
        email
        feedRegion
        tippingAccount {
          code
          merchant
          accountId
          verified
          missingRequirements {
            key
            description
            __typename
          }
          pendingValidation
          bankDetails {
            name
            sortCode
            last4Digits
            __typename
          }
          __typename
        }
        phone_number
        phoneNumberConfirmed
        pushNotificationToken
        pushNotificationReadDate
        createdAt
        updatedAt
        __typename
      }
      employers {
        items {
          id
          jobRole
          businessId
          profileId
          employeeId
          tippingPolicyAcknowledgedDate
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          confirmed
          __typename
        }
        nextToken
        __typename
      }
      experiences {
        items {
          id
          jobTitle
          businessId
          companyName
          profileId
          startDate
          endDate
          employmentType
          location
          description
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      jobs {
        items {
          id
          profileId
          jobId
          coverLetter
          saved
          savedDate
          applied
          appliedDate
          createdAt
          updatedAt
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          job {
            id
            owner
            jobType
            businessId
            jobRole
            title
            description
            salary {
              type
              from
              to
              __typename
            }
            region
            requireCV
            requireCoverLetter
            createdAt
            archived
            startDate
            endDate
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            reported {
              items {
                id
                postId
                userId
                commentId
                jobId
                creatorId
                createdAt
                message
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          rejected
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCommentLikeMutationVariables,
  APITypes.DeleteCommentLikeMutation
>;
export const createUserNotification = /* GraphQL */ `mutation CreateUserNotification(
  $input: CreateUserNotificationInput!
  $condition: ModelUserNotificationConditionInput
) {
  createUserNotification(input: $input, condition: $condition) {
    id
    receiverId
    senderId
    type
    targetId
    subTargetId
    additionalTargetId
    read
    title
    message
    createdAt
    updatedAt
    sender {
      id
      slug
      firstName
      lastName
      bio
      picture
      location {
        searchName
        address
        address2
        city
        region
        postcode
        country
        latitude
        longitude
        __typename
      }
      createdAt
      updatedAt
      business {
        id
        verified
        name
        tipping {
          merchantId
          merchant
          policy
          type
          active
          __typename
        }
        marketing {
          customerId
          subscriptionId
          active
          expirationDate
          sequenceId
          cancelled
          cancelledAt
          __typename
        }
        createdAt
        updatedAt
        owner
        code
        answers {
          questionId
          value
          __typename
        }
        isOutlet
        details {
          id
          contactName
          jobRole
          phone
          email
          website
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        ownerProfile {
          id
          slug
          firstName
          lastName
          bio
          picture
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          archived
          details {
            id
            accountType
            isOnboardingComplete
            email
            feedRegion
            tippingAccount {
              code
              merchant
              accountId
              verified
              missingRequirements {
                key
                description
                __typename
              }
              pendingValidation
              bankDetails {
                name
                sortCode
                last4Digits
                __typename
              }
              __typename
            }
            phone_number
            phoneNumberConfirmed
            pushNotificationToken
            pushNotificationReadDate
            createdAt
            updatedAt
            __typename
          }
          employers {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          experiences {
            items {
              id
              jobTitle
              businessId
              companyName
              profileId
              startDate
              endDate
              employmentType
              location
              description
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          jobs {
            items {
              id
              profileId
              jobId
              coverLetter
              saved
              savedDate
              applied
              appliedDate
              createdAt
              updatedAt
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              job {
                id
                owner
                jobType
                businessId
                jobRole
                title
                description
                region
                requireCV
                requireCoverLetter
                createdAt
                archived
                startDate
                endDate
                updatedAt
                __typename
              }
              rejected
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        invites {
          items {
            id
            businessId
            receiver
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        employees {
          items {
            id
            jobRole
            businessId
            profileId
            employeeId
            tippingPolicyAcknowledgedDate
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            confirmed
            __typename
          }
          nextToken
          __typename
        }
        brands {
          items {
            id
            businessId
            brandId
            status
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profileId
            brand {
              id
              name
              logo
              active
              productType
              createdAt
              updatedAt
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      archived
      details {
        id
        accountType
        isOnboardingComplete
        email
        feedRegion
        tippingAccount {
          code
          merchant
          accountId
          verified
          missingRequirements {
            key
            description
            __typename
          }
          pendingValidation
          bankDetails {
            name
            sortCode
            last4Digits
            __typename
          }
          __typename
        }
        phone_number
        phoneNumberConfirmed
        pushNotificationToken
        pushNotificationReadDate
        createdAt
        updatedAt
        __typename
      }
      employers {
        items {
          id
          jobRole
          businessId
          profileId
          employeeId
          tippingPolicyAcknowledgedDate
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          confirmed
          __typename
        }
        nextToken
        __typename
      }
      experiences {
        items {
          id
          jobTitle
          businessId
          companyName
          profileId
          startDate
          endDate
          employmentType
          location
          description
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      jobs {
        items {
          id
          profileId
          jobId
          coverLetter
          saved
          savedDate
          applied
          appliedDate
          createdAt
          updatedAt
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          job {
            id
            owner
            jobType
            businessId
            jobRole
            title
            description
            salary {
              type
              from
              to
              __typename
            }
            region
            requireCV
            requireCoverLetter
            createdAt
            archived
            startDate
            endDate
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            reported {
              items {
                id
                postId
                userId
                commentId
                jobId
                creatorId
                createdAt
                message
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          rejected
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserNotificationMutationVariables,
  APITypes.CreateUserNotificationMutation
>;
export const updateUserNotification = /* GraphQL */ `mutation UpdateUserNotification(
  $input: UpdateUserNotificationInput!
  $condition: ModelUserNotificationConditionInput
) {
  updateUserNotification(input: $input, condition: $condition) {
    id
    receiverId
    senderId
    type
    targetId
    subTargetId
    additionalTargetId
    read
    title
    message
    createdAt
    updatedAt
    sender {
      id
      slug
      firstName
      lastName
      bio
      picture
      location {
        searchName
        address
        address2
        city
        region
        postcode
        country
        latitude
        longitude
        __typename
      }
      createdAt
      updatedAt
      business {
        id
        verified
        name
        tipping {
          merchantId
          merchant
          policy
          type
          active
          __typename
        }
        marketing {
          customerId
          subscriptionId
          active
          expirationDate
          sequenceId
          cancelled
          cancelledAt
          __typename
        }
        createdAt
        updatedAt
        owner
        code
        answers {
          questionId
          value
          __typename
        }
        isOutlet
        details {
          id
          contactName
          jobRole
          phone
          email
          website
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        ownerProfile {
          id
          slug
          firstName
          lastName
          bio
          picture
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          archived
          details {
            id
            accountType
            isOnboardingComplete
            email
            feedRegion
            tippingAccount {
              code
              merchant
              accountId
              verified
              missingRequirements {
                key
                description
                __typename
              }
              pendingValidation
              bankDetails {
                name
                sortCode
                last4Digits
                __typename
              }
              __typename
            }
            phone_number
            phoneNumberConfirmed
            pushNotificationToken
            pushNotificationReadDate
            createdAt
            updatedAt
            __typename
          }
          employers {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          experiences {
            items {
              id
              jobTitle
              businessId
              companyName
              profileId
              startDate
              endDate
              employmentType
              location
              description
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          jobs {
            items {
              id
              profileId
              jobId
              coverLetter
              saved
              savedDate
              applied
              appliedDate
              createdAt
              updatedAt
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              job {
                id
                owner
                jobType
                businessId
                jobRole
                title
                description
                region
                requireCV
                requireCoverLetter
                createdAt
                archived
                startDate
                endDate
                updatedAt
                __typename
              }
              rejected
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        invites {
          items {
            id
            businessId
            receiver
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        employees {
          items {
            id
            jobRole
            businessId
            profileId
            employeeId
            tippingPolicyAcknowledgedDate
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            confirmed
            __typename
          }
          nextToken
          __typename
        }
        brands {
          items {
            id
            businessId
            brandId
            status
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profileId
            brand {
              id
              name
              logo
              active
              productType
              createdAt
              updatedAt
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      archived
      details {
        id
        accountType
        isOnboardingComplete
        email
        feedRegion
        tippingAccount {
          code
          merchant
          accountId
          verified
          missingRequirements {
            key
            description
            __typename
          }
          pendingValidation
          bankDetails {
            name
            sortCode
            last4Digits
            __typename
          }
          __typename
        }
        phone_number
        phoneNumberConfirmed
        pushNotificationToken
        pushNotificationReadDate
        createdAt
        updatedAt
        __typename
      }
      employers {
        items {
          id
          jobRole
          businessId
          profileId
          employeeId
          tippingPolicyAcknowledgedDate
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          confirmed
          __typename
        }
        nextToken
        __typename
      }
      experiences {
        items {
          id
          jobTitle
          businessId
          companyName
          profileId
          startDate
          endDate
          employmentType
          location
          description
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      jobs {
        items {
          id
          profileId
          jobId
          coverLetter
          saved
          savedDate
          applied
          appliedDate
          createdAt
          updatedAt
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          job {
            id
            owner
            jobType
            businessId
            jobRole
            title
            description
            salary {
              type
              from
              to
              __typename
            }
            region
            requireCV
            requireCoverLetter
            createdAt
            archived
            startDate
            endDate
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            reported {
              items {
                id
                postId
                userId
                commentId
                jobId
                creatorId
                createdAt
                message
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          rejected
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserNotificationMutationVariables,
  APITypes.UpdateUserNotificationMutation
>;
export const createPublicEmail = /* GraphQL */ `mutation CreatePublicEmail(
  $input: CreatePublicEmailInput!
  $condition: ModelPublicEmailConditionInput
) {
  createPublicEmail(input: $input, condition: $condition) {
    id
    email
    allowMarketing
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePublicEmailMutationVariables,
  APITypes.CreatePublicEmailMutation
>;
export const createTipSplit = /* GraphQL */ `mutation CreateTipSplit(
  $input: CreateTipSplitInput!
  $condition: ModelTipSplitConditionInput
) {
  createTipSplit(input: $input, condition: $condition) {
    id
    amount
    owner
    status
    from
    to
    createdAt
    updatedAt
    ownerProfile {
      id
      slug
      firstName
      lastName
      bio
      picture
      location {
        searchName
        address
        address2
        city
        region
        postcode
        country
        latitude
        longitude
        __typename
      }
      createdAt
      updatedAt
      business {
        id
        verified
        name
        tipping {
          merchantId
          merchant
          policy
          type
          active
          __typename
        }
        marketing {
          customerId
          subscriptionId
          active
          expirationDate
          sequenceId
          cancelled
          cancelledAt
          __typename
        }
        createdAt
        updatedAt
        owner
        code
        answers {
          questionId
          value
          __typename
        }
        isOutlet
        details {
          id
          contactName
          jobRole
          phone
          email
          website
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        ownerProfile {
          id
          slug
          firstName
          lastName
          bio
          picture
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          archived
          details {
            id
            accountType
            isOnboardingComplete
            email
            feedRegion
            tippingAccount {
              code
              merchant
              accountId
              verified
              missingRequirements {
                key
                description
                __typename
              }
              pendingValidation
              bankDetails {
                name
                sortCode
                last4Digits
                __typename
              }
              __typename
            }
            phone_number
            phoneNumberConfirmed
            pushNotificationToken
            pushNotificationReadDate
            createdAt
            updatedAt
            __typename
          }
          employers {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          experiences {
            items {
              id
              jobTitle
              businessId
              companyName
              profileId
              startDate
              endDate
              employmentType
              location
              description
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          jobs {
            items {
              id
              profileId
              jobId
              coverLetter
              saved
              savedDate
              applied
              appliedDate
              createdAt
              updatedAt
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              job {
                id
                owner
                jobType
                businessId
                jobRole
                title
                description
                region
                requireCV
                requireCoverLetter
                createdAt
                archived
                startDate
                endDate
                updatedAt
                __typename
              }
              rejected
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        invites {
          items {
            id
            businessId
            receiver
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        employees {
          items {
            id
            jobRole
            businessId
            profileId
            employeeId
            tippingPolicyAcknowledgedDate
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            confirmed
            __typename
          }
          nextToken
          __typename
        }
        brands {
          items {
            id
            businessId
            brandId
            status
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profileId
            brand {
              id
              name
              logo
              active
              productType
              createdAt
              updatedAt
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      archived
      details {
        id
        accountType
        isOnboardingComplete
        email
        feedRegion
        tippingAccount {
          code
          merchant
          accountId
          verified
          missingRequirements {
            key
            description
            __typename
          }
          pendingValidation
          bankDetails {
            name
            sortCode
            last4Digits
            __typename
          }
          __typename
        }
        phone_number
        phoneNumberConfirmed
        pushNotificationToken
        pushNotificationReadDate
        createdAt
        updatedAt
        __typename
      }
      employers {
        items {
          id
          jobRole
          businessId
          profileId
          employeeId
          tippingPolicyAcknowledgedDate
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          confirmed
          __typename
        }
        nextToken
        __typename
      }
      experiences {
        items {
          id
          jobTitle
          businessId
          companyName
          profileId
          startDate
          endDate
          employmentType
          location
          description
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      jobs {
        items {
          id
          profileId
          jobId
          coverLetter
          saved
          savedDate
          applied
          appliedDate
          createdAt
          updatedAt
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          job {
            id
            owner
            jobType
            businessId
            jobRole
            title
            description
            salary {
              type
              from
              to
              __typename
            }
            region
            requireCV
            requireCoverLetter
            createdAt
            archived
            startDate
            endDate
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            reported {
              items {
                id
                postId
                userId
                commentId
                jobId
                creatorId
                createdAt
                message
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          rejected
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    transfers {
      items {
        id
        splitId
        employeeId
        amount
        status
        createdAt
        updatedAt
        employee {
          id
          slug
          firstName
          lastName
          bio
          picture
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          archived
          details {
            id
            accountType
            isOnboardingComplete
            email
            feedRegion
            tippingAccount {
              code
              merchant
              accountId
              verified
              missingRequirements {
                key
                description
                __typename
              }
              pendingValidation
              bankDetails {
                name
                sortCode
                last4Digits
                __typename
              }
              __typename
            }
            phone_number
            phoneNumberConfirmed
            pushNotificationToken
            pushNotificationReadDate
            createdAt
            updatedAt
            __typename
          }
          employers {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          experiences {
            items {
              id
              jobTitle
              businessId
              companyName
              profileId
              startDate
              endDate
              employmentType
              location
              description
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          jobs {
            items {
              id
              profileId
              jobId
              coverLetter
              saved
              savedDate
              applied
              appliedDate
              createdAt
              updatedAt
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              job {
                id
                owner
                jobType
                businessId
                jobRole
                title
                description
                region
                requireCV
                requireCoverLetter
                createdAt
                archived
                startDate
                endDate
                updatedAt
                __typename
              }
              rejected
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    transactions {
      items {
        id
        splitId
        receiverId
        amount
        fee
        currency
        createdAt
        status
        type
        source
        payoutInvoiceId
        transactionId
        updatedAt
        receiver {
          id
          slug
          firstName
          lastName
          bio
          picture
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          archived
          details {
            id
            accountType
            isOnboardingComplete
            email
            feedRegion
            tippingAccount {
              code
              merchant
              accountId
              verified
              missingRequirements {
                key
                description
                __typename
              }
              pendingValidation
              bankDetails {
                name
                sortCode
                last4Digits
                __typename
              }
              __typename
            }
            phone_number
            phoneNumberConfirmed
            pushNotificationToken
            pushNotificationReadDate
            createdAt
            updatedAt
            __typename
          }
          employers {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          experiences {
            items {
              id
              jobTitle
              businessId
              companyName
              profileId
              startDate
              endDate
              employmentType
              location
              description
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          jobs {
            items {
              id
              profileId
              jobId
              coverLetter
              saved
              savedDate
              applied
              appliedDate
              createdAt
              updatedAt
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              job {
                id
                owner
                jobType
                businessId
                jobRole
                title
                description
                region
                requireCV
                requireCoverLetter
                createdAt
                archived
                startDate
                endDate
                updatedAt
                __typename
              }
              rejected
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateTipSplitMutationVariables,
  APITypes.CreateTipSplitMutation
>;
export const updateTipSplit = /* GraphQL */ `mutation UpdateTipSplit(
  $input: UpdateTipSplitInput!
  $condition: ModelTipSplitConditionInput
) {
  updateTipSplit(input: $input, condition: $condition) {
    id
    amount
    owner
    status
    from
    to
    createdAt
    updatedAt
    ownerProfile {
      id
      slug
      firstName
      lastName
      bio
      picture
      location {
        searchName
        address
        address2
        city
        region
        postcode
        country
        latitude
        longitude
        __typename
      }
      createdAt
      updatedAt
      business {
        id
        verified
        name
        tipping {
          merchantId
          merchant
          policy
          type
          active
          __typename
        }
        marketing {
          customerId
          subscriptionId
          active
          expirationDate
          sequenceId
          cancelled
          cancelledAt
          __typename
        }
        createdAt
        updatedAt
        owner
        code
        answers {
          questionId
          value
          __typename
        }
        isOutlet
        details {
          id
          contactName
          jobRole
          phone
          email
          website
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        ownerProfile {
          id
          slug
          firstName
          lastName
          bio
          picture
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          archived
          details {
            id
            accountType
            isOnboardingComplete
            email
            feedRegion
            tippingAccount {
              code
              merchant
              accountId
              verified
              missingRequirements {
                key
                description
                __typename
              }
              pendingValidation
              bankDetails {
                name
                sortCode
                last4Digits
                __typename
              }
              __typename
            }
            phone_number
            phoneNumberConfirmed
            pushNotificationToken
            pushNotificationReadDate
            createdAt
            updatedAt
            __typename
          }
          employers {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          experiences {
            items {
              id
              jobTitle
              businessId
              companyName
              profileId
              startDate
              endDate
              employmentType
              location
              description
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          jobs {
            items {
              id
              profileId
              jobId
              coverLetter
              saved
              savedDate
              applied
              appliedDate
              createdAt
              updatedAt
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              job {
                id
                owner
                jobType
                businessId
                jobRole
                title
                description
                region
                requireCV
                requireCoverLetter
                createdAt
                archived
                startDate
                endDate
                updatedAt
                __typename
              }
              rejected
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        invites {
          items {
            id
            businessId
            receiver
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        employees {
          items {
            id
            jobRole
            businessId
            profileId
            employeeId
            tippingPolicyAcknowledgedDate
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            confirmed
            __typename
          }
          nextToken
          __typename
        }
        brands {
          items {
            id
            businessId
            brandId
            status
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profileId
            brand {
              id
              name
              logo
              active
              productType
              createdAt
              updatedAt
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      archived
      details {
        id
        accountType
        isOnboardingComplete
        email
        feedRegion
        tippingAccount {
          code
          merchant
          accountId
          verified
          missingRequirements {
            key
            description
            __typename
          }
          pendingValidation
          bankDetails {
            name
            sortCode
            last4Digits
            __typename
          }
          __typename
        }
        phone_number
        phoneNumberConfirmed
        pushNotificationToken
        pushNotificationReadDate
        createdAt
        updatedAt
        __typename
      }
      employers {
        items {
          id
          jobRole
          businessId
          profileId
          employeeId
          tippingPolicyAcknowledgedDate
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          confirmed
          __typename
        }
        nextToken
        __typename
      }
      experiences {
        items {
          id
          jobTitle
          businessId
          companyName
          profileId
          startDate
          endDate
          employmentType
          location
          description
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      jobs {
        items {
          id
          profileId
          jobId
          coverLetter
          saved
          savedDate
          applied
          appliedDate
          createdAt
          updatedAt
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          job {
            id
            owner
            jobType
            businessId
            jobRole
            title
            description
            salary {
              type
              from
              to
              __typename
            }
            region
            requireCV
            requireCoverLetter
            createdAt
            archived
            startDate
            endDate
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            reported {
              items {
                id
                postId
                userId
                commentId
                jobId
                creatorId
                createdAt
                message
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          rejected
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    transfers {
      items {
        id
        splitId
        employeeId
        amount
        status
        createdAt
        updatedAt
        employee {
          id
          slug
          firstName
          lastName
          bio
          picture
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          archived
          details {
            id
            accountType
            isOnboardingComplete
            email
            feedRegion
            tippingAccount {
              code
              merchant
              accountId
              verified
              missingRequirements {
                key
                description
                __typename
              }
              pendingValidation
              bankDetails {
                name
                sortCode
                last4Digits
                __typename
              }
              __typename
            }
            phone_number
            phoneNumberConfirmed
            pushNotificationToken
            pushNotificationReadDate
            createdAt
            updatedAt
            __typename
          }
          employers {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          experiences {
            items {
              id
              jobTitle
              businessId
              companyName
              profileId
              startDate
              endDate
              employmentType
              location
              description
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          jobs {
            items {
              id
              profileId
              jobId
              coverLetter
              saved
              savedDate
              applied
              appliedDate
              createdAt
              updatedAt
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              job {
                id
                owner
                jobType
                businessId
                jobRole
                title
                description
                region
                requireCV
                requireCoverLetter
                createdAt
                archived
                startDate
                endDate
                updatedAt
                __typename
              }
              rejected
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    transactions {
      items {
        id
        splitId
        receiverId
        amount
        fee
        currency
        createdAt
        status
        type
        source
        payoutInvoiceId
        transactionId
        updatedAt
        receiver {
          id
          slug
          firstName
          lastName
          bio
          picture
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          archived
          details {
            id
            accountType
            isOnboardingComplete
            email
            feedRegion
            tippingAccount {
              code
              merchant
              accountId
              verified
              missingRequirements {
                key
                description
                __typename
              }
              pendingValidation
              bankDetails {
                name
                sortCode
                last4Digits
                __typename
              }
              __typename
            }
            phone_number
            phoneNumberConfirmed
            pushNotificationToken
            pushNotificationReadDate
            createdAt
            updatedAt
            __typename
          }
          employers {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          experiences {
            items {
              id
              jobTitle
              businessId
              companyName
              profileId
              startDate
              endDate
              employmentType
              location
              description
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          jobs {
            items {
              id
              profileId
              jobId
              coverLetter
              saved
              savedDate
              applied
              appliedDate
              createdAt
              updatedAt
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              job {
                id
                owner
                jobType
                businessId
                jobRole
                title
                description
                region
                requireCV
                requireCoverLetter
                createdAt
                archived
                startDate
                endDate
                updatedAt
                __typename
              }
              rejected
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateTipSplitMutationVariables,
  APITypes.UpdateTipSplitMutation
>;
export const createEmployeeTransfer = /* GraphQL */ `mutation CreateEmployeeTransfer(
  $input: CreateEmployeeTransferInput!
  $condition: ModelEmployeeTransferConditionInput
) {
  createEmployeeTransfer(input: $input, condition: $condition) {
    id
    splitId
    employeeId
    amount
    status
    createdAt
    updatedAt
    employee {
      id
      slug
      firstName
      lastName
      bio
      picture
      location {
        searchName
        address
        address2
        city
        region
        postcode
        country
        latitude
        longitude
        __typename
      }
      createdAt
      updatedAt
      business {
        id
        verified
        name
        tipping {
          merchantId
          merchant
          policy
          type
          active
          __typename
        }
        marketing {
          customerId
          subscriptionId
          active
          expirationDate
          sequenceId
          cancelled
          cancelledAt
          __typename
        }
        createdAt
        updatedAt
        owner
        code
        answers {
          questionId
          value
          __typename
        }
        isOutlet
        details {
          id
          contactName
          jobRole
          phone
          email
          website
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        ownerProfile {
          id
          slug
          firstName
          lastName
          bio
          picture
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          archived
          details {
            id
            accountType
            isOnboardingComplete
            email
            feedRegion
            tippingAccount {
              code
              merchant
              accountId
              verified
              missingRequirements {
                key
                description
                __typename
              }
              pendingValidation
              bankDetails {
                name
                sortCode
                last4Digits
                __typename
              }
              __typename
            }
            phone_number
            phoneNumberConfirmed
            pushNotificationToken
            pushNotificationReadDate
            createdAt
            updatedAt
            __typename
          }
          employers {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          experiences {
            items {
              id
              jobTitle
              businessId
              companyName
              profileId
              startDate
              endDate
              employmentType
              location
              description
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          jobs {
            items {
              id
              profileId
              jobId
              coverLetter
              saved
              savedDate
              applied
              appliedDate
              createdAt
              updatedAt
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              job {
                id
                owner
                jobType
                businessId
                jobRole
                title
                description
                region
                requireCV
                requireCoverLetter
                createdAt
                archived
                startDate
                endDate
                updatedAt
                __typename
              }
              rejected
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        invites {
          items {
            id
            businessId
            receiver
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        employees {
          items {
            id
            jobRole
            businessId
            profileId
            employeeId
            tippingPolicyAcknowledgedDate
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            confirmed
            __typename
          }
          nextToken
          __typename
        }
        brands {
          items {
            id
            businessId
            brandId
            status
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profileId
            brand {
              id
              name
              logo
              active
              productType
              createdAt
              updatedAt
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      archived
      details {
        id
        accountType
        isOnboardingComplete
        email
        feedRegion
        tippingAccount {
          code
          merchant
          accountId
          verified
          missingRequirements {
            key
            description
            __typename
          }
          pendingValidation
          bankDetails {
            name
            sortCode
            last4Digits
            __typename
          }
          __typename
        }
        phone_number
        phoneNumberConfirmed
        pushNotificationToken
        pushNotificationReadDate
        createdAt
        updatedAt
        __typename
      }
      employers {
        items {
          id
          jobRole
          businessId
          profileId
          employeeId
          tippingPolicyAcknowledgedDate
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          confirmed
          __typename
        }
        nextToken
        __typename
      }
      experiences {
        items {
          id
          jobTitle
          businessId
          companyName
          profileId
          startDate
          endDate
          employmentType
          location
          description
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      jobs {
        items {
          id
          profileId
          jobId
          coverLetter
          saved
          savedDate
          applied
          appliedDate
          createdAt
          updatedAt
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          job {
            id
            owner
            jobType
            businessId
            jobRole
            title
            description
            salary {
              type
              from
              to
              __typename
            }
            region
            requireCV
            requireCoverLetter
            createdAt
            archived
            startDate
            endDate
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            reported {
              items {
                id
                postId
                userId
                commentId
                jobId
                creatorId
                createdAt
                message
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          rejected
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateEmployeeTransferMutationVariables,
  APITypes.CreateEmployeeTransferMutation
>;
export const updateEmployeeTransfer = /* GraphQL */ `mutation UpdateEmployeeTransfer(
  $input: UpdateEmployeeTransferInput!
  $condition: ModelEmployeeTransferConditionInput
) {
  updateEmployeeTransfer(input: $input, condition: $condition) {
    id
    splitId
    employeeId
    amount
    status
    createdAt
    updatedAt
    employee {
      id
      slug
      firstName
      lastName
      bio
      picture
      location {
        searchName
        address
        address2
        city
        region
        postcode
        country
        latitude
        longitude
        __typename
      }
      createdAt
      updatedAt
      business {
        id
        verified
        name
        tipping {
          merchantId
          merchant
          policy
          type
          active
          __typename
        }
        marketing {
          customerId
          subscriptionId
          active
          expirationDate
          sequenceId
          cancelled
          cancelledAt
          __typename
        }
        createdAt
        updatedAt
        owner
        code
        answers {
          questionId
          value
          __typename
        }
        isOutlet
        details {
          id
          contactName
          jobRole
          phone
          email
          website
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        ownerProfile {
          id
          slug
          firstName
          lastName
          bio
          picture
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          archived
          details {
            id
            accountType
            isOnboardingComplete
            email
            feedRegion
            tippingAccount {
              code
              merchant
              accountId
              verified
              missingRequirements {
                key
                description
                __typename
              }
              pendingValidation
              bankDetails {
                name
                sortCode
                last4Digits
                __typename
              }
              __typename
            }
            phone_number
            phoneNumberConfirmed
            pushNotificationToken
            pushNotificationReadDate
            createdAt
            updatedAt
            __typename
          }
          employers {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          experiences {
            items {
              id
              jobTitle
              businessId
              companyName
              profileId
              startDate
              endDate
              employmentType
              location
              description
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          jobs {
            items {
              id
              profileId
              jobId
              coverLetter
              saved
              savedDate
              applied
              appliedDate
              createdAt
              updatedAt
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              job {
                id
                owner
                jobType
                businessId
                jobRole
                title
                description
                region
                requireCV
                requireCoverLetter
                createdAt
                archived
                startDate
                endDate
                updatedAt
                __typename
              }
              rejected
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        invites {
          items {
            id
            businessId
            receiver
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        employees {
          items {
            id
            jobRole
            businessId
            profileId
            employeeId
            tippingPolicyAcknowledgedDate
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            confirmed
            __typename
          }
          nextToken
          __typename
        }
        brands {
          items {
            id
            businessId
            brandId
            status
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profileId
            brand {
              id
              name
              logo
              active
              productType
              createdAt
              updatedAt
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      archived
      details {
        id
        accountType
        isOnboardingComplete
        email
        feedRegion
        tippingAccount {
          code
          merchant
          accountId
          verified
          missingRequirements {
            key
            description
            __typename
          }
          pendingValidation
          bankDetails {
            name
            sortCode
            last4Digits
            __typename
          }
          __typename
        }
        phone_number
        phoneNumberConfirmed
        pushNotificationToken
        pushNotificationReadDate
        createdAt
        updatedAt
        __typename
      }
      employers {
        items {
          id
          jobRole
          businessId
          profileId
          employeeId
          tippingPolicyAcknowledgedDate
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          confirmed
          __typename
        }
        nextToken
        __typename
      }
      experiences {
        items {
          id
          jobTitle
          businessId
          companyName
          profileId
          startDate
          endDate
          employmentType
          location
          description
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      jobs {
        items {
          id
          profileId
          jobId
          coverLetter
          saved
          savedDate
          applied
          appliedDate
          createdAt
          updatedAt
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          job {
            id
            owner
            jobType
            businessId
            jobRole
            title
            description
            salary {
              type
              from
              to
              __typename
            }
            region
            requireCV
            requireCoverLetter
            createdAt
            archived
            startDate
            endDate
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            reported {
              items {
                id
                postId
                userId
                commentId
                jobId
                creatorId
                createdAt
                message
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          rejected
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateEmployeeTransferMutationVariables,
  APITypes.UpdateEmployeeTransferMutation
>;
export const deleteEmployeeTransfer = /* GraphQL */ `mutation DeleteEmployeeTransfer(
  $input: DeleteEmployeeTransferInput!
  $condition: ModelEmployeeTransferConditionInput
) {
  deleteEmployeeTransfer(input: $input, condition: $condition) {
    id
    splitId
    employeeId
    amount
    status
    createdAt
    updatedAt
    employee {
      id
      slug
      firstName
      lastName
      bio
      picture
      location {
        searchName
        address
        address2
        city
        region
        postcode
        country
        latitude
        longitude
        __typename
      }
      createdAt
      updatedAt
      business {
        id
        verified
        name
        tipping {
          merchantId
          merchant
          policy
          type
          active
          __typename
        }
        marketing {
          customerId
          subscriptionId
          active
          expirationDate
          sequenceId
          cancelled
          cancelledAt
          __typename
        }
        createdAt
        updatedAt
        owner
        code
        answers {
          questionId
          value
          __typename
        }
        isOutlet
        details {
          id
          contactName
          jobRole
          phone
          email
          website
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        ownerProfile {
          id
          slug
          firstName
          lastName
          bio
          picture
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          archived
          details {
            id
            accountType
            isOnboardingComplete
            email
            feedRegion
            tippingAccount {
              code
              merchant
              accountId
              verified
              missingRequirements {
                key
                description
                __typename
              }
              pendingValidation
              bankDetails {
                name
                sortCode
                last4Digits
                __typename
              }
              __typename
            }
            phone_number
            phoneNumberConfirmed
            pushNotificationToken
            pushNotificationReadDate
            createdAt
            updatedAt
            __typename
          }
          employers {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          experiences {
            items {
              id
              jobTitle
              businessId
              companyName
              profileId
              startDate
              endDate
              employmentType
              location
              description
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          jobs {
            items {
              id
              profileId
              jobId
              coverLetter
              saved
              savedDate
              applied
              appliedDate
              createdAt
              updatedAt
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              job {
                id
                owner
                jobType
                businessId
                jobRole
                title
                description
                region
                requireCV
                requireCoverLetter
                createdAt
                archived
                startDate
                endDate
                updatedAt
                __typename
              }
              rejected
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        invites {
          items {
            id
            businessId
            receiver
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        employees {
          items {
            id
            jobRole
            businessId
            profileId
            employeeId
            tippingPolicyAcknowledgedDate
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            confirmed
            __typename
          }
          nextToken
          __typename
        }
        brands {
          items {
            id
            businessId
            brandId
            status
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profileId
            brand {
              id
              name
              logo
              active
              productType
              createdAt
              updatedAt
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      archived
      details {
        id
        accountType
        isOnboardingComplete
        email
        feedRegion
        tippingAccount {
          code
          merchant
          accountId
          verified
          missingRequirements {
            key
            description
            __typename
          }
          pendingValidation
          bankDetails {
            name
            sortCode
            last4Digits
            __typename
          }
          __typename
        }
        phone_number
        phoneNumberConfirmed
        pushNotificationToken
        pushNotificationReadDate
        createdAt
        updatedAt
        __typename
      }
      employers {
        items {
          id
          jobRole
          businessId
          profileId
          employeeId
          tippingPolicyAcknowledgedDate
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          confirmed
          __typename
        }
        nextToken
        __typename
      }
      experiences {
        items {
          id
          jobTitle
          businessId
          companyName
          profileId
          startDate
          endDate
          employmentType
          location
          description
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      jobs {
        items {
          id
          profileId
          jobId
          coverLetter
          saved
          savedDate
          applied
          appliedDate
          createdAt
          updatedAt
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          job {
            id
            owner
            jobType
            businessId
            jobRole
            title
            description
            salary {
              type
              from
              to
              __typename
            }
            region
            requireCV
            requireCoverLetter
            createdAt
            archived
            startDate
            endDate
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            reported {
              items {
                id
                postId
                userId
                commentId
                jobId
                creatorId
                createdAt
                message
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          rejected
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteEmployeeTransferMutationVariables,
  APITypes.DeleteEmployeeTransferMutation
>;
export const createTipTransaction = /* GraphQL */ `mutation CreateTipTransaction(
  $input: CreateTipTransactionInput!
  $condition: ModelTipTransactionConditionInput
) {
  createTipTransaction(input: $input, condition: $condition) {
    id
    splitId
    receiverId
    amount
    fee
    currency
    createdAt
    status
    type
    source
    payoutInvoiceId
    transactionId
    updatedAt
    receiver {
      id
      slug
      firstName
      lastName
      bio
      picture
      location {
        searchName
        address
        address2
        city
        region
        postcode
        country
        latitude
        longitude
        __typename
      }
      createdAt
      updatedAt
      business {
        id
        verified
        name
        tipping {
          merchantId
          merchant
          policy
          type
          active
          __typename
        }
        marketing {
          customerId
          subscriptionId
          active
          expirationDate
          sequenceId
          cancelled
          cancelledAt
          __typename
        }
        createdAt
        updatedAt
        owner
        code
        answers {
          questionId
          value
          __typename
        }
        isOutlet
        details {
          id
          contactName
          jobRole
          phone
          email
          website
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        ownerProfile {
          id
          slug
          firstName
          lastName
          bio
          picture
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          archived
          details {
            id
            accountType
            isOnboardingComplete
            email
            feedRegion
            tippingAccount {
              code
              merchant
              accountId
              verified
              missingRequirements {
                key
                description
                __typename
              }
              pendingValidation
              bankDetails {
                name
                sortCode
                last4Digits
                __typename
              }
              __typename
            }
            phone_number
            phoneNumberConfirmed
            pushNotificationToken
            pushNotificationReadDate
            createdAt
            updatedAt
            __typename
          }
          employers {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          experiences {
            items {
              id
              jobTitle
              businessId
              companyName
              profileId
              startDate
              endDate
              employmentType
              location
              description
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          jobs {
            items {
              id
              profileId
              jobId
              coverLetter
              saved
              savedDate
              applied
              appliedDate
              createdAt
              updatedAt
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              job {
                id
                owner
                jobType
                businessId
                jobRole
                title
                description
                region
                requireCV
                requireCoverLetter
                createdAt
                archived
                startDate
                endDate
                updatedAt
                __typename
              }
              rejected
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        invites {
          items {
            id
            businessId
            receiver
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        employees {
          items {
            id
            jobRole
            businessId
            profileId
            employeeId
            tippingPolicyAcknowledgedDate
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            confirmed
            __typename
          }
          nextToken
          __typename
        }
        brands {
          items {
            id
            businessId
            brandId
            status
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profileId
            brand {
              id
              name
              logo
              active
              productType
              createdAt
              updatedAt
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      archived
      details {
        id
        accountType
        isOnboardingComplete
        email
        feedRegion
        tippingAccount {
          code
          merchant
          accountId
          verified
          missingRequirements {
            key
            description
            __typename
          }
          pendingValidation
          bankDetails {
            name
            sortCode
            last4Digits
            __typename
          }
          __typename
        }
        phone_number
        phoneNumberConfirmed
        pushNotificationToken
        pushNotificationReadDate
        createdAt
        updatedAt
        __typename
      }
      employers {
        items {
          id
          jobRole
          businessId
          profileId
          employeeId
          tippingPolicyAcknowledgedDate
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          confirmed
          __typename
        }
        nextToken
        __typename
      }
      experiences {
        items {
          id
          jobTitle
          businessId
          companyName
          profileId
          startDate
          endDate
          employmentType
          location
          description
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      jobs {
        items {
          id
          profileId
          jobId
          coverLetter
          saved
          savedDate
          applied
          appliedDate
          createdAt
          updatedAt
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          job {
            id
            owner
            jobType
            businessId
            jobRole
            title
            description
            salary {
              type
              from
              to
              __typename
            }
            region
            requireCV
            requireCoverLetter
            createdAt
            archived
            startDate
            endDate
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            reported {
              items {
                id
                postId
                userId
                commentId
                jobId
                creatorId
                createdAt
                message
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          rejected
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateTipTransactionMutationVariables,
  APITypes.CreateTipTransactionMutation
>;
export const updateTipTransaction = /* GraphQL */ `mutation UpdateTipTransaction(
  $input: UpdateTipTransactionInput!
  $condition: ModelTipTransactionConditionInput
) {
  updateTipTransaction(input: $input, condition: $condition) {
    id
    splitId
    receiverId
    amount
    fee
    currency
    createdAt
    status
    type
    source
    payoutInvoiceId
    transactionId
    updatedAt
    receiver {
      id
      slug
      firstName
      lastName
      bio
      picture
      location {
        searchName
        address
        address2
        city
        region
        postcode
        country
        latitude
        longitude
        __typename
      }
      createdAt
      updatedAt
      business {
        id
        verified
        name
        tipping {
          merchantId
          merchant
          policy
          type
          active
          __typename
        }
        marketing {
          customerId
          subscriptionId
          active
          expirationDate
          sequenceId
          cancelled
          cancelledAt
          __typename
        }
        createdAt
        updatedAt
        owner
        code
        answers {
          questionId
          value
          __typename
        }
        isOutlet
        details {
          id
          contactName
          jobRole
          phone
          email
          website
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        ownerProfile {
          id
          slug
          firstName
          lastName
          bio
          picture
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          archived
          details {
            id
            accountType
            isOnboardingComplete
            email
            feedRegion
            tippingAccount {
              code
              merchant
              accountId
              verified
              missingRequirements {
                key
                description
                __typename
              }
              pendingValidation
              bankDetails {
                name
                sortCode
                last4Digits
                __typename
              }
              __typename
            }
            phone_number
            phoneNumberConfirmed
            pushNotificationToken
            pushNotificationReadDate
            createdAt
            updatedAt
            __typename
          }
          employers {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          experiences {
            items {
              id
              jobTitle
              businessId
              companyName
              profileId
              startDate
              endDate
              employmentType
              location
              description
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          jobs {
            items {
              id
              profileId
              jobId
              coverLetter
              saved
              savedDate
              applied
              appliedDate
              createdAt
              updatedAt
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              job {
                id
                owner
                jobType
                businessId
                jobRole
                title
                description
                region
                requireCV
                requireCoverLetter
                createdAt
                archived
                startDate
                endDate
                updatedAt
                __typename
              }
              rejected
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        invites {
          items {
            id
            businessId
            receiver
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        employees {
          items {
            id
            jobRole
            businessId
            profileId
            employeeId
            tippingPolicyAcknowledgedDate
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            confirmed
            __typename
          }
          nextToken
          __typename
        }
        brands {
          items {
            id
            businessId
            brandId
            status
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profileId
            brand {
              id
              name
              logo
              active
              productType
              createdAt
              updatedAt
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      archived
      details {
        id
        accountType
        isOnboardingComplete
        email
        feedRegion
        tippingAccount {
          code
          merchant
          accountId
          verified
          missingRequirements {
            key
            description
            __typename
          }
          pendingValidation
          bankDetails {
            name
            sortCode
            last4Digits
            __typename
          }
          __typename
        }
        phone_number
        phoneNumberConfirmed
        pushNotificationToken
        pushNotificationReadDate
        createdAt
        updatedAt
        __typename
      }
      employers {
        items {
          id
          jobRole
          businessId
          profileId
          employeeId
          tippingPolicyAcknowledgedDate
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          confirmed
          __typename
        }
        nextToken
        __typename
      }
      experiences {
        items {
          id
          jobTitle
          businessId
          companyName
          profileId
          startDate
          endDate
          employmentType
          location
          description
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      jobs {
        items {
          id
          profileId
          jobId
          coverLetter
          saved
          savedDate
          applied
          appliedDate
          createdAt
          updatedAt
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          job {
            id
            owner
            jobType
            businessId
            jobRole
            title
            description
            salary {
              type
              from
              to
              __typename
            }
            region
            requireCV
            requireCoverLetter
            createdAt
            archived
            startDate
            endDate
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            reported {
              items {
                id
                postId
                userId
                commentId
                jobId
                creatorId
                createdAt
                message
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          rejected
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateTipTransactionMutationVariables,
  APITypes.UpdateTipTransactionMutation
>;
export const createBusinessSetupTippingInvite = /* GraphQL */ `mutation CreateBusinessSetupTippingInvite(
  $input: CreateBusinessSetupTippingInviteInput!
  $condition: ModelBusinessSetupTippingInviteConditionInput
) {
  createBusinessSetupTippingInvite(input: $input, condition: $condition) {
    id
    sender
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateBusinessSetupTippingInviteMutationVariables,
  APITypes.CreateBusinessSetupTippingInviteMutation
>;
export const updateBusinessSetupTippingInvite = /* GraphQL */ `mutation UpdateBusinessSetupTippingInvite(
  $input: UpdateBusinessSetupTippingInviteInput!
  $condition: ModelBusinessSetupTippingInviteConditionInput
) {
  updateBusinessSetupTippingInvite(input: $input, condition: $condition) {
    id
    sender
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateBusinessSetupTippingInviteMutationVariables,
  APITypes.UpdateBusinessSetupTippingInviteMutation
>;
export const deleteBusinessSetupTippingInvite = /* GraphQL */ `mutation DeleteBusinessSetupTippingInvite(
  $input: DeleteBusinessSetupTippingInviteInput!
  $condition: ModelBusinessSetupTippingInviteConditionInput
) {
  deleteBusinessSetupTippingInvite(input: $input, condition: $condition) {
    id
    sender
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteBusinessSetupTippingInviteMutationVariables,
  APITypes.DeleteBusinessSetupTippingInviteMutation
>;
export const createUserMessage = /* GraphQL */ `mutation CreateUserMessage(
  $input: CreateUserMessageInput!
  $condition: ModelUserMessageConditionInput
) {
  createUserMessage(input: $input, condition: $condition) {
    id
    senderId
    receiverId
    content
    createdAt
    updatedAt
    sender {
      id
      slug
      firstName
      lastName
      bio
      picture
      location {
        searchName
        address
        address2
        city
        region
        postcode
        country
        latitude
        longitude
        __typename
      }
      createdAt
      updatedAt
      business {
        id
        verified
        name
        tipping {
          merchantId
          merchant
          policy
          type
          active
          __typename
        }
        marketing {
          customerId
          subscriptionId
          active
          expirationDate
          sequenceId
          cancelled
          cancelledAt
          __typename
        }
        createdAt
        updatedAt
        owner
        code
        answers {
          questionId
          value
          __typename
        }
        isOutlet
        details {
          id
          contactName
          jobRole
          phone
          email
          website
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        ownerProfile {
          id
          slug
          firstName
          lastName
          bio
          picture
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          archived
          details {
            id
            accountType
            isOnboardingComplete
            email
            feedRegion
            tippingAccount {
              code
              merchant
              accountId
              verified
              missingRequirements {
                key
                description
                __typename
              }
              pendingValidation
              bankDetails {
                name
                sortCode
                last4Digits
                __typename
              }
              __typename
            }
            phone_number
            phoneNumberConfirmed
            pushNotificationToken
            pushNotificationReadDate
            createdAt
            updatedAt
            __typename
          }
          employers {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          experiences {
            items {
              id
              jobTitle
              businessId
              companyName
              profileId
              startDate
              endDate
              employmentType
              location
              description
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          jobs {
            items {
              id
              profileId
              jobId
              coverLetter
              saved
              savedDate
              applied
              appliedDate
              createdAt
              updatedAt
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              job {
                id
                owner
                jobType
                businessId
                jobRole
                title
                description
                region
                requireCV
                requireCoverLetter
                createdAt
                archived
                startDate
                endDate
                updatedAt
                __typename
              }
              rejected
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        invites {
          items {
            id
            businessId
            receiver
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        employees {
          items {
            id
            jobRole
            businessId
            profileId
            employeeId
            tippingPolicyAcknowledgedDate
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            confirmed
            __typename
          }
          nextToken
          __typename
        }
        brands {
          items {
            id
            businessId
            brandId
            status
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            profileId
            brand {
              id
              name
              logo
              active
              productType
              createdAt
              updatedAt
              __typename
            }
            __typename
          }
          nextToken
          __typename
        }
        __typename
      }
      archived
      details {
        id
        accountType
        isOnboardingComplete
        email
        feedRegion
        tippingAccount {
          code
          merchant
          accountId
          verified
          missingRequirements {
            key
            description
            __typename
          }
          pendingValidation
          bankDetails {
            name
            sortCode
            last4Digits
            __typename
          }
          __typename
        }
        phone_number
        phoneNumberConfirmed
        pushNotificationToken
        pushNotificationReadDate
        createdAt
        updatedAt
        __typename
      }
      employers {
        items {
          id
          jobRole
          businessId
          profileId
          employeeId
          tippingPolicyAcknowledgedDate
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          confirmed
          __typename
        }
        nextToken
        __typename
      }
      experiences {
        items {
          id
          jobTitle
          businessId
          companyName
          profileId
          startDate
          endDate
          employmentType
          location
          description
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          __typename
        }
        nextToken
        __typename
      }
      jobs {
        items {
          id
          profileId
          jobId
          coverLetter
          saved
          savedDate
          applied
          appliedDate
          createdAt
          updatedAt
          profile {
            id
            slug
            firstName
            lastName
            bio
            picture
            location {
              searchName
              address
              address2
              city
              region
              postcode
              country
              latitude
              longitude
              __typename
            }
            createdAt
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            archived
            details {
              id
              accountType
              isOnboardingComplete
              email
              feedRegion
              tippingAccount {
                code
                merchant
                accountId
                verified
                pendingValidation
                __typename
              }
              phone_number
              phoneNumberConfirmed
              pushNotificationToken
              pushNotificationReadDate
              createdAt
              updatedAt
              __typename
            }
            employers {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            experiences {
              items {
                id
                jobTitle
                businessId
                companyName
                profileId
                startDate
                endDate
                employmentType
                location
                description
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            jobs {
              items {
                id
                profileId
                jobId
                coverLetter
                saved
                savedDate
                applied
                appliedDate
                createdAt
                updatedAt
                rejected
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          job {
            id
            owner
            jobType
            businessId
            jobRole
            title
            description
            salary {
              type
              from
              to
              __typename
            }
            region
            requireCV
            requireCoverLetter
            createdAt
            archived
            startDate
            endDate
            updatedAt
            business {
              id
              verified
              name
              tipping {
                merchantId
                merchant
                policy
                type
                active
                __typename
              }
              marketing {
                customerId
                subscriptionId
                active
                expirationDate
                sequenceId
                cancelled
                cancelledAt
                __typename
              }
              createdAt
              updatedAt
              owner
              code
              answers {
                questionId
                value
                __typename
              }
              isOutlet
              details {
                id
                contactName
                jobRole
                phone
                email
                website
                createdAt
                updatedAt
                __typename
              }
              ownerProfile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              invites {
                nextToken
                __typename
              }
              employees {
                nextToken
                __typename
              }
              brands {
                nextToken
                __typename
              }
              __typename
            }
            reported {
              items {
                id
                postId
                userId
                commentId
                jobId
                creatorId
                createdAt
                message
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          rejected
          __typename
        }
        nextToken
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserMessageMutationVariables,
  APITypes.CreateUserMessageMutation
>;
export const createUserPhoneValidation = /* GraphQL */ `mutation CreateUserPhoneValidation(
  $input: CreateUserPhoneValidationInput!
  $condition: ModelUserPhoneValidationConditionInput
) {
  createUserPhoneValidation(input: $input, condition: $condition) {
    id
    userId
    phoneNumber
    code
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserPhoneValidationMutationVariables,
  APITypes.CreateUserPhoneValidationMutation
>;
export const updateUserPhoneValidation = /* GraphQL */ `mutation UpdateUserPhoneValidation(
  $input: UpdateUserPhoneValidationInput!
  $condition: ModelUserPhoneValidationConditionInput
) {
  updateUserPhoneValidation(input: $input, condition: $condition) {
    id
    userId
    phoneNumber
    code
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserPhoneValidationMutationVariables,
  APITypes.UpdateUserPhoneValidationMutation
>;
export const deleteUserPhoneValidation = /* GraphQL */ `mutation DeleteUserPhoneValidation(
  $input: DeleteUserPhoneValidationInput!
  $condition: ModelUserPhoneValidationConditionInput
) {
  deleteUserPhoneValidation(input: $input, condition: $condition) {
    id
    userId
    phoneNumber
    code
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteUserPhoneValidationMutationVariables,
  APITypes.DeleteUserPhoneValidationMutation
>;
export const createPayoutInvoice = /* GraphQL */ `mutation CreatePayoutInvoice(
  $input: CreatePayoutInvoiceInput!
  $condition: ModelPayoutInvoiceConditionInput
) {
  createPayoutInvoice(input: $input, condition: $condition) {
    id
    createdAt
    file
    amount
    fee
    currency
    status
    payedAt
    updatedAt
    tipTransactions {
      items {
        id
        splitId
        receiverId
        amount
        fee
        currency
        createdAt
        status
        type
        source
        payoutInvoiceId
        transactionId
        updatedAt
        receiver {
          id
          slug
          firstName
          lastName
          bio
          picture
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          archived
          details {
            id
            accountType
            isOnboardingComplete
            email
            feedRegion
            tippingAccount {
              code
              merchant
              accountId
              verified
              missingRequirements {
                key
                description
                __typename
              }
              pendingValidation
              bankDetails {
                name
                sortCode
                last4Digits
                __typename
              }
              __typename
            }
            phone_number
            phoneNumberConfirmed
            pushNotificationToken
            pushNotificationReadDate
            createdAt
            updatedAt
            __typename
          }
          employers {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          experiences {
            items {
              id
              jobTitle
              businessId
              companyName
              profileId
              startDate
              endDate
              employmentType
              location
              description
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          jobs {
            items {
              id
              profileId
              jobId
              coverLetter
              saved
              savedDate
              applied
              appliedDate
              createdAt
              updatedAt
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              job {
                id
                owner
                jobType
                businessId
                jobRole
                title
                description
                region
                requireCV
                requireCoverLetter
                createdAt
                archived
                startDate
                endDate
                updatedAt
                __typename
              }
              rejected
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePayoutInvoiceMutationVariables,
  APITypes.CreatePayoutInvoiceMutation
>;
export const updatePayoutInvoice = /* GraphQL */ `mutation UpdatePayoutInvoice(
  $input: UpdatePayoutInvoiceInput!
  $condition: ModelPayoutInvoiceConditionInput
) {
  updatePayoutInvoice(input: $input, condition: $condition) {
    id
    createdAt
    file
    amount
    fee
    currency
    status
    payedAt
    updatedAt
    tipTransactions {
      items {
        id
        splitId
        receiverId
        amount
        fee
        currency
        createdAt
        status
        type
        source
        payoutInvoiceId
        transactionId
        updatedAt
        receiver {
          id
          slug
          firstName
          lastName
          bio
          picture
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          archived
          details {
            id
            accountType
            isOnboardingComplete
            email
            feedRegion
            tippingAccount {
              code
              merchant
              accountId
              verified
              missingRequirements {
                key
                description
                __typename
              }
              pendingValidation
              bankDetails {
                name
                sortCode
                last4Digits
                __typename
              }
              __typename
            }
            phone_number
            phoneNumberConfirmed
            pushNotificationToken
            pushNotificationReadDate
            createdAt
            updatedAt
            __typename
          }
          employers {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          experiences {
            items {
              id
              jobTitle
              businessId
              companyName
              profileId
              startDate
              endDate
              employmentType
              location
              description
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          jobs {
            items {
              id
              profileId
              jobId
              coverLetter
              saved
              savedDate
              applied
              appliedDate
              createdAt
              updatedAt
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              job {
                id
                owner
                jobType
                businessId
                jobRole
                title
                description
                region
                requireCV
                requireCoverLetter
                createdAt
                archived
                startDate
                endDate
                updatedAt
                __typename
              }
              rejected
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdatePayoutInvoiceMutationVariables,
  APITypes.UpdatePayoutInvoiceMutation
>;
export const deletePayoutInvoice = /* GraphQL */ `mutation DeletePayoutInvoice(
  $input: DeletePayoutInvoiceInput!
  $condition: ModelPayoutInvoiceConditionInput
) {
  deletePayoutInvoice(input: $input, condition: $condition) {
    id
    createdAt
    file
    amount
    fee
    currency
    status
    payedAt
    updatedAt
    tipTransactions {
      items {
        id
        splitId
        receiverId
        amount
        fee
        currency
        createdAt
        status
        type
        source
        payoutInvoiceId
        transactionId
        updatedAt
        receiver {
          id
          slug
          firstName
          lastName
          bio
          picture
          location {
            searchName
            address
            address2
            city
            region
            postcode
            country
            latitude
            longitude
            __typename
          }
          createdAt
          updatedAt
          business {
            id
            verified
            name
            tipping {
              merchantId
              merchant
              policy
              type
              active
              __typename
            }
            marketing {
              customerId
              subscriptionId
              active
              expirationDate
              sequenceId
              cancelled
              cancelledAt
              __typename
            }
            createdAt
            updatedAt
            owner
            code
            answers {
              questionId
              value
              __typename
            }
            isOutlet
            details {
              id
              contactName
              jobRole
              phone
              email
              website
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              __typename
            }
            ownerProfile {
              id
              slug
              firstName
              lastName
              bio
              picture
              location {
                searchName
                address
                address2
                city
                region
                postcode
                country
                latitude
                longitude
                __typename
              }
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              archived
              details {
                id
                accountType
                isOnboardingComplete
                email
                feedRegion
                phone_number
                phoneNumberConfirmed
                pushNotificationToken
                pushNotificationReadDate
                createdAt
                updatedAt
                __typename
              }
              employers {
                nextToken
                __typename
              }
              experiences {
                nextToken
                __typename
              }
              jobs {
                nextToken
                __typename
              }
              __typename
            }
            invites {
              items {
                id
                businessId
                receiver
                createdAt
                updatedAt
                __typename
              }
              nextToken
              __typename
            }
            employees {
              items {
                id
                jobRole
                businessId
                profileId
                employeeId
                tippingPolicyAcknowledgedDate
                createdAt
                updatedAt
                confirmed
                __typename
              }
              nextToken
              __typename
            }
            brands {
              items {
                id
                businessId
                brandId
                status
                createdAt
                updatedAt
                profileId
                __typename
              }
              nextToken
              __typename
            }
            __typename
          }
          archived
          details {
            id
            accountType
            isOnboardingComplete
            email
            feedRegion
            tippingAccount {
              code
              merchant
              accountId
              verified
              missingRequirements {
                key
                description
                __typename
              }
              pendingValidation
              bankDetails {
                name
                sortCode
                last4Digits
                __typename
              }
              __typename
            }
            phone_number
            phoneNumberConfirmed
            pushNotificationToken
            pushNotificationReadDate
            createdAt
            updatedAt
            __typename
          }
          employers {
            items {
              id
              jobRole
              businessId
              profileId
              employeeId
              tippingPolicyAcknowledgedDate
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              confirmed
              __typename
            }
            nextToken
            __typename
          }
          experiences {
            items {
              id
              jobTitle
              businessId
              companyName
              profileId
              startDate
              endDate
              employmentType
              location
              description
              createdAt
              updatedAt
              business {
                id
                verified
                name
                createdAt
                updatedAt
                owner
                code
                isOutlet
                __typename
              }
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              __typename
            }
            nextToken
            __typename
          }
          jobs {
            items {
              id
              profileId
              jobId
              coverLetter
              saved
              savedDate
              applied
              appliedDate
              createdAt
              updatedAt
              profile {
                id
                slug
                firstName
                lastName
                bio
                picture
                createdAt
                updatedAt
                archived
                __typename
              }
              job {
                id
                owner
                jobType
                businessId
                jobRole
                title
                description
                region
                requireCV
                requireCoverLetter
                createdAt
                archived
                startDate
                endDate
                updatedAt
                __typename
              }
              rejected
              __typename
            }
            nextToken
            __typename
          }
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeletePayoutInvoiceMutationVariables,
  APITypes.DeletePayoutInvoiceMutation
>;
