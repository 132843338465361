import i18next from "i18next";
import EN_GB from "./translations/EN-GB.json";

// copied from enterprise-app codebase and fixed with copilot
export default function setupTranslations() {
  i18next.init({
    lng: "en-UK",
    debug: true,
    resources: { "en-UK": EN_GB },
    interpolation: {
      format: (
        value: string,
        raw: string = "",
        locale?: string,
        options = {}
      ) => {
        const [format, ...additionalValues] = raw.split(", ");

        switch (format) {
          case "uppercase":
            return value.toUpperCase();
          case "lowercase":
            return value.toLowerCase();
          case "capitalize":
            return value.charAt(0).toUpperCase() + value.slice(1);
          case "datetime":
            const date = new Date(value);
            if (isNaN(date.getTime())) {
              return value; // If date is invalid, return original value
            }

            const formatOptions: {
              year?: "numeric" | "2-digit";
              month?: "numeric" | "2-digit" | "long" | "short" | "narrow";
              day?: "numeric" | "2-digit";
              hour?: "numeric" | "2-digit";
              minute?: "numeric" | "2-digit";
              hour12?: boolean;
            } = {
              year: "numeric",
              month: "numeric",
              day: "numeric",
            };

            if (!options?.short) {
              formatOptions.hour = "numeric";
              formatOptions.minute = "numeric";
              formatOptions.hour12 = true;
            }

            return date.toLocaleDateString(locale, formatOptions);
          case "currency":
            const [currency = "GBP"] = additionalValues;
            return new Intl.NumberFormat(locale, {
              style: "currency",
              currency: options?.currency || currency,

              // These options are needed to round to whole numbers if that's what you want.
              // minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
              maximumFractionDigits: Number.isNaN(options?.digits)
                ? 2
                : options.digits, // (causes 2500.99 to be printed as $2,501)
            }).format(parseFloat(value));
          default:
            return value;
        }
      },
    },
  });
}
