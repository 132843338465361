import {
  IonButton,
  IonIcon,
  IonItem,
  IonLabel,
  useIonAlert,
} from "@ionic/react";
import "./PaymentModalHeader.css";
import { informationCircleOutline } from "ionicons/icons";
import { t } from "i18next";

interface IProps {
  amount: number;
  fee: number;
  isFeeCovered: boolean;
}

const PaymentModalHeader = ({ amount, isFeeCovered, fee }: IProps) => {
  const [presentAlert] = useIonAlert();

  const FEE_ALERT_CONTENT = {
    header: t("Why are there transaction fees?"),
    message: t("Fee Alert Message"),
    buttons: [t("Got it")],
  };

  return (
    <IonItem className="modal-header" lines="none">
      <IonLabel>
        <strong>
          {t("{{amount}}", { amount: isFeeCovered ? amount + fee : amount })}{" "}
        </strong>
      </IonLabel>
      <IonButton
        onClick={() => presentAlert(FEE_ALERT_CONTENT)}
        fill="clear"
        color="dark"
        slot="end"
        className="ion-no-padding ion-no-margin"
      >
        <IonIcon icon={informationCircleOutline} slot="icon-only" />
      </IonButton>
    </IonItem>
  );
};

export default PaymentModalHeader;
