import {
  IonButton,
  IonChip,
  IonCol,
  IonContent,
  IonFooter,
  IonGrid,
  IonHeader,
  IonModal,
  IonRow,
  IonText,
  IonTitle,
  IonToolbar,
  useIonViewWillLeave,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import Header from "../../../../common/Header/Header";
import { callSharp, locationSharp, mailOpenSharp } from "ionicons/icons";
import IconList from "../../../../common/IconList/IconList";
import { IS_MOBILE } from "../../../../../utils";
import QRCodesModal from "../QRCodesModal/QRCodesModal";
import { Profile } from "../../../../../types/DBTypes";
import {
  getEmployeeSlug,
  getLocation,
  getProfileIsVerified,
} from "../../../../../utils/profileController";
import "./IndividualModal.css";
import { ModalBreakpointChangeEventDetail } from "@ionic/core";
import { IonModalCustomEvent } from "@ionic/core/dist/types/components";
import Avatar from "../../../../common/Avatar/Avatar";
import ItemOutlet from "../../../outlets/components/ItemOutlet/ItemOutlet";
import { t } from "i18next";

interface IProps {
  user?: Profile;
  setUser: (user?: Profile) => void;
}

const IndividualModal = ({ user, setUser }: IProps) => {
  const [modalFull, setModalFull] = useState(false);
  const [QRCodesArray, setQRCodeArray] = useState<any[]>([]);

  useEffect(() => {
    const QRCodesArrayNew = [];
    if (!user) return;

    const employeeSlug = getEmployeeSlug(user);
    if (employeeSlug) {
      QRCodesArrayNew.push({
        path: employeeSlug,
        badge: "employee",
      });
    }

    setQRCodeArray(QRCodesArrayNew);
  }, [user]);

  useIonViewWillLeave(() => {
    setUser(undefined);
  });

  const handleBreakpointChange = (
    e: IonModalCustomEvent<ModalBreakpointChangeEventDetail>
  ) => {
    setModalFull(e.detail.breakpoint === 1);
  };
  if (!user) return null;

  const { slug, details, firstName, employers, lastName, picture } = user;

  return (
    <IonModal
      isOpen={!!user}
      initialBreakpoint={0.5}
      breakpoints={[0, 0.5, 0.75, 1]}
      onIonBreakpointDidChange={handleBreakpointChange}
      className={`individual-modal ${
        modalFull ? "individual-modal--full" : ""
      }`}
      onDidDismiss={() => setUser(undefined)}
    >
      <IonHeader>
        <IonToolbar>
          <Avatar url={picture} slot="start" />
          <IonTitle>
            {firstName} {lastName}
            {slug && (
              <IonText>
                <span>@{slug}</span>
              </IonText>
            )}
            <div>
              {details?.feedRegion && <IonChip>{details?.feedRegion}</IonChip>}
              {getProfileIsVerified(user) && <IonChip>Verified</IonChip>}
            </div>
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding-horizontal">
        <IonGrid>
          <IonRow>
            <IonCol sizeSm="8" sizeXs="12">
              <Header title={t("Contacts")} />
              <IconList
                data={[
                  { icon: callSharp, text: details?.phone_number },
                  { icon: mailOpenSharp, text: details?.email },
                  {
                    icon: locationSharp,
                    text: getLocation(user),
                  },
                ]}
              />
            </IonCol>
          </IonRow>

          {employers?.items && employers.items.length > 0 && (
            <IonRow>
              <IonCol sizeSm="8" sizeXs="12">
                <Header title={t("Employers")} />
                {employers?.items?.map(
                  (empl) =>
                    empl?.business && (
                      <ItemOutlet
                        owner={empl.business.ownerProfile}
                        outlet={empl.business}
                      />
                    )
                )}
              </IonCol>
            </IonRow>
          )}
        </IonGrid>
      </IonContent>
      {IS_MOBILE && QRCodesArray.length > 0 && (
        <IonFooter>
          <IonButton expand="full" color="medium" id="open-qr-modal">
            Show QR code
          </IonButton>
          <QRCodesModal data={QRCodesArray} />
        </IonFooter>
      )}
    </IonModal>
  );
};

export default IndividualModal;
