import React, { useEffect, useState } from "react";
import { IonSpinner } from "@ionic/react";
import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import ButtonWide from "../../../../common/ButtonWide/ButtonWide";
import "./PaymentForm.css";
import { t } from "i18next";

interface TProps {
  successUrl: string;
  totalAmount: number;
}

const PaymentForm = ({ successUrl, totalAmount }: TProps) => {
  const stripe = useStripe();
  const elements = useElements();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      elements?.fetchUpdates();
    }, 1000);
  }, [elements, totalAmount]);

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    const handleLoadingStop = () => setTimeout(() => setLoading(false), 1000);

    if (!stripe || !elements) return;

    setLoading(true);
    stripe
      .confirmPayment({
        //`Elements` instance that was used to create the Payment Element
        elements,
        confirmParams: {
          return_url: successUrl,
        },
      })
      .then(handleLoadingStop)
      .catch(handleLoadingStop);
  };

  return (
    <form className="payment-wrapper" onSubmit={handleSubmit}>
      <PaymentElement />
      <ButtonWide type="submit">
        {loading ? <IonSpinner /> : <>{t("Pay {{amount}}", { amount: totalAmount })}</>}
      </ButtonWide>
    </form>
  );
};

export default PaymentForm;
