import { IonContent, IonModal, useIonModal } from "@ionic/react";
import BankCardModal from "../BankCardModal/BankCardModal";
import React, { useContext, useLayoutEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import { connect } from "react-redux";
import { cardOutline } from "ionicons/icons";
import ButtonWide from "../../../../common/ButtonWide/ButtonWide";
import { TRootState } from "../../../../../store/store";
import { getTippingType } from "../../../../../store/selectors/tipping.selectors";
import "./PaymentModal.css";
import PaymentModalHeader from "../PaymentModalHeader/PaymentModalHeader";
import { PaymentModalContext } from "../PaymentModalProvider/PaymentModalProvider";
import { t } from "i18next";

interface TProps {
  amount: number;
  isFeeCovered: boolean;
  fee: number;
  isActive: boolean;
  tippingType: string;
}

const mapState = (state: TRootState) => ({
  tippingType: getTippingType(state),
});

const PaymentModal = ({
  amount,
  isFeeCovered,
  fee,
  isActive,
  tippingType,
}: TProps) => {
  // @ts-ignore
  const { slug, employeeSlug } = useParams();
  const contentRef = useRef<HTMLDivElement>();
  const { clientSecret, paymentIntent } = useContext(PaymentModalContext);

  const [breakpoints, setBreakpoints] = useState<number[]>([]);

  useLayoutEffect(() => {
    if (!contentRef?.current) return;

    const { scrollHeight, children } = contentRef?.current;
    if (scrollHeight < 10) return;

    const calcBreakpoint = (height: number, gap: number) =>
      +((height + gap) / window.innerHeight).toFixed(2);

    const breakpointOpen = calcBreakpoint(scrollHeight, 35);
    const breakpointClose = calcBreakpoint(children[0].scrollHeight, 15);

    setBreakpoints([breakpointClose, breakpointOpen]);
  }, [contentRef?.current?.scrollHeight]);

  const presentModalOptions = {
    breakpoints: [0, 550 / window.innerHeight, 1],
    initialBreakpoint: 500 / window.innerHeight,
    cssClass: "card-modal-wrapper",
  };

  const [presentBankModal, onDismissBankModal] = useIonModal(BankCardModal, {
    onDismiss: (data: string, role: string) => onDismissBankModal(data, role),
    clientSecret,
    totalAmount: amount + (isFeeCovered ? fee : 0),
    slug: tippingType === "group" ? slug : employeeSlug,
    paymentIntent,
  });

  const openBankCardModal = () => presentBankModal(presentModalOptions);
  const Content = (
    <>
      <PaymentModalHeader
        amount={amount}
        fee={fee}
        isFeeCovered={isFeeCovered}
      />
      <ButtonWide onClick={openBankCardModal} icon={cardOutline}>
        {t("Tip")}
      </ButtonWide>
    </>
  );

  return (
    <>
      {breakpoints?.length > 0 && (
        <IonModal
          className="payment-modal"
          // onDidDismiss={() => setActive(false);)}
          isOpen={isActive}
          initialBreakpoint={breakpoints[1]}
          breakpoints={breakpoints}
          backdropBreakpoint={breakpoints[1]}
          backdropDismiss={false}
        >
          <IonContent className="ion-padding modal-content">
            {Content}
          </IonContent>
        </IonModal>
      )}
      <div
        //@ts-ignore Used to calculate real height of modalcontent
        ref={contentRef}
        style={{ opacity: 0, pointerEvents: "none", marginTop: "2.5rem" }}
      >
        {Content}
      </div>
    </>
  );
};

export default connect(mapState)(PaymentModal);
