import { useContext } from "react";
import { IonButton, IonItem, IonLabel } from "@ionic/react";
import { FieldContext } from "./EnterpriseUserCreate";
import { useAppDispatch } from "../../../../../store/store";
import { createProfileEnterprise } from "../../../../../store/actions/enterprise.actions";
import FieldItem from "../../components/FieldItem/FieldItem";
import { useHistory } from "react-router";
import { t } from "i18next";

const Step4 = () => {
  const history = useHistory();
  const call = useAppDispatch();
  const context = useContext(FieldContext);
  if (!context) return null;
  const { fields, setTitle } = context;
  setTitle(t("Review and submit"));

  const handleSubmit = () => {
    call(createProfileEnterprise({ input: fields }));
    history.push("/enterprise");
  };

  return (
    <>
      <FieldItem title={t("Company Name")}>{fields.name}</FieldItem>
      <FieldItem title={t("Company Registered number")}>
        {fields.companyNumber}
      </FieldItem>
      <FieldItem title={t("Address line 1")}>{fields.address.address}</FieldItem>
      <FieldItem title={t("Address line 2")}>{fields.address.address2}</FieldItem>
      <FieldItem title={t("City")}>{fields.address.city}</FieldItem>
      <FieldItem title={t("Region")}>{fields.address.region}</FieldItem>
      <FieldItem title={t("Postal code")}>{fields.address.postcode}</FieldItem>
      <FieldItem title={t("Country")}>{fields.address.country}</FieldItem>
      <FieldItem title={t("Contact Name")}>{fields.contactDetails.name}</FieldItem>
      <FieldItem title={t("Contact Job Role")}>
        {fields.contactDetails.role}
      </FieldItem>
      <FieldItem title={t("Contact Email")}>{fields.contactDetails.email}</FieldItem>
      <FieldItem title={t("Contact Phone")}>{fields.contactDetails.phone}</FieldItem>
      <FieldItem title={t("Email for account")}>{fields.email}</FieldItem>
      <IonItem>
        <IonLabel slot="end">
          <IonButton onClick={handleSubmit}>{t("Submit")}</IonButton>
        </IonLabel>
      </IonItem>
    </>
  );
};
export default Step4;
