import React, { useCallback } from "react";
import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonChip,
  IonImg,
} from "@ionic/react";
import { QRCode as QRCodeComponent } from "react-qrcode-logo";
import logo from "../../../../../assets/images/logo.svg";
import { useAppDispatch } from "../../../../../store/store";
import { SHOW_TOAST } from "../../../../../store/actions/ui.actions";
import useClipboard from "../../../../../utils/useClipboard";

import "./QRCode.css";
import { t } from "i18next";

interface TProps {
  path: string;
  badge?: string;
}

const QRCode = ({ path, badge }: TProps) => {
  const call = useAppDispatch();
  const fullPath = `https://tip.tipinc.tech/${path}`;
  const copyToClipboard = useClipboard();
  const handleLinkClick = useCallback(
    () => copyToClipboard({ url: fullPath }),
    [fullPath, copyToClipboard]
  );

  const shareData = useCallback(async () => {
    navigator.share({ url: fullPath, text: fullPath }).catch(() =>
      call({
        type: SHOW_TOAST,
        payload: { text: t("Your browser does not support sharing") },
      })
    );
  }, [call, fullPath]);

  return (
    <IonCard className="qrcode-wrapper">
      <div onClick={shareData} className={"qrcode-element"}>
        {/*@ts-ignore unresolved error 'QRCode' cannot be used as a JSX component.*/}
        <QRCodeComponent
          value={fullPath}
          logoImage={logo}
          size={300}
          logoPadding={8}
        />
      </div>
      {badge && (
        <IonCardHeader>
          <IonChip color={"danger"}>{badge}</IonChip>
        </IonCardHeader>
      )}
      <IonCardContent onClick={handleLinkClick}>
        <IonImg src={logo} className="path-img" />
        <span>{"/" + path}</span>
      </IonCardContent>
    </IonCard>
  );
};

export default QRCode;
