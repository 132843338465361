import { Business, SearchBusinesssQuery } from "../../../types/DBTypes";
import { useEffect, useState } from "react";
import {
  IonList,
  IonSearchbar,
  SearchbarInputEventDetail,
} from "@ionic/react";
import { IonSearchbarCustomEvent } from "@ionic/core/dist/types/components";
import { API } from "aws-amplify";
import { searchBusinesss } from "../../../graphql/queries";
import BusinessItem from "../BusinessItem/BusinessItem";
import { t } from "i18next";

interface IProps {
  onSelect: (value: Business | null) => void;
  value: Business | null;
}

const BusinessDropdown = ({ value, onSelect }: IProps) => {
  const [searchString, setSearchString] = useState<string>("");
  const [businessList, setBusinessList] = useState<Business[]>([]);

  useEffect(() => {
    handleProfileSearch();
    if (!searchString) onSelect(null);
  }, [searchString]);

  const handleProfileSearch = async () => {
    const business = await API.graphql<SearchBusinesssQuery>({
      query: searchBusinesss,
      variables: {
        filter: {
          name: { matchPhrasePrefix: searchString },
        },
      },
    });

    // @ts-ignore
    const { items } = business.data.searchBusinesss;
    setBusinessList(items);
  };
  const handleInput = (
    event: IonSearchbarCustomEvent<SearchbarInputEventDetail>
  ) => {
    setSearchString(event.detail.value || "");
  };

  return (
    <>
      <IonSearchbar
        debounce={1000}
        placeholder={t("Search by profile")}
        value={searchString}
        onIonInput={handleInput}
      />

      <IonList>
        {businessList.map((business: Business) => (
          <BusinessItem
            item={business}
            key={business.id}
            color={business.id === value?.id ? "primary" : undefined}
            // @ts-ignore
            onClick={() => onSelect(business)}
          />
        ))}
      </IonList>
    </>
  );
};

export default BusinessDropdown;
