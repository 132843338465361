import React, { useEffect, useState } from "react";
import {
  IonContent,
  IonModal,
  IonPage,
  IonSpinner,
  IonText,
} from "@ionic/react";
import "./TippingResult.css";
import ProfileHeader from "../../components/ProfileHeader/ProfileHeader";
import { useParams } from "react-router";
import { TRootState } from "../../../../../store/store";
import {
  loadTippingInfo as loadTippingInfoAction,
  saveTippingTip as saveTippingTipAction,
} from "../../../../../store/actions/tipping.actions";
import { connect } from "react-redux";
import { getTippingInfo } from "../../../../../store/selectors/tipping.selectors";
import {
  TSaveTippingTipPayload,
  TTippingData,
} from "../../../../../store/types/tipping.types";
import Confetti from "../../components/Confetti/Confetti";
import Receipt from "../../components/Receipt/Receipt";
import { requestController } from "../../../../../utils";
import { t } from "i18next";

interface TProps {
  data: TTippingData;
  saveTippingTip: (data: TSaveTippingTipPayload) => void;
  loadTippingInfo: (slug: string) => void;
}

const mapState = (state: TRootState) => ({
  data: getTippingInfo(state),
});
const mapProps = (dispatch: any) => ({
  saveTippingTip: (data: TSaveTippingTipPayload) =>
    dispatch(saveTippingTipAction(data)),
  loadTippingInfo: (slug: string) => dispatch(loadTippingInfoAction({ slug })),
});

interface TReceipt {
  amount: number;
  fee: number;
  feeIncluded: boolean;
  id: string;
  createdAt: string;
}

const TippingResult = ({ data, saveTippingTip, loadTippingInfo }: TProps) => {
  const { slug } = useParams<{ slug: string }>();

  const [receipt, setReceipt] = useState<TReceipt | null>(null);

  useEffect(() => {
    loadTippingInfo(slug);

    const urlParams = new URLSearchParams(window.location.search);
    const paymentIntent = urlParams.get("payment_intent");
    requestController
      .get(`/api/tipping/transactions/${paymentIntent}`)
      .then(({ data }) => {
        const { amount, fee, id, createdAt } = data;
        setReceipt({ amount, fee, feeIncluded: false, id, createdAt });
      });
  }, [loadTippingInfo, slug]);

  if (!data)
    return (
      <IonPage>
        <IonContent>
          <IonSpinner
            color="dark"
            name="crescent"
            className="tipping-result--loading"
          />
        </IonContent>
      </IonPage>
    );

  const { name, picture, type } = data;

  const calcPoint = (height: number) =>
    Math.floor((height / window.innerHeight) * 100) / 100;
  const breakpoints = [calcPoint(70), calcPoint(190), 0.9];

  return (
    <IonPage>
      <IonContent className="tipping-result" fullscreen>
        <Confetti />
        <ProfileHeader
          title={
            <>
              {t("Thanks!")}
              <br />
              {t("You just tipped {{name}}", { name })}
            </>
          }
          // @ts-ignore
          subtitle={data?.role || data?.location}
          picture={picture}
        />
        <IonText className="tipping-result-footer-content">
          <p>
            {t("100% of your tip has gone directly to {{recipient}}", { recipient: type === "group" ? t("team") : name })}
          </p>
        </IonText>
        {receipt && (
          <IonModal
            isOpen
            breakpoints={breakpoints}
            initialBreakpoint={breakpoints[1]}
            backdropDismiss={false}
          >
            <Receipt
              amount={+receipt.amount / 100}
              fee={receipt.fee / 100}
              feeIncluded={receipt.feeIncluded}
              transactionId={receipt.id}
              createdAt={receipt.createdAt}
            />
          </IonModal>
        )}
      </IonContent>
    </IonPage>
  );
};

export default connect(mapState, mapProps)(TippingResult);
