import { IonContent, IonFooter, IonPage } from "@ionic/react";
import React, { useEffect } from "react";
import ProfileHeader from "../../tipping/components/ProfileHeader/ProfileHeader";
import ButtonWide from "../../../common/ButtonWide/ButtonWide";
import { useParams } from "react-router";
import { t } from "i18next";

const ConnectCompleted = () => {
  const { type } = useParams<{ type: string }>();

  useEffect(() => {
    let ua = navigator.userAgent.toLowerCase();
    let isAndroid = ua.indexOf("android") > -1; // android check
    let isIphone = ua.indexOf("iphone") > -1; // ios check

    if (!isIphone && !isAndroid) return;

    handleAppOpen();
  }, []);

  const handleAppOpen = () => {
    window.location.href = "tipinc://app";
  };

  return (
    <IonPage className="page--outlet-profile">
      <IonContent>
        <ProfileHeader
          title={
            type === "completed"
              ? t("You are now connected")
              : t("Please, return back to the app")
          }
          subtitle={
            type === "completed"
              ? t("You can now close this window and go back to the app")
              : t("Start registration again")
          }
          src={require("../../../../assets/images/stripe.webp")}
        />
      </IonContent>
      <IonFooter>
        <ButtonWide onClick={handleAppOpen}>Open app</ButtonWide>
      </IonFooter>
    </IonPage>
  );
};

export default ConnectCompleted;
