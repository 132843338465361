import { put, takeLatest } from "redux-saga/effects";
import { TLoadIndividualsPayload } from "../types/individuals.types";
import { requestController } from "../../utils";
import { TAction } from "../store";
import { Simulate } from "react-dom/test-utils";
import {
  GET_OUTLET_BY_SLUG,
  GET_OUTLET_STATISTICS,
  getOutletBySlugSuccess,
  getOutletStatisticsSuccess,
  LOAD_OUTLETS,
  loadOutletsSuccess,
  UPDATE_BUSINESS,
  updateBusinessSuccess,
} from "../actions/outlets.actions";
import { getOutletsNextToken } from "../selectors/outlets.selectors";
import select = Simulate.select;
import { API } from "aws-amplify";
import { updateBusiness } from "../../graphql/mutations";
import {
  TGetOutletBySlugPayload,
  TGetOutletStatisticsPayload,
  TUpdateBusinessPayload,
} from "../types/outlets.types";
import safe from "../utils/safe";
import { getProfilesBySlug } from "./queries";

function* loadOutletsSaga({ payload }: TAction<TLoadIndividualsPayload>) {
  const { regions, verified, notVerified, createdAfter } = payload;

  // @ts-ignore TODO types
  const nextToken = yield select(getOutletsNextToken);

  const params: { [key: string]: string } = {};
  if (regions?.length) params.regions = regions.map(encodeURI).join(",");
  if (verified) params.verified = verified.toString();
  if (notVerified) params.notVerified = notVerified.toString();
  if (createdAfter) params.createdAfter = createdAfter;
  if (nextToken) params.nextToken = nextToken;

  const { data } = yield requestController.get(
    "/api/profiles/outlets",
    params,
    true
  );

  yield put(loadOutletsSuccess({ data }));
}

function* updateBusinessSaga({ payload }: TAction<TUpdateBusinessPayload>) {
  const { input } = payload;

  // @ts-ignore
  if (input.tipping && input.tipping.__typename)
    // @ts-ignore
    delete input.tipping.__typename;

  const { data } = yield API.graphql({
    query: updateBusiness,
    variables: {
      input,
    },
  });

  yield put(updateBusinessSuccess({ data: data?.updateBusiness }));
}

function* getOutletStatisticsSaga({
  payload,
}: TAction<TGetOutletStatisticsPayload>) {
  const { id } = payload;

  const { data } = yield requestController.get(
    "/api/profiles/statistics",
    {
      id,
    },
    true
  );

  yield put(getOutletStatisticsSuccess({ data }));
}

function* getOutletBySlugSaga({ payload }: TAction<TGetOutletBySlugPayload>) {
  const { slug } = payload;

  const { data } = yield API.graphql({
    query: getProfilesBySlug,
    variables: {
      slug,
    },
  });

  const outlet = data?.getProfilesBySlug?.items[0];

  yield put(getOutletBySlugSuccess({ data: outlet }));
}

export default function* outletsSagas() {
  yield takeLatest(LOAD_OUTLETS, safe(loadOutletsSaga));
  yield takeLatest(UPDATE_BUSINESS, safe(updateBusinessSaga));
  yield takeLatest(GET_OUTLET_STATISTICS, safe(getOutletStatisticsSaga));
  yield takeLatest(GET_OUTLET_BY_SLUG, safe(getOutletBySlugSaga));
}
